import { useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { ChoiceOption, Modal, ModalBox } from '../atoms';
import ChooseModal from '../atoms/Modal/ChooseModal';
import { InitialsModal } from '../pages/account/components';
import { Signature } from '.';

type Props = {
  isOpenChoiceModal: boolean;
  setIsOpenChoiceModal: (arg0: boolean) => void;
  refreshSignature: () => void;
  userName: string;
  title?: string;
  onAddSignature?: () => void;
};

function ChooseSignatureModal({
  isOpenChoiceModal,
  setIsOpenChoiceModal,
  refreshSignature,
  userName,
  title,
  onAddSignature,
}: Props) {
  const [isOpenUpdateSign, setIsOpenUpdateSign] = useState(false);
  const [isOpenInitialSign, setIsOpenInitialSign] = useState(false);

  return (
    <>
      <ChooseModal
        modalIsOpen={isOpenChoiceModal}
        setModalIsOpen={setIsOpenChoiceModal}
        title={title || 'Select type of signature'}
      >
        <>
          <ChoiceOption
            onClick={() => {
              setIsOpenChoiceModal(false);
              setIsOpenUpdateSign(true);
            }}
            imageSrc={SVG.pencil}
            description="Draw a signature"
          />

          <ChoiceOption
            onClick={() => {
              setIsOpenChoiceModal(false);
              setIsOpenInitialSign(true);
            }}
            imageSrc={SVG.addedUser}
            description="Signature with name & surname"
          />
        </>
      </ChooseModal>

      <Modal
        modalIsOpen={isOpenUpdateSign}
        setModalIsOpen={setIsOpenInitialSign}
      >
        <ModalBox
          title="Add a signature"
          onCloseButtonClick={() => setIsOpenUpdateSign(false)}
        >
          <StyledSignature
            updateUser={() => {
              setIsOpenUpdateSign(false);
              refreshSignature();
              onAddSignature && onAddSignature();
            }}
          />
        </ModalBox>
      </Modal>

      <Modal
        modalIsOpen={isOpenInitialSign}
        setModalIsOpen={setIsOpenInitialSign}
      >
        <ModalBox
          title="Add a signature"
          onCloseButtonClick={() => setIsOpenInitialSign(false)}
        >
          <InitialsModal
            name={userName}
            updateUser={() => {
              setIsOpenInitialSign(false);
              refreshSignature();
              onAddSignature && onAddSignature();
            }}
          />
        </ModalBox>
      </Modal>
    </>
  );
}

const StyledSignature = styled(Signature)`
  margin: 0 auto;
`;

export default ChooseSignatureModal;
