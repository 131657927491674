import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  contacts as apiContacts,
  ContactType,
  dashboard as apiDashboard,
  members as apiMember,
  nda as apiNda,
  OneProjectType,
  OneSignerGroup,
  projectBranches as apiProjectBranches,
  ProjectBranchType,
  projects as apiProjects,
  signers as apiSigners,
} from '../../../../api';
import { DotsLoading, Select, SelectOption } from '../../../../atoms';
import CardWrapper from '../../../../atoms/CardWrapper';
import FormInput from '../../../../atoms/FormInput';
import SolidButton from '../../../../atoms/SolidButton';
import { Errors } from '../../../../const';
import { isEmail, routeTemplate } from '../../../../helpers';
import { useNotification, usePermissions } from '../../../../hooks';
import SwitchControl from '../../../../molecules/SwitchControl';
import { Routes } from '../../../../routes';
import { Colors, Fonts, rem } from '../../../../themes';
import GroupContacts from './GroupContacts';
import { GroupContact } from './types';

type Props = {
  addNewGroup?: (newGroup: OneSignerGroup) => void;
  addContact?: (contact: ContactType) => void;
  updateContact?: (contact: ContactType) => void;
  goToExistingGroups: () => void;
  allUserContacts: ContactType[];
  defaultValue: any;
  branchInfo: ProjectBranchType | null;
};
type Params = {
  projectId: string;
  branchId?: string;
};

type NewBranch = {
  name: string;
  contactGroupId: number;
  type: string;
  allowComments: boolean;
  sendTeaser: boolean;
};

function CreateGroupBlock({
  addNewGroup,
  goToExistingGroups,
  allUserContacts,
  defaultValue,
  branchInfo,
}: Props) {
  const [userMembers, setUserMembers] = useState<string[]>([]);
  const schema = yup.object().shape({
    groupName: yup.string().label('Group Name').required(),
    contact: yup.array().of(
      yup.object().shape({
        firstName: yup.string().label('First Name').required(),
        lastName: yup.string().label('Last Name').required(),
        email: yup
          .string()
          .label('Email')
          .required()
          .test({
            name: 'is-email',
            message: 'Invalid email format',
            test: (value: string | undefined) =>
              value ? isEmail(value) : true,
          })
          .test({
            name: 'is-email-static',
            message: Errors.RecipientContact,
            test: value => (value ? !userMembers.includes(value) : true),
          }),
        userId: yup.number(),
      }),
    ),
    nda: yup.boolean(),
    teaser: yup.boolean(),
  });
  const { showError } = useNotification();
  const {
    canAddSignergroup,
    canAddProjectbranch,
    canChangeSignergroup,
    canViewSignergroup,
  } = usePermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState<SelectOption[]>([]);
  const { projectId, branchId } = useParams<Params>();
  const history = useHistory();
  const [project, setProject] = useState<OneProjectType | null>(null);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contact',
  });

  useEffect(() => {
    canViewSignergroup &&
      apiSigners.getSignersGroups().then(({ data }) => {
        const groupOptions: any = [];
        const contactOptions: any = [];
        allUserContacts.map(con => {
          const group = data.find(d => d.users.includes(con.user.pk));
          if (group) {
            groupOptions.push({
              label: `${group.name} : ${con.firstName} ${con.firstName}`,
              value: con.user.pk.toString(),
              users: group.users,
              contact_id: con.id,
              groupName: group.name,
            });
          }
          contactOptions.push({
            label: `${con.firstName} ${con.firstName}`,
            value: con.user.pk.toString(),
            contact_id: con.id,
            firstName: con.firstName,
            lastName: con.lastName,
            email: con.user.email,
          });
        });
        setFilteredContacts(groupOptions.concat(contactOptions));
      });
  }, [canViewSignergroup]);

  useEffect(() => {
    apiMember.getAllMembers().then(({ data }) => {
      setUserMembers(data.map(d => d.email));
    });
  }, []);
  useEffect(() => {
    const getProjectDetails = async () => {
      const response = await apiProjects.getOneProject(Number(projectId));
      setProject(response.data);
    };
    getProjectDetails();
  }, [projectId]);

  const addEditContact = async (con: GroupContact) => {
    try {
      const payload = {
        firstName: con.firstName,
        email: con.email,
        lastName: con.lastName,
      };
      if (con.userId) {
        const { data: editContact } = await apiContacts.updateContact(
          con.userId,
          payload,
        );
        return editContact.user.pk;
      }
      const { data: contact } = await apiContacts.createContact(payload);
      return contact.user.pk;
    } catch (error) {
      const { data } = error as any;

      if (data) {
        if (Array.isArray(data.nonFieldErrors)) {
          showError(data.nonFieldErrors[0]);
        }
        if (data.detail) {
          showError(data.detail);
        }
        if (Array.isArray(data.members)) {
          showError(data.members[0]);
        }
        return 0;
      }
      return 0;
    }
  };
  const createNewBranch = async (payload: NewBranch): Promise<boolean> => {
    if (!canAddProjectbranch) return false;
    const { name, contactGroupId, type, sendTeaser, allowComments } = payload;
    const { data: createdBranch } =
      await apiProjectBranches.createProjectBranch({
        name,
        project: Number(projectId),
        signerGroups: [contactGroupId],
        inheritNdaFromProject: true,
        sendTeaser,
        allowComments,
      });

    if (type === 'save') {
      const { data: projectData } = await apiProjects.getOneProject(
        Number(projectId),
      );
      const { data: projectNda } = await apiNda.getNdaByProjectId(
        Number(projectId),
      );
      await apiDashboard.sendNdaToAllSigners(createdBranch.id, {
        projectBranch: createdBranch.id,
        note: projectData.summary || 'Invited',
        subject: `${projectData.name} | ${projectNda.results[0].name}`,
      });
      history.push(`${Routes.projects}/${projectId}`);
    } else {
      const path = routeTemplate(Routes.reviewNda, {
        projectId,
        branchId: createdBranch.id.toString(),
      });
      history.push(path);
    }
    return true;
  };
  const createNewGroup = (formData: any, type: string) => {
    if (!canAddSignergroup) return;

    if (isLoading) return;

    setIsLoading(true);
    const uniqueContact = formData.contact.map((contact: any) => {
      if (!contact.userId) {
        const duplicateEmail = allUserContacts.find(
          (data: any) => data.user.email === contact.email,
        );
        if (duplicateEmail) {
          return {
            ...contact,
            userId: duplicateEmail?.id,
          };
        }
        return contact;
      }
      return contact;
    });

    const contactPromises = uniqueContact.map(addEditContact);

    Promise.all(contactPromises)
      .then(userIds => {
        const filteredUserIds = userIds.filter(id => id !== 0);
        if (filteredUserIds) {
          return apiSigners.createSignersGroup({
            name: formData.groupName,
            users: filteredUserIds,
          });
        }
        return null;
      })
      .then(async ({ data }: any) => {
        if (data) {
          addNewGroup && addNewGroup(data);
          await createNewBranch({
            name: formData.groupName,
            contactGroupId: data.id,
            type,
            sendTeaser: formData.sendTeaser,
            allowComments: formData.allowComments,
          });
        }
      })
      .catch(({ data }) => {
        if (data.note) showError(data.note[0]);
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.users) showError(data.users[0]);
      })
      .finally(() => setIsLoading(false));
  };

  const handleOnChange = (option: any) => {
    if (fields.find((con: any) => !con.email)) {
      remove(0);
    }
    if (option.users) {
      setValue('groupName', option.groupName);
      option.users.map((id: number) => {
        const contact: any = allUserContacts.find(
          group => group.user.pk === id,
        );
        if (!fields.find((con: any) => con.email === contact.user.email)) {
          append({
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.user.email,
            userId: contact.id,
          });
        }
      });
    } else if (!fields.find((con: any) => con.email === option.email)) {
      append({
        firstName: option.firstName,
        lastName: option.lastName,
        email: option.email,
        userId: option.contact_id,
      });
    }
  };

  const handleUpdateRecipients = (formData: any) => {
    if (!branchInfo) return;
    if (isLoading) return;
    if (!canChangeSignergroup) return;

    const uniqueContact = formData.contact.map((contact: any) => {
      if (!contact.userId) {
        const duplicateEmail = allUserContacts.find(
          (data: any) => data.user.email === contact.email,
        );
        if (duplicateEmail) {
          return {
            ...contact,
            userId: duplicateEmail?.id,
          };
        }
        return contact;
      }
      return contact;
    });

    const contactPromises = uniqueContact.map(addEditContact);
    Promise.all(contactPromises)
      .then(userIds => {
        const filteredUserIds = userIds.filter(id => id !== 0);
        if (filteredUserIds) {
          apiSigners
            .updateSignersGroup(branchInfo.signerGroups[0], {
              name: formData.groupName,
              users: filteredUserIds,
            })
            .then(data => {
              history.push(`${Routes.projects}/${projectId}`);
            })
            .catch(data => {
              showError(data.message);
              if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
              if (data.detail) showError(data.detail);
              if (data.users) showError(data.users[0]);
            });
        }
      })
      .catch(data => {
        showError(data.message);
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.users) showError(data.users[0]);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledForm>
      <CardWrapper title={`${branchId ? 'Edit' : 'Add'} Recipients`}>
        <>
          {/* <InfoBlock>
            <p>
              Create a group to organize recipients by company, department, or
              other criteria that work for you.
            </p>

            <span>Created groups before? Pick the </span>
            <LinkToExisting onClick={goToExistingGroups}>
              existing group.
            </LinkToExisting>
          </InfoBlock> */}

          <StyledSelect
            options={filteredContacts}
            value={null}
            onChange={option => handleOnChange(option as SelectOption)}
            placeholder="Search your existing contacts or groups"
          />
          <InputBox>
            {isLoading && <DotsLoading />}

            <Title>Step 1. Company or Group Name</Title>

            <FormInput
              disabled={isLoading}
              placeholder="Name *"
              ref={register('groupName')}
              name="groupName"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue('groupName', e.target.value)
              }
              value={watch('groupName')}
              error={errors.groupName?.message as string}
            />
          </InputBox>

          <Title>Step 2. Add Recipients</Title>
          <GroupContacts
            fields={fields}
            register={register}
            setValue={setValue}
            errors={errors}
            remove={remove}
            append={append}
          />

          <BottomContainer>
            <SwitchControl
              label="Allow recipients comments on the NDA"
              labelPlacement="start"
              controlName="allowComments"
              onSwitchChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue('allowComments', e.target.checked)
              }
              {...register('allowComments')}
            />
            {project?.teaser === null ? (
              <Tooltip title="Teaser is not added" arrow>
                <span>
                  <SwitchControl
                    label="Add the Project teasers"
                    labelPlacement="start"
                    controlName="sendTeaser"
                    onSwitchChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setValue('sendTeaser', e.target.checked)
                    }
                    {...register('sendTeaser')}
                    disabled={project?.teaser === null}
                  />
                </span>
              </Tooltip>
            ) : (
              <SwitchControl
                label="Add the Project teasers"
                labelPlacement="start"
                controlName="sendTeaser"
                onSwitchChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setValue('sendTeaser', e.target.checked)
                }
                {...register('sendTeaser')}
              />
            )}
          </BottomContainer>
        </>
      </CardWrapper>
      <ButtonContainer>
        {branchId && branchInfo ? (
          <StyledSubmitButton
            isLoading={isLoading}
            type="submit"
            text={isLoading ? 'Update...' : 'Update'}
            onClick={handleSubmit(data => handleUpdateRecipients(data))}
          />
        ) : (
          <>
            <StyledButton
              type="button"
              text="Cancel"
              onClick={() => history.push(`${Routes.projects}/${projectId}`)}
            />
            <StyledSubmitButton
              isLoading={isLoading}
              type="submit"
              text="Send"
              onClick={handleSubmit(data => createNewGroup(data, 'save'))}
            />
            <StyledSubmitButton
              isLoading={isLoading}
              type="submit"
              text="Review and Send"
              onClick={handleSubmit(data => createNewGroup(data, 'review'))}
            />
          </>
        )}
      </ButtonContainer>
    </StyledForm>
  );
}

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const StyledForm = styled.form`
  padding: 0 10px 10px;
`;

// const LinkToExisting = styled.span`
//   text-decoration: underline;
//   cursor: pointer;

//   &:hover {
//     color: ${Colors.LightBlue};
//   }
// `;

const InputBox = styled.div`
  width: 450px;
  margin: 10px 0;
`;

const Title = styled.p`
  margin-bottom: 10px;
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px;
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: 18px;
`;

const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: 18px;
  width: auto;
  padding-inline: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 35px;
`;

export default CreateGroupBlock;
