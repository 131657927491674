import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Fonts, rem, removeScrollStyles } from '../themes';

const StyledContainer = styled(ToastContainer).attrs({})`
  ${removeScrollStyles}

  .Toastify__toast {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(8)};
    padding: 0 5px;
    min-height: 40px;
  }

  .Toastify__close-button {
    align-self: center;
  }
`;

function useNotification() {
  const settings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const showInfo = (message: string) =>
    toast.info(message, {
      ...(settings as ToastOptions),
      icon: () => (
        <img src={SVG.eventMessage} width="20px" alt="event message" />
      ),
    });

  const showSuccess = (message: string) =>
    toast.success(message, {
      ...(settings as ToastOptions),
      icon: () => (
        <img src={SVG.successMessage} width="20px" alt="success message" />
      ),
    });

  const showError = (message: string) =>
    toast.error(message, {
      ...(settings as ToastOptions),
      autoClose: 10000,
      icon: () => (
        <img src={SVG.errorMessage} width="20px" alt="error message" />
      ),
    });

  return {
    Notifications: StyledContainer,
    showInfo,
    showSuccess,
    showError,
  };
}

export default useNotification;
