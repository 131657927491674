import styled from 'styled-components';

import { Pointer } from '../../../api';
import { MoveButton } from '../../../atoms';
import { Colors, Fonts, rem } from '../../../themes';

interface Props {
  pageNumber: number | null;
  totalPages: number;
  setPageNumber: (prev: any) => void;
  setAccentPointer: (prop: Pointer | null) => void;
}

const Pages = ({
  pageNumber,
  setAccentPointer,
  setPageNumber,
  totalPages,
}: Props) => {
  const moveToPrevPage = () => {
    if (pageNumber === 1) return;

    setAccentPointer(null);
    setPageNumber((prev: number) => prev - 1);
  };

  const moveToNextPage = () => {
    if (!totalPages) return;
    if (pageNumber === totalPages) return;

    setAccentPointer(null);
    setPageNumber((prev: number) => prev + 1);
  };

  return (
    <PdfNav>
      <Buttons onClick={moveToPrevPage} disabled={pageNumber === 1}>
        Previous
      </Buttons>
      <PageNumber>
        {pageNumber}/{totalPages}
      </PageNumber>
      <Buttons onClick={moveToNextPage} disabled={pageNumber === totalPages}>
        Next
      </Buttons>
    </PdfNav>
  );
};

const PdfNav = styled.div`
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkGrey};

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const Buttons = styled.div<{ disabled: boolean }>`
  background-color: #e3f2ff;
  padding: 5px;
  border-radius: 5px;
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  ${props => `cursor: ${props.disabled ? 'not-allowed' : 'pointer'}`};
  ${props => `pointer-events: ${props.disabled ? 'none' : 'auto'}`};
`;

const PageNumber = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  font-weight: 700;
`;
export default Pages;
