import { AxiosResponse } from 'axios';

import api from '../authApi';
import { Reports, ReportsFilter } from './types';

const getReports = (filter: ReportsFilter): Promise<AxiosResponse<Reports>> => {
  const projectsFilter =
    filter?.projects && filter?.projects.length > 0
      ? `project__in=${filter.projects.toString()}`
      : '';
  const projectBranchesFilter =
    filter?.projectBranches && filter?.projectBranches.length > 0
      ? `project_branch__in=${filter.projectBranches.toString()}`
      : '';

  return api.get(
    `/reports/metrics/?${projectsFilter}&${projectBranchesFilter}`,
  );
};

const reports = { getReports };

export default reports;
