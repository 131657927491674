import styled, { css } from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  text: string;
  completed?: boolean;
};

function ChecklistItem({ text, completed }: Props) {
  return (
    <InnerWrapper>
      <Status completed={!!completed} />
      <StyledText>{text}</StyledText>
    </InnerWrapper>
  );
}

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 10px;
  background-color: ${Colors.White};
  width: 100%;
`;

const Status = styled.div<{ completed: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 10.5px;
  border: ${props =>
    props.completed ? 'none' : `1px solid ${Colors.LightSecondGrey}`};
  border-radius: 50%;
  background-color: ${props =>
    props.completed ? Colors.DarkBlue : Colors.Transparent};

  ${props =>
    props.completed &&
    css`
      &::before {
        position: absolute;
        top: 27%;
        left: 23%;
        display: block;
        content: '';
        width: 10.5px;
        height: 5.25px;
        border-bottom: 1px solid ${Colors.White};
        border-left: 1px solid ${Colors.White};
        transform: rotate(-45deg);
      }
    `}
`;

const StyledText = styled.div`
  display: inline-block;
  margin-top: 3.5px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.5)};
  color: ${Colors.LightSecondGrey};
`;

export default ChecklistItem;
