import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  ErrorResponse,
  EventType,
  Onboarding,
  onboarding as apiOnboarding,
  projectEvents as apiProjectEvents,
} from '../api';
import { usePermissions } from '../hooks';
import { Checklist } from '../molecules';
import { selectors } from '../store';

const initOnboarding = {
  isMembersInvited: false,
  isProjectsCreated: false,
  isNdaUploaded: false,
};

type Props = {
  setAllEvents?: (events: EventType[]) => void;
  setAllUnreadEvents?: (events: EventType[]) => void;
  updateEvents?: boolean;
};

function SideMenu({ setAllEvents, setAllUnreadEvents, updateEvents }: Props) {
  const { canViewEvent } = usePermissions();
  const user = useSelector(selectors.user);
  const { id: userId, isMember } = user;

  const [isShowOnboarding, setIsShowOnboarding] = useState(false);
  const [unreadEvents, setUnreadEvents] = useState<EventType[]>([]);
  const [userOnboarding, setUserOnboarding] =
    useState<Onboarding>(initOnboarding);

  const eventUpdateHandler = useCallback(() => {
    canViewEvent &&
      apiProjectEvents.getAllEvents().then(({ data }) => {
        const unread = data.results.filter(
          event => !event.isRead && event.authorId !== userId,
        );
        setUnreadEvents(unread);

        setAllUnreadEvents && setAllUnreadEvents(unread);
        setAllEvents && setAllEvents(data.results);
      });

    // setAllEvents and setAllUnreadEvents mast use with useCallback

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewEvent, userId]);

  useEffect(() => {
    eventUpdateHandler();
  }, [eventUpdateHandler]);

  useEffect(() => {
    if (!updateEvents) return;
    eventUpdateHandler();
  }, [eventUpdateHandler, updateEvents]);

  useEffect(() => {
    !isMember &&
      apiOnboarding
        .getOnboarding()
        .then(({ data }) => {
          setIsShowOnboarding(true);
          setUserOnboarding(data);
        })
        .catch((error: ErrorResponse) => {
          if (error.status === 403) {
            setIsShowOnboarding(false);
          }
        });
  }, [isMember]);

  return (
    <Wrapper>
      {isShowOnboarding && <Checklist onboarding={userOnboarding} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default memo(SideMenu);
