/* eslint-disable no-nested-ternary */
import { format, parseISO } from 'date-fns';
import { MouseEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { billing as apiBilling, ProductType } from '../../../../api';
import SVG from '../../../../assets/svg';
import { ButtonEllipse, Modal, ModalBox, Popover } from '../../../../atoms';
import CommonModal from '../../../../atoms/Modal/CommonModal';
import { formatDateMode, Messages } from '../../../../const';
import { useNotification, useSubscription } from '../../../../hooks';
import { Loader } from '../../../../molecules';
import { selectors, updateSubscription } from '../../../../store';
import { BoxShadow, Colors, Fonts, rem } from '../../../../themes';
import CancelTime from './CancelTime';
import {
  memberAddonDetail,
  projectAddonDetail,
  useSubscribe,
  useUpgrade,
} from './helpers';
import ProcessingAlert from './ProcessingModal';

export type SelectOptions = { value: string; label: string };

type Props = {
  chosen: ProductType | boolean;
  onePlan?: ProductType;
  freePlan?: true;
  selectedProjects: string;
  selectedMembers: string;
  selectedPeriod: string;
  description?: string;
  isDisableUpgrade: number | null;
  setSelectedProjects: (value: string) => void;
  setSelectedMembers: (value: string) => void;
  currencySymbol: string;
  mainPlan?: ProductType;
};

const PlanStatus = ({
  chosen,
  onePlan,
  freePlan,
  selectedProjects,
  selectedMembers,
  selectedPeriod,
  description,
  isDisableUpgrade,
  setSelectedProjects,
  setSelectedMembers,
  currencySymbol,
  mainPlan,
}: Props) => {
  const subscriptionName = useSubscription();
  const { showError, showSuccess } = useNotification();
  const subscription = useSelector(selectors.subscription);
  const { product: currentUserPlan } = useSelector(selectors.subscription);
  const dispatch = useDispatch();

  const [planInfoAnchor, setPlanInfoAnchor] = useState<HTMLElement | null>(
    null,
  );

  const [isConfirmBuyProject, setIsConfirmBuyProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const projectAddon = projectAddonDetail(
    currentUserPlan?.product?.addon,
    currentUserPlan?.product?.price.interval as string,
  );
  const memberAddon = memberAddonDetail(
    currentUserPlan?.product?.addon,
    currentUserPlan?.product?.price.interval as string,
  );
  const [activeStep, setActiveStep] = useState('');
  const { handleCheckout } = useSubscribe();
  const { upgradeSubscription } = useUpgrade();

  const unsubscribe = () => {
    setIsLoading(true);

    apiBilling
      .cancelSubscription()
      .then(({ data }) => {
        dispatch(
          updateSubscription({
            ...subscription,
            product: {
              ...subscription.product,
              cancelAtPeriodEnd: data.detail.cancelAtPeriodEnd,
              cancelAt: data.detail.cancelAt,
            },
          }),
        );
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenUpgradeModal = () => {
    if (!selectedMembers || parseInt(selectedMembers, 10) === 0) {
      if (
        (selectedProjects !== '&infin;' && !selectedProjects) ||
        selectedProjects === '0'
      ) {
        showError('Select quantity of Projects and Team Members to upgrade');
        return;
      }
    }

    if (isDisableUpgrade !== null) return;
    setIsConfirmBuyProject(true);
  };

  const handleUpgrade = async () => {
    if (isLoading) return;
    if (!memberAddon?.prices?.chargebeePriceId) return;
    if (
      onePlan?.name !== 'Bulge-Bracket' &&
      !projectAddon?.prices?.chargebeePriceId
    )
      return;

    setIsConfirmBuyProject(false);
    setIsLoading(true);
    try {
      const addon = [];
      if (selectedProjects && selectedProjects !== '0') {
        addon.push({
          itemPriceId: projectAddon?.prices?.chargebeePriceId,
          quantity: parseInt(selectedProjects, 10),
        });
      }
      if (selectedMembers && selectedMembers !== '0') {
        addon.push({
          itemPriceId: memberAddon?.prices?.chargebeePriceId,
          quantity: parseInt(selectedMembers, 10),
        });
      }

      await upgradeSubscription({ addon }).then(async data => {
        showSuccess(Messages.SuccessPurchase);
        setSelectedMembers('');
        setSelectedProjects('');
        dispatch(
          updateSubscription({
            ...subscription,
            product: {
              ...subscription.product,
              projectAllowed:
                (subscription.product.projectAllowed || 0) +
                Number(selectedProjects),
              quantity:
                (subscription.product.quantity || 0) + Number(selectedMembers),
            },
          }),
        );
      });
    } catch (error: any) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const projectPrice = useMemo(
    () =>
      projectAddon && projectAddon.prices
        ? projectAddon.prices.unitAmount / 100
        : 0,
    [projectAddon],
  );

  const memberPrice = useMemo(
    () =>
      memberAddon && memberAddon.prices
        ? memberAddon.prices.unitAmount / 100
        : 0,
    [memberAddon],
  );

  const totalProjectsPrice = useMemo(
    () => projectPrice * Number(selectedProjects),
    [projectPrice, selectedProjects],
  );
  const totalMembersPrice = useMemo(
    () => memberPrice * Number(selectedMembers),
    [memberPrice, selectedMembers],
  );
  const totalPrice = useMemo(() => {
    if (totalMembersPrice < 0) {
      return totalProjectsPrice;
    }
    return totalProjectsPrice + totalMembersPrice;
  }, [totalMembersPrice, totalProjectsPrice]);

  const isDisabledSetPlan = () => {
    if (subscriptionName === 'FREE') {
      return false;
    }

    if (subscription.product.cancelAtPeriodEnd) {
      return true;
    }

    return true;
  };
  const confirmDeleteAlert = () => {
    Swal.fire({
      title: 'Are you sure, you want to cancel your subscription ?',
      text: `${description ? { description } : ''}`,
      iconHtml: `
      <img src="${SVG.deleteImg}" alt="delete" width="40px" />`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      showLoaderOnConfirm: isLoading,
      preConfirm: unsubscribe,
      customClass: {
        confirmButton: 'confirm-button',
        icon: 'icon',
        title: 'title',
        container: 'container',
      },
    });
  };

  const changePlan = async () => {
    if (isLoading) return;
    if (!onePlan) return;
    const chosenPrice = onePlan.prices.find(p => p.interval === selectedPeriod);

    if (!chosenPrice) return;
    setIsLoading(true);

    const checkoutInstance = {
      data: {
        plan: chosenPrice.chargebeePriceId,
        price: chosenPrice.unitAmount,
      },
      onClose: () => {
        setIsLoading(false);
      },
      onError: (error: any) => {
        if (error?.nonFieldErrors) showError(error.nonFieldErrors);
        if (error?.detail) showError(error.detail);
      },
      onStep: (step: string) => {
        setActiveStep(step);
      },
    };

    await handleCheckout(checkoutInstance);
  };
  const isShowNote = () => {
    if (
      subscription &&
      subscription?.product &&
      subscription.product.product?.name.toLowerCase().includes('boutique') &&
      mainPlan
    ) {
      const price = mainPlan.prices.find(
        item => item.interval === currentUserPlan?.product?.price.interval,
      );
      if (!price) return false;

      return Number(selectedProjects) * projectPrice >= price.unitAmount / 100;
    }
    return false;
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Popover refElem={planInfoAnchor} offsetY={20} offsetX={-40}>
        {subscription.product.cancelAt && !chosen ? (
          <PlanInfo>
            You can set a new plan after your current subscription expires on{' '}
            <span>
              {format(
                parseISO(`${subscription.product.cancelAt}`),
                formatDateMode.dateTime,
              )}
            </span>
          </PlanInfo>
        ) : (
          <PlanInfo>
            You can set a new plan after your current subscription will be{' '}
            <span>canceled</span>
          </PlanInfo>
        )}
      </Popover>
      <Modal
        modalIsOpen={isConfirmBuyProject}
        setModalIsOpen={setIsConfirmBuyProject}
      >
        <ModalBox
          title="Change Subscription Plan"
          onCloseButtonClick={() => setIsConfirmBuyProject(false)}
        >
          <CommonModal
            totalPrice={
              subscriptionName === 'Bulge-Bracket'
                ? totalMembersPrice < 0
                  ? 0
                  : totalMembersPrice
                : totalPrice
            }
            selectedProjects={selectedProjects}
            selectedMembers={selectedMembers}
            memberPrice={memberPrice}
            projectPrice={projectPrice}
            handleUpgrade={handleUpgrade}
            setIsConfirmBuyProject={setIsConfirmBuyProject}
            isConfirmBuyProject={isConfirmBuyProject}
            isShowNote={isShowNote()}
          />
        </ModalBox>
      </Modal>

      {subscription.product.cancelAt && chosen ? (
        <CancelTime
          subscription={subscription}
          cancelAt={subscription.product.cancelAt}
        />
      ) : (
        <>
          {!freePlan && onePlan && !chosen && (
            <StyledButton
              text="Upgrade"
              onClick={changePlan}
              onPointerEnter={(event: MouseEvent<HTMLElement>) =>
                isDisabledSetPlan() && setPlanInfoAnchor(event.currentTarget)
              }
              onPointerLeave={() => setPlanInfoAnchor(null)}
              disabled={isDisabledSetPlan()}
            />
          )}
          {!freePlan && onePlan && chosen && (
            <>
              <RedButton text="Cancel" onClick={confirmDeleteAlert} />
              <StyledUpgradeButton
                text="Upgrade"
                onClick={handleOpenUpgradeModal}
                disabled={isDisableUpgrade !== null}
              />
            </>
          )}
          {freePlan && chosen && <StyledButton disabled text="Cancel" />}
          {freePlan && !chosen && <StyledButton disabled text="Upgrade" />}
        </>
      )}
      {!isLoading && activeStep === 'thankyou_screen' && (
        <ProcessingAlert
          isOpen={!isLoading && activeStep === 'thankyou_screen'}
          setIsOpen={setActiveStep}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledButton = styled(ButtonEllipse)`
  width: auto;
  height: auto;
  padding: 13px 41px;
  margin-top: 20px;

  white-space: nowrap;
  font-size: ${rem(11.25)};
  border-radius: 6px;
  border: 1px solid ${Colors.White};
  background-color: ${Colors.White};
  color: ${Colors.DarkBlue};
  cursor: pointer;

  &:disabled {
    color: ${Colors.White};
    cursor: not-allowed;

    &:hover {
      border: 1px solid ${Colors.BackgroundGrey};
    }
  }
`;

const StyledUpgradeButton = styled(StyledButton)`
  &:disabled {
    color: ${Colors.DarkBlue};
    cursor: not-allowed;
    border: 1px solid ${Colors.White};
    background-color: ${Colors.White};
    &:hover {
      color: ${Colors.DarkBlue};
      border: 1px solid ${Colors.White};
      background-color: ${Colors.White};
    }
  }
`;
const RedButton = styled(StyledButton)`
  margin-top: 20px;
  border: 1px solid ${Colors.SkySecondBlue};
  background-color: ${Colors.SkySecondBlue};

  &:hover {
    border: 1px solid ${Colors.SkySecondBlue};
    background-color: ${Colors.SkySecondBlue};
  }
`;

const PlanInfo = styled.div`
  width: 300px;
  padding: 10px 20px;

  font-size: ${rem(9)};
  border-radius: 6px;
  text-align: center;
  box-shadow: ${BoxShadow.Third};
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    transform: rotate(180deg);
    right: 175px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${Colors.White} transparent;
  }
  & > span {
    text-decoration: underline;
    color: ${Colors.DarkRed};
  }
`;
export default PlanStatus;
