import { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { members as apiMembers } from '../../../../api';
import { Modal, ModalBox, Popover } from '../../../../atoms';
import CommonModal from '../../../../atoms/Modal/CommonModal';
import { initialValue } from '../../../../helpers';
import { usePermissions } from '../../../../hooks';
import { UsersLogos } from '../../../../molecules';
import { Routes } from '../../../../routes';
import { Colors, Fonts, rem } from '../../../../themes';
import { MemberTypeChecked as Member } from './OneMember';
import TeamMembersForm from './TeamMembersForm';

type Props = {
  projectId: number;
  membersInProject?: number[] | null;
};

function TeamMembers({ projectId, membersInProject }: Props) {
  const { canChangeProject } = usePermissions();

  const [allUserMembers, setAllUserMembers] = useState<Member[]>([]);
  const [email, setEmail] = useState(initialValue);

  const history = useHistory();

  const [anchorButtons, setAnchorButtons] = useState<HTMLElement | null>(null);

  const [projectMembersId, setProjectMembersId] = useState<number[]>([]);
  const [isAddPlanQuantity, setIsAddPlanQuantity] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    apiMembers.getAllMembers().then(res => {
      const checkedMembers = res.data.map(member => ({
        ...member,
        checked: false,
      }));
      setAllUserMembers(checkedMembers);
      setEmail(initialValue);
    });
  }, []);

  useEffect(() => {
    if (membersInProject) {
      setProjectMembersId(membersInProject);
    }
  }, [membersInProject]);

  const onOverlayClick = (event: MouseEvent) => {
    if (event.currentTarget !== event.target) {
      return;
    }
    setIsOpenModal(false);
  };

  const goToPlans = () => {
    history.push(Routes.plan);
  };

  const closeModal = () => setIsAddPlanQuantity(false);
  return (
    <Wrapper>
      <ShowButton
        disabled={!canChangeProject}
        onClick={e => {
          if (allUserMembers.length) {
            canChangeProject && setIsOpenModal(true);
            setAnchorButtons(e.currentTarget);
          }
        }}
      >
        {allUserMembers.length ? (
          <UsersLogos
            showedAvatars={3}
            users={allUserMembers.map(member => ({
              logo: member.avatar,
              namesLetters: `${
                member.firstName && member.lastName
                  ? member.firstName[0] + member.lastName[0]
                  : member.email[0]
              }`,
            }))}
          />
        ) : null}
        <span style={{ marginBottom: '12px' }}>Add Team Members</span>
      </ShowButton>
      {allUserMembers.length === 0 && (
        <UpgradeButton onClick={goToPlans}>Upgrade Now</UpgradeButton>
      )}
      <Modal
        modalIsOpen={isAddPlanQuantity}
        setModalIsOpen={setIsAddPlanQuantity}
      >
        <ModalBox
          title="Add team member on current plan"
          onCloseButtonClick={() => setIsAddPlanQuantity(false)}
        >
          <CommonModal closeModal={closeModal} />
        </ModalBox>
      </Modal>

      {isOpenModal && (
        <Popover
          onOverlayClick={onOverlayClick}
          offsetY={-130}
          offsetX={215}
          refElem={anchorButtons}
        >
          <PopoverContainer>
            <TeamMembersForm
              projectId={Number(projectId)}
              isOpenModal={isOpenModal}
              setIsOpenModal={setIsOpenModal}
              setIsAddPlanQuantity={setIsAddPlanQuantity}
              projectMembersId={projectMembersId}
              setProjectMembersId={setProjectMembersId}
              setAllUserMembers={setAllUserMembers}
              allUserMembers={allUserMembers}
            />
          </PopoverContainer>
        </Popover>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  min-width: 200px;
  height: fit-content;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkBlack};
`;

const ShowButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  background: ${Colors.White};
  font-size: ${rem(12.5)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
`;

const PopoverContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 300px;
  z-index: 100000000000;
  padding: 5px;
  background-color: ${Colors.SkyBlue};
  border: 1px solid ${Colors.PaleAquaBlue};
  border-radius: 10px;
`;

const UpgradeButton = styled.button`
  border: 1px solid ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  cursor: pointer;
  background: ${Colors.DarkBlue};
  color: ${Colors.White};
  border-radius: 6px;
  padding: 10px 10px;
`;

export default TeamMembers;
