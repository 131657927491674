import { ChangeEvent, FocusEvent, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { StyledInput } from '../atoms';
import { Colors, Fonts, rem } from '../themes';
import ErrorText from './ErrorText';

type Props = {
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error: string;
  disabled?: boolean;
};

function InputPassword({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  disabled,
}: Props) {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const handleRevealButtonClick = () => {
    setIsHidden(!isHidden);
  };
  return (
    <PasswordInputWrapper>
      <StyledInputPassword
        type={isHidden ? 'password' : 'text'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {error.length > 0 && (
        <ErrorText>
          {error.split('.').map(errString => (
            <span key={errString}>
              {errString} <br />
            </span>
          ))}
        </ErrorText>
      )}
      <StyledRevealButton onClick={handleRevealButtonClick}>
        <img
          src={isHidden ? SVG.eye : SVG.hideEye}
          alt={isHidden ? 'SHOW' : 'HIDE'}
          style={{ marginRight: 7 }}
        />
      </StyledRevealButton>
    </PasswordInputWrapper>
  );
}

const PasswordInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledInputPassword = styled(StyledInput)`
  padding-right: 50px;
  overflow-x: hidden;
`;

const StyledRevealButton = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
  font-size: ${rem(7)};
  font-family: ${Fonts.Sofia};
  cursor: pointer;
  color: ${Colors.DarkBlack};
`;

export default InputPassword;
