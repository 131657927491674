export enum Permissions {
  AddNda = 'add_nda',
  ChangeNda = 'change_nda',
  DeleteNda = 'delete_nda',
  ViewNda = 'view_nda',

  AddNdatemplate = 'add_ndatemplate',
  ChangeNdatemplate = 'change_ndatemplate',
  DeleteNdatemplate = 'delete_ndatemplate',
  ViewNdatemplate = 'view_ndatemplate',

  AddProject = 'add_project',
  ChangeProject = 'change_project',
  DeleteProject = 'delete_project',
  ViewProject = 'view_project',

  AddProjectbranch = 'add_projectbranch',
  ChangeProjectbranch = 'change_projectbranch',
  DeleteProjectbranch = 'delete_projectbranch',
  ViewProjectbranch = 'view_projectbranch',

  AddSignergroup = 'add_signergroup',
  ChangeSignergroup = 'change_signergroup',
  DeleteSignergroup = 'delete_signergroup',
  ViewSignergroup = 'view_signergroup',

  AddSignership = 'add_signership',
  ChangeSignership = 'change_signership',
  DeleteSignership = 'delete_signership',
  ViewSignership = 'view_signership',

  AddPointer = 'add_pointer',
  ChangePointer = 'change_pointer',
  DeletePointer = 'delete_pointer',
  ViewPointer = 'view_pointer',

  AddCommentthread = 'add_commentthread',
  ChangeCommentthread = 'change_commentthread',
  DeleteCommentthread = 'delete_commentthread',
  ViewCommentthread = 'view_commentthread',

  AddNegotiation = 'add_negotiation',
  ChangeNegotiation = 'change_negotiation',
  DeleteNegotiation = 'delete_negotiation',
  ViewNegotiation = 'view_negotiation',

  AddComment = 'add_comment',
  ChangeComment = 'change_comment',
  DeleteComment = 'delete_comment',
  ViewComment = 'view_comment',

  ViewEvent = 'view_event',
}

export type PermissionsResponse = { permissionsList: Permissions[] };
