import { useState } from 'react';
import styled from 'styled-components';

import {
  ColorType,
  projectBranches as apiProjectBranches,
  signership as apiSignership,
} from '../../../../api';
import { useNotification } from '../../../../hooks';
import { Colors, Fonts, rem } from '../../../../themes';

type Props = {
  color: ColorType;
  description: string;
  updateDashboard: () => void;
  updatedBranch?: number;
  updatedSigner?: number;
};

function ColorChanger({
  color,
  description,
  updateDashboard,
  updatedBranch,
  updatedSigner,
}: Props) {
  const { showError } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const onColorChange = (newColor: ColorType) => {
    if (isLoading) return;
    setIsLoading(true);

    if (updatedBranch) {
      apiProjectBranches
        .updateProjectBranch(updatedBranch, {
          colorStatus: newColor,
        })
        .then(updateDashboard)
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);
        })
        .finally(() => setIsLoading(false));
    }

    if (updatedSigner) {
      apiSignership
        .updateSignership(updatedSigner, {
          colorStatus: newColor,
        })
        .then(updateDashboard)
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <ColorBox>
      {color !== 'red' && (
        <ColorDisc
          color="red"
          width={15}
          onClick={() => onColorChange('red')}
        />
      )}
      {color !== 'yellow' && (
        <ColorDisc
          color="yellow"
          width={15}
          onClick={() => onColorChange('yellow')}
        />
      )}
      {color !== 'green' && (
        <ColorDisc
          color="green"
          width={15}
          onClick={() => onColorChange('green')}
        />
      )}
      <ColorTitle>{description}</ColorTitle>
    </ColorBox>
  );
}

const ColorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid ${Colors.LightGrey};

  & > *:not(:last-child) {
    margin-right: 5px;
  }

  & > * {
    cursor: pointer;
  }
`;

const ColorTitle = styled.div`
  font-family: ${Fonts.Sofia};
  text-align: center;
  color: ${Colors.DarkGrey};
  font-size: ${rem(8)};
`;

export const ColorDisc = styled.div<{ color: ColorType; width?: number }>`
  width: ${props => props?.width ?? 15}px;
  height: ${props => props?.width ?? 15}px;
  border-radius: 50%;

  background-color: ${props => {
    switch (props.color) {
      case 'red':
        return Colors.DarkRed;
      case 'yellow':
        return Colors.Yellow;
      case 'green':
        return Colors.Green;
      default:
        return Colors.WeakGrey;
    }
  }};
`;

export default ColorChanger;
