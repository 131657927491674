import {
  apiFieldErrorHandler,
  dataURItoImageFile,
  handleInputBlur,
  handleInputChange,
  isEmail,
  isEmptyInput,
  isEmptyMultiSelect,
  routeTemplate,
} from './functions';

const initialValue = {
  value: '',
  errorMsg: '',
};

const setInitialValue = (initValue: string) => ({
  value: initValue,
  errorMsg: '',
});

const mailNoteValue =
  'Namda is an NDA negotiation and signing platform dedicated to the M&A community, learn more about Namda at Namda.io';

export {
  initialValue,
  setInitialValue,
  apiFieldErrorHandler,
  dataURItoImageFile,
  handleInputBlur,
  handleInputChange,
  isEmail,
  isEmptyInput,
  isEmptyMultiSelect,
  routeTemplate,
  mailNoteValue,
};
