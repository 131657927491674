import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Comment,
  comments as apiComments,
  NegotiationStatus,
  OneNdaType,
  Pointer,
  thread as apiThread,
  threadPointer as apiPointer,
  UserData,
} from '../../../api';
import SVG from '../../../assets/svg';
import { DotsLoading, Input } from '../../../atoms';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../../helpers';
import { useNotification, usePermissions } from '../../../hooks';
import { ActionsButton } from '../../../organisms';
import { selectors } from '../../../store';
import { Colors, Fonts, rem, scrollBarStyles } from '../../../themes';
import OneComment from './OneComment';
import ThreadActions from './ThreadActions';

export interface AccentPointer extends Pointer {
  click?: boolean;
}

type Props = {
  value: AccentPointer;
  onChange: React.Dispatch<React.SetStateAction<AccentPointer | null>>;
  deleteOneThread: (threadId: number) => void;
  editOneThread: (threadId: number, text: string) => void;
  nda: OneNdaType | null;
};

function NewComment({
  value,
  onChange,
  deleteOneThread,
  editOneThread,
  nda,
}: Props) {
  const [comment, setComment] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const { showError } = useNotification();
  const [isHidePopover, setIsHidePopover] = useState(false);
  const [editedThread, setEditedThread] = useState({
    value: value.text ? value.text : '',
    errorMsg: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditLoader, setIsEditLoader] = useState(false);
  const user: UserData = useSelector(selectors.user);
  const { canAddComment } = usePermissions();
  const [comments, setComments] = useState<Comment[]>([]);

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(comment, setComment, Errors.EnterField)) {
      isValid = false;
    }

    if (comment.errorMsg) isValid = false;
    return isValid;
  };
  const addNewComment = () => {
    if (isLoading) return;
    if (!validate()) return;
    if (isEmptyInput(comment, setComment, Errors.EnterField)) return;

    setIsLoading(true);
    apiComments
      .createComment({ commentThread: value.threadId, text: comment.value })
      .then(({ data }) => {
        onChange(null);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.text, setComment);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteThread = () => {
    apiThread
      .deleteThread(value.threadId)
      .then(() => {
        deleteOneThread(value.id);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  };

  const editThread = () => {
    if (isEditLoader) return;
    if (isEmptyInput(editedThread, setEditedThread, Errors.EnterField)) return;

    setIsEditLoader(true);
    apiThread
      .updateThread(value.id, { text: editedThread.value })
      .then(() => {
        setIsEditMode(false);
        editOneThread(value.id, editedThread.value);
        onChange({ ...value, text: editedThread.value });
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.text, setEditedThread);
      })
      .finally(() => setIsEditLoader(false));
  };

  const isShowActionsButton =
    value.negotiationStatus !== NegotiationStatus.ApprovedByBothParties &&
    value.negotiationStatus !== NegotiationStatus.RejectedByBothParties;

  useEffect(() => {
    if (value.threadId) {
      apiComments.getCommentsByThreadId(value.threadId).then(({ data }) => {
        setComments(data);
      });
    }
  }, [value]);
  return (
    <MainSection>
      <InnerSection>
        <StyledClick
          onClick={() => {
            canAddComment && onChange({ ...value, click: true });
          }}
        >
          <ImageContainer isClickable>
            <img src={SVG.profileAvtar} alt="" />
          </ImageContainer>
          <div>
            <NameText>
              {value.author}
              <StyledSpan>
                {formatDistanceToNow(new Date(value.created), {
                  addSuffix: true,
                }).replace('about', '')}
              </StyledSpan>
            </NameText>
            {!isEditMode && <CommentText>{value.text}</CommentText>}
          </div>
        </StyledClick>
        {value &&
          user &&
          Number(value.authorId) === user.id &&
          isShowActionsButton &&
          nda &&
          !nda?.isRecalled &&
          !nda?.isSigned && (
            <ThreadActionsButton
              isHidePopover={isHidePopover}
              offsetX={-130}
              offsetY={20}
            >
              <ThreadActions
                onEditClick={() => {
                  setIsHidePopover(true);
                  setIsEditMode(true);
                }}
                onDeleteClick={deleteThread}
              />
            </ThreadActionsButton>
          )}
      </InnerSection>
      {isEditMode && (
        <EditContainer>
          <InnerEdit>
            <InputBox>
              {isEditLoader && <StyledLoading />}
              <StyledInput
                value={editedThread.value}
                onChange={handleInputChange(setEditedThread)}
                onBlur={handleInputBlur(editedThread, setEditedThread)}
                placeholder="Add a comment..."
                // error={editedThread.errorMsg}
                error=""
              />
            </InputBox>
            <ImageContainer
              onClick={editThread}
              isClickable={Boolean(editedThread.value)}
            >
              <img src={SVG.rightArrow} alt="right arrow" />
            </ImageContainer>
          </InnerEdit>
        </EditContainer>
      )}
      {value.click && (
        <div>
          <ReplyContainer>
            {comments.map(com => (
              <OneComment
                key={com.id}
                comment={com as Comment}
                threadStatus={value.negotiationStatus}
                deleteOneComment={commentId => {
                  setComments(prev =>
                    prev.filter(oneComment => oneComment.id !== commentId),
                  );
                  onChange(null);
                }}
                updateComment={updatedComment => {
                  setComments(prev =>
                    prev.map(oneComment =>
                      oneComment.id === updatedComment.id
                        ? updatedComment
                        : oneComment,
                    ),
                  );
                  onChange(null);
                }}
              />
            ))}
          </ReplyContainer>
          <BottomSection>
            <ImageContainer isClickable>
              <img src={SVG.profileAvtar} alt="" />
            </ImageContainer>
            <Container>
              <InputBox>
                {isLoading && <StyledLoading />}
                <StyledInput
                  value={comment.value}
                  onChange={handleInputChange(setComment)}
                  onBlur={handleInputBlur(comment, setComment)}
                  placeholder="Reply"
                  // error={comment.errorMsg}
                  error=""
                />
              </InputBox>
              <ImageContainer
                onClick={addNewComment}
                isClickable={Boolean(comment.value)}
              >
                <img src={SVG.rightArrow} alt="right arrow" />
              </ImageContainer>
            </Container>
          </BottomSection>
        </div>
      )}
    </MainSection>
  );
}

const ThreadActionsButton = styled(ActionsButton)`
  transform: rotate(90deg);
  z-index: 1px;
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLoading = styled(DotsLoading)`
  z-index: 1px;
  background-color: ${Colors.Transparent};
`;

const MainSection = styled.div`
  cursor: pointer;
  background-color: ${Colors.offBlue};
  border-radius: 20px 20px 20px 0px;
`;

const InnerSection = styled.div`
  padding: 10px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const StyledClick = styled.div`
  display: flex;
  gap: 8px;
`;
const ReplyContainer = styled.div`
  padding-left: 20px;
  max-height: 400px;
  overflow-y: auto;
  ${scrollBarStyles}
`;

const BottomSection = styled.div`
  padding: 10px;
  display: flex;
  gap: 8px;
  border-top: 1px solid ${Colors.DarkSkyBlue};
  position: relative;
`;

const ImageContainer = styled.div<{ isClickable?: boolean }>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: ${Colors.offBlue};
  height: 33px;
  > img {
    padding: 5px;
  }
  &:hover {
    cursor: ${props => (props.isClickable ? 'pointer' : 'not-allowed')};
  }
`;

const NameText = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledSpan = styled.span`
  color: ${Colors.semiOffGrey};
  font-size: ${rem(7.5)};
  margin-left: 6px;
`;

const CommentText = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledInput = styled(Input)`
  padding: 5px;
  background-color: ${Colors.DarkSkyBlue};
  border: none;
  border-radius: 4px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.DarkSkyBlue};
  border-radius: 5px;
  align-items: center;
`;

const EditContainer = styled.div`
  background-color: ${Colors.Transparent};
  padding: 0px 10px 20px 10px;
`;

const InnerEdit = styled.div`
  background-color: ${Colors.DarkSkyBlue};
  display: flex;
  width: 100%;
  border-radius: 5px;
`;
export default NewComment;
