import styled from 'styled-components';

import ICON_BUTTON from '../assets/svg/ICON_BUTTON';

type IconType =
  | 'Active'
  | 'Cross'
  | 'Deactivate'
  | 'Delete'
  | 'Disabled'
  | 'Expired'
  | 'Resend'
  | 'Reset'
  | 'Sent';

type Props = {
  type: string;
  width?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

function IconButton({ type, width, className, onClick, disabled }: Props) {
  const imgSrc = ICON_BUTTON[type as IconType] ?? ICON_BUTTON.Active;
  return (
    <Container
      onClick={() => !disabled && onClick && onClick()}
      className={className}
      disabled={disabled}
    >
      <img width={width ?? '10px'} src={imgSrc} alt={type} />
    </Container>
  );
}

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export default IconButton;
