type Coupon = {
  id: string;
  amountOff: number;
  currency: string;
  duration: string;
  durationInMonths: number;
  percentOff: string;
  valid: boolean;
};

type Discount = {
  id: string;
  coupon: Coupon;
  customer: string;
  end: string;
  start: string;
  subscription: string;
};

type StatusTransitions = {
  finalizedAt: string;
  markedUncollectibleAt: string;
  paidAt: string;
  voidedAt: string;
};

export type Invoice = {
  id: string;
  number: string;
  created: string;
  dueDate: string;
  statusTransitions: StatusTransitions;
  plan: string;
  product: string;
  discount: Discount | null;
  currency: string;
  total: number;
  customerEmail: string;
  paid: boolean;
  status: string;
  hostedInvoiceUrl: string;
  invoicePdf: string;
};

export type CardBrand =
  | 'amex'
  | 'diners'
  | 'discover'
  | 'jcb'
  | 'mastercard'
  | 'unionpay'
  | 'visa'
  | 'unknown';

export type PaymentMethod = {
  brand: CardBrand;
  country: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export enum PriceInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export type PriceType = {
  chargebeePriceId: string;
  interval: PriceInterval | null;
  currency: string;
  unitAmount: number;
  productPlan: string;
};

export type AddonPrice = {
  chargebeePriceId: string;
  interval: string;
  currency: string;
  unitAmount: number;
  productAddonPlan: string;
};
export type Addon = {
  chargebeeProductId: string;
  prices: AddonPrice[];
  name: string;
  description: null | string;
  numberOfProjectsAllowed: number;
  productPlan: string;
};
export type ProductType = {
  chargebeeProductId: string;
  name: string;
  description: null | string;
  prices: PriceType[];
  numberOfProjectsAllowed: number;
  additionalProjectLifetime: boolean;
  allowAdditionalLifetimeProjects: boolean;
  addon: Addon[];
  projectAllowed: number | null;
};

export type ProductsList = ProductType[];

export type Subscription = {
  memberAllowed: number | null;
  projectAllowed: number | null;
  quantity: number | null;
  status: string | null;
  stripeId: string;
  cancelAtPeriodEnd: boolean;
  cancelAt: Date | null;
  product?: Pick<
    ProductType,
    | 'chargebeeProductId'
    | 'name'
    | 'description'
    | 'numberOfProjectsAllowed'
    | 'additionalProjectLifetime'
    | 'addon'
    | 'projectAllowed'
  > & { price: PriceType };
};

export type UpdateSubscriptionResponse = { details: Subscription };
export type AddOrUpdateSubscriptionResponse = {
  id: string;
  type: string;
  url: string;
  state: string;
  embed: boolean;
  createdAt: number;
  expiresAt: number;
};

export type BuySingleProject = {
  plan: string;
  quantity?: number;
};

export type AddSubscription = Pick<BuySingleProject, 'plan'>;

export type AddonItem = {
  itemPriceId?: string;
  quantity: number;
};
export type UpdateSubscription = {
  addon: AddonItem[];
};

export type OneTimePurchases = {
  stripeId: string;
  price: PriceType;
  quantity: number;
  dateCreated: string;
  invoiceStatus: string;
  invoiceRefunded: boolean;
};

export type OneTimePurchasesList = OneTimePurchases[];
