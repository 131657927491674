import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { OneSignerGroup } from '../../../../api';
import SVG from '../../../../assets/svg';
import { ButtonEllipse } from '../../../../atoms';
import {
  BoxShadow,
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
} from '../../../../themes';

type Props = {
  backToForm: () => void;
  selectGroup: (group: OneSignerGroup) => void;
  editOneGroup: (group: OneSignerGroup) => void;
  allGroups: OneSignerGroup[];
};

function ChoiceExistingGroup({
  backToForm,
  selectGroup,
  editOneGroup,
  allGroups,
}: Props) {
  const [choisenGroup, setChoisenGroup] = useState<OneSignerGroup | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    allGroups.length === 0 && setError('Please, first create group');
  }, [allGroups]);

  const doChoice = () => {
    if (choisenGroup === null) {
      setError('Please, select a contacts group');
      return;
    }

    selectGroup(choisenGroup);
  };

  return (
    <Container>
      <IconButton src={SVG.goBack} alt="go back button" onClick={backToForm} />
      <Error>{error}</Error>

      {allGroups.length === 0 ? (
        <TextBox>You don&apos;t have any groups</TextBox>
      ) : (
        <GroupsBox>
          {allGroups.map((group, index) => (
            <OneGroupeWrapper
              key={group.id}
              elect={group.id === choisenGroup?.id}
            >
              <OneGroupeBox
                onClick={() => {
                  setChoisenGroup(group);
                  setError(null);
                }}
              >
                Groupe {index + 1}: {group.name}
              </OneGroupeBox>

              <IconButton
                src={SVG.pencil}
                alt="Edit button"
                onClick={() => editOneGroup(group)}
              />
            </OneGroupeWrapper>
          ))}
        </GroupsBox>
      )}

      {allGroups.length !== 0 && (
        <StyledButton text="Select group" onClick={doChoice} />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 10px 0px;
`;

const IconButton = styled.img`
  width: 20px;
  cursor: pointer;
`;

const Error = styled.div`
  text-align: center;
  color: ${Colors.DarkRed};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  margin-bottom: 10px;
`;

const GroupsBox = styled.div`
  max-height: 400px;
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 15px;
  padding: 10px;
  overflow: scroll;

  ${removeScrollStyles}
`;

const OneGroupeWrapper = styled.div<{ elect: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 5px 15px;
  height: 46px;
  border-radius: 23px;

  &:hover {
    box-shadow: ${props => (props.elect ? 'none' : BoxShadow.Secondary)};
  }

  background-color: ${props =>
    props.elect ? `${Colors.BackgroundGrey}` : `${Colors.Transparent}`};
`;

const OneGroupeBox = styled.div`
  max-width: 90%;
  flex-grow: 2;
  cursor: pointer;
  font-size: ${rem(14)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextBox = styled.div`
  font-size: ${rem(14)};
  font-family: ${Fonts.Sofia};
  text-align: center;
  color: ${Colors.DarkGrey};
`;

const StyledButton = styled(ButtonEllipse)`
  width: 170px;
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  margin: 30px auto 0 auto;
`;

export default ChoiceExistingGroup;
