import { useState } from 'react';
import styled from 'styled-components';

import { nda as apiNda, OneNdaType } from '../../api';
import SVG from '../../assets/svg';
import { ButtonEllipse, FileInput, Input } from '../../atoms';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import { Colors, Fonts, rem } from '../../themes';

type Props = {
  projectId: number;
  addNda: (nda: OneNdaType) => void;
  goToChoices: () => void;
  branchId?: number;
  addUploadedNda?: (file: File, fileName: string) => void;
};

function UploadNda({
  projectId,
  addNda,
  branchId,
  goToChoices,
  addUploadedNda,
}: Props) {
  const { showError } = useNotification();

  const [name, setName] = useState(initialValue);
  const [file, setFile] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (!file) {
      showError(Errors.UploadFile);
      isValid = false;
    }

    if (isEmptyInput(name, setName, Errors.EnterField)) {
      isValid = false;
    }

    if (name.errorMsg) isValid = false;
    return isValid;
  };

  const addNdaHandler = () => {
    if (isLoading) return;
    if (!validate()) return;

    if (addUploadedNda) {
      addUploadedNda(file as File, name.value);
      return;
    }

    const formNda = new FormData();
    if (branchId) {
      formNda.append('project_branch', `${branchId}`);
    } else {
      formNda.append('project', `${projectId}`);
    }
    formNda.append('file', file as File);
    formNda.append('name', name.value);

    setIsLoading(true);
    apiNda
      .createOneNda(formNda)
      .then(res => addNda(res.data))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.file) showError(data.file[0]);

        apiFieldErrorHandler(data.name, setName);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container>
      <IconButton src={SVG.goBack} alt="go back button" onClick={goToChoices} />

      <Description>
        Enter new name for the file otherwise it will be saved the way it’s
        called.
      </Description>
      <StyledFileInput
        file={file}
        onChange={event => {
          if (!event.target.files) return;
          setFile(event.target?.files[0]);
        }}
        removeFile={() => setFile(null)}
      />
      <InputWrapper>
        <Input
          disabled={isLoading}
          value={name.value}
          onChange={handleInputChange(setName)}
          onBlur={handleInputBlur(name, setName)}
          placeholder="NDA Name"
          error={name.errorMsg}
        />
      </InputWrapper>
      <StyledButton
        isLoading={isLoading}
        text={`Add NDA ${isLoading ? '...' : ''}`}
        onClick={addNdaHandler}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
`;

const Description = styled.p`
  width: 300px;
  margin: 0 auto 30px auto;
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  font-size: ${rem(10)};
  text-align: center;
`;

const StyledFileInput = styled(FileInput)`
  margin: 0 auto;
`;

const IconButton = styled.img`
  width: 20px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  margin: 20px auto;
  width: 300px;
`;

const StyledButton = styled(ButtonEllipse)<{ isLoading?: boolean }>`
  width: 150px;
  margin: 30px auto 0 auto;
  background-color: ${Colors.White};
  color: ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
  border: 1px solid
    ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
`;

export default UploadNda;
