import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder?: string;
  className?: string;
  file: any | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  removeFile: (event: React.MouseEvent<HTMLDivElement>) => void;
  name?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  clearFile?: (event: any) => void;
  sizeError?: { [key: string]: boolean };
};

function FileInput({
  placeholder,
  className,
  file,
  onChange,
  removeFile,
  name,
  inputRef,
  clearFile,
  sizeError,
}: Props) {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };
  return (
    <Wrapper className={className}>
      <Label htmlFor={name || 'file-input'} onClick={handleClick}>
        <span>{placeholder ?? 'Upload a File'}</span>
        <StyledInput
          id={name || 'file-input'}
          type="file"
          accept="application/pdf"
          onChange={onChange}
          ref={inputRef}
        />
      </Label>
      {file && (
        <FileContainer>
          <FileName>{file.name}</FileName>
          <RemoveButton onClick={removeFile}>Remove</RemoveButton>
        </FileContainer>
      )}
      {sizeError && sizeError[name as string] && (
        <RemoveButton onClick={clearFile}>Clear</RemoveButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: fit-content;
  font-family: ${Fonts.Sofia};
  text-align: center;
  width: 100%;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 10px auto;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const FileName = styled.span`
  color: ${Colors.MiddleSecondGrey};
  font-size: ${rem(10)};
`;

const RemoveButton = styled.span`
  color: ${Colors.DarkRed};
  font-size: ${rem(9)};
  text-decoration: underline;
  cursor: pointer;
`;

const Label = styled.label`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  text-align: center;
  width: 100%;
  margin-top: auto;
  cursor: pointer;
`;

const StyledInput = styled.input`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
`;

export default FileInput;
