import { format } from 'date-fns';
import { memo } from 'react';
import { Area, AreaChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import { BoxShadow, Colors, Fonts, rootBorderRadius } from '../../../themes';

type Props = {
  data: {
    month: string;
    Project: number;
    Recipients: number;
  }[];
};

function AreaCharts({ data }: Props) {
  const emptyData = [
    {
      count: 0,
      month: format(
        new Date(new Date().getFullYear(), new Date().getMonth()),
        'MMM',
      ),
    },
  ];

  return (
    <Container>
      <AreaChart
        width={550}
        height={310}
        data={data.length ? data : emptyData}
        margin={{
          top: 10,
          right: 10,
          left: -30,
          bottom: -10,
        }}
      >
        <XAxis
          type="category"
          dataKey="month"
          scale="point"
          tickLine={false}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis type="number" tickLine={false} allowDecimals={false} />
        <Tooltip
          labelStyle={{
            display: 'none',
          }}
        />
        <Area
          type="monotone"
          dataKey="Project"
          fill="#FFBB28"
          stroke="#FFBB28"
        />
        <Area
          type="monotone"
          dataKey="Recipients"
          fill="#00C49F99"
          stroke="#00C49F99"
        />
        <Legend
          verticalAlign="bottom"
          height={36}
          iconType="circle"
          iconSize={10}
        />
      </AreaChart>
    </Container>
  );
}

const Container = styled.div`
  border-radius: ${rootBorderRadius};
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  width: 100%;
`;

export const CustomTooltip = styled.div`
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${Colors.White};
  border: 0.5px solid ${Colors.LightGrey};
  box-sizing: border-box;
  box-shadow: ${BoxShadow.Secondary};
  border-radius: 6px;
`;

export default memo(AreaCharts);
