import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { MemberType } from '../../../../api';
import { IconButton, UserLogo } from '../../../../atoms';
import { selectors } from '../../../../store';
import { Fonts, rem } from '../../../../themes';

export interface MemberTypeChecked extends MemberType {
  checked: boolean;
}

type Props = {
  member: MemberTypeChecked;
  removeFromProject: (id: number) => void;
};

function AddedMember({ member, removeFromProject }: Props) {
  const { id: userId } = useSelector(selectors.user);

  const { id, avatar, firstName, lastName, email } = member;
  const namesLetters = `${
    firstName && lastName ? firstName[0] + lastName[0] : email[0]
  }`;

  return (
    <MemberBox>
      <InfoBlock>
        <UserLogo logo={avatar} width={20} namesLetters={namesLetters} />
        <MemberInfo>
          {firstName} {lastName}
        </MemberInfo>
        <MemberInfo>{email}</MemberInfo>
      </InfoBlock>
      <ControlBlock>
        <IconButton
          width="15px"
          type="Deactivate"
          onClick={() => removeFromProject(id)}
          disabled={userId === Number(id)}
        />
      </ControlBlock>
    </MemberBox>
  );
}

const MemberBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const MemberInfo = styled.span`
  font-size: ${rem(7)};
  white-space: nowrap;
  font-family: ${Fonts.Sofia};
`;

const ControlBlock = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export default AddedMember;
