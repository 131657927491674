import { AxiosResponse } from 'axios';

import api from '../authApi';
import { ContentTypes } from '../guestApi';
import { UnlimitedList } from '../types';
import {
  GetPaginationProjectsList,
  GetProjectsList,
  OneProjectType,
  ProjectsPaginationLimit as Limit,
  UpdateProjectRequest,
} from './types';

const getAllProjects = (
  page: number,
  filter: string,
  searchName: string,
): Promise<AxiosResponse<GetPaginationProjectsList>> =>
  api.get(
    `/projects/projects/?limit=${Limit}&offset=${
      (page - 1) * Limit
    }${filter}${searchName}`,
  );

const getProjects = (): Promise<AxiosResponse<GetProjectsList>> =>
  api.get(`/projects/projects/?limit=${UnlimitedList}`);

const createOneProject = (
  form: FormData,
): Promise<AxiosResponse<OneProjectType>> =>
  api.post('/projects/projects/', form, {
    headers: { 'Content-Type': ContentTypes.formData },
  });

const updateProject = (
  id: number,
  body: UpdateProjectRequest | FormData,
): Promise<AxiosResponse<OneProjectType>> =>
  api.patch(`/projects/projects/${id}/`, body, {
    headers: {
      'Content-Type':
        body instanceof FormData ? ContentTypes.formData : ContentTypes.json,
    },
  });

const getOneProject = (id: number): Promise<AxiosResponse<OneProjectType>> =>
  api.get(`/projects/projects/${id}/`);

const projects = {
  getAllProjects,
  getProjects,
  createOneProject,
  getOneProject,
  updateProject,
};

export default projects;
