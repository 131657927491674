import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

enum Routes {
  main = '/',
  login = '/login',
  register = '/sign-up',
  home = '/home',
  signerInvitation = '/signer_invitation/:userIdAndToken',
  memberInvitation = '/member_invitation/:userIdAndToken',
  activateUser = '/user-activation/:key',
  forgotPassword = '/forgot-password/',
  passwordResetConfirm = '/password-reset-confirm/:userId/:token',
  projects = '/projects',
  oneProject = '/projects/:projectId',
  projectBranch = '/projects/:projectId/group/:branchId',
  projectBranchNda = '/projects/:projectId/group/:branchId/nda',
  updateRecipients = '/projects/:projectId/group/:branchId/edit',
  addProject = '/add-project',
  lookupNda = '/projects/:projectId/lookup-nda',
  reviewNda = '/projects/:projectId/group/:branchId/review-nda',
  recipients = '/projects/:projectId/group/create',
  updateTeaser = '/projects/:projectId/teaser',
  updateCim = '/projects/:projectId/cim',
  editProjectNda = '/projects/:projectId/nda',
  templates = '/templates',
  addTemplate = '/templates/create',
  plan = '/plan',
  reports = '/reports',
  dashboard = '/dashboard',
  account = '/account',
  privacy = '/privacyPolicy',
  terms = '/terms-condition',
  userList = '/projects/:projectId/group/:branchId/members',
  users = '/users',
}

export { Routes, PrivateRoute, PublicRoute };
