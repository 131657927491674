import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { me as apiMe } from '../../../api';
import { ButtonEllipse, ImageInput } from '../../../atoms';
import { Errors } from '../../../const';
import { useNotification } from '../../../hooks';
import ErrorText from '../../../molecules/ErrorText';
import { refreshUser } from '../../../store';
import { Colors } from '../../../themes';

type Props = {
  setIsModalOpen: (arg1: boolean) => void;
};

function ImageModal({ setIsModalOpen }: Props) {
  const { showError } = useNotification();
  const dispatch = useDispatch();

  const [file, setFile] = useState<null | Blob>(null);
  const [fileError, setFileError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    setFile(event.target?.files[0]);
  };

  useEffect(() => {
    if (file) setFileError('');
  }, [file]);

  const addNewAvatar = (event: SyntheticEvent) => {
    event.preventDefault();

    if (isLoading) return;

    if (!file) {
      setFileError(Errors.UploadFile);
      return;
    }

    const form = new FormData();
    form.append('avatar', file);

    setIsLoading(true);

    apiMe
      .updateUserAvatar(form)
      .then(({ data }) => {
        dispatch(refreshUser({ ...data, avatar: URL.createObjectURL(file) }));
        setIsModalOpen(false);
      })
      .catch((error: any) => {
        if (error?.data?.avatar) showError(error.data.avatar[0]);
        error.status === 413 &&
          showError('Image is too large, please select new image');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={addNewAvatar}>
      <StyledImageInput
        file={file}
        onChange={uploadFile}
        removeFile={() => {
          setFile(null);
        }}
      />

      {fileError && <Error>{fileError}</Error>}

      <StyledButton
        isLoading={isLoading}
        type="submit"
        text={isLoading ? 'Save...' : 'Save'}
      />
    </form>
  );
}

const StyledImageInput = styled(ImageInput)`
  margin: 0 auto;
`;

const StyledButton = styled(ButtonEllipse)<{ isLoading: boolean }>`
  margin: 30px auto 0 auto;
  background-color: ${Colors.White};
  border: 1px solid
    ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
  color: ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
`;

const Error = styled(ErrorText)`
  text-align: center;
`;

export default ImageModal;
