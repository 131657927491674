import styled from 'styled-components';

import { BoxShadow, Colors, Fonts, rem } from '../themes';

type Props = {
  paginationPages: number[];
  currentPage: number;
  onPageClick: (page: number) => void;
  disabled?: boolean;
};

function Pagination({
  paginationPages,
  currentPage,
  onPageClick,
  disabled,
}: Props) {
  const onPrevClick = () => {
    !disabled && currentPage !== 1 && onPageClick(currentPage - 1);
  };

  const onNextClick = () => {
    !disabled &&
      currentPage !== paginationPages.length &&
      onPageClick(currentPage + 1);
  };

  return (
    <Container>
      <OnePage disable={currentPage === 1} onClick={onPrevClick}>
        {'<'}
      </OnePage>
      {paginationPages.map(page => (
        <OnePage
          key={page}
          disable={currentPage === page}
          onClick={() => !disabled && currentPage !== page && onPageClick(page)}
        >
          {page}
        </OnePage>
      ))}
      <OnePage
        disable={currentPage === paginationPages.length}
        onClick={onNextClick}
      >
        {'>'}
      </OnePage>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  font-size: ${rem(10)};
  padding: 10px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const OnePage = styled.div<{ disable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 30px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${Colors.White};
  box-shadow: ${BoxShadow.Secondary};
  color: ${props => (props.disable ? Colors.Silver : 'inherit')};

  cursor: ${props => (props.disable ? 'default' : 'pointer')};
`;

export default Pagination;
