import { createContext, useEffect, useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import styled from 'styled-components';

import {
  ColorType,
  contacts as apiContacts,
  ContactType,
  DashboardGroupType,
  OneNdaType,
} from '../../../../api';
import { StatusEnum } from '../../../../atoms';
import { Colors, Fonts, rem, removeScrollStyles } from '../../../../themes';
import SubTable from './SubTable';

export type TableTitlesType = {
  id: number;
  name: string;
  email: string;
  company: string | null;
  color: { color: ColorType | null };
  status: string;
  date: string;
  actions: {
    userId: number;
    signerId: number;
    branchId: number;
    nda: OneNdaType;
    color: ColorType | null;
  };
  toggleOpen: null;
};

export type DataCell = Column<TableTitlesType>;

export const ContextTable = createContext({ updateDashboard: () => {} });

type Props = {
  tableData: DashboardGroupType[];
  isSignerOfProject: boolean;
  projectName: string | undefined;
  projectBranch: DashboardGroupType[] | undefined;
};

function Table({
  tableData,
  isSignerOfProject,
  projectName,
  projectBranch,
}: Props) {
  const [allContacts, setAllContacts] = useState<ContactType[]>([]);

  useEffect(() => {
    apiContacts.getAllContact().then(({ data }) => setAllContacts(data));
  }, [tableData]);

  const columns: DataCell[] = useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },

      {
        Header: 'NDA signing',
        accessor: 'color',
      },
      {
        Header: 'Activity',
        accessor: 'status',
      },
      {
        Header: 'Date Sent',
        accessor: 'date',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
      {
        Header: '',
        accessor: 'toggleOpen',
      },
    ],
    [],
  );

  const tableGroups = useMemo(
    () =>
      tableData.map(group => {
        const signerForTable = group.signership.map(
          ({ id, colorStatus, lastEvent, signer }) => {
            const { id: userId, email, company, firstName, lastName } = signer;

            const currentContact = allContacts.find(
              contact => contact.user.pk === userId,
            );

            const getName = () => {
              if (currentContact) {
                return `${currentContact.firstName} ${currentContact.lastName}`;
              }
              if (firstName && lastName) {
                return `${firstName} ${lastName}`;
              }

              return '-';
            };

            return {
              id,
              name: getName(),
              email,
              company: company?.name ?? '-',
              color: { color: colorStatus },
              status: group.nda?.isSigned
                ? StatusEnum.Signed
                : lastEvent?.status ?? '-',
              date: lastEvent?.created ?? '',
              actions: {
                userId,
                signerId: id,
                branchId: group.id,
                nda: group.nda,
                color: colorStatus,
              },
              toggleOpen: null,
            };
          },
        );

        return {
          projectId: group.project,
          groupId: group.id,
          groupName: group.name,
          colorStatus: group.colorStatus,
          nda: group.nda,
          signership: signerForTable,
        };
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData, allContacts],
  );

  const emptyRows = useMemo(() => [], []);

  const { getTableProps, getTableBodyProps, headerGroups } = useTable(
    {
      columns,
      data: emptyRows,
    },
    useExpanded,
  );

  return (
    <TableScrollBox>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledTd {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledTd>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {tableGroups.map((tableGroup, index) => (
            <SubTable
              key={tableGroup.groupId}
              projectId={tableGroup.projectId}
              groupId={tableGroup.groupId}
              groupData={tableGroup.signership}
              nda={tableGroup.nda}
              groupName={tableGroup.groupName}
              isSignerOfProject={isSignerOfProject}
              colorStatus={tableGroup.colorStatus}
              projectName={projectName}
              index={index}
              projectBranch={projectBranch}
            />
          ))}
          {tableData.length === 0 && (
            <EmptyMessage>
              <td colSpan={12}>
                <span>No records found</span>
              </td>
            </EmptyMessage>
          )}
        </tbody>
      </StyledTable>
    </TableScrollBox>
  );
}

const TableScrollBox = styled.div`
  max-height: 50vh;

  overflow-y: scroll;

  ${removeScrollStyles}
`;

const StyledTable = styled.table`
  width: 100%;

  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkBlack};
  border: 1px solid #d5e4f1;
  border-radius: 15px;
  border-spacing: 0;
`;

const StyledTd = styled.td`
  padding: 10px 5px;
  font-size: ${rem(11.25)};
  font-weight: 300;
  text-align: center;
  height: 52px;
  background-color: ${Colors.SkyBlue};

  &:not(:last-child) {
    min-width: 65px;
  }

  &:last-child {
    border-radius: 0 15px 0 0;
  }

  &:first-child {
    border-radius: 15px 0 0 0;
  }

  &:nth-child(1) {
    min-width: 100px;
  }

  &:nth-child(2) {
    min-width: 200px;
  }

  &:nth-child(3) {
    min-width: 160px;
  }
  &:nth-child(6) {
    min-width: 90px;
  }
`;

const EmptyMessage = styled.tr`
  height: 52px;
  text-align: center;
  font-size: ${rem(10)};
`;

export default Table;
