import { ColorType } from '../../../../api';
import SVG from '../../../../assets/svg';
import ColorChanger from './ColorChanger';
import { Button, Container, OneAction, StyledButton } from './RecipientActions';

type Props = {
  branchId: number;
  color: ColorType;
  isHasNda: boolean;
  ndaIsSigned: boolean;
  ndaIsSent: boolean;
  ndaIsRecalled: boolean;
  onEditNameClick: () => void;
  onAddRecipientsClick: () => void;
  onLookNdaClick: () => void;
  onUploadNdaClick: () => void;
  onRecallNdaClick: () => void;
  onResendNdaClick: () => void;
  onDeleteNdaClick: () => void;
  updateDashboard: () => void;
};

function GroupActions({
  branchId,
  color,
  isHasNda,
  ndaIsSigned,
  ndaIsSent,
  ndaIsRecalled,
  onEditNameClick,
  onAddRecipientsClick,
  onLookNdaClick,
  onUploadNdaClick,
  onRecallNdaClick,
  onResendNdaClick,
  onDeleteNdaClick,
  updateDashboard,
}: Props) {
  return (
    <Container>
      <OneAction onClick={onEditNameClick}>
        <Button src={SVG.pencilGrey} alt="Edit Name" />
        <StyledButton>Edit group name</StyledButton>
      </OneAction>

      <OneAction onClick={onAddRecipientsClick}>
        <Button src={SVG.addedUser} alt="Add Recipients" />
        <StyledButton>Add Recipients</StyledButton>
      </OneAction>

      {isHasNda && (
        <OneAction>
          <Button src={SVG.lookNDA} alt="Look NDA" />
          <StyledButton onClick={onLookNdaClick}>Look group NDA</StyledButton>
        </OneAction>
      )}

      {!isHasNda && (
        <OneAction onClick={onUploadNdaClick}>
          <Button src={SVG.uploadSecond} alt="Upload NDA" />
          <StyledButton>Upload NDA</StyledButton>
        </OneAction>
      )}

      {isHasNda && !ndaIsRecalled && !ndaIsSigned && (
        <OneAction onClick={onRecallNdaClick}>
          <Button src={SVG.resend} alt="Recall NDA" />
          <StyledButton>Recall NDA</StyledButton>
        </OneAction>
      )}

      {isHasNda && !ndaIsRecalled && !ndaIsSigned && !ndaIsSent && (
        <OneAction onClick={onResendNdaClick}>
          <Button src={SVG.sent2} alt="Recall NDA" />
          <StyledButton>Resend NDA</StyledButton>
        </OneAction>
      )}

      {isHasNda && ndaIsRecalled && !ndaIsSigned && (
        <OneAction onClick={onDeleteNdaClick}>
          <Button src={SVG.deleteImg} alt="Delete NDA" />
          <StyledButton>Delete NDA</StyledButton>
        </OneAction>
      )}
      <ColorChanger
        color={color}
        description="Tag group..."
        updatedBranch={branchId}
        updateDashboard={updateDashboard}
      />
    </Container>
  );
}

export default GroupActions;
