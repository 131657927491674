import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  members as apiMembers,
  nda as apiNda,
  OneProjectType,
  projects as apiProjects,
  templates as apiTemplates,
} from '../../../api';
import {
  Input,
  Modal,
  ModalBox,
  MultiSelect,
  SelectOption,
  Textarea,
} from '../../../atoms';
import CardWrapper from '../../../atoms/CardWrapper';
import CommonModal from '../../../atoms/Modal/CommonModal';
import SolidButton from '../../../atoms/SolidButton';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
  routeTemplate,
} from '../../../helpers';
import { useNotification, usePermissions } from '../../../hooks';
import { Routes } from '../../../routes';
import { selectors } from '../../../store';
import { PageWrapper } from '../../../templates';
import { Colors, Fonts, rem } from '../../../themes';
import AddNda from './AddNda';
import AddTeaserAndCim from './AddTeaserAndCim';
import { Nda } from './types';

type Payload = {
  project: number;
  name: string;
  file?: File | string;
  template?: string;
};

type FormPayload = {
  name: string;
  summary: string;
  members?: string;
  teaser?: File;
  cim?: File;
};

function AddProject() {
  const { showError } = useNotification();
  const { canAddProject } = usePermissions();
  const history = useHistory();
  const maxAllowedProjects = useSelector(selectors.maxAllowedProjects);
  const { product: currentUserPlan } = useSelector(selectors.subscription);
  const [sizeError, setSizeError] = useState<{ [key: string]: boolean }>({
    cim: false,
    teaser: false,
  });

  const [name, setName] = useState(initialValue);
  const [allUserMembers, setAllUserMembers] = useState<SelectOption[]>([]);

  const [members, setMembers] = useState<SelectOption[]>([]);
  const [allProjects, setAllProjects] = useState<OneProjectType[] | null>(null);
  const [isAdditionalProject, setIsAdditionalProject] = useState(false);
  const [description, setDescription] = useState(initialValue);
  const [nda, setNda] = useState<Nda>({
    file: null,
    ...initialValue,
    template: null,
  });
  const [teaserCim, setTeaserCim] = useState({
    teaser: null,
    cim: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    apiMembers.getAllMembers().then(({ data }) => {
      setAllUserMembers(
        data.map(member => ({
          label: member.email,
          value: member.id.toString(),
        })),
      );
    });

    apiProjects.getProjects().then(({ data }) => {
      setAllProjects(data);
    });
  }, []);

  const formData = (payload: Payload | FormPayload) => {
    const formNda = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (typeof value === 'number') {
        formNda.append(key, value.toString());
      } else {
        formNda.append(key, value);
      }
    });
    return formNda;
  };
  const addNewProject = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) return;
    if (!canAddProject) return;

    if (isEmptyInput(name, setName, Errors.EnterProjectName)) return;
    if (!nda.value) {
      setNda({ ...nda, errorMsg: Errors.EnterNdaName });
      return;
    }
    if (!nda.file && !nda.template) {
      showError(`${Errors.ChouseTemplate} or ${Errors.UploadFile} for NDA`);
      return;
    }
    if (Object.values(sizeError).find(value => value)) {
      showError(Errors.CimAndTeaserError);
      return;
    }
    const ownProjects = allProjects?.filter(project => project.created);

    if (ownProjects && maxAllowedProjects <= ownProjects.length) {
      setIsAdditionalProject(true);
      showError(Errors.NotEnoughProjects);
      return;
    }
    const addedMembers = members.map(member => Number(member.value));

    setIsLoading(true);
    try {
      const formPayload: FormPayload = {
        name: name.value,
        summary: description.value,
      };
      if (teaserCim.teaser) {
        formPayload.teaser = teaserCim.teaser;
      }
      if (teaserCim.cim) {
        formPayload.cim = teaserCim.cim;
      }
      const projectData = formData(formPayload);
      addedMembers.forEach((member: number) => {
        projectData.append('members', member.toString());
      });
      const { data: project } = await apiProjects.createOneProject(projectData);
      const payload: Payload = {
        project: project.id,
        name: name.value,
        file: nda.file || '',
      };
      if (nda?.template?.value) {
        payload.template = nda?.template?.value;
      }
      await apiNda.createOneNda(formData(payload));
      const path = routeTemplate(Routes.recipients, {
        projectId: project.id.toString(),
      });
      if (nda.file) {
        const form = new FormData();
        form.append('name', name.value);
        form.append('file', nda.file);

        await apiTemplates.createOneTemplate(form);
      }
      history.push(path);
    } catch (error) {
      const { data } = error as any;
      if (data) {
        if (Array.isArray(data.nonFieldErrors)) {
          showError(data.nonFieldErrors[0]);
        }
        if (data.detail) {
          showError(data.detail);
        }
        if (Array.isArray(data.members)) {
          showError(data.members[0]);
        }
        if (data.file) {
          showError(data.file[0]);
        }
      }
      apiFieldErrorHandler(data.name, setName);
      apiFieldErrorHandler(data.summary, setDescription);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    history.push(Routes.addProject);
    setIsAdditionalProject(false);
  };

  return (
    <PageWrapper>
      <StyledForm onSubmit={addNewProject}>
        <CardWrapper title="Add a new project">
          <Container>
            <div>
              <Label>
                Project Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <Input
                disabled={isLoading}
                value={name.value}
                onChange={handleInputChange(setName)}
                onBlur={handleInputBlur(name, setName)}
                placeholder=""
                error={name.errorMsg}
                maxLength={30}
              />
            </div>
            <div>
              <MultiSelect
                options={allUserMembers}
                value={members}
                onChange={option => setMembers(option as SelectOption[] | [])}
                placeholder="Select team members of your team to add to this project"
              />
              {!allUserMembers.length && (
                <NoteText>
                  You can add your team members based on your plan to join the
                  project. It can be done after the project is created as well.
                  Add team members <Link to={Routes.account}>Now</Link>
                </NoteText>
              )}
            </div>
            <div>
              <Label>Write a project summary</Label>
              <Textarea
                disabled={isLoading}
                value={description.value}
                onChange={handleInputChange(setDescription)}
                onBlur={handleInputBlur(description, setDescription)}
                placeholder="A few lines about your project, which will shared with your counterparts when sending the information"
                error={description.errorMsg}
                minRows={3}
              />
            </div>
          </Container>
        </CardWrapper>
        <AddNda value={nda} onChange={setNda} isLoading={isLoading} />
        <AddTeaserAndCim
          value={teaserCim}
          onChange={setTeaserCim}
          setSizeError={setSizeError}
          sizeError={sizeError}
        />
        <ButtonContainer>
          <StyledButton
            type="button"
            text="Cancel"
            onClick={() => history.push(Routes.projects)}
          />
          <StyledSubmitButton
            isLoading={isLoading}
            type="submit"
            text={isLoading ? 'Save...' : 'Save'}
          />
        </ButtonContainer>
      </StyledForm>

      <Modal
        modalIsOpen={isAdditionalProject}
        setModalIsOpen={setIsAdditionalProject}
      >
        <ModalBox
          title={
            currentUserPlan.product?.name
              ? 'Buy Additional Project'
              : 'Subscribe'
          }
          onCloseButtonClick={() => setIsAdditionalProject(false)}
        >
          <CommonModal
            closeModal={closeModal}
            isAdditionalProject={isAdditionalProject}
          />
        </ModalBox>
      </Modal>
    </PageWrapper>
  );
}

const StyledForm = styled.form`
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Label = styled.p`
  margin-bottom: 10px;
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;
const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
`;

const NoteText = styled.p`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2px;
  margin-bottom: 0px;
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;
export default AddProject;
