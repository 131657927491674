import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { templates as apiTemplates } from '../../../api';
import { Input, TextButton } from '../../../atoms';
import PdfView from '../../../atoms/PdfView';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  isEmptyInput,
} from '../../../helpers';
import { useNotification, usePermissions } from '../../../hooks';
import { Colors } from '../../../themes';

type Props = {
  templateId: number;
  templateName: string;
  closeModal: () => void;
  deleteTemplateSuccess: (id: number) => void;
  setIsShowTemplateModal: React.Dispatch<React.SetStateAction<boolean>>;
  editTemplate: (templateId: number, name: string) => void;
};

function TemplateModal({
  templateId,
  templateName,
  closeModal,
  deleteTemplateSuccess,
  setIsShowTemplateModal,
  editTemplate,
}: Props) {
  const { showError } = useNotification();
  const { canChangeNdatemplate, canViewNdatemplate } = usePermissions();

  const [isOpenEditName, setIsOpenEditName] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);

  const [prevName, setPrevName] = useState('');
  const [name, setName] = useState({ errorMsg: '', value: templateName });

  const [pdfURL, setPdfURL] = useState<string>('');

  useEffect(() => {
    canViewNdatemplate &&
      apiTemplates.getTemplatesPdf(templateId).then(response => {
        const fileURL = URL.createObjectURL(response.data);
        setPdfURL(fileURL);
      });
  }, [templateId, canViewNdatemplate]);

  const openEditNameMode = () => {
    if (!canChangeNdatemplate) return;

    setPrevName(name.value);
    setIsOpenEditName(true);
  };

  const cancelEditName = () => {
    setName({ errorMsg: '', value: prevName });
    setIsOpenEditName(false);
  };

  const editTemplateName = () => {
    if (!canChangeNdatemplate) return;
    if (isEmptyInput(name, setName, Errors.EnterName)) return;

    apiTemplates
      .updateTemplate(templateId, { name: name.value })
      .then(() => {
        editTemplate(templateId, name.value);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.name, setName);
      })
      .then(() => setIsOpenEditName(false));
  };

  const deleteTemplate = () => {
    apiTemplates
      .deleteTemplate(templateId)
      .then(() => {
        deleteTemplateSuccess(templateId);
        closeModal();
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  };

  if (pdfURL === '') return <></>;

  return (
    <Wrapper>
      <Header>
        <Title>
          <StyledInput
            value={name.value}
            onChange={handleInputChange(setName)}
            onBlur={handleInputBlur(name, setName)}
            placeholder="Title"
            error={name.errorMsg}
            disabled={!canChangeNdatemplate || !isOpenEditName}
            editMode={!isOpenEditName}
          />
          {isOpenEditName ? (
            <>
              <RedButton type="button" onClick={cancelEditName}>
                Cancel
              </RedButton>
              <StyledButton type="button" onClick={editTemplateName}>
                Confirm edit
              </StyledButton>
            </>
          ) : (
            <StyledButton type="button" onClick={openEditNameMode}>
              Edit title
            </StyledButton>
          )}
        </Title>

        {isOpenDeleteConfirm ? (
          <ButtonBox>
            <RedButton type="button" onClick={deleteTemplate}>
              Confirm delete
            </RedButton>
            <StyledButton
              type="button"
              onClick={() => setIsOpenDeleteConfirm(false)}
            >
              Cancel
            </StyledButton>
          </ButtonBox>
        ) : (
          <RedButton type="button" onClick={() => setIsOpenDeleteConfirm(true)}>
            Delete
          </RedButton>
        )}
      </Header>
      <PdfView
        pdfURL={pdfURL}
        onClose={() => {
          setPdfURL('');
          setIsShowTemplateModal(false);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 95vh;
  width: 80vw;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.White};
  padding: 10px;
`;

const Title = styled.div`
  display: flex;
`;

const StyledInput = styled(Input)<{ editMode: boolean }>`
  width: 200px;
  text-overflow: ellipsis;
  ${props => props.editMode && 'border: none;'}
`;

const ButtonBox = styled.div`
  display: flex;
`;

const StyledButton = styled(TextButton)`
  white-space: nowrap;
`;

const RedButton = styled(TextButton)`
  white-space: nowrap;
  color: ${Colors.DarkRed};
`;

export default TemplateModal;
