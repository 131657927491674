import { useSelector } from 'react-redux';

import { Permissions } from '../api';
import { selectors } from '../store';

function usePermissions() {
  const permissions = useSelector(selectors.permissions);
  const canDo = (action: string) => permissions?.includes(action);

  // Nda
  const canAddNda = canDo(Permissions.AddNda);
  const canChangeNda = canDo(Permissions.ChangeNda);
  const canDeleteNda = canDo(Permissions.DeleteNda);
  const canViewNda = canDo(Permissions.ViewNda);

  // Ndatemplate
  const canAddNdatemplate = canDo(Permissions.AddNdatemplate);
  const canChangeNdatemplate = canDo(Permissions.ChangeNdatemplate);
  const canDeleteNdatemplate = canDo(Permissions.DeleteNdatemplate);
  const canViewNdatemplate = canDo(Permissions.ViewNdatemplate);

  // Project
  const canAddProject = canDo(Permissions.AddProject);
  const canChangeProject = canDo(Permissions.ChangeProject);
  const canDeleteProject = canDo(Permissions.DeleteProject);
  const canViewProject = canDo(Permissions.ViewProject);

  // Projectbranch
  const canAddProjectbranch = canDo(Permissions.AddProjectbranch);
  const canChangeProjectbranch = canDo(Permissions.ChangeProjectbranch);
  const canDeleteProjectbranch = canDo(Permissions.DeleteProjectbranch);
  const canViewProjectbranch = canDo(Permissions.ViewProjectbranch);

  // Signergroup
  const canAddSignergroup = canDo(Permissions.AddSignergroup);
  const canChangeSignergroup = canDo(Permissions.ChangeSignergroup);
  const canDeleteSignergroup = canDo(Permissions.DeleteSignergroup);
  const canViewSignergroup = canDo(Permissions.ViewSignergroup);

  // Signership
  const canAddSignership = canDo(Permissions.AddSignership);
  const canChangeSignership = canDo(Permissions.ChangeSignership);
  const canDeleteSignership = canDo(Permissions.DeleteSignership);
  const canViewSignership = canDo(Permissions.ViewSignership);

  // Pointer
  const canAddPointer = canDo(Permissions.AddPointer);
  const canChangePointer = canDo(Permissions.ChangePointer);
  const canDeletePointer = canDo(Permissions.DeletePointer);
  const canViewPointer = canDo(Permissions.ViewPointer);

  // Commentthread
  const canAddCommentthread = canDo(Permissions.AddCommentthread);
  const canChangeCommentthread = canDo(Permissions.ChangeCommentthread);
  const canDeleteCommentthread = canDo(Permissions.DeleteCommentthread);
  const canViewCommentthread = canDo(Permissions.ViewCommentthread);

  // Negotiation
  const canAddNegotiation = canDo(Permissions.AddNegotiation);
  const canChangeNegotiation = canDo(Permissions.ChangeNegotiation);
  const canDeleteNegotiation = canDo(Permissions.DeleteNegotiation);
  const canViewNegotiation = canDo(Permissions.ViewNegotiation);

  // Comment
  const canAddComment = canDo(Permissions.AddComment);
  const canChangeComment = canDo(Permissions.ChangeComment);
  const canDeleteComment = canDo(Permissions.DeleteComment);
  const canViewComment = canDo(Permissions.ViewComment);

  // Event
  const canViewEvent = canDo(Permissions.ViewEvent);

  return {
    canAddNda,
    canChangeNda,
    canDeleteNda,
    canViewNda,

    canAddNdatemplate,
    canChangeNdatemplate,
    canDeleteNdatemplate,
    canViewNdatemplate,

    canAddProject,
    canChangeProject,
    canDeleteProject,
    canViewProject,

    canAddProjectbranch,
    canChangeProjectbranch,
    canDeleteProjectbranch,
    canViewProjectbranch,

    canAddSignergroup,
    canChangeSignergroup,
    canDeleteSignergroup,
    canViewSignergroup,

    canAddSignership,
    canChangeSignership,
    canDeleteSignership,
    canViewSignership,

    canAddPointer,
    canChangePointer,
    canDeletePointer,
    canViewPointer,

    canAddCommentthread,
    canChangeCommentthread,
    canDeleteCommentthread,
    canViewCommentthread,

    canAddNegotiation,
    canChangeNegotiation,
    canDeleteNegotiation,
    canViewNegotiation,

    canAddComment,
    canChangeComment,
    canDeleteComment,
    canViewComment,

    canViewEvent,
  };
}

export default usePermissions;
