import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  OneProjectType,
  projectBranches as apiProjectBranches,
  ProjectBranchType,
  projects as apiProjects,
  ReportsFilter,
} from '../../../api';
import {
  GroupedSelectOptions,
  MultiSelect,
  SelectOption,
} from '../../../atoms';
import { usePermissions } from '../../../hooks';
import { Colors, Fonts, rem } from '../../../themes';

type MultiSelectOptions = (SelectOption | GroupedSelectOptions)[];

type Props = {
  setNewFilter: (filter: ReportsFilter) => void;
};

function FilterSelect({ setNewFilter }: Props) {
  const { canViewProject } = usePermissions();

  const [projects, setProjects] = useState<OneProjectType[] | null>(null);
  const [branches, setBranches] = useState<ProjectBranchType[]>([]);

  const [options, setOptions] = useState<MultiSelectOptions>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    canViewProject &&
      apiProjects.getProjects().then(({ data }) => setProjects(data));
  }, [canViewProject]);

  useEffect(() => {
    apiProjectBranches
      .getAllProjectBranches()
      .then(({ data }) => setBranches(data));
  }, []);

  const isProject = (option: SelectOption) => option.value.includes('project');
  const isBranch = (option: SelectOption) => option.value.includes('branch');
  const getProjectId = (option: SelectOption) => {
    if (option.value.includes('project')) {
      return option.value.split(' ')[1];
    }
    return option.value.split(' ')[1].split('.')[0];
  };
  const getBranchById = (option: SelectOption) =>
    option.value.split(' ')[1].split('.')[1];

  // create filter for API request
  useEffect(() => {
    const filter = selectedOptions.reduce<ReportsFilter>((acc, oneOption) => {
      if (isProject(oneOption)) {
        const projectId = getProjectId(oneOption);
        if (acc?.projects) {
          return { ...acc, projects: [...acc.projects, Number(projectId)] };
        }

        return { ...acc, projects: [Number(projectId)] };
      }

      if (isBranch(oneOption)) {
        const branchId = getBranchById(oneOption);
        if (acc?.projectBranches) {
          return {
            ...acc,
            projectBranches: [...acc.projectBranches, Number(branchId)],
          };
        }

        return { ...acc, projectBranches: [Number(branchId)] };
      }

      return acc;
    }, {});

    setNewFilter(filter);
  }, [selectedOptions, setNewFilter]);

  // create options for MultiSelect from user project and projectBranches
  useEffect(() => {
    if (!projects) return;

    const apiProjectOptions = projects.reduce<MultiSelectOptions>(
      (acc, project) => {
        const branchesOptions = [
          ...branches
            .filter(branch => branch.project === project.id)
            .map(branch => ({
              label: `Group: ${branch.name}`,
              value: `branch ${branch.project}.${branch.id}`,
            })),
        ];

        if (branchesOptions.length === 0) {
          return [
            ...acc,
            {
              label: `Project: ${project.name}`,
              value: `project ${project.id}`,
            },
          ];
        }

        return project.name
          ? [
              ...acc,
              {
                label: `Project: ${project.name}`,
                value: `project ${project.id}`,
              },
              {
                label: `Project: ${project.name}`,
                options: branchesOptions,
              },
            ]
          : [...acc];
      },

      [],
    );

    setOptions(apiProjectOptions);
  }, [projects, branches]);

  // custom onSelectChange for select all branches in project
  const onSelectChange = (newOptions: SelectOption[]) => {
    // prevOptions beter then selectedOptions for reading code
    const prevOptions = selectedOptions;
    const allOptions = options.reduce<SelectOption[]>((acc, oneOption) => {
      if ((oneOption as SelectOption)?.value) {
        return [...acc, oneOption as SelectOption];
      }
      return [...acc, ...(oneOption as GroupedSelectOptions)?.options];
    }, []);

    // WHEN REMOVE ONE OPTION
    if (prevOptions?.length > newOptions?.length) {
      const optionValues = newOptions.map(oneOption => oneOption.value);
      const deletedOption = prevOptions.find(
        oneOption => !optionValues.includes(oneOption.value),
      ) as SelectOption;
      const projectId = getProjectId(deletedOption);

      // When remove main project option
      // Also we need remove all projectBranch options
      if (isProject(deletedOption)) {
        setSelectedOptions(
          newOptions.filter(oneOption => getProjectId(oneOption) !== projectId),
        );
        return;
      }

      // When remove branch option
      // Also we need remove main project option
      setSelectedOptions(
        newOptions.filter(
          oneOption => oneOption.value !== `project ${projectId}`,
        ),
      );
      return;
      // FINISH REMOVE ONE OPTION BLOCK
    }

    // WHEN ADD ONE OPTION
    if (prevOptions?.length < newOptions?.length) {
      const prevOptionsValues = prevOptions.map(oneOption => oneOption.value);
      const addedOption = newOptions.find(
        oneOption => !prevOptionsValues.includes(oneOption.value),
      ) as SelectOption;
      const projectId = getProjectId(addedOption);

      // When add main project option
      // Also we need add all projectBranch options
      if (isProject(addedOption)) {
        // newOptions
        const optionsWithoutProject = newOptions.filter(oneOption => {
          if (isBranch(oneOption) && getProjectId(oneOption) === projectId)
            return false;

          if (isProject(oneOption) && getProjectId(oneOption) === projectId)
            return false;

          return true;
        });

        const newOptionsWithAllProjectBranches = [
          addedOption,
          ...optionsWithoutProject,
          ...allOptions.filter(
            oneOption =>
              isBranch(oneOption) && getProjectId(oneOption) === projectId,
          ),
        ];

        setSelectedOptions(newOptionsWithAllProjectBranches);
        return;
      }

      // When add branch option
      const allGroupBranches = allOptions.filter(
        oneOption =>
          isBranch(oneOption) && getProjectId(oneOption) === projectId,
      );
      const allSelectedGroupBranches = newOptions.filter(
        oneOption =>
          isBranch(oneOption) && getProjectId(oneOption) === projectId,
      );

      // If we add last branch option
      // Also we need add main project option
      if (
        allGroupBranches.length === allSelectedGroupBranches.length &&
        !newOptions.find(
          oneOption => oneOption.value === `project ${projectId}`,
        )
      ) {
        const mainGroup = allOptions.find(
          oneOption => oneOption.value === `project ${projectId}`,
        ) as SelectOption;
        setSelectedOptions([...newOptions, mainGroup]);
        return;
      }

      // If we add not last branch option
      // set in state newOptions without changes
      setSelectedOptions(newOptions);
      // FINISH ADD ONE OPTION BLOCK
    }
  };

  return (
    <Wrapper>
      <Title>Reports</Title>
      <CustomMultiSelect
        options={options}
        value={selectedOptions}
        onChange={option => onSelectChange(option as SelectOption[])}
        placeholder="Filter by projects or groups..."
      />
    </Wrapper>
  );
}

const CustomMultiSelect = styled(MultiSelect)`
  width: 75%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

const Title = styled.p`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export default FilterSelect;
