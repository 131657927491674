import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Popover } from '../atoms';
import { Colors } from '../themes';

type Props = {
  className?: string;
  children: ReactElement;
  isHidePopover?: boolean;
  offsetY?: number;
  offsetX?: number;
  isDisabled?: boolean;
};

function ActionsButton({
  className,
  children,
  isHidePopover,
  offsetY,
  offsetX,
  isDisabled,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    isHidePopover && setAnchorEl(null);
  }, [isHidePopover]);

  const handleActionsOpen = (event: MouseEvent<HTMLElement>) => {
    if (isDisabled) return;

    setAnchorEl(event.currentTarget);
  };

  const onOverlayClick = (event: MouseEvent<HTMLElement>) => {
    if (event.currentTarget !== event.target) {
      return;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        isDisabled={isDisabled}
        className={className}
        onClick={handleActionsOpen}
      >
        <Dot /> <Dot /> <Dot />
      </Button>
      <Popover
        onOverlayClick={onOverlayClick}
        refElem={anchorEl}
        offsetY={offsetY}
        offsetX={offsetX}
      >
        {children}
      </Popover>
    </>
  );
}

const Button = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${Colors.DarkGrey};

  &:not(:last-child) {
    margin-right: 3px;
  }
`;

export default ActionsButton;
