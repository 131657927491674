/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonEllipse } from '../../../atoms';
import ChooseSignatureModal from '../../../organisms/ChooseSignatureModal';
import { selectors } from '../../../store';
import { Colors, Fonts, rem } from '../../../themes';

type Props = {
  refreshSignature: () => void;
  imageUrl: string;
  isAddedSign: boolean;
};

function SignatureBlock({ refreshSignature, imageUrl, isAddedSign }: Props) {
  const user = useSelector(selectors.user);

  const [isOpenChoiceModal, setIsOpenChoiceModal] = useState(false);

  return (
    <Wrapper>
      <ChooseSignatureModal
        isOpenChoiceModal={isOpenChoiceModal}
        setIsOpenChoiceModal={setIsOpenChoiceModal}
        refreshSignature={refreshSignature}
        userName={`${user.firstName} ${user.lastName}`}
      />

      <Container>
        {isAddedSign ? (
          imageUrl ? (
            <SignatureImage
              width="240px"
              height="100px"
              src={imageUrl}
              alt="Signature"
            />
          ) : (
            <h3>Loading...</h3>
          )
        ) : null}

        <UpdateSignature
          text={isAddedSign ? 'Update the signature' : 'Add a signature'}
          onClick={() => setIsOpenChoiceModal(true)}
        />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignatureImage = styled.img`
  height: 60px;
  width: auto;
  margin-bottom: 20px;
`;
const UpdateSignature = styled(ButtonEllipse)`
  border-radius: 15px;
  color: ${Colors.White};
  font-size: ${rem(11.25)};
  background-color: ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  border-radius: 5px;
  margin: 0;
  height: auto;
  width: auto;
  padding: 13px 30px;
  &:hover {
    background-color: ${Colors.SkySecondaryBlue};
  }
`;

export default SignatureBlock;
