import { AxiosResponse } from 'axios';

import api from '../authApi';

const getCimPdf = (id: number): Promise<AxiosResponse> =>
  api.get(`/projects/projects/${id}/get_cim_pdf/`, {
    responseType: 'blob',
  });

const cim = {
  getCimPdf,
};

export default cim;
