import styled from 'styled-components';

import { ProductType, Subscription } from '../../../../api';
import OnePlan from './OnePlan';

type Props = {
  allPlans: ProductType[];
  userSubscription: Subscription;
  selectedPeriod: string;
  membersCount: number;
  currencySymbol: string;
};

function Plans({
  allPlans,
  userSubscription,
  selectedPeriod,
  membersCount,
  currencySymbol,
}: Props) {
  const mainPlan = allPlans.find(item =>
    item.name.toLowerCase().includes('bulge'),
  );
  return (
    <Wrapper>
      <OnePlan
        freePlan
        chosen={!userSubscription?.product}
        membersCount={membersCount}
        currencySymbol={currencySymbol}
      />
      {allPlans
        .filter(plan => !plan.additionalProjectLifetime)
        .sort((a, b) => a.prices[0]?.unitAmount - b.prices[0]?.unitAmount)
        .map(plan => (
          <OnePlan
            key={plan.chargebeeProductId}
            onePlan={plan}
            chosen={
              plan.chargebeeProductId ===
              userSubscription?.product?.chargebeeProductId
            }
            selectedPeriod={selectedPeriod}
            membersCount={membersCount}
            currencySymbol={currencySymbol}
            mainPlan={mainPlan}
          />
        ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

export default Plans;
