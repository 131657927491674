import { AxiosResponse } from 'axios';

import api from '../authApi';
import {
  GetAllDashboardsList,
  PaginationDashboardLimit as Limit,
  SendAllSignersNda,
  SendOneSignerNda,
} from './types';

const getAllDashboardInfo = (
  id: number,
  limit?: number,
): Promise<AxiosResponse<GetAllDashboardsList>> =>
  api.get(`/projects/dashboard/?project=${id}&limit=${limit ?? Limit}`);

const sendNdaToAllSigners = (branchId: number, body: SendAllSignersNda) =>
  api.post(`/projects/dashboard/${branchId}/send_nda_to_all_signers/`, body);

const sendNdaToOneSigners = (branchId: number, body: SendOneSignerNda) =>
  api.post(`/projects/dashboard/${branchId}/send_nda_to_signer/`, body);

const signNdaBySigner = (branchId: number) =>
  api.get(`/projects/dashboard/${branchId}/sign/`);

const rejectNdaBySigner = (branchId: number) =>
  api.get(`/projects/dashboard/${branchId}/reject/`);

const dashboard = {
  getAllDashboardInfo,
  sendNdaToAllSigners,
  sendNdaToOneSigners,
  signNdaBySigner,
  rejectNdaBySigner,
};

export default dashboard;
