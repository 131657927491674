import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

const ButtonEllipse = styled(
  ({ className, text, onClick, type, disabled, ...props }) => (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {text}
    </button>
  ),
)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid ${Colors.Transparent};
  outline: none;
  background-color: ${Colors.White};

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:disabled {
    background-color: ${Colors.DarkBlue};
    &:hover {
      color: ${Colors.White};
      background-color: ${Colors.DarkBlue};
      border: 1px solid ${Colors.DarkBlue};
    }
  }

  &:hover {
    background-color: ${Colors.White};
    color: ${Colors.DarkBlack};
    border: 1px solid ${Colors.White};
  }
`;

export default ButtonEllipse;
