import { memo } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { Reports } from '../../../api';

type Props = {
  chartData: Reports;
};

const COLORS = ['#0088FE', '#00C49F'];

const PIESECONDCOLORS = ['#FFBB28', '#FF8042'];

function CustomLineChart({ chartData }: Props) {
  const {
    numberOfUnsignedNda,
    numberOfSignedNda,
    numberOfActiveNda,
    numberOfRecalledNda,
  } = chartData;

  const data = [
    { name: 'Signed NDA', value: numberOfSignedNda },
    { name: 'Unsigned NDA', value: numberOfUnsignedNda },
  ];

  const allNdaData = [
    { name: 'Active NDA', value: numberOfActiveNda },
    { name: 'Recalled NDA', value: numberOfRecalledNda },
  ];

  const emptyData = [{ name: 'No Data', value: 0 }];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    value,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? 'start' : 'end'}
          style={{ fontSize: '14px' }}
        >
          {`${value}`}
        </text>
        <text
          x={x}
          y={y + 14}
          fill="black"
          textAnchor={x > cx ? 'start' : 'end'}
          style={{ fontSize: '14px' }}
        >
          {`${name}`}
        </text>
      </>
    );
  };

  const dataCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    value,
  }: any) => {
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? 'start' : 'end'}
          style={{ fontSize: '14px' }}
          dominantBaseline="central"
        >
          {`${value} ${name}`}
        </text>
      </>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart height={320} width={500} margin={{ bottom: 0 }}>
        <Pie
          data={allNdaData.length ? allNdaData : emptyData}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          outerRadius={100}
          labelLine={false}
          cy="80%"
          cx="50%"
          label={renderCustomizedLabel}
        >
          {allNdaData.map((entry, index) => (
            <Cell
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${index}`}
              fill={PIESECONDCOLORS[index % PIESECONDCOLORS.length]}
            />
          ))}
        </Pie>
        <Pie
          data={data.length ? data : emptyData}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          cx="50%"
          cy="80%"
          innerRadius={120}
          outerRadius={170}
          label={dataCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>

        <Tooltip />
        <Legend
          verticalAlign="bottom"
          height={60}
          iconType="circle"
          iconSize={10}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default memo(CustomLineChart);
