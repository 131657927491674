import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { me as apiMe } from '../../../api';
import SVG from '../../../assets/svg';
import { GeoInput, Input } from '../../../atoms';
import SolidButton from '../../../atoms/SolidButton';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  isEmptyInput,
  setInitialValue,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import { refreshUser, selectors } from '../../../store';
import { Colors, Fonts, rem } from '../../../themes';

function MyProfile() {
  const { showError } = useNotification();
  const { firstName, lastName, email, location, linkedinLink, isMember } =
    useSelector(selectors.user);
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const [newFirstName, setNewFirstName] = useState(setInitialValue(firstName));
  const [newLastName, setNewLastName] = useState(setInitialValue(lastName));
  const [newLocation, setNewLocation] = useState(
    setInitialValue(location ?? ''),
  );
  const [newLinkedinLink, setNewLinkedinLink] = useState(
    setInitialValue(linkedinLink ?? ''),
  );

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(newFirstName, setNewFirstName, Errors.EnterFirstName)) {
      isValid = false;
    }

    if (isEmptyInput(newLastName, setNewLastName, Errors.EnterLastName)) {
      isValid = false;
    }

    return isValid;
  };

  const editUser = () => {
    if (!validate()) return;

    apiMe
      .editUser({
        firstName: newFirstName.value,
        lastName: newLastName.value,
        location: newLocation.value,
        linkedinLink: newLinkedinLink.value,
      })
      .then(({ data }) => {
        dispatch(refreshUser(data));
        setEditMode(false);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.firstName, setNewFirstName);
        apiFieldErrorHandler(data.lastName, setNewLastName);
        apiFieldErrorHandler(data.newLocation, setNewLocation);
        apiFieldErrorHandler(data.newLinkedinLink, setNewLinkedinLink);
      });
  };

  const cancelEdit = () => {
    setNewFirstName(setInitialValue(firstName));
    setNewLastName(setInitialValue(lastName));
    setNewLocation(setInitialValue(location ?? ''));
    setNewLinkedinLink(setInitialValue(linkedinLink ?? ''));
    setEditMode(false);
  };

  return (
    <Container>
      <TopContainer>
        <DetailText>Personal Details</DetailText>
        <DeleteAccount href="mailto:info@namda.io?subject=I want delete my account">
          <img src={SVG.deleteImg} alt="" /> Delete Account
        </DeleteAccount>
      </TopContainer>
      <RowWrapper>
        <RowColWrapper>
          <InputLabel>First Name</InputLabel>
          <InputWrapper>
            <StyledInput
              value={newFirstName.value}
              onChange={handleInputChange(setNewFirstName)}
              onBlur={handleInputBlur(newFirstName, setNewFirstName)}
              placeholder="-"
              error={newFirstName.errorMsg}
              disabled={!editMode}
              maxLength={30}
            />
          </InputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Last Name</InputLabel>
          <InputWrapper>
            <StyledInput
              value={newLastName.value}
              onChange={handleInputChange(setNewLastName)}
              onBlur={handleInputBlur(newLastName, setNewLastName)}
              placeholder="-"
              error={newLastName.errorMsg}
              disabled={!editMode}
              maxLength={30}
            />
          </InputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Email</InputLabel>
          <InputValueText>{email || '-'}</InputValueText>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Role</InputLabel>
          <InputValueText>
            {isMember ? 'team member' : 'admin / signer'}
          </InputValueText>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Location</InputLabel>
          <InputWrapper>
            {editMode ? (
              <GeoInput location={newLocation} setLocation={setNewLocation} />
            ) : (
              <InputValueText>{newLocation.value || '-'}</InputValueText>
            )}
          </InputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>LinkedIn URL</InputLabel>
          <InputWrapper>
            <StyledInput
              value={newLinkedinLink.value}
              onChange={handleInputChange(setNewLinkedinLink)}
              onBlur={handleInputBlur(newLinkedinLink, setNewLinkedinLink)}
              placeholder="-"
              error={newLinkedinLink.errorMsg}
              disabled={!editMode}
            />
          </InputWrapper>
        </RowColWrapper>
      </RowWrapper>
      <EditButtons>
        {editMode ? (
          <>
            <CancelButton text="Cancel" onClick={cancelEdit} />
            <StyledButton text="Save" onClick={editUser} />
          </>
        ) : (
          <StyledButton text="Edit" onClick={() => setEditMode(true)} />
        )}
      </EditButtons>
    </Container>
  );
}

const Container = styled.div`
  font-family: ${Fonts.Sofia};
  padding: 30px;
`;

export const RowWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RowColWrapper = styled.div`
  width: calc(50% - 15px);
  padding: 15px 0;
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const DetailText = styled(Text)`
  font-size: ${rem(12.5)};
`;
export const InputLabel = styled(Text)`
  font-size: ${rem(11.25)};
  margin-bottom: 6px;
`;

export const InputValue = styled.div`
  padding: 7px;
  color: ${Colors.LightSecondGrey};
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 6px;
  width: 100%;
  font-size: ${rem(11)};
  font-family: ${Fonts.Sofia};
`;

const InputValueText = styled(InputValue)`
  height: 52px;
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > *:not(:first-child) {
    font-size: ${rem(8)};
  }
`;

export const StyledInput = styled(Input)`
  font-size: ${rem(11)};
  padding: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  height: 52px;
  color: ${Colors.LightSecondGrey};
  ::placeholder {
    color: ${Colors.LightSecondGrey};
  }
`;

export const EditButtons = styled.div`
  display: flex;
  width: fit-content;
  margin: 31px auto 0px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

// TODO add support mail
const DeleteAccount = styled.a`
  font-size: ${rem(9)};
  color: ${Colors.DarkRed};
  text-decoration: none;
  cursor: pointer;
  font-family: ${Fonts.Sofia};
  padding: 10px 20px;
  border-radius: 5px;
  background-color: ${Colors.SecondaryRed};
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
  height: auto;
  width: auto;
  padding: 13px 41px;
`;

export const CancelButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
  padding: 13px 41px;
  width: auto;
  height: auto;
`;

export default MyProfile;
