import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  ErrorResponse,
  members as apiMembers,
  projects as apiProjects,
} from '../../../../api';
import { ButtonEllipse, DotsLoading } from '../../../../atoms';
import { Errors } from '../../../../const';
import { isEmail } from '../../../../helpers';
import { useNotification } from '../../../../hooks';
import ErrorText from '../../../../molecules/ErrorText';
import { selectors } from '../../../../store';
import { BoxShadow, Colors, Fonts, rem } from '../../../../themes';
import AddedMember from './AddedMember';
import OneMember, { MemberTypeChecked as Member } from './OneMember';

type Props = {
  projectId: number;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddPlanQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  projectMembersId: number[];
  setProjectMembersId: React.Dispatch<React.SetStateAction<number[]>>;
  setAllUserMembers: React.Dispatch<React.SetStateAction<Member[]>>;
  allUserMembers: Member[];
};

function TeamMembersForm({
  projectId,
  isOpenModal,
  setIsOpenModal,
  setIsAddPlanQuantity,
  projectMembersId,
  setProjectMembersId,
  setAllUserMembers,
  allUserMembers,
}: Props) {
  const { showError } = useNotification();
  const maxAllowedMembers = useSelector(selectors.maxAllowedMembers);

  const [addedMembers, setAddedUserMembers] = useState<Member[]>([]);
  const [membersError, setMembersError] = useState('');

  const [notAddedMembers, setNotAddedMembers] = useState<Member[]>([]);

  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);

  const [filter, setFilter] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const canAddMember = isEmail(filter) && filteredMembers.length === 0;

  useEffect(() => {
    if (!isOpenModal) {
      setFilteredMembers(notAddedMembers);
    }
  }, [isOpenModal, notAddedMembers]);

  useEffect(() => {
    const notAdded = allUserMembers.filter(
      member => !projectMembersId.includes(member.id),
    );
    const added = allUserMembers.filter(member =>
      projectMembersId.includes(member.id),
    );

    setNotAddedMembers(notAdded);
    setFilteredMembers(notAdded);
    setAddedUserMembers(added);
  }, [allUserMembers, projectMembersId]);

  const filterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!allUserMembers.length) {
      if (isEmail(value)) {
        setMembersError('');
      } else {
        setMembersError(Errors.EnterValidEmail);
      }
    }

    setFilter(value);
    setFilteredMembers(
      notAddedMembers.filter(
        member =>
          value === '' ||
          member.firstName.toLowerCase().includes(value.toLowerCase()) ||
          member.lastName.toLowerCase().includes(value.toLowerCase()) ||
          member.email.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const onChangeChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;

    setMembersError('');

    setFilteredMembers(prev => [
      ...prev.map(member =>
        Number(id) === member.id ? { ...member, checked } : member,
      ),
    ]);
  };

  const addNewMember = async () => {
    if (allUserMembers.length >= maxAllowedMembers) {
      showError(Errors.NotEnoughMembers);
      setFilter('');
      setIsOpenModal(false);
      setIsAddPlanQuantity(true);
      return;
    }

    if (!allUserMembers.length) {
      if (filter === '') {
        setMembersError(Errors.EnterEmail);
        return;
      }
      if (isEmail(filter)) {
        setMembersError('');
      } else {
        setMembersError(Errors.EnterValidEmail);
        return;
      }
    }

    setMembersError('');

    setIsLoading(true);
    try {
      const { data: newMember } = await apiMembers.createMember({
        email: filter,
      });

      setAllUserMembers(prev => [...prev, { ...newMember, checked: false }]);

      const { data } = await apiProjects.updateProject(projectId, {
        members: [...projectMembersId, newMember.id],
      });
      setProjectMembersId(data.members);
    } catch (error) {
      const { data } = error as ErrorResponse;

      if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
      if (data.detail) showError(data.detail as string);
      if (data.email) showError(data.email[0]);
      if (data.members) showError(data.members[0]);
    } finally {
      setFilter('');
      setIsLoading(false);
    }
  };

  const addSelectedMember = () => {
    const selectedMembers = filteredMembers
      .filter(member => member.checked)
      .map(member => member.id);

    if (selectedMembers.length === 0) {
      setMembersError('You have not selected any team member');
      return;
    }

    setMembersError('');

    const addedMembersId = addedMembers.map(member => member.id);

    setIsLoading(true);

    apiProjects
      .updateProject(projectId, {
        members: [...addedMembersId, ...selectedMembers],
      })
      .then(res => {
        setProjectMembersId(res.data.members);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setIsLoading(false));
  };

  const removeFromProject = (memberId: number) => {
    const newProjectMembersId = addedMembers
      .filter(member => member.id !== memberId)
      .map(member => member.id);

    setIsLoading(true);

    apiProjects
      .updateProject(projectId, { members: newProjectMembersId })
      .then(res => {
        setProjectMembersId(res.data.members);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <FilterInput
        value={filter}
        onChange={filterChange}
        placeholder={
          allUserMembers.length > 0
            ? 'Type to search for team members...'
            : 'Type to add a new team member...'
        }
        isEmail={
          membersError === Errors.EnterValidEmail ||
          membersError === Errors.EnterEmail
        }
      />

      <Container>
        {isLoading ? (
          <DotsLoading />
        ) : (
          <>
            {allUserMembers.length > 0 ? (
              <>
                <MembersBox>
                  {filteredMembers.map(member => (
                    <OneMember
                      key={member.id}
                      checked={member.checked}
                      member={member}
                      onChangeChecked={onChangeChecked}
                    />
                  ))}
                </MembersBox>
                <MembersBox>
                  {addedMembers.map(member => (
                    <AddedMember
                      key={member.id}
                      member={member}
                      removeFromProject={removeFromProject}
                    />
                  ))}
                </MembersBox>
              </>
            ) : (
              <DescriptionBlock>
                You have no team members yet
                <p>Enter the email address of the person you want to invite.</p>
              </DescriptionBlock>
            )}
          </>
        )}

        {canAddMember && (
          <DescriptionBlock>
            This user is not team member yet.
            <p>Send him a invitation to add to the system.</p>
          </DescriptionBlock>
        )}

        {membersError && notAddedMembers.length > 0 && (
          <StyledError>{membersError}</StyledError>
        )}

        <AddMemberBlock>
          {!allUserMembers.length ? (
            <AddMemberButton
              text={isLoading ? 'Loading...' : '+ Add a new team member'}
              onClick={addNewMember}
              disabled={isLoading}
            />
          ) : (
            <>
              {allUserMembers.length !== addedMembers.length && (
                <AddMemberButton
                  text={
                    isLoading ? 'Loading...' : 'Add team members to project'
                  }
                  onClick={addSelectedMember}
                  disabled={isLoading}
                />
              )}
            </>
          )}
        </AddMemberBlock>
      </Container>
    </>
  );
}

const StyledError = styled(ErrorText)`
  font-size: ${rem(8)};
  text-align: center;
  padding-bottom: 6px;
`;

interface FilterProps {
  isEmail: boolean;
}

const FilterInput = styled.input<FilterProps>`
  display: block;
  width: 100%;
  padding: 5px 15px;
  height: 30px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  box-shadow: ${BoxShadow.Base};
  margin-bottom: 10px;

  ${props =>
    props.isEmail &&
    css`
      border: 1.5px solid ${Colors.DarkRed};
      box-shadow: none;
    `}

  ::placeholder {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px;
  background: #ffffff;
  box-shadow: ${BoxShadow.Base};
  border-radius: 10px;
`;

const MembersBox = styled.div``;

const AddMemberBlock = styled.div`
  padding: 15px 15px 5px;
  border-top: 1px solid ${Colors.DarkGrey};
`;

const DescriptionBlock = styled.div`
  padding: 20px;
  font-size: ${rem(8)};
  text-align: center;

  & p {
    font-size: ${rem(9)};
  }
`;

const AddMemberButton = styled(ButtonEllipse)`
  width: 100%;
  height: 35px;
  margin: 0 auto;
  font-size: ${rem(8)};
  border: 1px solid ${Colors.DarkGrey};
  border-radius: 5px;
  color: ${Colors.DarkGrey};
`;

export default TeamMembersForm;
