import styled from 'styled-components';

import IMG from '../../../assets/IMG';
import SVG from '../../../assets/svg';
import { Colors, Fonts, rem } from '../../../themes';

function SendAndNegotiate() {
  return (
    <Wrraper>
      <ImgSec src={IMG.Negotiate} alt="negotiate" />
      <ContentWrapper>
        <Title>
          The first step of your M&A process is sending and sometimes
          negotiating a NDA with potential buyers
        </Title>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            The first step of selling a business is sending and sometimes
            negotiating a NDA with potential buyers.
          </Details>
        </CheckWrapper>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            It’s a long and painful process, juggling between hundreds of
            e-mails back and fourth.
          </Details>
        </CheckWrapper>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            99% of these NDA’s will are useless as only one party will end up
            acquiring the business.
          </Details>
        </CheckWrapper>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            It’s an annoying and low value process not easy to track. Namda is
            changing that ! Automate your NDA process.
          </Details>
        </CheckWrapper>
      </ContentWrapper>
    </Wrraper>
  );
}

const Wrraper = styled.div`
  display: flex;
  padding: 65px 50px 50px 50px;
  gap: 65px;
  @media (max-width: 768px) {
    display: block;
    gap: 0;
    padding: 0;
  }
`;

const ImgSec = styled.img`
  width: 100%;
  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div``;

const Title = styled.p`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(18.75)};
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const Details = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
`;

const Img = styled.img`
  margin-top: 7px;
  margin-right: 12px;
`;
export default SendAndNegotiate;
