import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import {
  ColorType,
  DashboardGroupType,
  nda as apiNda,
  OneNdaType,
  projectBranches as apiBranch,
} from '../../../../api';
import SVG from '../../../../assets/svg';
import { HidenBoxHovered, Modal, Popover } from '../../../../atoms';
import { routeTemplate } from '../../../../helpers';
import { useNotification } from '../../../../hooks';
import { ConfirmDelete } from '../../../../molecules';
import { ActionsButton, AddNda, EditNameBox } from '../../../../organisms';
import ColorActionButton from '../../../../organisms/ColorActionButton';
import { Routes } from '../../../../routes';
import { Colors, rem } from '../../../../themes';
import ColorChanger, { ColorDisc } from './ColorChanger';
import GroupActions from './GroupActions';
import ModalAddRecipients from './ModalAddRecipients';
import { Container } from './RecipientActions';
import { ContextTable } from './Table';

type Props = {
  projectId: number;
  groupId: number;
  groupName: string;
  groupUsers: number[];
  nda: OneNdaType | null;
  isShowRows: boolean;
  setIsShowRows: Dispatch<SetStateAction<boolean>>;
  isSignerOfProject: boolean;
  colorStatus: ColorType;
  resendNdaForGroup: () => void;
  ndaSigning: ColorType | null | undefined;
  projectBranch: DashboardGroupType[] | undefined;
};

function SubTableHeader({
  projectId,
  groupId,
  groupName,
  groupUsers,
  nda,
  isShowRows,
  setIsShowRows,
  isSignerOfProject,
  colorStatus,
  resendNdaForGroup,
  ndaSigning,
  projectBranch,
}: Props) {
  const { showError } = useNotification();
  const history = useHistory();
  const { updateDashboard } = useContext(ContextTable);
  const anchorNameBlock = useRef<HTMLDivElement | null>(null);
  const anchorActionsButton = useRef<HTMLDivElement | null>(null);
  const [recallNdaMode, setRecallNdaMode] = useState(false);
  const [deleteNdaMode, setDeleteNdaMode] = useState(false);
  const [editNameMode, setEditNameMode] = useState(false);
  const [name, setName] = useState(groupName);

  const [isOpenAddRecipients, setIsOpenAddRecipients] = useState(false);
  const [isOpenModalAddNda, setIsOpenModalAddNda] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHidePopover, setIsHidePopover] = useState(false);
  useEffect(() => {
    isHidePopover && setIsHidePopover(false);
  }, [isHidePopover]);

  const onEditNameClick = () => {
    setEditNameMode(true);
    setIsHidePopover(true);
  };

  const onDeleteNdaClick = () => {
    if (!nda) return;
    apiNda
      .deleteNda(nda.id)
      .then(updateDashboard)
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setDeleteNdaMode(false));
  };

  const onRecallNdaClick = () => {
    if (!nda) return;
    apiNda
      .editNda(nda.id, { isRecalled: true })
      .then(updateDashboard)
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setRecallNdaMode(false));
  };

  const confirmEditName = (newName: string) => {
    if (isLoading) return;

    setIsLoading(true);
    apiBranch
      .updateProjectBranch(groupId, { name: newName })
      .then(({ data }) => {
        setName(data.name);
        setEditNameMode(false);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.name) showError(data.name[0]);
      })
      .finally(() => setIsLoading(false));
  };

  const cancelEditName = () => {
    if (isLoading) return;
    setEditNameMode(false);
  };

  const onOverlayClick = (event: MouseEvent<HTMLElement>) => {
    if (event.currentTarget !== event.target) return;

    cancelEditName();
  };

  const getColorStatusLabel = () => {
    switch (ndaSigning) {
      case 'red':
        return { label: 'Rejected', color: 'red' };
      case 'green':
        return { label: 'Completed', color: 'green' };
      case 'yellow':
        return { label: 'Pending', color: 'yellow' };
      default:
        return { label: '', color: 'red' };
    }
  };

  const showRecallAlert = () => {
    Swal.fire({
      title: 'Recall NDA',
      text: 'Are you sure, you want to recall group NDA?',
      iconHtml: `
      <img src="${SVG.recall}" alt="recall" width="40px" />`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      showLoaderOnConfirm: isLoading,
      preConfirm: onRecallNdaClick,
      customClass: {
        confirmButton: 'confirm-button',
        title: 'title',
        icon: 'icon',
        container: 'container',
      },
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        setRecallNdaMode(!recallNdaMode);
      }
    });
  };
  const unreadCount =
    projectBranch?.find(i => i.id === groupId)?.unresolvedCommentsCount ?? 0;
  return (
    <>
      <Modal
        modalIsOpen={isOpenAddRecipients}
        setModalIsOpen={setIsOpenAddRecipients}
      >
        <ModalAddRecipients
          branchId={groupId}
          branchName={groupName}
          groupUsers={groupUsers}
          closeModal={() => setIsOpenAddRecipients(false)}
        />
      </Modal>
      <Modal
        modalIsOpen={isOpenModalAddNda}
        setModalIsOpen={setIsOpenModalAddNda}
      >
        <AddNda
          branchId={groupId}
          addNda={() => {
            updateDashboard();
            setIsOpenModalAddNda(false);
          }}
          closeModal={() => setIsOpenModalAddNda(false)}
        />
      </Modal>

      {deleteNdaMode && (
        <Popover
          onOverlayClick={(event: MouseEvent) => {
            if (event.currentTarget !== event.target) return;
            setDeleteNdaMode(false);
          }}
          refElem={anchorActionsButton.current}
          offsetX={-100}
        >
          <ConfirmDelete
            title="Are you sure, you want to delete group NDA?"
            description="You won’t be able to undo this. NDA will disappear from this group and you’ll need to add it again."
            onConfirmClick={onDeleteNdaClick}
            onCancelClick={() => setDeleteNdaMode(false)}
          />
        </Popover>
      )}

      {editNameMode && (
        <Popover
          onOverlayClick={onOverlayClick}
          refElem={anchorNameBlock.current}
        >
          <EditNameBox
            isLoading={isLoading}
            name={name}
            confirmEditName={confirmEditName}
            cancelEditName={cancelEditName}
          />
        </Popover>
      )}

      <GroupHeader data-value="group-header">
        <Td>
          <GroupData>
            <ColorActionButton
              color={colorStatus}
              isHidePopover={isHidePopover}
            >
              <Container>
                <ColorChanger
                  color={colorStatus}
                  description="Tag group..."
                  updatedBranch={groupId}
                  updateDashboard={() => {
                    setIsHidePopover(true);
                    updateDashboard();
                  }}
                />
              </Container>
            </ColorActionButton>
            <GroupName ref={anchorNameBlock}>
              <Wrapper
                to={routeTemplate(Routes.userList, {
                  projectId: projectId.toString(),
                  branchId: groupId.toString(),
                })}
              >
                <HidenBoxHovered value={name} popoverWidth="300px" />
              </Wrapper>
            </GroupName>
          </GroupData>
        </Td>
        <Td>
          <GroupData />
        </Td>
        <Td>
          <GroupData />
        </Td>
        <Td>
          <StatusData color={getColorStatusLabel().color ?? 'red'}>
            <ColorDisc color={getColorStatusLabel().color ?? 'red'} />
            <span style={{ marginLeft: '10px' }}>
              {nda?.isRecalled ? 'Recalled' : getColorStatusLabel().label}
            </span>
          </StatusData>
        </Td>
        <Td>
          <GroupData>
            {unreadCount > 0 && (
              <Link
                to={routeTemplate(Routes.projectBranchNda, {
                  projectId: projectId.toString(),
                  branchId: groupId.toString(),
                })}
              >
                <img src={SVG.bell} alt="add" />
              </Link>
            )}
          </GroupData>
        </Td>
        <Td>
          <GroupData />
        </Td>
        <Td>
          <GroupData ref={anchorActionsButton}>
            <ActionsButton
              isDisabled={isSignerOfProject}
              isHidePopover={isHidePopover}
              offsetY={70}
            >
              <GroupActions
                branchId={groupId}
                color={colorStatus}
                isHasNda={Boolean(nda)}
                ndaIsSigned={Boolean(nda?.isSigned)}
                ndaIsSent={Boolean(nda?.isSent)}
                ndaIsRecalled={Boolean(nda?.isRecalled)}
                onEditNameClick={onEditNameClick}
                onAddRecipientsClick={() => {
                  history.push(
                    routeTemplate(Routes.updateRecipients, {
                      projectId: projectId.toString(),
                      branchId: groupId.toString(),
                    }),
                  );
                }}
                onLookNdaClick={() =>
                  history.push(
                    `${Routes.projects}/${projectId}/group/${groupId}/nda`,
                  )
                }
                onUploadNdaClick={() => {
                  setIsHidePopover(true);
                  setIsOpenModalAddNda(true);
                }}
                onRecallNdaClick={() => {
                  setIsHidePopover(true);
                  setRecallNdaMode(true);
                  showRecallAlert();
                }}
                onResendNdaClick={() => {
                  setIsHidePopover(true);
                  resendNdaForGroup();
                }}
                onDeleteNdaClick={() => {
                  setIsHidePopover(true);
                  setDeleteNdaMode(true);
                }}
                updateDashboard={() => {
                  setIsHidePopover(true);
                  updateDashboard();
                }}
              />
            </ActionsButton>
          </GroupData>
        </Td>
        <Td>
          <GroupData>
            {!isSignerOfProject && (
              <ToggleOpen
                isOpen={isShowRows}
                onClick={() => setIsShowRows(prev => !prev)}
              />
            )}
          </GroupData>
        </Td>
      </GroupHeader>
    </>
  );
}

const GroupHeader = styled.tr`
  height: 52px;
  font-size: ${rem(10)};
  background-color: ${Colors.White};

  &:last-child {
    & > :last-child {
      border-radius: 0 0 10px 0;
    }
    & > :first-child {
      border-radius: 0 0 0 10px;
    }
  }
`;

const Td = styled.td`
  padding: 0 0 0 0;
  overflow: visible;
  border: 1px solid rgba(2, 48, 97, 0.1);

  &:first-child {
    & > * {
      justify-content: flex-start;
      padding: 25px;
    }
    width: 20%;
  }

  &:last-child {
    & > * {
      position: relative;
    }
  }
`;

const GroupData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.WeakBlack};
  height: 60px;
  padding: 10px;
  text-align: center;
`;

const StatusData = styled(GroupData)`
  justify-content: left;

  color: ${props => {
    switch (props.color) {
      case 'red':
        return Colors.DarkRed;
      case 'yellow':
        return Colors.Yellow;
      case 'green':
        return Colors.Green;
      default:
        return Colors.WeakGrey;
    }
  }};
`;
const GroupName = styled.div`
  width: max-content;
  padding: 5px 15px;
  line-height: 1.2;
  white-space: nowrap;
  font-size: ${rem(11.25)};
  color: ${Colors.WeakBlack};
  display: flex;

  & > * {
    margin-right: 15px;
  }
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  color: ${Colors.Black};
`;
const ToggleOpen = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 10px;
    height: 10px;
    border-left: 2px solid ${Colors.TextGrey};
    border-bottom: 2px solid ${Colors.TextGrey};

    transform: translate(-50%, -50%)
      ${props => (props.isOpen ? 'rotate(135deg)' : 'rotate(-45deg)')};
  }
`;

export default SubTableHeader;
