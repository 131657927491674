import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { projects as apiProjects } from '../../api';
import SolidButton from '../../atoms/SolidButton';
import { Errors } from '../../const';
import { useNotification, usePermissions } from '../../hooks';
import { Loader, TeaserOrCim } from '../../molecules';
import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { Colors, rem } from '../../themes';

type Params = {
  projectId: string;
};

function Teaser() {
  const [teaser, setTeaser] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { canViewProject } = usePermissions();
  const { projectId } = useParams<Params>();
  const history = useHistory();
  const { showError } = useNotification();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!teaser) {
      showError(`${Errors.UploadFile} for teaser`);
      return;
    }
    if (teaser) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('teaser', teaser);
      apiProjects
        .updateProject(Number(projectId), formData)
        .then(({ data }) => {
          if (data.teaser) {
            setTeaser(data.teaser);
          }
          history.push(`${Routes.projects}/${projectId}`);
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);
          if (data.name) showError(data.name[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    canViewProject &&
      apiProjects
        .getOneProject(Number(projectId))
        .then(
          response => response.data.teaser && setTeaser(response.data.teaser),
        )
        .catch(error => error.status === '404' && history.push(Routes.projects))
        .finally(() => setIsLoading(false));
  }, [canViewProject, history, projectId]);

  return (
    <PageWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <TeaserOrCim value={teaser} onChange={setTeaser} name="Teaser" />
          <ButtonContainer>
            <StyledButton
              type="button"
              text="Cancel"
              onClick={() => history.push(`${Routes.projects}/${projectId}`)}
            />
            <StyledSubmitButton type="submit" text="Save" />
          </ButtonContainer>
        </StyledForm>
      )}
    </PageWrapper>
  );
}

const StyledForm = styled.form`
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
`;
export default Teaser;
