import { useMachine } from '@xstate/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { assign, createMachine } from 'xstate';

import {
  contacts as apiContacts,
  ContactType,
  projectBranches as apiProjectBranches,
  ProjectBranchType,
} from '../../api';
import { usePermissions } from '../../hooks';
import { Loader } from '../../molecules';
import { PageWrapper } from '../../templates';
import CreateGroupBlock from '../oneProject/components/ProjectBranch/CreateGroupBlock';
import { CurrentForm } from '../oneProject/components/ProjectBranch/ProjectBranch';

const choiseMachine = createMachine({
  id: 'currentForm',
  initial: CurrentForm.New,
  context: { prevForm: CurrentForm.New },
  states: {
    [CurrentForm.New]: {},
    [CurrentForm.Existing]: {},
    [CurrentForm.EditGroup]: {},
    [CurrentForm.FinalForm]: {},
    [CurrentForm.AddNdaForm]: {},
    [CurrentForm.SendMail]: {},
  },
  on: {
    [CurrentForm.New]: {
      target: CurrentForm.New,
      actions: assign({ prevForm: () => CurrentForm.New }),
    },
    [CurrentForm.Existing]: {
      target: CurrentForm.Existing,
      actions: assign({ prevForm: () => CurrentForm.Existing }),
    },
    [CurrentForm.EditGroup]: {
      target: CurrentForm.EditGroup,
      actions: assign({ prevForm: () => CurrentForm.EditGroup }),
    },
    [CurrentForm.FinalForm]: {
      target: CurrentForm.FinalForm,
    },
    [CurrentForm.AddNdaForm]: {
      target: CurrentForm.AddNdaForm,
    },
    [CurrentForm.SendMail]: {
      target: CurrentForm.SendMail,
    },
  },
});
type Params = {
  branchId?: string;
  projectId: string;
};

type ContactForm = {
  firstName: string;
  lastName: string;
  userId: number;
  email: string;
};
function Recipients() {
  const [{ value: currentForm, context: machineContext }, sendChoise] =
    useMachine(choiseMachine);
  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [defaultValues, setDefaultValues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { branchId } = useParams<Params>();
  const { canViewProjectbranch } = usePermissions();
  const [branchInfo, setBranchInfo] = useState<ProjectBranchType | null>(null);

  useEffect(() => {
    setLoading(true);
    apiContacts
      .getAllContact()
      .then(({ data }) => {
        setAllContacts(data);
        if (branchId) {
          canViewProjectbranch &&
            apiProjectBranches
              .getBranchById(Number(branchId))
              .then(({ data: branchData }) => {
                setBranchInfo(branchData);
                const contactArr: ContactForm[] = [];
                data.map((d: ContactType) => {
                  if (branchData.signers.includes(d.user.pk)) {
                    contactArr.push({
                      userId: d.id,
                      email: d.user.email,
                      firstName: d.firstName,
                      lastName: d.lastName,
                    });
                  }
                });
                setDefaultValues({
                  groupName: branchData.name,
                  contact:
                    contactArr.length === 0
                      ? [{ firstName: '', lastName: '', email: '' }]
                      : contactArr,
                });
              });
        } else {
          setDefaultValues({
            contact: [{ firstName: '', lastName: '', email: '' }],
            sendTeaser: false,
            allowComments: false,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [branchId]);

  return (
    <PageWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          {defaultValues && Object.keys(defaultValues).length > 0 && (
            <CreateGroupBlock
              goToExistingGroups={() => sendChoise(CurrentForm.Existing)}
              allUserContacts={allContacts}
              defaultValue={defaultValues}
              branchInfo={branchInfo}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
}

export default Recipients;
