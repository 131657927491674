import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { nda as apiNda, OneNdaType, TemplateType } from '../../api';
import SVG from '../../assets/svg';
import { ButtonEllipse, Input, Select, SelectOption } from '../../atoms';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

type Props = {
  projectId: number;
  addNda: (nda: OneNdaType) => void;
  goToChoices: () => void;
  branchId?: number;
  addTemplateNda?: (template: TemplateType, templateName: string) => void;
  templates: SelectOption[] | null;
  allTemplates: TemplateType[];
};

function FromTemplate({
  projectId,
  addNda,
  branchId,
  goToChoices,
  addTemplateNda,
  templates,
  allTemplates,
}: Props) {
  const { showError } = useNotification();

  const [name, setName] = useState(initialValue);
  const [oneTemplate, setOneTemplates] = useState<SelectOption | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (oneTemplate === null) {
      showError(Errors.ChouseTemplate);
      isValid = false;
    }

    if (isEmptyInput(name, setName, Errors.EnterField)) {
      isValid = false;
    }

    if (name.errorMsg) isValid = false;
    return isValid;
  };

  const addNdaToProject = () => {
    if (isLoading) return;
    if (!validate()) return;
    if (oneTemplate === null) return;

    if (addTemplateNda) {
      const selectedTemplate = allTemplates.find(
        template => template.id === Number(oneTemplate.value),
      );
      addTemplateNda(selectedTemplate as TemplateType, name.value);
      return;
    }

    const formNda = new FormData();
    if (branchId) {
      formNda.append('project_branch', `${branchId}`);
    } else {
      formNda.append('project', `${projectId}`);
    }
    formNda.append('template', oneTemplate.value);
    formNda.append('file', '');
    formNda.append('name', name.value);

    setIsLoading(true);
    apiNda
      .createOneNda(formNda)
      .then(res => addNda(res.data))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.name, setName);
      })
      .finally(() => setIsLoading(false));
  };

  if (Array.isArray(templates) && templates.length > 0) {
    return (
      <Container>
        <IconButton
          src={SVG.goBack}
          alt="go back button"
          onClick={goToChoices}
        />

        <CustomSelect
          options={templates}
          value={oneTemplate}
          onChange={option => {
            setOneTemplates(option);
          }}
        />

        <Description>
          Enter a new name for the file otherwise it will be saved the way it’s
          called.
        </Description>

        <InputWrapper>
          <Input
            disabled={isLoading}
            value={name.value}
            onChange={handleInputChange(setName)}
            onBlur={handleInputBlur(name, setName)}
            placeholder="NDA Name"
            error={name.errorMsg}
          />
        </InputWrapper>
        <StyledButton
          isLoading={isLoading}
          type="button"
          text={`Add NDA${isLoading ? '...' : ''}`}
          onClick={addNdaToProject}
        />
      </Container>
    );
  }

  if (Array.isArray(templates) && templates.length === 0) {
    return (
      <Container>
        <IconButton
          src={SVG.goBack}
          alt="go back button"
          onClick={goToChoices}
        />

        <InfoText>
          Add template or upload new document for this project
        </InfoText>
        <Link to={Routes.templates}>
          <StyledButton text="Go to templates" />
        </Link>
      </Container>
    );
  }

  return <></>;
}

const Container = styled.div`
  padding: 20px;
  height: 380px;
`;

const Description = styled.p`
  width: 400px;
  margin: 10px auto;
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  font-size: ${rem(10)};
  text-align: center;
`;

const IconButton = styled.img`
  width: 20px;
  cursor: pointer;
`;

const InfoText = styled.p`
  text-align: center;
  color: ${Colors.DarkGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  line-height: 1.4;
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  margin: 20px auto;
  width: 400px;
`;

const CustomSelect = styled(Select)`
  border-bottom: 1px solid ${Colors.Black};
  margin: 0px auto 50px;
  width: 400px;
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;

const StyledButton = styled(ButtonEllipse)<{ isLoading?: boolean }>`
  width: 150px;
  margin: 70px auto 0 auto;
  background-color: ${Colors.White};
  color: ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
  border: 1px solid
    ${props => (props.isLoading ? Colors.DarkGrey : Colors.Black)};
`;

export default FromTemplate;
