import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

import { Colors, Fonts, rem } from '../themes';

const StyledTabs = styled(Tabs)({
  '.MuiTabs-indicator': {
    backgroundColor: Colors.DarkBlue,
  },
});

const StyledTab = styled(Tab)(() => ({
  width: '141px',
  height: '46px',
  fontFamily: Fonts.Sofia,
  fontSize: rem(11.25),
  textTransform: 'capitalize',
  color: Colors.OffGrey,
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: 'normal',
  '&.Mui-selected': {
    color: Colors.White,
    background: Colors.DarkBlue,
    borderRadius: '10px 10px 0 0',
  },
}));

type SelectOptions = { value: string; label: string };

type SelectProps = {
  value: SelectOptions | null;
  onChange: (value: SelectOptions | null) => void;
  options: SelectOptions[];
};

export default function TabNav({ options, onChange, value }: SelectProps) {
  const selectedIndex = options.findIndex(
    option => option.value === value?.value,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange && options[newValue]) {
      onChange(options[newValue]);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginY: '25px' }}>
        <StyledTabs value={selectedIndex} onChange={handleChange}>
          {options.map(option => (
            <StyledTab key={option.value} label={option.label} />
          ))}
        </StyledTabs>
      </Box>
    </Box>
  );
}
