import styled from 'styled-components';

import { PageWrapper } from '../../templates';
import ContactUs from './ContactUs';
import HeroSection from './HeroSection';
import Features from './ourFeatures';
import SavingTime from './SavingTime';
import Testimonials from './Testimonials';
import WhyChoose from './WhyChoose';

function Home() {
  return (
    <Wrapper>
      <HeroSection />
      <WhyChoose />
      <Features />
      <SavingTime />
      <Testimonials />
      <ContactUs />
    </Wrapper>
  );
}

const Wrapper = styled(PageWrapper)`
  padding: 0;
  width: 100%;
`;

export default Home;
