import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

const ErrorText = styled.div`
  margin-top: 10px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
`;

export default ErrorText;
