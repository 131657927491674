/* eslint-disable no-nested-ternary */
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContactType } from '../../../../api';
import { ButtonEllipse, FilterInput } from '../../../../atoms';
import { NewContactForm } from '../../../../organisms';
import { Colors } from '../../../../themes';
import Table from './Table';

type Contact = ContactType & { checked: boolean };

type Props = {
  groupContacts: Contact[];
  handleContactCheck: (
    props: ChangeEvent<HTMLInputElement> | Contact[],
  ) => void;
  updateContact: (contact: ContactType) => void;
  addContact: (contact: ContactType) => void;
};
function EditGroupContacts({
  groupContacts,
  handleContactCheck,
  updateContact,
  addContact,
}: Props) {
  const [filter, setFilter] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);

  const [isAddContactMode, setIsAddContactMode] = useState(false);

  useEffect(() => {
    setFilteredContacts(
      groupContacts
        .filter(
          contact =>
            filter === '' ||
            contact.firstName.toLowerCase().includes(filter.toLowerCase()) ||
            contact.lastName.toLowerCase().includes(filter.toLowerCase()) ||
            contact.user.email.toLowerCase().includes(filter.toLowerCase()),
        )
        .sort((contactX, contactY) =>
          contactX.checked === contactY.checked ? 0 : contactX.checked ? -1 : 1,
        ),
    );
  }, [groupContacts, filter]);

  const filterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilter(value);

    setFilteredContacts(
      groupContacts.filter(
        contact =>
          value === '' ||
          contact.firstName.toLowerCase().includes(value.toLowerCase()) ||
          contact.lastName.toLowerCase().includes(value.toLowerCase()) ||
          contact.user.email.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  return (
    <>
      <ControlBox>
        <StyledFilter
          value={filter}
          onChange={filterChange}
          placeholder="Type to search for recipient..."
        />

        <ToggleContactForm
          text="Add the Contact"
          onClick={() => setIsAddContactMode(!isAddContactMode)}
        />
      </ControlBox>

      {isAddContactMode && addContact && (
        <NewContactForm
          addNewContact={addContact}
          closeForm={() => setIsAddContactMode(false)}
        />
      )}

      <Table
        filteredContacts={filteredContacts}
        handleContactCheck={handleContactCheck}
        updateContact={updateContact}
      />
    </>
  );
}

const ControlBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFilter = styled(FilterInput)`
  width: 450px;
  padding: 20px 10px;
`;

const ToggleContactForm = styled(ButtonEllipse)`
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  color: ${Colors.TextGrey};
`;

export default EditGroupContacts;
