import styled, { css } from 'styled-components';

import { Colors } from '../themes';

type Props = {
  direction: string;
  onClick: () => void;
  disabled?: boolean;
};

const MoveButton = styled.div<Props>`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.15);
  }

  &::before {
    content: '';
    position: absolute;
    top: 30%;
    display: block;
    width: 10.5px;
    height: 10.5px;
    border-bottom: 1px solid ${Colors.DarkGrey};
    border-left: 1px solid ${Colors.DarkGrey};

    ${props => {
      switch (props.direction) {
        case 'right':
          return `left: 20%;
                  transform: rotate(-135deg);`;
        case 'left':
          return `left: 40%;
                  transform: rotate(45deg);`;
        default:
          return `left: 20%;
                  transform: rotate(-135deg);`;
      }
    }}
  }

  ${props =>
    props.disabled &&
    css`
      display: none;
    `}
`;
export default MoveButton;
