import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DashboardGroupType, MemberType } from '../../../api';
import SVG from '../../../assets/svg';
import { FolderCard, SelectOption } from '../../../atoms';
import { formatDateMode } from '../../../const';
import { UsersInProject } from '../../../molecules';
import { Routes } from '../../../routes';
import { selectors } from '../../../store';
import { baseTransition, BoxShadow, Colors, Fonts, rem } from '../../../themes';

type Props = {
  id: number;
  name: string;
  created: string;
  projectMembers: number[];
  userMembers: MemberType[];
  description: string;
  unreadEvents: number;
  projectBranches: DashboardGroupType[];
  option: SelectOption | null;
};

function OneProject({
  id,
  name,
  created,
  projectMembers,
  userMembers,
  description,
  unreadEvents,
  projectBranches,
  option,
}: Props) {
  const user = useSelector(selectors.user);
  const [users, setUsers] = useState<UsersInProject[]>([]);

  const isReceived =
    !!projectBranches.find(m => m.signers.includes(user.id as number)) &&
    !projectMembers?.includes(user.id as number);

  useEffect(() => {
    if (userMembers?.length === 0) return;
    if (projectMembers?.length === 0) return;

    setUsers(
      userMembers
        .filter(member => projectMembers?.includes(member.id))
        .map(member => ({
          logo: member.avatar,
          namesLetters: `${
            member.firstName && member.lastName
              ? member.firstName[0] + member.lastName[0]
              : member.email[0]
          }`,
        })),
    );
  }, [projectMembers, userMembers]);

  const date = created ? new Date(created) : null;
  return (
    <>
      <StyledLink to={`${Routes.projects}/${id}`}>
        <Project>
          <ProjectBackground
            src={SVG.projectListingBg}
            alt="projectListingBg"
          />
          <ProjectWrapper>
            <FolderCard
              name={
                name ||
                (projectBranches.length === 1
                  ? projectBranches[0].name
                  : `${projectBranches[0].name}...`)
              }
              unreadEvents={unreadEvents}
            />
            <DescriptionProject>
              <DescriptionProjectDetails>
                {description !== '' ? description : '-'}
              </DescriptionProjectDetails>
            </DescriptionProject>
            <Created>
              {option && (option.label === 'Received' || isReceived) ? (
                'Received'
              ) : (
                <>
                  <img src={SVG.date} alt="Date" style={{ marginRight: 7 }} />
                  {date ? format(date, formatDateMode.additionalDateTime) : ''}
                </>
              )}
            </Created>
          </ProjectWrapper>
        </Project>
      </StyledLink>
    </>
  );
}

export const NewProject = styled.div<{ disabled?: boolean }>`
  height: 100%;
  padding: 10px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkBlack};
  text-align: center;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const AddNewProject = styled.div<{ disabled?: boolean }>`
  height: 60px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px dashed ${Colors.WeakSecondGrey};
  border-radius: 10px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.LightSecondGrey};
  text-align: center;
  transition: ${baseTransition};
  background-color: ${Colors.White};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props =>
    !props.disabled &&
    `&:hover {
      box-shadow: ${BoxShadow.Base};
  }
`}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Project = styled(NewProject)`
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
`;

const ProjectWrapper = styled.div`
  width: 100%;
  height: 95%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;
const ProjectBackground = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const DescriptionProject = styled.div`
  text-align: start;
  width: 100%;
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7)};
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DescriptionProjectDetails = styled.div`
  margin-top: 4px;
  font-size: ${rem(10)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Created = styled.div`
  font-size: ${rem(8)};
  color: ${Colors.DarkGrey};
`;

export default OneProject;
