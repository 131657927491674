import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SVG from '../../assets/svg';
import { Logo } from '../../atoms';
import { Routes } from '../../routes';
import { Colors } from '../../themes';
import { StyledButton } from '../account/components/MyProfile';
import ContactUs from '../home/ContactUs';
import HeroSection from '../home/HeroSection';
import Features from '../home/ourFeatures';
import SavingTime from '../home/SavingTime';
import Testimonials from '../home/Testimonials';
import WhyChoose from '../home/WhyChoose';
import Footer from './Footer';
import NavigationLinks from './NavigationLinks';

function Landing() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderContainer>
          <Logo size="big" />
          {!isMenuOpen && window.innerWidth ? (
            <Toggle isMenuOpen={isMenuOpen}>
              <NavigationLinks />
            </Toggle>
          ) : null}
          <Container isMenuOpen={isMenuOpen}>
            <ToggleMobile isMenuOpen={isMenuOpen}>
              <NavigationLinks />
            </ToggleMobile>
            <NavLink to={Routes.login}>
              <Button text="Log in" />
            </NavLink>
          </Container>
        </HeaderContainer>
        <MobileMenu>
          <MobileMenuBar onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <img src={SVG.menuBar} alt="menu bar" width={30} />
          </MobileMenuBar>
        </MobileMenu>
      </HeaderWrapper>
      <HeroSection />
      <WhyChoose />
      <Features />
      <SavingTime />
      <Testimonials />
      <ContactUs />
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0;
  width: 100%;
  background: ${Colors.LightGrayishBlue};
`;

const HeaderWrapper = styled.div`
  background-color: ${Colors.LightBlue};
  @media (max-width: 768px) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
}
`;

const Toggle = styled.div<{ isMenuOpen: boolean }>`
  @media (max-width: 768px) {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  }
`;

const ToggleMobile = styled.div<{ isMenuOpen: boolean }>`
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
`;

const Container = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    top: 70px;
    right: 0;
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
    width: 100%;
    transition: left 0.3s ease-in-out;
    background: ${Colors.LightBlue};
    justify-content: center;
    margin: auto;
    z-index: 1;
    padding: 15px;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1320px;
  height: 87px;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const MobileMenuBar = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.LightBlue};
  padding: 20px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Button = styled(StyledButton)`
  border: 1px solid ${Colors.White};
  background-color: ${Colors.White};
  color: ${Colors.Black};
  padding: 13px 40px;
  margin-top: 10px;
`;

export default Landing;
