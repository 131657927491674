import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import { useNotification } from './hooks';
import Account from './pages/account';
import Cim from './pages/cim';
import ForgotPassword from './pages/forgotPassword';
import Home from './pages/home';
import MemberInvitation from './pages/invitation';
import SignerInvitation from './pages/invitationSigner';
import Landing from './pages/landing';
import Login from './pages/login';
import LookupNda from './pages/lookupNda';
import EditProjectNda from './pages/nda';
import OneProject from './pages/oneProject';
import PasswordReset from './pages/passwordReset';
import Plan from './pages/plan';
import PrivacyPolicy from './pages/privacyPolicy';
import NdaPage from './pages/projectBranchNda';
import Projects from './pages/projects';
import AddProject from './pages/projects/components/AddProject';
import Recipients from './pages/recipients';
import Register from './pages/register';
import Reports from './pages/reports';
import ReviewNda from './pages/reviewNda';
import Teaser from './pages/teaser';
import Templates from './pages/templates';
import { AddTemplate } from './pages/templates/components';
import TermsAndCondition from './pages/termsAndConditions';
import UserActivation from './pages/user-activation';
import UserList from './pages/userList';
import { PrivateRoute, PublicRoute, Routes } from './routes';
import GlobalStyles from './themes/GlobalStyles';

function App() {
  const { Notifications } = useNotification();

  return (
    <>
      <GlobalStyles />
      <Notifications newestOnTop theme="colored" />
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact
            path={Routes.main}
            redirectTo={Routes.home}
            component={<Landing />}
          />
          <PublicRoute
            restricted
            path={Routes.login}
            redirectTo={Routes.projects}
            component={<Login />}
          />
          <PublicRoute
            restricted
            path={Routes.register}
            redirectTo={Routes.home}
            component={<Register />}
          />
          <PublicRoute
            path={Routes.activateUser}
            redirectTo={Routes.home}
            component={<UserActivation />}
          />
          <PublicRoute
            restricted
            path={Routes.forgotPassword}
            redirectTo={Routes.home}
            component={<ForgotPassword />}
          />
          <PublicRoute
            exact
            path={Routes.passwordResetConfirm}
            redirectTo={Routes.home}
            component={<PasswordReset />}
          />
          <PublicRoute
            exact
            path={Routes.memberInvitation}
            redirectTo={Routes.home}
            component={<MemberInvitation />}
          />
          <PublicRoute
            exact
            path={Routes.signerInvitation}
            redirectTo={Routes.home}
            component={<SignerInvitation />}
          />
          <PublicRoute
            exact
            path={Routes.privacy}
            redirectTo={Routes.privacy}
            component={<PrivacyPolicy />}
          />
          <PublicRoute
            exact
            path={Routes.terms}
            redirectTo={Routes.terms}
            component={<TermsAndCondition />}
          />
          <PrivateRoute
            exact
            path={Routes.home}
            redirectTo={Routes.login}
            component={<Home />}
          />
          <PrivateRoute
            exact
            path={Routes.userList}
            redirectTo={Routes.login}
            component={<UserList />}
          />
          <PrivateRoute
            exact
            path={Routes.users}
            redirectTo={Routes.login}
            component={<UserList />}
          />
          <PrivateRoute
            exact
            path={Routes.oneProject}
            redirectTo={Routes.login}
            component={<OneProject />}
          />
          <PrivateRoute
            exact
            path={Routes.recipients}
            redirectTo={Routes.login}
            component={<Recipients />}
          />
          <PrivateRoute
            exact
            path={Routes.updateRecipients}
            redirectTo={Routes.login}
            component={<Recipients />}
          />
          <PrivateRoute
            exact
            path={Routes.projectBranch}
            redirectTo={Routes.login}
            component={<OneProject />}
          />
          <PrivateRoute
            exact
            path={Routes.projectBranchNda}
            redirectTo={Routes.login}
            component={<NdaPage />}
          />
          <PrivateRoute
            exact
            path={Routes.lookupNda}
            redirectTo={Routes.login}
            component={<LookupNda />}
          />
          <PrivateRoute
            exact
            path={Routes.projects}
            redirectTo={Routes.login}
            component={<Projects />}
          />
          <PrivateRoute
            exact
            path={Routes.addProject}
            redirectTo={Routes.login}
            component={<AddProject />}
          />
          <PrivateRoute
            exact
            path={Routes.reviewNda}
            redirectTo={Routes.login}
            component={<ReviewNda />}
          />
          <PrivateRoute
            exact
            path={Routes.updateTeaser}
            redirectTo={Routes.login}
            component={<Teaser />}
          />
          <PrivateRoute
            exact
            path={Routes.updateCim}
            redirectTo={Routes.login}
            component={<Cim />}
          />
          <PrivateRoute
            exact
            path={Routes.recipients}
            redirectTo={Routes.login}
            component={<Recipients />}
          />
          <PrivateRoute
            exact
            path={Routes.editProjectNda}
            redirectTo={Routes.login}
            component={<EditProjectNda />}
          />
          <PrivateRoute
            exact
            path={Routes.templates}
            redirectTo={Routes.login}
            component={<Templates />}
          />
          <PrivateRoute
            exact
            path={Routes.addTemplate}
            redirectTo={Routes.login}
            component={<AddTemplate />}
          />
          <PrivateRoute
            exact
            path={Routes.plan}
            redirectTo={Routes.login}
            component={<Plan />}
          />
          <PrivateRoute
            exact
            path={Routes.reports}
            redirectTo={Routes.login}
            component={<Reports />}
          />
          <PrivateRoute
            exact
            path={Routes.account}
            redirectTo={Routes.login}
            component={<Account />}
          />
          <Redirect to={Routes.projects} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
