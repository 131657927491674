import { useCallback, useEffect, useRef } from 'react';

import { FiltersType } from '../pages/projects';

function useCancellableDebounce(
  callback: (arg: FiltersType) => void,
  delay: number,
) {
  const timer = useRef();
  // Cleanup for debounced functions
  useEffect(() => timer.current && clearTimeout(timer.current), []);
  const cancel = () => {
    timer.current && clearTimeout(timer.current);
  };
  const debouncedCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      // @ts-ignore: Unreachable code error
      timer.current = window.setTimeout(() => {
        // @ts-ignore: Unreachable code error
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return {
    run: debouncedCallback,
    cancel,
  };
}

export default useCancellableDebounce;
