import { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContactType, dashboard as apiDashboard } from '../api';
import { Signer } from '../api/signership';
import { ButtonEllipse, Textarea } from '../atoms';
import { Errors } from '../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../helpers';
import { useNotification } from '../hooks';
import { Contacts, Loader } from '../molecules';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  projectBranchId?: number;
  ndaName: string;
  allContacts: ContactType[];
  groupUsers?: number[];
  signerId?: number;
  closeModal: () => void;
  projectName: string | undefined;
  summary: string;
  allsigner: Signer[];
};

function SendMailForm({
  projectBranchId,
  ndaName,
  allContacts,
  groupUsers,
  signerId,
  closeModal,
  projectName,
  summary,
  allsigner,
}: Props) {
  const { showError } = useNotification();

  const [mailNote, setMailNote] = useState({
    ...initialValue,
    value: summary,
  });
  const [subject, setSubject] = useState(initialValue);

  const [isLoading, setIsLoading] = useState(false);

  const sendMail = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) return;
    if (!projectBranchId) return;

    if (isEmptyInput(mailNote, setMailNote, Errors.EnterField)) return;
    if (isEmptyInput(subject, setSubject, Errors.EnterField)) return;

    setIsLoading(true);
    if (groupUsers) {
      apiDashboard
        .sendNdaToAllSigners(projectBranchId, {
          projectBranch: projectBranchId,
          note: mailNote.value,
          subject: subject.value,
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
            closeModal();
          }, 3000);
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);

          apiFieldErrorHandler(data.note, setMailNote);
          apiFieldErrorHandler(data.subject, setSubject);
          setIsLoading(false);
        });
    }

    if (signerId) {
      apiDashboard
        .sendNdaToOneSigners(projectBranchId, {
          projectBranch: projectBranchId,
          note: mailNote.value,
          subject: subject.value,
          signer: signerId,
        })
        .then(() => {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            closeModal();
          }, 3000);
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);

          apiFieldErrorHandler(data.note, setMailNote);
          apiFieldErrorHandler(data.subject, setSubject);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (projectName && ndaName) {
      setMailNote(props => ({
        ...props,
        value: summary,
      }));
      setSubject(props => ({ ...props, value: `${projectName} | ${ndaName}` }));
    }
  }, [projectName, ndaName, summary]);

  return (
    <StyledForm onSubmit={sendMail}>
      {isLoading && <Loader />}

      <NdaName>NDA name: {ndaName}</NdaName>

      <SenderWrapper>
        <SendersBox>
          <div>Recipients:</div>
          <Contacts
            allContacts={allContacts}
            usersInGroup={groupUsers ?? [signerId as number]}
          />
        </SendersBox>
        <SendersBox>
          <div>Company Name:</div>
          <Contacts allsigner={allsigner} signerId={signerId} />
        </SendersBox>
      </SenderWrapper>

      <Label>
        Write a note
        <InputBox>
          <StyledTextarea
            disabled={isLoading}
            value={mailNote.value}
            onChange={handleInputChange(setMailNote)}
            onBlur={handleInputBlur(mailNote, setMailNote)}
            placeholder="This note will be sent with the NDA to recipients"
            error={mailNote.errorMsg}
          />
        </InputBox>
      </Label>
      <Label>
        Subject
        <InputBox>
          <StyledTextarea
            disabled={isLoading}
            value={subject.value}
            onChange={handleInputChange(setSubject)}
            onBlur={handleInputBlur(subject, setSubject)}
            placeholder="For example, the name of project."
            error={subject.errorMsg}
          />
        </InputBox>
      </Label>
      <StyledButton type="submit" text="Send" />
    </StyledForm>
  );
}

const StyledForm = styled.form`
  padding: 0 30px 10px;
`;

const NdaName = styled.p`
  padding: 10px 0;
  margin: 0 0 10px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12)};
  color: ${Colors.DarkGrey};
`;

const SenderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SendersBox = styled.div`
  padding: 15px 15px 5px;
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 15px;
  width: fit-content;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.Black};

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const Label = styled.label`
  display: block;
  margin-top: 20px;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(12)};
  color: ${Colors.Black};
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  margin-top: 10px;
  font-size: ${rem(10)};

  ::placeholder {
    font-size: ${rem(10)};
  }
`;

const StyledButton = styled(ButtonEllipse)`
  width: 100px;
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  margin: 10px auto 0;
`;

export default SendMailForm;
