import ReactSelect, { ActionMeta, ValueType } from 'react-select';

import { Colors, Fonts, rem } from '../themes';

const customSelectStyles = {
  container: (provided: object) => ({
    ...provided,
    fontSize: rem(12),
    fontFamily: Fonts.Sofia,
    border: `1px solid ${Colors.WeakGrey}`,
    borderRadius: '10px',
    cursor: 'pointer',
    padding: '5px 10px',
    background: `${Colors.White}`,
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    width: 70,
    padding: 0,
    cursor: 'pointer',
  }),
  control: (provided: object, state: any) => ({
    ...provided,
    minHeight: '20px',
    border: 0,
    backgroundColor: Colors.Transparent,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: object) => ({
    ...provided,
    '& > *': {
      cursor: 'pointer',
      padding: 0,
      ':active': {
        padding: 0,
      },
    },
  }),
  placeholder: (provided: object) => ({
    ...provided,
    color: Colors.StrongGrey,
    cursor: 'pointer',
    fontSize: rem(10),
  }),
};

export type SelectOption = {
  value: string;
  label: string;
};

export type GroupedSelectOptions = { label: string; options: SelectOption[] };

type MultiSelectValues = SelectOption[] | [];

type SelectProps = {
  value: MultiSelectValues;
  onChange: (
    value: ValueType<SelectOption, boolean>,
    actionMeta?: ActionMeta<SelectOption>,
  ) => void;
  options: (SelectOption | GroupedSelectOptions)[];
  className?: string;
  placeholder?: string;
};

function MultiSelect({
  value,
  onChange,
  options,
  className,
  placeholder,
}: SelectProps) {
  return (
    <ReactSelect
      isMulti
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      styles={customSelectStyles}
      options={options}
      closeMenuOnSelect={false}
      // remove border  onFocus
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: 'neutral0',
        },
      })}
    />
  );
}

export default MultiSelect;
