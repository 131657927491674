/* eslint-disable no-nested-ternary */
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import { billing as apiBilling, permissions as api } from '../api';
import { routeTemplate } from '../helpers';
import { Loader } from '../molecules';
import { getPermissions, selectors, updateSubscription } from '../store';
import { Routes } from '.';

type RouteProps = {
  component: ReactElement;
  path: string;
  redirectTo: string;
  exact?: boolean;
};

export default function PrivateRoute({
  component,
  redirectTo,
  path,
  exact,
}: RouteProps) {
  const isAuth = useSelector(selectors.isAuthenticated);
  const dispatch = useDispatch();
  const locations = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(locations.search);
  const [isLoading, setIsLoading] = useState(false);
  const singer = queryParams.get('signer') || '';

  useEffect(() => {
    if (singer) {
      history.push(
        routeTemplate(Routes.signerInvitation, {
          userIdAndToken: singer,
        }),
      );
      localStorage.setItem('signer', locations.pathname);
    }
  }, [singer]);

  useEffect(() => {
    setIsLoading(true);
    api
      .getPermissions()
      .then(({ data }) => dispatch(getPermissions(data.permissionsList)))
      .finally(() => setIsLoading(false));

    apiBilling
      .getSubscription()
      .then(({ data }) => dispatch(updateSubscription({ product: data })))
      .finally(() => setIsLoading(false));

    apiBilling
      .getOneTimePurchases()
      .then(({ data }) => {
        const additionalProjects = data.reduce(
          (acc, onePayment) => acc + onePayment.quantity,
          0,
        );
        dispatch(updateSubscription({ additionalProjects }));
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Route
          exact={exact}
          path={path}
          render={({ location }) =>
            isAuth ? (
              component
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
}
