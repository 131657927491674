import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import IMG from '../../assets/IMG';
import SVG from '../../assets/svg';
import { ButtonEllipse } from '../../atoms';
import { LandingPageData } from '../../const';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

function SavingTime() {
  const heroSec = LandingPageData.savingTime;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 425);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setEmail(e.target.value);
  };

  const handleRegister = () => {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
    });

    schema
      .validate({ email })
      .then(() => {
        history.push({
          pathname: Routes.register,
          search: `?email=${encodeURIComponent(email)}`,
        });
      })
      .catch(validationError => {
        setError(validationError.message);
      });
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <LandingInfoBox>
          <PlatformDescription>{heroSec.title}</PlatformDescription>
          <StyledText>{heroSec.details}</StyledText>
          <StyledInput
            value={email}
            onChange={handleChange}
            placeholder="Enter Email"
            name="email"
          />
          <ErrorText>{error}</ErrorText>
          <ButtonWrapper>
            <StyledButton onClick={handleRegister} text="Register for free" />
            {isMobileView ? null : (
              <img
                src={SVG.savingTimes}
                alt="saving time"
                style={{ margin: '10px 0 0 27px' }}
              />
            )}
          </ButtonWrapper>
        </LandingInfoBox>
        <LandingImg>
          <StyledLandingImage alt="landing" src={IMG.HandShake} />
        </LandingImg>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${IMG.SavingTimeBg});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 0;
  min-height: 636px;
  @media (min-width: 1024px) {
    padding: 0 40px;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 1320px;
  margin: auto;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
  }
`;

const LandingInfoBox = styled.div`
  @media (max-width: 768px) {
    padding: 0 50px;
  }
  @media (max-width: 425px) {
    padding: 0 20px;
  }
`;

const LandingImg = styled.div`
  @media (max-width: 1440px) {
    right: 0;
  }
  @media (max-width: 1024px) {
    right: 0;
    bottom: 25px;
  }
  @media (max-width: 768px) {
    right: 0;
    bottom: 0;
    margin: 35px 0;
  }
`;

const PlatformDescription = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(21.25)};
  color: ${Colors.White};
  font-style: normal;
  font-weight: 300;
  line-height: 52px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: ${rem(15)};
  }
`;

const StyledText = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  color: ${Colors.White};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: ${rem(10)};
  }
`;

const StyledInput = styled.input`
  font-size: ${rem(11)};
  padding: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 52px;
  width: 420px;
  color: #dadada;
  ::placeholder {
    color: #dadada;
    font-size: ${rem(10)};
  }
  :disabled {
    background-color: transparent;
  }
  background-color: rgba(255, 255, 255, 0.18);

  border-radius: 7px;
  border: 1px solid #d8d8d8;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLandingImage = styled.img`
  height: auto;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  text-decoration: none;
  display: flex;
  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const StyledButton = styled(ButtonEllipse)`
  border-radius: 6px;
  border: 1px solid ${Colors.LightFifthGrey};
  background-color: ${Colors.White};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
  font-weight: 300;
  padding: 13px 30px;
`;

const ErrorText = styled.div`
  color: ${Colors.Red};
  margin-top: 10px;
  margin-bottom: 40px;
  font-family: ${Fonts.Sofia};
`;

export default SavingTime;
