import { TokenKeys, TokenTimes } from '../guestApi';

export const updateAccessToken = () => {
  localStorage.setItem(
    TokenKeys.Access,
    (Date.now() + TokenTimes.Access).toString(),
  );
};

export const updateTokens = () => {
  localStorage.setItem(
    TokenKeys.Refresh,
    (Date.now() + TokenTimes.Refresh).toString(),
  );

  updateAccessToken();
};

export * from './types';
export { default } from './authorization';
