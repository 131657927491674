import { AxiosResponse } from 'axios';

import guestApi, { TokenKeys, TokenTimes } from '../guestApi';
import {
  LoginDataType,
  LoginRequestType,
  RefreshTokensResponse,
  RegisterMember,
  RegisterRequestType,
  RegisterResponse,
  RegisterSigner,
  ResetPasswordConfirmReq,
  ResetPasswordReq,
  VerifyEmailResponse,
} from './types';

const login = (data: LoginRequestType): Promise<AxiosResponse<LoginDataType>> =>
  guestApi.post('/authentication/login/', data);

const register = (
  data: RegisterRequestType,
): Promise<AxiosResponse<RegisterResponse>> =>
  guestApi.post('/registration/', data);

const registerMember = (data: RegisterMember): Promise<RegisterMember> =>
  guestApi.post('/registration/member_invitation_confirm/', data);

const registerSigner = (data: RegisterSigner): Promise<RegisterMember> =>
  guestApi.post('/registration/signer_invitation_confirm/', data);

const verifyEmail = (key: string): Promise<VerifyEmailResponse> =>
  guestApi.post('/registration/verify-email/', { key });

const refreshToken = (token: string): Promise<RefreshTokensResponse> =>
  guestApi.post('/authentication/token/refresh/', { refresh: token });

const logout = () => guestApi.post('/authentication/logout/');

const resetPassword = (data: ResetPasswordReq): Promise<AxiosResponse> =>
  guestApi.post('/authentication/password/reset/', data);

const passwordResetConfirm = (data: ResetPasswordConfirmReq) =>
  guestApi.post('/authentication/password/reset/confirm/', data);

export const updateAccessToken = () => {
  localStorage.setItem(
    TokenKeys.Access,
    (Date.now() + TokenTimes.Access).toString(),
  );
};

export const updateTokens = () => {
  localStorage.setItem(
    TokenKeys.Refresh,
    (Date.now() + TokenTimes.Refresh).toString(),
  );

  updateAccessToken();
};

const authorization = {
  login,
  register,
  registerMember,
  registerSigner,
  verifyEmail,
  refreshToken,
  logout,
  resetPassword,
  passwordResetConfirm,
};

export default authorization;
