import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonEllipse, Select, SelectOption } from '../../../atoms';
import { Errors } from '../../../const';
import { useNotification } from '../../../hooks';
import { Routes } from '../../../routes';
import { Colors, Fonts, rem } from '../../../themes';
import { SelectOptions } from '../../plan/components/Plans/PlanStatus';

type Props = {
  templates: SelectOption[];
  value: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  handleClose: () => void;
};

function AddTemplate({ templates, value, onChange, handleClose }: Props) {
  const { showError } = useNotification();
  const [template, setTemplate] = useState<SelectOptions | null>(
    value.template || null,
  );

  const handleClick = () => {
    if (!template) {
      showError(Errors.ChouseTemplate);
    } else {
      onChange({ ...value, file: null, template });
      handleClose();
    }
  };
  return (
    <Container>
      {Array.isArray(templates) && templates.length === 0 ? (
        <>
          <InfoText>
            You don&apos;t have templates. <br /> Add template or upload new
            document for this project
          </InfoText>
          <Link to={Routes.templates}>
            <StyledButton text="Go to templates" />
          </Link>
        </>
      ) : (
        <>
          <CustomSelect
            options={templates}
            value={template}
            onChange={option => {
              setTemplate(option);
            }}
          />
          <StyledButton type="button" text="Add NDA" onClick={handleClick} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  height: 380px;
`;

const InfoText = styled.p`
  text-align: center;
  color: ${Colors.DarkGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  line-height: 1.4;
  margin-bottom: 10px;
`;

const Link = styled(NavLink)`
  text-decoration: none;
`;

const StyledButton = styled(ButtonEllipse)`
  width: 150px;
  margin: 70px auto 0 auto;
  background-color: ${Colors.White};
  color: ${Colors.Black};
  border: 1px solid ${Colors.Black};
`;

const CustomSelect = styled(Select)`
  border-bottom: 1px solid ${Colors.Black};
  margin: 0px auto 50px;
  width: 400px;
`;

export default AddTemplate;
