import IMG from '../assets/IMG';
import SVG from '../assets/svg';
import Errors from './errors';

export enum ProjectsEnum {
  All = 'All',
  Active = 'Active',
  Received = 'Received',
  Initiated = 'Initiated',
  Archived = 'Archived',
}

export enum Messages {
  InitProjects = 'This is your projects page, where you can get a quick overview of all your live activities',
  InitTemplates = 'Save your most used NDA templates for faster reference in the future.',
  InitPlan = 'Enjoy flexible pricing based on your team size and number of documents uploaded.',
  SuccessPurchase = 'Purchase was successful',
  ProjectPlanConfirmation = 'You won’t get any more additional projects, to avail more, upgrade to Boutique plan',
}

export const ActivityMessages = {
  none: '-',
  member_added: 'New team members were added:',
  nda_sent: 'NDA was sent by:',
  rejected: 'Recipients rejected the comment',
  nda_signed: 'NDA was signed by:',
  new_comments_thread: 'New comments thread was added by:',
  new_comment: 'New comments were added by:',
  approved: 'Comment was approved by:',
  nda_recalled: 'NDA was recalled by:',
  nda_restored: 'NDA was restored by:',
  project_archived: 'Project was archived by:',
  project_unarchived: 'Project was unarchived by:',
  member_removed: 'Following team members were removed from the project:',
  new_project: 'New project was created by:',
  nda_opened: 'NDA was opened by:',
  recipient_added: 'New recipients were added:',
  recipient_removed: 'Recipients were removed:',
  comments_thread_deleted: 'Comments thread was deleted by:',
  nda_rejected: 'Recipients rejected the NDA',
};

export const formatDateMode = {
  default: 'dd/MM/yy',
  additional: 'd LLL yyyy',
  withWordsDate: 'do LLL yyyy',
  dateTime: 'd/LL/yyyy k:mm',
  additionalDateTime: 'dd.MM.yyyy k:mm',
};

export const projectOptions = [
  { value: ProjectsEnum.All, label: ProjectsEnum.All },
  { value: ProjectsEnum.Active, label: ProjectsEnum.Active },
  { value: ProjectsEnum.Received, label: ProjectsEnum.Received },
  { value: ProjectsEnum.Initiated, label: ProjectsEnum.Initiated },
  { value: ProjectsEnum.Archived, label: ProjectsEnum.Archived },
];

export const DefaultFreePlan = {
  name: 'FREE',
  cost: 0,
  interval: 'month',
  projects: 2,
  members: 0,
};

export const LandingPageData = {
  heroSection: {
    platformDescription: 'Seamless NDA management for M&A professionals.',
    platformInfo:
      'Namda is a secure cloud platform that lets you upload, modify and sign NDA\'s efficiently',
    uploadButtonText: 'Send an NDA',
    LandingHome: IMG.HeroSection,
    upload: SVG.upload,
  },
  whyChooseUs: [
    {
      icon: SVG.shield,
      title: 'Security and Confidentiality',
      content:
        'Security and confidentiality are of the outmost importance. We use top level services to guarantee security and we do not share any of your data with third-parties',
    },
    {
      icon: SVG.mobile,
      title: 'Full cloud, access from everywhere',
      content:
        'Namda is cloud-native, access from everywhere, share with your team, store, at your desk or on the move',
    },
    {
      icon: SVG.messageQuote,
      title: 'A to Z process management',
      content:
        'On Namda, you can manage the whole first phase of your M&A marketing process. Everything in one place',
    },
    {
      icon: SVG.rocket,
      title: 'Expertise',
      content:
        'Namda has been created by M&A professionals for M&A professionals. We know your needs',
    },
  ],
  savingTime: {
    title: 'Start saving time ! Register for Free* Today.',
    details:
      'It’s an annoying and low value process not easy to track. Namda is changing that ! Automate your NDA process.',
  },
  testimonials: [
    {
      avtar: IMG.Avtar1,
      avtarName: 'Brooklyn Simmons',
      location: 'SME broker, Connecticut, USA',
      content:
        '"Since we use Namda, we have increased our productvity tenfolds when it comes to starting our marketing process"',
      rating: 5,
    },
    {
      avtar: IMG.Avtar2,
      avtarName: 'Leslie Alexander',
      location: 'Paris, France',
      content:
        '"Namda takes away the painful process of sending NDAs and negotiating terms"',
      rating: 4,
    },
    {
      avtar: IMG.Avtar3,
      avtarName: 'Guy Hawkins',
      location: 'Singapore',
      content:
        '"Namda allows us to track the parties we have reached, their status in our process, but it also allows us to track our performance as an adviser thanks to the data we now collect"',
      rating: 3,
    },
  ],
  trackSale: {
    title: 'Track your sale process',
    content:
      'Once the company information is shared with many parties, you can track their status with Namda. Simply organise your interested parties by status : interested, pass, considering',
  },
  ndaComments: {
    title: 'Invite your team and track your firm’s activity',
    content:
      'provide full transparency to your colleagues on the status of a process: your team can simply see where a status stands. How many parties are looking at a transaction and what is their status. Thanks to our reports, track your firm’s activity: how many deals have been advised, how many parties have been contacted. You can communicate these important data to your customers, to prove the efficiency of your reach.',
  },
  securePlatform: {
    title: 'A confidential and secure platform',
    content:
      'Namda is hosted on AWS and benefits from all the safety features. We do not share your data and do not access your transaction sensitive data',
  },
};

export { Errors };
