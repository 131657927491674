import axios, { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';

import { Errors } from '../const';
import { logout, refreshAccessToken, store } from '../store';
import authApi, {
  RefreshTokensResponse,
  updateAccessToken,
} from './authorization';
import {
  baseApiURL,
  ReqInterceptorType,
  requestSnakeCased,
  responseCamelCasedError,
  responseCamelCasedSuccess,
  TokenKeys,
} from './guestApi';

let tokenUpdatingPromise: Promise<RefreshTokensResponse> | null;

const api = axios.create({
  baseURL: baseApiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const isExpiredRefreshToken = (): boolean => {
  const { refreshToken } = store.getState();

  if (!refreshToken) {
    return true;
  }

  const refreshExpiredTime = localStorage.getItem(TokenKeys.Refresh);
  if (!refreshExpiredTime) {
    return true;
  }

  return Date.now() > Number(refreshExpiredTime);
};

export const isExpiredAccessToken = (): boolean => {
  const { accessToken } = store.getState();
  if (!accessToken) {
    return true;
  }

  const accessExpiredTime = localStorage.getItem(TokenKeys.Access);
  if (!accessExpiredTime) {
    return true;
  }

  return Date.now() > Number(accessExpiredTime);
};

const checkTokensInterceptor: ReqInterceptorType = async request => {
  if (tokenUpdatingPromise) {
    await tokenUpdatingPromise;
  }

  if (isExpiredRefreshToken()) {
    store.dispatch(logout());
    throw new Error(Errors.RefreshTokenExpired);
  }

  if (isExpiredAccessToken()) {
    const token = store.getState().refreshToken;
    if (!token) {
      throw new Error(Errors.FailedUpdateTokens);
    }

    tokenUpdatingPromise = authApi.refreshToken(token);
    try {
      const res = await tokenUpdatingPromise;

      const newToken = res.data.access.replaceAll('"', '');

      store.dispatch(refreshAccessToken(newToken));

      request.headers.Authorization = `Bearer ${newToken}`;

      updateAccessToken();
    } catch (error) {
      store.dispatch(logout());
      throw new Error(Errors.FailedUpdateTokens);
    } finally {
      tokenUpdatingPromise = null;
    }
  } else {
    const token = store.getState().accessToken;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }

  return requestSnakeCased(request);
};

let isRefreshing = false;
let requestsQueue: ((token: string) => void)[] = [];

const onGetRefreshToken = (token: string) => {
  requestsQueue.map(callback => callback(token));
};

const addToRequestQueue = (callback: (token: string) => void) => {
  requestsQueue.push(callback);
};

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  error => {
    const {
      config,
      response: { status },
    } = error;

    const { refreshToken } = store.getState();

    if (status === 401) {
      if (config.url === '/authentication/token/refresh/') {
        store.dispatch(logout());
        return Promise.reject(error);
      }

      if (!isRefreshing && refreshToken) {
        isRefreshing = true;

        authApi
          .refreshToken(refreshToken)
          .then(({ data }) => {
            const newToken = data.access.replaceAll('"', '');

            isRefreshing = false;
            onGetRefreshToken(newToken);
            store.dispatch(refreshAccessToken(newToken));

            requestsQueue = [];
            config.headers.Authorization = `Bearer ${data.access}`;
            return api(config);
          })
          .catch(e => {
            store.dispatch(logout());
            return Promise.reject(e);
          });
      }

      return new Promise(resolve => {
        addToRequestQueue((token: string) => {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(api(config));
        });
      });
    }

    return Promise.reject(error);
  },
);

api.interceptors.request.use(checkTokensInterceptor);
api.interceptors.response.use(
  responseCamelCasedSuccess,
  responseCamelCasedError,
);

export default api;
