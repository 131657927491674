/* eslint-disable no-nested-ternary */
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { PriceInterval, ProductType } from '../../../../api';
import SVG from '../../../../assets/svg';
import { Input } from '../../../../atoms';
import { Errors } from '../../../../const';
import { selectors } from '../../../../store';
import { Colors, Fonts, rem } from '../../../../themes';
import { memberAddonDetail, projectAddonDetail } from './helpers';

const Benefits = {
  Free: [
    'NDA Process Management',
    'Approve, Decline NDA terms',
    'NDA Templates',
    'NDA Analytics',
  ],
  Boutique: [
    'Everything in Free',
    'Add and collaborate with team member',
    'Purchase additional projects',
  ],
  'Bulge-Bracket': ['Everything in Boutique', 'Unlimited projects'],
};

type Props = {
  onePlan?: ProductType;
  freePlan?: true;
  selectedProjects: string;
  setSelectedProjects: React.Dispatch<React.SetStateAction<string>>;
  selectedMembers: string;
  setSelectedMembers: React.Dispatch<React.SetStateAction<string>>;
  chosen: boolean;
  isDisableUpgrade: number | null;
  selectedPeriod?: string;
  currencySymbol: string;
  monthPlanCost: number;
  yearPlanCost: number;
};

const PlanBody = ({
  freePlan,
  onePlan,
  selectedProjects,
  setSelectedProjects,
  selectedMembers,
  setSelectedMembers,
  chosen,
  isDisableUpgrade,
  selectedPeriod,
  currencySymbol,
  monthPlanCost,
  yearPlanCost,
}: Props) => {
  const checkIsPositive = (e: ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) < 0) e.target.value = '0';
  };

  const subscriptionInterval = useSelector(selectors.subscriptionInterval);

  const monthPrice = projectAddonDetail(
    onePlan?.addon,
    selectedPeriod === 'Monthly' ? PriceInterval.MONTH : PriceInterval.YEAR,
  );
  const yearPrice = memberAddonDetail(
    onePlan?.addon,
    selectedPeriod === 'Monthly' ? PriceInterval.MONTH : PriceInterval.YEAR,
  );

  const monthCost =
    monthPrice && monthPrice.prices ? monthPrice.prices.unitAmount / 100 : 0;
  const yearCost =
    yearPrice && yearPrice.prices ? yearPrice.prices.unitAmount / 100 : 0;

  const projectMultiplier = Number(selectedProjects) * monthCost;
  const memberMultiplier = Number(selectedMembers) * yearCost;

  const totalCost = projectMultiplier + memberMultiplier;

  const defaultPlanCost =
    selectedPeriod === 'Monthly' ? monthPlanCost : yearPlanCost;
  const totalCustomCost = totalCost + defaultPlanCost;

  const handleProjectIncrement = () => {
    setSelectedProjects((prev: string) => {
      const newNumber = Number(prev) + 1;
      return newNumber.toString();
    });
  };

  const handleProjectDecrement = () => {
    setSelectedProjects((prev: any) => {
      const newNumber = Number(prev) > 0 ? Number(prev) - 1 : 0;
      return newNumber.toString();
    });
  };

  const handleMemberIncrement = () => {
    setSelectedMembers((prev: string) => {
      const newNumber = Number(prev) + 1;
      return newNumber.toString();
    });
  };

  const handleMemberDecrement = () => {
    setSelectedMembers((prev: string) => {
      const newNumber = Number(prev) > 0 ? Number(prev) - 1 : 0;
      return newNumber.toString();
    });
  };

  const totalProjects = selectedProjects ? Number(selectedProjects) + 3 : 3;
  const totalMembers = selectedMembers ? Number(selectedMembers) + 1 : 1;

  return (
    <Wrapper chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
      {freePlan ? (
        <Variants>
          <BilledCostWrapper>
            <BilledCostInfo
              chosen={chosen}
              freePlan={freePlan}
              onePlan={onePlan}
            >
              <AddMemberBG
                chosen={chosen}
                freePlan={freePlan}
                onePlan={onePlan}
              >
                <img src={SVG.file} alt="billing" width="16px" />
              </AddMemberBG>
              <ProjectWrapper>
                <ProjectName
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  Projects:{' '}
                  <ProjectCount
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    2
                  </ProjectCount>
                </ProjectName>
              </ProjectWrapper>
            </BilledCostInfo>
            <BilledCostInfo
              chosen={chosen}
              freePlan={freePlan}
              onePlan={onePlan}
            >
              <AddMemberBG
                chosen={chosen}
                freePlan={freePlan}
                onePlan={onePlan}
              >
                <img src={SVG.profileAvtar} alt="billing" width="16px" />
              </AddMemberBG>
              <ProjectWrapper>
                <ProjectName
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  Users:{' '}
                  <ProjectCount
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    1
                  </ProjectCount>
                </ProjectName>
              </ProjectWrapper>
            </BilledCostInfo>
          </BilledCostWrapper>
        </Variants>
      ) : onePlan?.name === 'Bulge-Bracket' ? (
        <Variants>
          <BilledCostInfoWrapperPremium
            chosen={chosen}
            freePlan={freePlan}
            onePlan={onePlan}
          >
            <AddMemberBG chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
              <img src={SVG.file} alt="billing" width="16px" />
            </AddMemberBG>
            <ProjectWrapper>
              <ProjectName
                chosen={chosen}
                freePlan={freePlan}
                onePlan={onePlan}
              >
                Projects: Unlimited
              </ProjectName>
            </ProjectWrapper>
          </BilledCostInfoWrapperPremium>

          {!freePlan && onePlan && !chosen && (
            <>
              <BilledCostInfoBuldgeWrapper
                chosen={chosen}
                freePlan={freePlan}
                onePlan={onePlan}
              >
                <AddMemberBGBoutique
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  <img src={SVG.profileAvtar} alt="billing" width="16px" />
                </AddMemberBGBoutique>
                <ProjectWrapper>
                  <ProjectNameBoutique
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    <div
                      style={{
                        display: 'flex',
                        color: 'rgba(0, 0, 0, 0.63)',
                      }}
                    >
                      Inc.
                      <span
                        style={{
                          color: `${Colors.DarkBlack}`,
                          fontWeight: '300',
                          marginRight: '5px',
                        }}
                      >
                        1
                      </span>
                      Members
                    </div>
                    <ProjectCountBoutique
                      chosen={chosen}
                      freePlan={freePlan}
                      onePlan={onePlan}
                    >
                      <span style={{ fontSize: '12px' }}>For Additional</span>
                      <span
                        style={{
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        {currencySymbol} {yearCost}/
                        <span style={{ fontSize: '12px' }}>per Members</span>
                      </span>
                    </ProjectCountBoutique>
                  </ProjectNameBoutique>
                </ProjectWrapper>
              </BilledCostInfoBuldgeWrapper>
            </>
          )}

          {!freePlan && onePlan && chosen && (
            <>
              <StyledInputNameWrapper>
                <InputName>Add Team Members</InputName>
                <InputContainer>
                  <StyledButton
                    onClick={handleMemberDecrement}
                    disabled={Number(selectedMembers) <= 0}
                  >
                    <img src={SVG.minusWhite} alt="minus" />
                  </StyledButton>
                  <StyledInput
                    type="number"
                    value={selectedMembers}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (parseInt(e.target.value, 10) > 1000) return;
                      setSelectedMembers(e.target.value);
                    }}
                    onBlur={() => null}
                    placeholder="Quantity of Team Members"
                  />
                  <StyledButton
                    onClick={handleMemberIncrement}
                    disabled={Number(selectedMembers) >= 999}
                  >
                    <img src={SVG.plusWhite} alt="plus" />
                  </StyledButton>
                </InputContainer>
              </StyledInputNameWrapper>
              {isDisableUpgrade !== null ? (
                <Error>
                  {`${Errors.AllowDecreaseMember} ${isDisableUpgrade}`}
                </Error>
              ) : null}
            </>
          )}
        </Variants>
      ) : (
        <Variants>
          {!freePlan && onePlan && !chosen && (
            <>
              <BilledCostInfoMainWrapper>
                <BilledCostInfoBoutiqueWrapper
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  <AddMemberBGBoutique
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    <img src={SVG.file} alt="billing" width="16px" />
                  </AddMemberBGBoutique>
                  <ProjectWrapper>
                    <ProjectNameBoutique
                      chosen={chosen}
                      freePlan={freePlan}
                      onePlan={onePlan}
                    >
                      <div
                        style={{
                          display: 'flex',
                          color: 'rgba(0, 0, 0, 0.63)',
                        }}
                      >
                        Inc.
                        <span
                          style={{
                            color: `${Colors.DarkBlack}`,
                            fontWeight: '300',
                            marginRight: '5px',
                          }}
                        >
                          3
                        </span>
                        Projects
                      </div>
                      <ProjectCountBoutique
                        chosen={chosen}
                        freePlan={freePlan}
                        onePlan={onePlan}
                      >
                        <span style={{ fontSize: '12px' }}>For Additional</span>
                        <span
                          style={{
                            display: 'flex',
                            fontSize: '16px',
                            flexDirection: 'column',
                          }}
                        >
                          {currencySymbol} {monthCost}/{' '}
                          <span style={{ fontSize: '12px' }}>per project</span>
                        </span>
                      </ProjectCountBoutique>
                    </ProjectNameBoutique>
                  </ProjectWrapper>
                </BilledCostInfoBoutiqueWrapper>

                <BilledCostInfoBoutiqueWrapper
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  <AddMemberBGBoutique
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    <img src={SVG.profileAvtar} alt="billing" width="16px" />
                  </AddMemberBGBoutique>
                  <ProjectWrapper>
                    <ProjectNameBoutique
                      chosen={chosen}
                      freePlan={freePlan}
                      onePlan={onePlan}
                    >
                      <div
                        style={{
                          display: 'flex',
                          color: 'rgba(0, 0, 0, 0.63)',
                        }}
                      >
                        Inc.
                        <span
                          style={{
                            color: '#000',
                            fontWeight: '300',
                            marginRight: '5px',
                          }}
                        >
                          1
                        </span>
                        Member
                      </div>
                      <ProjectCountBoutique
                        chosen={chosen}
                        freePlan={freePlan}
                        onePlan={onePlan}
                      >
                        <span style={{ fontSize: '12px' }}>For Additional</span>
                        <span
                          style={{
                            display: 'flex',
                            fontSize: '16px',
                            flexDirection: 'column',
                          }}
                        >
                          {currencySymbol} {yearCost}/
                          <span style={{ fontSize: '12px' }}>per members</span>
                        </span>
                      </ProjectCountBoutique>
                    </ProjectNameBoutique>
                  </ProjectWrapper>
                </BilledCostInfoBoutiqueWrapper>
              </BilledCostInfoMainWrapper>
            </>
          )}
          {!freePlan && onePlan && chosen && (
            <>
              <StyledInputWrapper>
                <StyledInputNameWrapper>
                  <InputName>Add Project</InputName>
                  <InputContainer>
                    <StyledButton
                      onClick={handleProjectDecrement}
                      disabled={Number(selectedProjects) <= 0}
                    >
                      <img src={SVG.minusWhite} alt="minus" />
                    </StyledButton>
                    <StyledInput
                      value={selectedProjects}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (parseInt(e.target.value, 10) > 1000) return;
                        checkIsPositive(e);

                        setSelectedProjects(e.target.value);
                      }}
                      onBlur={() => null}
                      type="number"
                      placeholder="Projects"
                    />
                    <StyledButton
                      onClick={handleProjectIncrement}
                      disabled={Number(selectedProjects) >= 999}
                    >
                      <img src={SVG.plusWhite} alt="plus" />
                    </StyledButton>
                  </InputContainer>
                </StyledInputNameWrapper>
                <StyledInputNameWrapper>
                  <InputName>Add Members</InputName>
                  <InputContainer>
                    <StyledButton
                      onClick={handleMemberDecrement}
                      disabled={Number(selectedMembers) <= 0}
                    >
                      <img src={SVG.minusWhite} alt="minus" />
                    </StyledButton>
                    <StyledInput
                      value={selectedMembers}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (parseInt(e.target.value, 10) > 1000) return;
                        setSelectedMembers(e.target.value);
                      }}
                      onBlur={() => null}
                      type="number"
                      placeholder="Members"
                    />
                    <StyledButton
                      onClick={handleMemberIncrement}
                      disabled={Number(selectedMembers) >= 999}
                    >
                      <img src={SVG.plusWhite} alt="plus" />
                    </StyledButton>
                  </InputContainer>
                </StyledInputNameWrapper>
                {isDisableUpgrade != null ? (
                  <Error>
                    {`${Errors.AllowDecreaseMember} ${isDisableUpgrade}`}
                  </Error>
                ) : null}
              </StyledInputWrapper>
              <BilledWrapper>
                <BilledStats>
                  <AddMemberBillingBG>
                    <img src={SVG.fileWhite} alt="file" width="22px" />
                  </AddMemberBillingBG>
                  <ProjectCardWrapper>
                    <TotalProject>
                      Total Project {totalProjects || 3}
                    </TotalProject>
                    <IncludedProject>
                      Included{' '}
                      <span style={{ color: `${Colors.White}` }}>3</span>{' '}
                      Projects
                    </IncludedProject>
                  </ProjectCardWrapper>
                </BilledStats>
                <BilledStats>
                  <AddMemberBillingBG>
                    <img src={SVG.avtar} alt="avtar" width="22px" />
                  </AddMemberBillingBG>
                  <ProjectCardWrapper>
                    <TotalProject>
                      Total Member {totalMembers || 1}
                    </TotalProject>
                    <IncludedProject>
                      Included{' '}
                      <span style={{ color: `${Colors.White}` }}>1</span>{' '}
                      Members
                    </IncludedProject>
                  </ProjectCardWrapper>
                </BilledStats>
              </BilledWrapper>
              <CustomPlanWrapper
                chosen={chosen}
                freePlan={freePlan}
                onePlan={onePlan}
              >
                <CustomName
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                >
                  Your new pricing per {subscriptionInterval}
                </CustomName>
                <CustomSelectedPrice
                  chosen={chosen}
                  freePlan={freePlan}
                  onePlan={onePlan}
                  selectedPeriod={selectedPeriod}
                >
                  {currencySymbol}{' '}
                  {totalCost.toFixed(2) !== '0.00'
                    ? totalCustomCost.toFixed(2)
                    : defaultPlanCost.toFixed(2)}
                  /
                  <CustomProject
                    chosen={chosen}
                    freePlan={freePlan}
                    onePlan={onePlan}
                  >
                    per {subscriptionInterval}
                  </CustomProject>
                </CustomSelectedPrice>
              </CustomPlanWrapper>
            </>
          )}
        </Variants>
      )}

      <div>
        <Title chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
          What&apos;s included:
        </Title>

        {freePlan ? (
          <Included chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
            {Benefits.Free.map(benefit => (
              <StatusWrapper>
                <Status chosen={chosen} freePlan={freePlan} onePlan={onePlan} />
                <li key={benefit}>{benefit}</li>
              </StatusWrapper>
            ))}
          </Included>
        ) : onePlan?.name === 'Boutique' ? (
          <Included chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
            {Benefits.Boutique.map(benefit => (
              <StatusWrapper>
                <Status chosen={chosen} freePlan={freePlan} onePlan={onePlan} />
                <li key={benefit}>{benefit}</li>
              </StatusWrapper>
            ))}
          </Included>
        ) : (
          <Included chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
            {Benefits['Bulge-Bracket'].map(benefit => (
              <StatusWrapper>
                <Status chosen={chosen} freePlan={freePlan} onePlan={onePlan} />
                <li key={benefit}>{benefit}</li>
              </StatusWrapper>
            ))}
          </Included>
        )}
      </div>
    </Wrapper>
  );
};

const BilledCostWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const BilledCostInfo = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${({ chosen, freePlan, onePlan }) => {
      if (!freePlan && onePlan && chosen) {
        return 'rgb(255 255 255 / 20%)';
      }
      return 'rgba(2, 48, 97, 0.2)';
    }};
  background: ${Colors.White};
  display: flex;
  align-items: center;
  background: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return 'rgb(255 255 255 / 8%)';
    }
    return 'rgba(2, 48, 97, 0.05)';
  }};
  padding: 10px;
  gap: 7px;
`;

const BilledCostInfoMainWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const BilledCostInfoWrapper = styled(BilledCostInfo)`
  width: 100%;
  margin-bottom: 10px;
`;

const BilledCostInfoBoutiqueWrapper = styled(BilledCostInfoWrapper)`
  width: 180px;
`;

const BilledCostInfoBuldgeWrapper = styled(BilledCostInfoWrapper)`
  gap: 10px;
`;

const BilledCostInfoWrapperPremium = styled(BilledCostInfo)`
  width: 100%;
  margin-bottom: 10px;
`;

const CustomName = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return 'rgba(0, 0, 0, 0.85)';
  }};
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
`;

const CustomPlanWrapper = styled(BilledCostInfo)`
  width: 100%;
  justify-content: space-between;
  border: 0px;
`;

const AddMemberBG = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.SkySecondBlue;
    }
    return 'rgb(2 48 97 / 9%)';
  }};
`;

const AddMemberBGBoutique = styled(AddMemberBG)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

const ProjectWrapper = styled.div`
  min-width: calc(100% - 100px);
`;

const CustomPrice = styled.div<{
  selectedPeriod: string | undefined;
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlue;
  }};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(13.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: ${({ selectedPeriod }) =>
    selectedPeriod === 'Monthly' ? '47%' : '54%'};
`;

const CustomSelectedPrice = styled(CustomPrice)`
  text-align: right;
  width: calc(100% - 26px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;
`;
const CustomProject = styled.span<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return 'rgba(0, 0, 0, 0.85)';
  }};
  font-size: ${rem(7.5)};
`;

const Wrapper = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  height: 270px;
  margin-top: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return '-90px';
    }
    return '0';
  }};
`;

const StyledInputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 0 5px;
  background: rgba(255, 255, 255, 0.05);
`;

const StyledButton = styled.button`
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: ${rem(10)};
  outline: none;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  flex-shrink: 0;
  background: rgb(62 97 134);
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledInputNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputName = styled.span`
  color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ProjectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const TotalProject = styled.div`
  color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const IncludedProject = styled.div`
  color: rgba(255, 255, 255, 0.63);
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const BilledStats = styled.div`
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(2, 48, 97, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
`;

const AddMemberBillingBG = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff30;
`;

const BilledWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.06);
  justify-content: space-around;
  padding: 15px;
  margin-bottom: 15px;
`;

const StyledInput = styled(Input)`
  height: 48px;
  border: none;
  text-align: center;
  padding: 8px;

  color: ${Colors.GreyThird};
  background: ${Colors.Transparent};

  &::placeholder {
    color: rgba(255, 255, 255, 0.63);
  }

  &:disabled {
    border: 1px solid rgba(2, 48, 97, 0.16);
    background-color: rgba(2, 48, 97, 0.05);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
`;

const Variants = styled.div`
  & > h6 {
    margin: 10px 0;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.DarkBlack};

    font-size: ${rem(12)};
    & > span {
      margin-left: 4px;
      color: ${Colors.Blue};
    }
  }
`;

const ProjectName = styled.h6<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  margin: 10px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlack;
  }};
`;

const ProjectNameBoutique = styled(ProjectName)`
  font-size: 13px;
  flex-direction: column;
  margin: 0;
  align-items: start;
`;

const ProjectCount = styled.span<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  margin-left: 4px;
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlue;
  }};
  font-size: ${rem(8.75)};
`;

const ProjectCountBoutique = styled(ProjectCount)`
  margin-left: 0;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h6<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  padding: 0;
  margin: 20px 0 10px 0;

  font-size: ${rem(11)};
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlue;
  }};
  font-weight: 300;
`;

const Included = styled.ul<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  padding-left: 0;
  margin: 0;

  list-style: none;
  font-size: ${rem(10)};
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.GreyForth;
    }
    return Colors.DarkBlack;
  }};
  & li {
    margin-top: 4px;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0 10px 0;
`;

const Status = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 10.5px;
  border: none;
  border-radius: 50%;
  background-color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlue;
  }};

  ::before {
    position: absolute;
    top: 27%;
    left: 23%;
    display: block;
    content: '';
    width: 10.5px;
    height: 5.25px;
    border-bottom: 1px solid
      ${({ chosen, freePlan, onePlan }) => {
        if (!freePlan && onePlan && chosen) {
          return Colors.DarkBlue;
        }
        return Colors.White;
      }};
    border-left: 1px solid
      ${({ chosen, freePlan, onePlan }) => {
        if (!freePlan && onePlan && chosen) {
          return Colors.DarkBlue;
        }
        return Colors.White;
      }};
    transform: rotate(-45deg);
  }
`;

const Error = styled.div`
  color: ${Colors.Red};
  margin-top: 2px;
`;

export default PlanBody;
