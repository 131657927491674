import { ChangeEvent } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import SVG from '../../../../assets/svg';
import FormInput from '../../../../atoms/FormInput';
import { GroupContact } from './types';

interface Props {
  fields: any;
  register: Function;
  setValue: Function;
  errors: DeepMap<FieldValues, FieldError>;
  remove: (
    index?: number | number[] | undefined,
    shouldRender?: boolean | undefined,
  ) => void;
  append: Function;
}

function GroupContacts({
  fields,
  register,
  setValue,
  errors,
  remove,
  append,
}: Props) {
  const handleRemove = (index: number) => {
    remove(index);
    if (fields.length === 1) {
      append({
        firstName: '',
        lastName: '',
        email: '',
      });
    }
  };
  return (
    <GroupContainer>
      {fields.map((field: GroupContact, index: number) => (
        <Group key={field.id}>
          <FormInput
            value={field.firstName}
            placeholder="First name *"
            ref={register(`contact[${index}].firstName`)}
            name={`contact[${index}].firstName`}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue(`contact[${index}].firstName`, e.target.value)
            }
            error={errors.contact?.[index]?.firstName?.message}
          />
          <FormInput
            value={field.lastName}
            placeholder="Last name *"
            ref={register(`contact[${index}].lastName`)}
            name={`contact[${index}].lastName`}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue(`contact[${index}].lastName`, e.target.value)
            }
            error={errors.contact?.[index]?.lastName?.message}
          />
          <FormInput
            value={field.email}
            disabled={!!field.userId}
            placeholder="Email *"
            ref={register(`contact[${index}].email`)}
            name={`contact[${index}].email`}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue(`contact[${index}].email`, e.target.value)
            }
            error={errors.contact?.[index]?.email?.message}
          />
          <ButtonContainer>
            <DeleteButton
              src={SVG.deleteImg}
              onClick={() => handleRemove(index)}
              alt="Delete button"
            />
            <AddButton
              src={SVG.plus}
              onClick={() => append({ firstName: '', email: '', lastName: '' })}
              alt="Add button"
            />
          </ButtonContainer>
        </Group>
      ))}
    </GroupContainer>
  );
}

const GroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 18px;
`;
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 18px;
  align-items: flex-start;
`;

const DeleteButton = styled.img`
  width: 18px;
  cursor: pointer;
`;

const AddButton = styled.img`
  width: 18px;
  cursor: pointer;
`;

export default GroupContacts;
