/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import {
  cim as apiCim,
  dashboard as apiDashboard,
  DashboardGroupType,
  EventType,
  me as apiMe,
  nda as apiNda,
  OneNdaType,
  OneProjectType,
  PaginationDashboardLimit as DashboardLimit,
  projectEvents as apiProjectEvents,
  projects as apiProjects,
  teaser as apiTeaser,
} from '../../api';
import SVG from '../../assets/svg';
import {
  ButtonEllipse,
  Modal,
  ProjectCard,
  StyledInput,
  Textarea,
} from '../../atoms';
import PdfView from '../../atoms/PdfView';
import { ActivityMessages } from '../../const';
import { routeTemplate } from '../../helpers';
import { useNotification, usePermissions } from '../../hooks';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import { Routes } from '../../routes';
import { refreshUser, selectors } from '../../store';
import { PageWrapper } from '../../templates';
import {
  BoxShadow,
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../../themes';
import {
  ContextTable,
  ProjectActivity,
  ProjectBranch,
  Table,
  TeamMembers,
} from './components';

type UrlParams = {
  branchId: string;
  projectId: string;
};

function OneProject() {
  const { canChangeProject, canViewProject, canViewProjectbranch } =
    usePermissions();
  const { showInfo, showError } = useNotification();
  const history = useHistory();
  const dispatch = useDispatch();

  const { branchId, projectId } = useParams<UrlParams>();
  const user = useSelector(selectors.user);
  const isSignerOfProject = user.signerOfProjects.includes(Number(projectId));

  const [project, setProject] = useState<OneProjectType | null>(null);
  const [projectNda, setProjectNda] = useState<OneNdaType | null>(null);

  const [dashboard, setDashboard] = useState<DashboardGroupType[]>([]);

  const [dashboardLimit, setDashboardLimit] = useState(DashboardLimit);
  const [isAllDashboard, setIsAllDashboard] = useState(false);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const [showButtons, setShowButtons] = useState(true);

  const [events, setEvents] = useState<EventType[]>([]);
  const [unreadAllEvents, setAllUnreadEvents] = useState<EventType[]>([]);
  const [newUnreadEvents, setNewUnreadEvents] = useState<EventType[]>([]);

  const [isOpenAddBranch, setIsOpenAddBranch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfURL, setPdfURL] = useState('');

  const projectBranchId = project?.projectBranches.find(item => item.id);
  const title = isSignerOfProject
    ? dashboard.find(oneBranch => oneBranch.id === Number(projectBranchId))
        ?.name ?? ''
    : project?.name ?? '';

  const [editData, setEditData] = useState({
    title: '',
    description: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const [triggerUpdateEvents, setTriggerUpdateEvents] = useState(false);

  useEffect(() => {
    if (project && title) {
      setEditData({ title, description: project?.summary });
    }
  }, [project, title]);

  useEffect(() => {
    triggerUpdateEvents && setTriggerUpdateEvents(false);
  }, [triggerUpdateEvents]);

  useEffect(() => {
    // finding new unread events
    if (unreadAllEvents.length === 0) return;
    if (newUnreadEvents.length > 0) return;

    setNewUnreadEvents(
      unreadAllEvents.filter(event => event.project === Number(projectId)),
    );
  }, [projectId, unreadAllEvents]);

  useEffect(() => {
    // showing new unread events
    if (newUnreadEvents.length === 0) return;

    newUnreadEvents.forEach(({ author, status }) =>
      showInfo(`${ActivityMessages[status]} ${author}`),
    );
    setIsLoading(true);
    apiProjectEvents
      .setEventsAsRead({
        events: newUnreadEvents.map(e => `${e.id}`),
      })
      .then(() => setTriggerUpdateEvents(true))
      .finally(() => setIsLoading(false));
    // showInfo should not be in dependencies array
  }, [newUnreadEvents]);

  useEffect(() => {
    setIsLoading(true);
    // refresh user for getting current user projects
    apiMe
      .refreshUser()
      .then(({ data }) => dispatch(refreshUser(data)))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    // get current project
    setIsLoading(true);
    canViewProject &&
      apiProjects
        .getOneProject(Number(projectId))
        .then(response => setProject(response.data))
        .catch(error => error.status === '404' && history.push(Routes.projects))
        .finally(() => setIsLoading(false));
  }, [canViewProject, isSignerOfProject, projectId]);

  const updateDashboard = useCallback(() => {
    if (isLoadingDashboard) return;
    setIsLoadingDashboard(true);
    apiDashboard
      .getAllDashboardInfo(Number(projectId), dashboardLimit)
      .then(({ data }) => {
        if (!data.next) setIsAllDashboard(true);
        if (branchId) {
          const oneBranch = data.results.find(
            dashboardBranch => dashboardBranch.id === Number(branchId),
          );
          if (!oneBranch) return;
          setDashboard([oneBranch]);
          return;
        }
        setDashboard(data.results);
      })
      .finally(() => setIsLoadingDashboard(false));
  }, [dashboardLimit, branchId, projectId]);

  useEffect(() => {
    // get project dashboard
    canViewProjectbranch && updateDashboard();
  }, [canViewProjectbranch, updateDashboard]);

  const changeIsArchivedStatus = () => {
    if (isLoading) return;
    setIsLoading(true);

    const formData = new FormData();
    formData.append('is_archived', String(!project?.isArchived));
    apiProjects
      .updateProject(Number(projectId), formData)
      .then(({ data }) => setProject(data))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveEditData = () => {
    if (isLoading) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', editData.title);
    formData.append('summary', editData.description);
    apiProjects
      .updateProject(Number(projectId), formData)
      .then(({ data }) => {
        setEditData({ title: data.name, description: data.summary });
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.name) showError(data.name[0]);
      })
      .finally(() => {
        setIsEditMode(false);
        setIsLoading(false);
      });
  };

  const addRecipientsGroup = () => {
    canChangeProject &&
      history.push(routeTemplate(Routes.recipients, { projectId }));
  };

  const viewNda = () => {
    setShowPdf(true);
    if (projectNda && projectNda.id) {
      apiNda.getNdaPdf(projectNda.id).then(response => {
        const fileURL = URL.createObjectURL(response.data);
        setPdfURL(fileURL);
      });
    }
  };

  const viewTeaser = () => {
    setShowPdf(true);
    if (project && project.id) {
      apiTeaser.getTeaserPdf(project.id).then(res => {
        const fileURL = URL.createObjectURL(res.data);
        setPdfURL(fileURL);
      });
    }
  };

  const viewCim = () => {
    setShowPdf(true);
    if (project && project.id) {
      apiCim.getCimPdf(project.id).then(res => {
        const fileURL = URL.createObjectURL(res.data);
        setPdfURL(fileURL);
      });
    }
  };
  const viewSignNda = () => {
    setShowPdf(true);
    apiNda
      .getNdaPdf(dashboard[0]?.nda.id)
      .then(response => {
        const fileURL = URL.createObjectURL(response.data);
        setPdfURL(fileURL);
      })
      .catch(err => {
        showError(err.message);
        setShowPdf(false);
      });
  };

  const viewSignTeaser = () => {
    setShowPdf(true);
    apiTeaser
      .getTeaserPdf(Number(projectId))
      .then(res => {
        const fileURL = URL.createObjectURL(res.data);
        setPdfURL(fileURL);
      })
      .catch(err => {
        showError(err.message);
        setShowPdf(false);
      });
  };

  const viewSignCim = () => {
    setShowPdf(true);
    apiCim
      .getCimPdf(Number(projectId))
      .then(res => {
        const fileURL = URL.createObjectURL(res.data);
        setPdfURL(fileURL);
      })
      .catch(err => {
        showError(err.message);
        setShowPdf(false);
      });
  };

  const projectBranch = project?.projectBranches.find(t =>
    t.signers.includes(user.id as number),
  );

  const rejectNda = () => {
    Swal.fire({
      text: 'Are you sure you want to pass on this opportunity ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        apiDashboard
          .rejectNdaBySigner(Number(projectBranch?.id))
          .then(() => {
            setIsLoading(false);
            setShowButtons(false);
          })
          .catch(({ data }) => {
            if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
            if (data.detail) showError(data.detail);
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    apiNda.getNdaByProjectId(Number(projectId)).then(({ data }) => {
      if (!data.results[0]) {
        return;
      }
      setProjectNda(data.results[0]);
    });
  }, []);

  const showConfirmAlert = () => {
    Swal.fire({
      title: `${project?.isArchived ? 'Unarchive' : 'Archive'} Group`,
      text: `Are you sure, you want to ${
        project?.isArchived ? 'unarchive' : 'archive'
      } project ?`,
      iconHtml: `
      <img src="${SVG.deleteImg}" alt="delete" width="40px" />`,
      showCancelButton: true,
      confirmButtonText: project?.isArchived
        ? 'Unarchive'
        : 'Archive' || 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      showLoaderOnConfirm: isLoading,
      preConfirm: changeIsArchivedStatus,
      customClass: {
        confirmButton: 'confirm-button',
        title: 'title',
        icon: 'icon',
        container: 'container',
      },
    });
  };

  const tableGroups = useMemo(
    () =>
      dashboard.map(group => ({
        nda: group.nda,
      })),
    [dashboard],
  );

  const showNdaForSigner = () => {
    history.push(
      `${Routes.projects}/${projectId}/group/${tableGroups[0].nda.projectBranch}/nda`,
    );
  };
  return (
    <PageWrapper>
      <Modal
        disabledOverlayClick
        modalIsOpen={isOpenAddBranch}
        setModalIsOpen={setIsOpenAddBranch}
      >
        <ProjectBranch
          projectId={Number(projectId)}
          projectNda={projectNda}
          closeModal={() => setIsOpenAddBranch(false)}
          updateTable={updateDashboard}
          projectName={project?.name}
        />
      </Modal>

      <ContentWrapper>
        <SideMenu
          setAllEvents={useCallback(allEvents => setEvents(allEvents), [])}
          setAllUnreadEvents={useCallback(
            allUnreadEvents => setAllUnreadEvents(allUnreadEvents),
            [],
          )}
          updateEvents={triggerUpdateEvents}
        />

        <Main>
          {!isLoading ? (
            <>
              <Info>Project Dashboard</Info>
              <InfoBlock>
                <TitleBlock>
                  <div style={{ width: '100%' }}>
                    <div>
                      {isEditMode ? (
                        <Wrapper isSigner={isSignerOfProject}>
                          <ProjectInput
                            value={editData.title}
                            onChange={e =>
                              setEditData({
                                ...editData,
                                title: e.target.value,
                              })
                            }
                            onBlur={() => ''}
                            placeholder="Title"
                            maxLength={128}
                          />
                          <ButtonBox onClick={handleSaveEditData}>
                            <img width="16px" src={SVG.done} alt="Done" />
                          </ButtonBox>
                        </Wrapper>
                      ) : (
                        <Wrapper isSigner={isSignerOfProject}>
                          <Title>
                            {isSignerOfProject
                              ? project?.name
                              : editData?.title}
                          </Title>
                          <ButtonBox
                            disabled={!canChangeProject || isSignerOfProject}
                            onClick={() =>
                              !isSignerOfProject ? setIsEditMode(true) : null
                            }
                          >
                            {!isSignerOfProject && (
                              <img width="16px" src={SVG.pencil} alt="Edit" />
                            )}
                          </ButtonBox>
                        </Wrapper>
                      )}
                    </div>
                    {isEditMode ? (
                      <StyledTextarea
                        value={editData.description}
                        onChange={e =>
                          setEditData({
                            ...editData,
                            description: e.target.value,
                          })
                        }
                        onBlur={() => ''}
                        placeholder="Description"
                      />
                    ) : (
                      <Container>
                        <DescriptionContainer>
                          <Description>
                            {isSignerOfProject
                              ? project?.summary ?? '-'
                              : editData?.description ?? '-'}
                          </Description>
                        </DescriptionContainer>
                      </Container>
                    )}
                  </div>
                </TitleBlock>

                <>
                  {!isSignerOfProject ? (
                    <Members>
                      <ProjectCard
                        onClick={() => {
                          history.push(
                            routeTemplate(Routes.lookupNda, {
                              projectId,
                            }),
                          );
                        }}
                        project={SVG.file}
                        visible={
                          projectNda && (
                            <CreatedIcon onClick={() => viewNda()}>
                              <img src={SVG.eye} alt="" width="16px" />
                            </CreatedIcon>
                          )
                        }
                        created={
                          <CreatedIcon
                            onClick={() =>
                              history.push(
                                routeTemplate(Routes.editProjectNda, {
                                  projectId,
                                }),
                              )
                            }
                          >
                            <img
                              src={!projectNda ? SVG.add : SVG.pencilSky}
                              alt=""
                              width="16px"
                            />
                          </CreatedIcon>
                        }
                        cardbg={SVG.folderSkyBg}
                        alt="add member"
                        title="Project NDA"
                        details={projectNda?.name}
                      />
                      <ProjectCard
                        project={SVG.wordExcel}
                        visible={
                          <CreatedIcon onClick={() => viewTeaser()}>
                            <img
                              src={project?.teaser ? SVG.eye : ''}
                              alt=""
                              width="16px"
                            />
                          </CreatedIcon>
                        }
                        created={
                          <CreatedIcon
                            onClick={() =>
                              history.push(
                                routeTemplate(Routes.updateTeaser, {
                                  projectId,
                                }),
                              )
                            }
                            aria-hidden="true"
                          >
                            <img src={SVG.pencilSky} alt="" width="16px" />
                          </CreatedIcon>
                        }
                        cardbg={SVG.wordExcelBg}
                        alt="add member"
                        title="Project Teaser"
                      />
                      <ProjectCard
                        project={SVG.fileDocument}
                        visible={
                          <CreatedIcon onClick={() => viewCim()}>
                            <img
                              src={project?.cim ? SVG.eye : ''}
                              alt=""
                              width="16px"
                            />
                          </CreatedIcon>
                        }
                        created={
                          <CreatedIcon
                            onClick={() =>
                              history.push(
                                routeTemplate(Routes.updateCim, {
                                  projectId,
                                }),
                              )
                            }
                            aria-hidden="true"
                          >
                            <img src={SVG.pencilSky} alt="" width="16px" />
                          </CreatedIcon>
                        }
                        cardbg={SVG.fileDocumentBg}
                        alt="add member"
                        title="Project CIM"
                      />
                      <ProjectCard
                        project={SVG.addMember}
                        cardbg={SVG.addMemberCardBg}
                        title={
                          <TeamMembers
                            projectId={Number(projectId)}
                            membersInProject={project?.members}
                          />
                        }
                      />
                    </Members>
                  ) : (
                    <RecipientMember>
                      <ProjectCard
                        project={SVG.wordExcel}
                        visible={
                          projectBranch?.sendTeaser === true && (
                            <CreatedIcon onClick={() => viewSignTeaser()}>
                              <img
                                src={project?.teaser ? SVG.eye : ''}
                                alt=""
                                width="16px"
                              />
                            </CreatedIcon>
                          )
                        }
                        cardbg={SVG.wordExcelBg}
                        alt="add member"
                        title="Project Teaser"
                      />
                      <ProjectCard
                        project={SVG.file}
                        visible={
                          <CreatedIcon onClick={() => viewSignNda()}>
                            <img src={SVG.eye} alt="" width="16px" />
                          </CreatedIcon>
                        }
                        cardbg={SVG.folderSkyBg}
                        alt="add member"
                        title="Project NDA"
                        details={projectNda?.name}
                      />
                      <ProjectCard
                        project={SVG.fileDocument}
                        visible={dashboard.map(item =>
                          item.nda && item.nda.isSigned === true ? (
                            <CreatedIcon onClick={() => viewSignCim()}>
                              <img
                                src={project?.cim ? SVG.eye : ''}
                                alt=""
                                width="16px"
                              />
                            </CreatedIcon>
                          ) : null,
                        )}
                        cardbg={SVG.fileDocumentBg}
                        alt="add member"
                        title="Project CIM"
                      />
                    </RecipientMember>
                  )}
                </>
                {!canChangeProject ||
                  (isSignerOfProject &&
                    projectBranch &&
                    projectBranch.colorStatus !== 'red' &&
                    showButtons && (
                      <ButtonWrapper>
                        {project?.teaser &&
                          projectBranch.sendTeaser === true && (
                            <RecipientButton onClick={viewSignTeaser}>
                              View Teaser
                            </RecipientButton>
                          )}
                        <ReviewButton onClick={showNdaForSigner}>
                          {dashboard &&
                          dashboard[0] &&
                          dashboard[0].nda.isSigned
                            ? 'View signed NDA'
                            : 'Review and Sign NDA'}
                        </ReviewButton>
                        {dashboard.find(
                          item => item.nda && item.nda.isSigned === false,
                        ) && (
                          <RecipientButton onClick={rejectNda}>
                            Pass on Opportunity
                          </RecipientButton>
                        )}
                      </ButtonWrapper>
                    ))}

                {!canChangeProject || isSignerOfProject ? null : (
                  <>
                    <ActivityHeader>
                      <HeaderButton
                        disabled={!canChangeProject || isSignerOfProject}
                        onClick={e => canChangeProject && showConfirmAlert()}
                      >
                        {project?.isArchived ? (
                          <>
                            <img
                              src={SVG.unarchiveNda}
                              width="16px"
                              alt="Unarchive project"
                            />{' '}
                            <span>Unarchive project</span>
                          </>
                        ) : (
                          <>
                            <img
                              src={SVG.archiveNda}
                              width="16px"
                              alt="Archive project"
                            />
                            <span>Archive project</span>
                          </>
                        )}
                      </HeaderButton>
                    </ActivityHeader>

                    <ProjectActivity
                      projectId={Number(projectId)}
                      branchId={Number(branchId)}
                      allBranches={dashboard}
                      events={events}
                    />
                  </>
                )}
              </InfoBlock>
              {!canChangeProject || isSignerOfProject ? null : (
                <>
                  <TableWrapper>
                    <TableInfo>Project Tracker</TableInfo>
                    <HeaderButton
                      disabled={!canChangeProject || isSignerOfProject}
                      onClick={addRecipientsGroup}
                    >
                      Send the info to new Recipients
                    </HeaderButton>
                  </TableWrapper>
                  <TableContainer>
                    <ContextTable.Provider value={{ updateDashboard }}>
                      <Table
                        tableData={dashboard}
                        isSignerOfProject={isSignerOfProject}
                        projectName={project?.name}
                        projectBranch={project?.projectBranches}
                      />
                    </ContextTable.Provider>
                  </TableContainer>
                </>
              )}

              {!isAllDashboard && (
                <LoadMoreButton
                  text={isLoadingDashboard ? 'Loading...' : 'Load more'}
                  onClick={() =>
                    setDashboardLimit(prev => prev + DashboardLimit)
                  }
                />
              )}
            </>
          ) : (
            <Loader />
          )}
        </Main>
      </ContentWrapper>
      <>
        {showPdf && (
          <Modal
            modalIsOpen={showPdf}
            setModalIsOpen={() => {
              setShowPdf(false);
              setPdfURL('');
            }}
          >
            <PdfWrapper>
              <PdfView
                pdfURL={pdfURL}
                onClose={() => {
                  setShowPdf(false);
                  setPdfURL('');
                }}
              />
            </PdfWrapper>
          </Modal>
        )}
      </>
    </PageWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
`;

const RecipientButton = styled.button`
  display: inline-flex;
  padding: 13px 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid ${Colors.White};
  background: #023061;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.White};
  cursor: pointer;
`;

const ReviewButton = styled(RecipientButton)`
  border-radius: 6px;
  background: #d5e4f1;
  color: ${Colors.DarkBlack};
  margin: 0 20px 0 20px;
`;
const ContentWrapper = styled.div`
  padding: 10px 0;

  display: flex;
  flex-direction: column;
`;

const CreatedIcon = styled.div`
  cursor: pointer;
  margin-right: 5px;
`;

const Members = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

const RecipientMember = styled(Members)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
`;

const Main = styled.div`
  width: 100%;
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  background-color: ${Colors.Transparent};
`;

const TitleBlock = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colors.SkySecondBlue};
  border-radius: ${rootBorderRadius};
  background-color: ${Colors.White};
  box-shadow: ${BoxShadow.Base};
`;

const Info = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  color: ${Colors.Black};
  margin-top: 30px;
  margin-bottom: 25px;
`;

const TableInfo = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  color: ${Colors.Black};
`;

const Wrapper = styled.div<{ isSigner: boolean }>`
  border-radius: 10px 10px 0px 0px;
  border: 1px solid
    ${props => (props.isSigner ? Colors.CrystalEnigma : Colors.SkySecondBlue)};
  background: ${props =>
    props.isSigner ? Colors.CrystalEnigma : Colors.SkySecondBlue};
  padding: 5px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(13.75)};
  color: ${Colors.Black};
  font-weight: 300;
  line-height: normal;
  font-style: normal;
  word-wrap: break-word;
  word-break: break-all;
`;

const Description = styled.p`
  padding: 0px 25px;
  margin-top: 0px;

  word-wrap: break-word;
  word-break: break-all;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkGrey};
`;

const ProjectInput = styled(StyledInput)`
  padding: 0;
  font-size: ${rem(13.75)};
  border: none;
  border-radius: 0;
  background: ${Colors.SkyBlue};
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  padding: 5px 20px;
  margin-top: 10px;

  font-size: ${rem(13.75)};
  color: ${Colors.DarkGrey};
  border: none;

  ${removeScrollStyles};
`;

const ButtonBox = styled.div<{ disabled?: boolean }>`
  height: 35px;

  display: flex;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TableContainer = styled.div`
  width: 100%;
`;

const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0px;
`;

const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
`;
const HeaderButton = styled.button<{ disabled: boolean }>`
  display: inline-flex;
  padding: 13px 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #fff;
  background: var(--Dark-Blue-New, #023061);
  font-family: Sofia Pro, sans-serif;
  font-size: 1rem;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const LoadMoreButton = styled(ButtonEllipse)<{ disabled: boolean }>`
  height: 30px;
  border-radius: 15px;
  width: 100px;
  box-shadow: ${BoxShadow.Secondary};
  color: ${Colors.LightBlue};
  font-size: ${rem(8)};
  margin: 10px auto;

  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const PdfWrapper = styled.div`
  height: 95vh;
  width: 80vw;
  background-color: ${Colors.White};
`;

const DescriptionContainer = styled.div`
  height: 120px;
  overflow: auto;
  ${removeScrollStyles}
`;

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
export default OneProject;
