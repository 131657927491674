import { SyntheticEvent, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../../api';
import {
  ButtonEllipse,
  FormWrapper,
  FormWrapperPadding,
  GeoInput,
  Input,
} from '../../atoms';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import { InputPassword, Loader } from '../../molecules';
import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';

type Params = {
  userIdAndToken: string;
};

function RegisterMember() {
  const { showError } = useNotification();

  const [name, setName] = useState(initialValue);
  const [location, setLocation] = useState(initialValue);
  const [password, setPassword] = useState(initialValue);
  const [linkedInUrl, setLinkedInUrl] = useState(initialValue);
  const [isRegister, setIsRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { userIdAndToken } = useParams<Params>();
  const [userId, token] = userIdAndToken.split(':');

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(name, setName, Errors.EnterFirstAndLastname)) {
      isValid = false;
    }

    const lastName = name.value.split(' ')[1];
    if (isEmptyInput(lastName, setName, Errors.EnterFirstAndLastname)) {
      isValid = false;
    }

    if (isEmptyInput(password, setPassword, Errors.EnterPassword)) {
      isValid = false;
    }
    if (isEmptyInput(location, setLocation, Errors.EnterField)) {
      isValid = false;
    }

    if (isEmptyInput(linkedInUrl, setLinkedInUrl, Errors.EnterField)) {
      isValid = false;
    }

    if (
      name.errorMsg ||
      location.errorMsg ||
      password.errorMsg ||
      linkedInUrl.errorMsg
    ) {
      isValid = false;
    }

    return isValid;
  };

  const handleRegister = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isRegister) return;
    if (!validate()) return;

    setIsLoading(true);

    authorization
      .registerMember({
        newPassword1: password.value,
        newPassword2: password.value,
        uid: userId,
        token,
        firstName: name.value.split(' ')[0],
        lastName: name.value.split(' ')[1],
        location: location.value,
        linkedinLink: linkedInUrl.value,
      })
      .then(() => setIsRegister(true))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.newPassword1, setPassword);
        apiFieldErrorHandler(data.newPassword2, setPassword);
        apiFieldErrorHandler(data.firstName, setName);
        apiFieldErrorHandler(data.location, setLocation);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Wrapper>
      <>{isLoading && <Loader />}</>
      <CreateAccountText>Create an account</CreateAccountText>
      {!isRegister ? (
        <>
          <FromSubmit onSubmit={handleRegister}>
            <FormWrapper formSize={FormWrapperPadding.small}>
              <RowWrapper>
                <InputWrapper>
                  <Input
                    value={name.value}
                    onChange={handleInputChange(setName)}
                    onBlur={handleInputBlur(name, setName)}
                    placeholder="First name & Last name"
                    error={name.errorMsg}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputPassword
                    value={password.value}
                    onChange={handleInputChange(setPassword)}
                    onBlur={handleInputBlur(password, setPassword)}
                    placeholder="Password"
                    error={password.errorMsg}
                  />
                </InputWrapper>
              </RowWrapper>
              <RowWrapper>
                <InputWrapper>
                  <GeoInput location={location} setLocation={setLocation} />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    value={linkedInUrl.value}
                    onChange={handleInputChange(setLinkedInUrl)}
                    onBlur={handleInputBlur(linkedInUrl, setLinkedInUrl)}
                    placeholder="LinkedIn Url"
                    error={linkedInUrl.errorMsg}
                  />
                </InputWrapper>
              </RowWrapper>
            </FormWrapper>
            <ButtonWrapper>
              <StyledButton type="submit" text="Sign Up" />
            </ButtonWrapper>
          </FromSubmit>
        </>
      ) : (
        <SuccessRegister>
          Register Success!
          <StyledLink to={Routes.login}>Go to Login page</StyledLink>
        </SuccessRegister>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(PageWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.LightBlue};
`;

const CreateAccountText = styled.div`
  margin-bottom: 35px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(30)};
  color: ${Colors.White};
`;

const InputWrapper = styled.div`
  width: 250px;
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  padding-bottom: 20px;
`;

const FromSubmit = styled.form`
  width: fit-content;
`;

const SuccessRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 17px;
  border-radius: 10.5px;
  box-shadow: 0 3.5px 3.5px rgb(89 48 255 / 10%);
  background-color: #ffffff;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(21)};
  line-height: 1.5;
  color: ${Colors.LightBlue};
`;

const StyledLink = styled(NavLink)`
  display: block;
  font-size: ${rem(15)};
  font-family: ${Fonts.Roboto};
  color: ${Colors.LightBlue};
  text-decoration: none;
  transition: all 150ms linear;

  &:hover {
    color: ${Colors.Blue};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.White};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};

  &:hover {
    color: ${Colors.LightBlue};
    background-color: ${Colors.White};
  }
`;

export default RegisterMember;
