import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  NegotiationStatus,
  OneNdaType,
  Thread,
  thread as apiThread,
  UserData,
} from '../../../api';
import SVG from '../../../assets/svg';
import ICON_BUTTON from '../../../assets/svg/ICON_BUTTON';
import { usePermissions } from '../../../hooks';
import { selectors } from '../../../store';
import { Colors, Fonts, rem } from '../../../themes';

type Props = {
  thread: Thread;
  isAccented: boolean;
  updateThread: () => void;
  nda: OneNdaType | null;
  isSignerOfProject: boolean;
};

function OneThread({
  thread,
  isAccented,
  updateThread,
  nda,
  isSignerOfProject,
}: Props) {
  const [isHidePopover, setIsHidePopover] = useState(false);
  const { canAddNegotiation } = usePermissions();
  const user: UserData = useSelector(selectors.user);

  useEffect(() => {
    isHidePopover && setIsHidePopover(false);
  }, [isHidePopover]);

  const rejectThread = () => {
    apiThread.rejectThread(thread.id).then(updateThread);
  };

  const approveThread = () => {
    apiThread.approveThread(thread.id).then(updateThread);
  };

  const checkShowButtons = () => {
    if (
      isSignerOfProject &&
      thread.negotiationStatus ===
        NegotiationStatus.ApprovedByRecipientWaitingSender
    ) {
      return false;
    }
    if (
      isSignerOfProject &&
      thread.negotiationStatus ===
        NegotiationStatus.RejectedByRecipientWaitingSender
    ) {
      return false;
    }

    if (thread.negotiationStatus === NegotiationStatus.ApprovedByBothParties) {
      return false;
    }

    if (
      !isSignerOfProject &&
      thread.negotiationStatus ===
        NegotiationStatus.ApprovedBySenderWaitingRecipient
    ) {
      return false;
    }
    if (
      !isSignerOfProject &&
      thread.negotiationStatus ===
        NegotiationStatus.RejectedBySenderWaitingRecipient
    ) {
      return false;
    }

    return true;
  };
  return (
    <Wrapper>
      <Container>
        <InfoBlock>
          <FlexBlock>
            <Coords accent={isAccented} src={SVG.profileAvtar} alt="user" />
            <div>
              <Text>{thread.author}</Text>
              <StyleTime>
                {thread.timeAgo && thread.timeAgo.replace('about', '')}
              </StyleTime>
            </div>
          </FlexBlock>
          <FlexBlock>
            {nda &&
              !nda?.isRecalled &&
              !nda?.isSigned &&
              canAddNegotiation &&
              checkShowButtons() &&
              thread &&
              user &&
              user.id &&
              user.id !== parseInt(thread.authorId, 10) && (
                <InfoBlock>
                  <ActionButton
                    onClick={rejectThread}
                    src={
                      thread.negotiationStatus ===
                      ('rejected_by_sender_waiting_recipient' ||
                        'rejected_by_recipient_waiting_for_sender')
                        ? ICON_BUTTON.Cancel
                        : ICON_BUTTON.Cross
                    }
                    alt="reject thread"
                  />
                  <ActionButton
                    onClick={approveThread}
                    src={
                      thread.negotiationStatus ===
                      ('approved_by_sender_waiting_recipient' ||
                        'approved_by_recipient_waiting_sender')
                        ? ICON_BUTTON.Approve
                        : ICON_BUTTON.Active
                    }
                    alt="approve thread"
                  />
                </InfoBlock>
              )}
          </FlexBlock>
        </InfoBlock>
        <StyleComment>{thread.text}</StyleComment>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: max-content;
  border-bottom: 1px solid ${Colors.offBlue};
`;

const Container = styled.div`
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  padding: 10px 15px;
  transition: all 150ms linear;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexBlock = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const ActionButton = styled.img`
  cursor: pointer;
  transition: all 0.1s linear;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Text = styled.span`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Coords = styled.img<{ accent: boolean }>`
  background-color: ${Colors.offBlue};
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms linear;
  padding: 15px;
`;

const StyleTime = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyleComment = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  padding: 11px 10px 18px 4px;
  word-wrap: break-word;
`;
export default OneThread;
