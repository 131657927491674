import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ContactType } from '../api';
import { Signer } from '../api/signership';
import { Colors, Fonts, rem, removeScrollStyles } from '../themes';

const OneContactHeight = 30;

type Props = {
  allContacts?: ContactType[];
  usersInGroup?: number[];
  allsigner?: Signer[] | undefined;
  signerId?: number | undefined;
};

function Contacts({ allContacts, usersInGroup, allsigner, signerId }: Props) {
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const [hiddenBlocks, setHiddenBlocks] = useState<number | null>(null);

  const calcHiddenBlockCount = (parrentEl: HTMLDivElement) =>
    (parrentEl.scrollHeight - parrentEl.offsetHeight) / OneContactHeight;

  // calback in useEffect must be called on every render
  /* eslint-disable-next-line */
  useEffect(() => {
    const parrentEl = anchorEl.current;

    if (parrentEl === null) return;
    if (typeof hiddenBlocks === 'number') return;
    if (!parrentEl.children[0]) return;

    const hiddenCount = calcHiddenBlockCount(parrentEl);

    setHiddenBlocks(hiddenCount);
  });

  const connectionDetails = allsigner?.filter(i => i.id === signerId);
  return (
    <ContactsBox>
      <GroupContacts ref={anchorEl}>
        {allContacts
          ?.filter(contact => usersInGroup?.includes(Number(contact.user.pk)))
          .map(contact => (
            <OneContact key={contact.id}>
              {contact.firstName} {contact.lastName}
            </OneContact>
          ))}
      </GroupContacts>
      <GroupContacts ref={anchorEl}>
        {connectionDetails?.map(i => (
          <div key={i.id}>
            <OneContact>
              {i.firstName || '-'} {i.lastName || '-'}
            </OneContact>
            <OneContact>{i.email}</OneContact>
            <OneContact>{i.company?.name || '-'}</OneContact>
          </div>
        ))}
      </GroupContacts>
      {hiddenBlocks !== 0 && hiddenBlocks && (
        <HiddenCount>+{hiddenBlocks} connections</HiddenCount>
      )}
    </ContactsBox>
  );
}

const ContactsBox = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
`;

const GroupContacts = styled.div`
  max-height: ${3 * OneContactHeight}px;
  overflow: hidden;

  ${removeScrollStyles}
`;

const OneContact = styled.div`
  display: flex;
  align-items: center;
  height: ${OneContactHeight}px;
  width: fit-content;
  padding: 3px;
  white-space: nowrap;
  color: ${Colors.WeakBlack};
`;

const HiddenCount = styled.div`
  color: ${Colors.DarkGrey};
`;

export default Contacts;
