import styled from 'styled-components';

import { Colors } from '../themes';

const Spiner = styled.div`
  width: 130px;
  height: 130px;

  border: 7px ${Colors.Transparent} solid;
  border-top: 7px ${Colors.LightBlue} solid;
  border-radius: 50%;
  -webkit-animation: rotateSpinner 0.6s infinite linear;
  animation: rotateSpinner 0.6s infinite linear;

  @-webkit-keyframes rotateSpinner {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes rotateSpinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export default Spiner;
