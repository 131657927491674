import { AxiosResponse } from 'axios';

import api from '../authApi';
import { PermissionsResponse } from './types';

const getPermissions = (): Promise<AxiosResponse<PermissionsResponse>> =>
  api.get('/permissions/');

const permissions = { getPermissions };
export default permissions;
