import styled from 'styled-components';

import { Colors, Fonts, rem } from '../../../../themes';
import Invoices from '../Invoices';
import BillingInfo from './BillingInfo';

interface IProps {
  membersCount: number;
  currencySymbol: string;
}

const Billing = ({ currencySymbol, membersCount }: IProps) => (
  <Wrapper>
    <Header>
      <Title>Billing Information</Title>
      <Invoices />
    </Header>
    <BillingInfo membersCount={membersCount} currencySymbol={currencySymbol} />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  border-radius: 12px;
  padding: 35px 0 0 0;
  min-height: 210px;
  height: 100%;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const Title = styled.div`
  margin: 20px 0px;

  text-align: center;
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export default Billing;
