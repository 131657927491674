import styled from 'styled-components';

import SVG from '../../../../assets/svg';
import { TextButton } from '../../../../atoms';
import { BoxShadow, Colors, Fonts, rem } from '../../../../themes';

type Props = {
  onResendClick: () => void;
};
function RecipientActions({ onResendClick }: Props) {
  return (
    <Container>
      <OneAction onClick={onResendClick}>
        <Button src={SVG.sent2} alt="Resend" />
        <StyledButton>Resend NDA</StyledButton>
      </OneAction>
    </Container>
  );
}

export const Container = styled.div`
  background-color: ${Colors.White};
  position: relative;
  padding: 20px;
  border-radius: 10px;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  box-shadow: ${BoxShadow.Third};
`;

export const OneAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Button = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const StyledButton = styled(TextButton)`
  font-size: ${rem(9)};
`;

export const ColorBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid ${Colors.LightGrey};

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export default RecipientActions;
