import { useSelector } from 'react-redux';

import { selectors } from '../store';

const useSubscription = () => {
  const { product } = useSelector(selectors.subscription);

  if (product.product) {
    return product.product.name;
  }

  return 'FREE';
};

export default useSubscription;
