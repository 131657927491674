import { Alert, Avatar, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  contacts as apiContacts,
  ContactType,
  dashboard as apiDashboard,
} from '../../api';
import { Signer } from '../../api/signership';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';

type Props = {};

type UrlParams = {
  branchId: string;
  projectId: string;
};

const UserList = (props: Props) => {
  const { projectId, branchId } = useParams<UrlParams>();
  const location = useLocation();
  const [Allsigner, setAllSigner] = useState<Signer[]>([]);
  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [userNotFound, setUserNotFound] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('users')) {
      apiContacts.getAllContact().then(({ data }) => {
        setAllContacts(data);
        if (data.length === 0) {
          setUserNotFound(true);
        }
      });
    } else {
      apiDashboard.getAllDashboardInfo(Number(projectId)).then(({ data }) =>
        setAllSigner(
          data.results
            .filter(i => i.id === Number(branchId))
            .map(i => i.signership.map(o => o.signer))
            .flat(),
        ),
      );
    }
  }, [projectId, branchId, location.pathname]);

  return (
    <PageWrapper>
      <Wrapper>
        {userNotFound && (
          <Alert
            severity="error"
            style={{
              marginBottom: '10px',
              alignItems: 'center',
              borderLeft: '5px solid darkred',
            }}
          >
            Currently you have no connection in your network
          </Alert>
        )}
        <GridWrapper>
          {Allsigner.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card key={index}>
              <CardBody>
                <ImageCircle src={item.avatar || ''} alt={item.avatar || ''} />
                <TextHeading>
                  {item.firstName ? item.firstName : '-'}
                  <span style={{ marginLeft: '5px' }} />
                  {item.lastName ? item.lastName : '-'}
                </TextHeading>
                <Label>{item.email}</Label>
              </CardBody>
            </Card>
          ))}
          {!userNotFound &&
            allContacts.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Card key={index}>
                <CardBody>
                  <ImageCircle src="" alt="" />
                  <TextHeading>
                    {item.firstName ? item.firstName : '-'}
                    <span style={{ marginLeft: '5px' }} />
                    {item.lastName ? item.lastName : '-'}
                  </TextHeading>
                  <Label>{item.user.email}</Label>
                </CardBody>
              </Card>
            ))}
        </GridWrapper>
      </Wrapper>
    </PageWrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: ${Fonts.Sofia};
`;

const GridWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

const Card = styled.div`
  border: 1px solid ${Colors.Grey};
  border-radius: 16px;
  text-align: center;
  background: ${Colors.White};
`;

const CardBody = styled.div`
  padding: 20px;
`;

const ImageCircle = styled(Avatar)`
  height: 100px !important;
  width: 100px !important;
  border-radius: 100%;
  margin: 0 auto;
  border: 1px solid ${Colors.Grey};
`;

const TextHeading = styled.div`
  font-size: ${rem(12.5)};
  font-weight: 600;
  line-heigth: normal;
  margin: 15px 0;
`;

const Label = styled.div`
  font-size: ${rem(10)};
  font-weight: 400;
  line-heigth: normal;
`;

export default UserList;
