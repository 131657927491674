import { AxiosResponse } from 'axios';

import api from '../authApi';
import { CreatePointerRequest, Pointer } from './types';

const createPointer = (
  body: CreatePointerRequest,
): Promise<AxiosResponse<Pointer>> => api.post('/projects/pointers/', body);

const deletePointer = (pointerId: number): Promise<AxiosResponse> =>
  api.delete(`/projects/pointers/${pointerId}/`);

const threadPointer = { createPointer, deletePointer };
export default threadPointer;
