import Active from './Active.svg';
import Approve from './Approve.svg';
import Cancel from './Cancel.svg';
import Cross from './Cross.svg';
import Deactivate from './Deactivate.svg';
import Delete from './Delete-red.svg';
import Disabled from './Disabled.svg';
import Expired from './Expired.svg';
import Resend from './Resend-2.svg';
import Reset from './Reset.svg';
import Sent from './Sent-3.svg';

const ICON_BUTTON = {
  Active,
  Cross,
  Deactivate,
  Delete,
  Disabled,
  Expired,
  Resend,
  Reset,
  Sent,
  Inactive: Cross,
  Cancel,
  Approve,
};

export default ICON_BUTTON;
