import styled from 'styled-components';

import { Colors } from '../themes';

type Props = {
  formSize: string;
};

export enum FormWrapperPadding {
  small = '25px 25px 5px 30px',
  big = '30px 40px 10px 40px',
}

const FormWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: ${props => props.formSize};
  border-radius: 10.5px;
  box-shadow: 0 3.5px 3.5px rgba(89, 48, 255, 0.1);
  background-color: ${Colors.White};
`;

export default FormWrapper;
