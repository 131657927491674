import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { me as apiMe, UserData } from '../../../api';
import { ButtonEllipse } from '../../../atoms';
import { dataURItoImageFile } from '../../../helpers';
import { useNotification } from '../../../hooks';
import { BoxShadow, Colors, rem, rootBorderRadius } from '../../../themes';

type Props = {
  className?: string;
  name: string;
  updateUser?: (newUser: UserData) => void;
};

function InitialsModal({ className, name, updateUser }: Props) {
  const { showError } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [signature, setSignature] = useState('');

  const updateUserSign = async () => {
    if (isLoading) return;

    const newSign = await dataURItoImageFile(signature);

    setIsLoading(true);

    const form = new FormData();
    form.append('sign', newSign);

    apiMe
      .updateUserSign(form)
      .then(({ data }) => {
        setSignature('');
        setIsLoading(false);

        updateUser && updateUser(data);
      })
      .catch(({ data }) => {
        setIsLoading(false);
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  };

  useEffect(() => {
    const canvasTxt = document.querySelector(
      '#canvasComponent',
    ) as HTMLCanvasElement;

    const ctx = canvasTxt.getContext('2d');

    if (ctx) {
      ctx.canvas.width = 400;
      ctx.canvas.height = 80;
      ctx.font = 'italic 24px Sofia Pro';
      ctx.textAlign = 'center';
      ctx.fillText(name, 200, 46);

      setSignature(ctx.canvas.toDataURL('image/png'));
    }
  }, [name]);

  return (
    <Container className={className}>
      <canvas id="canvasComponent" style={{ display: 'none' }} />

      {signature.length > 0 ? (
        <Image id="imageComponent" src={signature} alt="signature" />
      ) : null}

      <ButtonBox>
        <SaveButton disabled={isLoading} onClick={updateUserSign} text="Save" />
      </ButtonBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  border: 0.5px solid ${Colors.MiddleGrey};
  border-radius: ${rootBorderRadius};
  box-shadow: ${BoxShadow.Base};
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const SaveButton = styled(ButtonEllipse)`
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-size: ${rem(9)};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
`;

export default InitialsModal;
