import styled from 'styled-components';

import { Onboarding } from '../api';
import { ChecklistItem } from '../atoms';
import { Colors, Fonts, rem } from '../themes';

enum ChecklistItems {
  CreateProject = 'Create your first project',
  AddMember = 'Add team members',
  UploadNda = 'Upload an NDA',
}

type Props = {
  onboarding: Onboarding;
};

function Checklist({ onboarding }: Props) {
  const onboardingFalse =
    !onboarding?.isNdaUploaded &&
    !onboarding?.isMembersInvited &&
    !onboarding?.isProjectsCreated;

  if (onboardingFalse) {
    return null;
  }
  return (
    <Wrapper>
      <ChecklistHeader>Get setup on Namda</ChecklistHeader>
      <ChecklistItem
        text={ChecklistItems.CreateProject}
        completed={onboarding?.isProjectsCreated}
      />
      <ChecklistItem
        text={ChecklistItems.AddMember}
        completed={onboarding?.isMembersInvited}
      />
      <ChecklistItem
        text={ChecklistItems.UploadNda}
        completed={onboarding?.isNdaUploaded}
      />
    </Wrapper>
  );
}

const ChecklistHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10.5px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  background-color: ${Colors.SkyBlue};
  color: ${Colors.DarkBlack};
  width: 100%;
`;

const Wrapper = styled.div`
  min-width: 200px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  border: 1px solid ${Colors.SkySecondBlue};
`;

export default Checklist;
