import { Checkbox } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../../api';
import { ButtonEllipse } from '../../atoms';
import AuthGeoInput from '../../atoms/AuthGeoInput';
import AuthWrapper from '../../atoms/AuthWrapper';
import MuiInput from '../../atoms/MuiInput';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import MuiInputHints from '../../molecules/MuiInputHints';
import MuiInputPassword from '../../molecules/MuiInputPassword';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

interface LocationState {
  from: {
    pathname: string;
  };
}

function Register() {
  const { showError } = useNotification();
  const locations = useLocation<LocationState>();
  const queryParams = new URLSearchParams(locations.search);
  const emailFromQuery = queryParams.get('email') || '';

  const [firstName, setFirstName] = useState(initialValue);
  const [lastName, setLastName] = useState(initialValue);
  const [email, setEmail] = useState({
    ...initialValue,
    value: emailFromQuery || '',
  });
  const [location, setLocation] = useState(initialValue);
  const [companyName, setCompanyName] = useState(initialValue);
  const [password, setPassword] = useState(initialValue);
  const [linkedInUrl, setLinkedInUrl] = useState(initialValue);
  const [country, setCountry] = useState(initialValue);

  const [isRegister, setIsRegister] = useState(false);
  const [termsChecked, setTermsChecked] = useState({
    value: false,
    errorMsg: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(firstName, setFirstName, Errors.EnterFirstName)) {
      isValid = false;
    }

    if (isEmptyInput(lastName, setLastName, Errors.EnterLastName)) {
      isValid = false;
    }

    if (isEmptyInput(email, setEmail, Errors.EnterYourEmail)) {
      isValid = false;
    }

    if (isEmptyInput(password, setPassword, Errors.EnterPassword)) {
      isValid = false;
    }

    if (isEmptyInput(companyName, setCompanyName, Errors.EnterCompanyName)) {
      isValid = false;
    }

    if (isEmptyInput(location, setLocation, Errors.EnterLocation)) {
      isValid = false;
    }

    if (!termsChecked.value) {
      setTermsChecked({ ...termsChecked, errorMsg: Errors.CheckTerms });
      isValid = false;
    }

    if (
      firstName.errorMsg ||
      lastName.errorMsg ||
      email.errorMsg ||
      location.errorMsg ||
      companyName.errorMsg ||
      password.errorMsg ||
      linkedInUrl.errorMsg
    )
      isValid = false;

    return isValid;
  };

  const handleRegister = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isRegister) return;
    if (!validate()) return;

    setIsLoading(true);

    authorization
      .register({
        email: email.value,
        password1: password.value,
        password2: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        location: location.value,
        companyName: companyName.value,
        linkedinLink: linkedInUrl.value,
        invitedWith: 's',
        country: country.value,
      })
      .then(() => setIsRegister(true))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.firstName, setFirstName);
        apiFieldErrorHandler(data.lastName, setLastName);
        apiFieldErrorHandler(data.email, setEmail);
        apiFieldErrorHandler(data.location, setLocation);
        apiFieldErrorHandler(data.companyName, setCompanyName);
        apiFieldErrorHandler(data.password1, setPassword);
        apiFieldErrorHandler(data.country, setCountry);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthWrapper isLoading={isLoading}>
      {!isRegister ? (
        <>
          <FromSubmit onSubmit={handleRegister}>
            <Text>Register to review the NDA</Text>
            <RowWrapper>
              <InputWrapper>
                <MuiInput
                  required
                  value={firstName.value}
                  onChange={handleInputChange(setFirstName)}
                  onBlur={handleInputBlur(firstName, setFirstName)}
                  placeholder="First name"
                  error={firstName.errorMsg}
                />
              </InputWrapper>
              <InputWrapper>
                <MuiInput
                  required
                  value={lastName.value}
                  onChange={handleInputChange(setLastName)}
                  onBlur={handleInputBlur(lastName, setLastName)}
                  placeholder="Last name"
                  error={lastName.errorMsg}
                />
              </InputWrapper>
            </RowWrapper>
            <InputWrapper>
              <MuiInputHints
                required
                value={companyName.value}
                onChange={handleInputChange(setCompanyName)}
                onBlur={handleInputBlur(companyName, setCompanyName)}
                placeholder="Company name"
                error={companyName.errorMsg}
                hint="Please specify company&#39;s legal name"
              />
            </InputWrapper>
            <InputWrapper>
              <MuiInput
                required
                type="email"
                value={email.value}
                onChange={handleInputChange(setEmail)}
                onBlur={handleInputBlur(email, setEmail)}
                placeholder="Company E-mail"
                error={email.errorMsg}
              />
            </InputWrapper>
            <InputWrapper>
              <MuiInputPassword
                required
                value={password.value}
                onChange={handleInputChange(setPassword)}
                onBlur={handleInputBlur(password, setPassword)}
                placeholder="Password"
                error={password.errorMsg}
              />
            </InputWrapper>
            <InputWrapper>
              <AuthGeoInput
                required
                location={location}
                setLocation={setLocation}
                country={country}
                setCountry={setCountry}
              />
            </InputWrapper>
            <InputWrapper>
              <MuiInput
                value={linkedInUrl.value}
                onChange={handleInputChange(setLinkedInUrl)}
                onBlur={handleInputBlur(linkedInUrl, setLinkedInUrl)}
                placeholder="LinkedIn Url"
                error={linkedInUrl.errorMsg}
              />
            </InputWrapper>
            <TermsWrapper>
              <Checkbox
                checked={termsChecked.value}
                onChange={() =>
                  setTermsChecked({ value: !termsChecked.value, errorMsg: '' })
                }
              />
              I agree to the{' '}
              <TermsLink to={Routes.terms}>Terms & Conditions</TermsLink>
              <Error>{termsChecked.errorMsg}</Error>
            </TermsWrapper>
            <ButtonWrapper>
              <StyledButton type="submit" text="Register" />
            </ButtonWrapper>
          </FromSubmit>
          <SignUpText>
            Already have an account?
            <StyledLink to={Routes.login}>Sign In</StyledLink>
          </SignUpText>
        </>
      ) : (
        <SuccessRegister>
          Register Success! <br /> Check your email and verify your account
        </SuccessRegister>
      )}
    </AuthWrapper>
  );
}

const InputWrapper = styled.div`
  margin-bottom: 31px;
  width: 100%;
  position: relative;
`;

const Text = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  width: 100%;
`;

const FromSubmit = styled.form`
  width: 100%;
`;

const SuccessRegister = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10.5px;
  box-shadow: 0 3.5px 3.5px rgb(89 48 255 / 10%);
  font-family: ${Fonts.Sofia};
  font-size: ${rem(21)};
  line-height: 1.5;
  color: ${Colors.LightBlue};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;

const StyledButton = styled(ButtonEllipse)`
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  width: 124px;
  height: 48px;
  border-radius: 10px;
  border: none;

  &:hover {
    border: none;
  }
`;

const SignUpText = styled.div`
  color: ${Colors.LightSecondGrey};
  margin-top: 30px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Roboto};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-left: 5px;
`;

const TermsWrapper = styled.div`
  font-family: ${Fonts.Roboto};
  color: ${Colors.LightSecondGrey};
  position: relative;
  font-size: ${rem(8.75)};
  span {
    padding: 0 10px 0 0;
  }
  svg path {
    fill: ${Colors.DarkBlue};
    stroke-width: 1px;
  }
`;

const TermsLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-left: 3px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Error = styled.div`
  margin-top: 12px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
  position: absolute;
  bottom: -20px;
  @media (max-width: 520px) {
    font-size: ${rem(8.75)};
  }
`;

export default Register;
