import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  ContactType,
  OneSignerGroup,
  signers as apiSigners,
} from '../../../../api';
import SVG from '../../../../assets/svg';
import { ButtonEllipse, DotsLoading, Input } from '../../../../atoms';
import { Errors } from '../../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
  isEmptyMultiSelect,
} from '../../../../helpers';
import { useNotification, usePermissions } from '../../../../hooks';
import { Colors, Fonts, rem } from '../../../../themes';
import EditGroupContacts from './EditGroupContacts';

type CheckedContact = ContactType & { checked: boolean };

type Props = {
  backToForm: () => void;
  updateGroup: (newGroup: OneSignerGroup) => void;
  addContact: (contact: ContactType) => void;
  updateContact: (contact: ContactType) => void;
  editedGroup: OneSignerGroup;
  allUserContacts: ContactType[];
};

function EditGroupBlock({
  backToForm,
  updateGroup,
  addContact,
  updateContact,
  editedGroup,
  allUserContacts,
}: Props) {
  const { showError } = useNotification();
  const { canChangeSignergroup } = usePermissions();

  const [groupName, setGroupName] = useState(initialValue);
  const [groupContacts, setGroupContacts] = useState<CheckedContact[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setGroupName({
      value: editedGroup.name,
      errorMsg: '',
    });
    setGroupContacts(
      allUserContacts.map(contact => ({
        ...contact,
        checked: editedGroup.users.includes(contact.user.pk),
      })),
    );
  }, [allUserContacts, editedGroup]);

  const handleContactCheck = (
    props: ChangeEvent<HTMLInputElement> | CheckedContact[],
  ) => {
    if (isLoading) return;

    if (Array.isArray(props)) {
      setGroupContacts(props);
    } else {
      const { id, checked } = props.target;
      setGroupContacts(prev =>
        prev.map(contact =>
          contact.user.pk === Number(id) ? { ...contact, checked } : contact,
        ),
      );
    }
  };

  const saveEditedGroup = () => {
    if (isLoading) return;
    if (!canChangeSignergroup) return;

    if (isEmptyInput(groupName, setGroupName, Errors.EnterName)) return;

    const users = groupContacts
      .filter(contact => contact.checked)
      .map(contact => contact.user.pk);

    if (isEmptyMultiSelect(users, setGroupName)) return;

    setIsLoading(true);
    apiSigners
      .updateSignersGroup(editedGroup.id, {
        name: groupName.value,
        users,
      })
      .then(({ data }) => {
        updateGroup(data);
        backToForm();
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.name, setGroupName);
        apiFieldErrorHandler(data.users, setGroupName);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <StyledForm>
        <IconButton
          src={SVG.goBack}
          alt="go back button"
          onClick={backToForm}
        />
        <InputBox>
          {isLoading && <DotsLoading />}
          <Label>
            <div>Group name:</div>
            <StyledInput
              disabled={isLoading}
              value={groupName.value}
              onChange={handleInputChange(setGroupName)}
              onBlur={handleInputBlur(groupName, setGroupName)}
              placeholder="Name"
              error={groupName.errorMsg}
            />
          </Label>
        </InputBox>

        <SmallText>
          You can remove signers from group or add new from your contacts list.
        </SmallText>
        <ContactsContainer>
          <EditGroupContacts
            groupContacts={groupContacts}
            handleContactCheck={handleContactCheck}
            updateContact={updateContact}
            addContact={addContact}
          />
        </ContactsContainer>

        <StyledButton text="Save changes" onClick={saveEditedGroup} />
      </StyledForm>
    </>
  );
}

const IconButton = styled.img`
  width: 20px;
  cursor: pointer;
`;

const StyledForm = styled.div`
  padding: 0 10px 10px;
`;

const SmallText = styled.span`
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  font-size: ${rem(10)};
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0;
`;

const Label = styled.label`
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};
`;

const StyledInput = styled(Input)`
  margin-top: 10px;
  width: 465px;
`;

const ContactsContainer = styled.div`
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 15px;
  padding: 15px;
  margin-top: 10px;
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.Black};
  background-color: ${Colors.White};
  color: ${Colors.Black};
  margin: 30px auto 0 auto;
`;

export default EditGroupBlock;
