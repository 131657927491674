/* eslint-disable prefer-spread */
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';

import { Loader } from '../molecules';
import { Colors } from '../themes';

type OnLoadPdf = {
  numPages: number;
};

const PdfView = ({ pdfURL, onClose }: any) => {
  const [pdfName, setPdfName] = useState('');
  const [totalPages, setTotalPages] = useState<number | null>(null);

  useEffect(() => {
    const urlParts = pdfURL.split('/');
    const filename = urlParts[urlParts.length - 1];
    setPdfName(filename);
  }, [pdfURL]);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <Wrapper>
      {pdfURL ? (
        <>
          <Header>
            <PdfName>{pdfName}</PdfName>
            <CloseButton onClick={onClose}>Close</CloseButton>
          </Header>
          <DocumentWrapper>
            <Document
              file={pdfURL}
              loading={<Loader />}
              onLoadSuccess={({ numPages }: OnLoadPdf) => {
                setTotalPages(numPages);
              }}
            >
              {Array.apply(null, Array(totalPages))
                .map((x, i) => i + 1)
                .map(page => (
                  <PdfContainer key={page}>
                    <Page width={950} pageNumber={page} renderAnnotationLayer />
                    <PageNumber>page {page}</PageNumber>
                  </PdfContainer>
                ))}
            </Document>
          </DocumentWrapper>
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 80vw;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
`;

const PdfName = styled.div`
  font-weight: bold;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const DocumentWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  margin-top: 10px;
`;

const PageNumber = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const PdfContainer = styled.div`
  border-bottom: 1px solid grey;
`;
export default PdfView;
