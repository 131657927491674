import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  contacts as apiContacts,
  ContactType,
  projectBranches as apiBranch,
  ProjectBranchType,
  signers as apiSigners,
} from '../../../../api';
import { ButtonEllipse, Input, ModalBox } from '../../../../atoms';
import {
  handleInputBlur,
  handleInputChange,
  initialValue,
} from '../../../../helpers';
import { useNotification, usePermissions } from '../../../../hooks';
import { InfoBlock } from '../../../../molecules';
import { Colors, Fonts, rem } from '../../../../themes';
import EditGroupContacts from '../ProjectBranch/EditGroupContacts';
import { ContextTable } from './Table';

type CheckedContact = ContactType & { checked: boolean };

type Props = {
  closeModal: () => void;
  branchId: number;
  branchName: string;
  groupUsers: number[];
};

function ModalAddRecipients({
  closeModal,
  branchId,
  branchName,
  groupUsers,
}: Props) {
  const { showError } = useNotification();
  const { canViewProjectbranch, canChangeSignergroup } = usePermissions();
  const { updateDashboard } = useContext(ContextTable);

  const [branchInfo, setBranchInfo] = useState<ProjectBranchType | null>(null);

  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [groupContacts, setGroupContacts] = useState<CheckedContact[]>([]);

  const [groupName, setGroupName] = useState({
    ...initialValue,
    value: branchName,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    canViewProjectbranch &&
      apiBranch.getBranchById(branchId).then(({ data }) => setBranchInfo(data));
  }, [branchId, canViewProjectbranch]);

  useEffect(() => {
    apiContacts.getAllContact().then(({ data }) => setAllContacts(data));
  }, []);

  useEffect(() => {
    setGroupContacts(
      allContacts.map(contact => ({
        ...contact,
        checked: groupUsers.includes(contact.user.pk),
      })),
    );
  }, [allContacts, groupUsers]);

  const handleContactCheck = (
    props: ChangeEvent<HTMLInputElement> | CheckedContact[],
  ) => {
    if (isLoading) return;

    if (Array.isArray(props)) {
      setGroupContacts(props);
    } else {
      const { id, checked } = props.target;
      setGroupContacts(prev =>
        prev.map(contact =>
          contact.user.pk === Number(id) ? { ...contact, checked } : contact,
        ),
      );
    }
  };

  const addNewContact = (newContact: ContactType) => {
    setAllContacts(prev => [...prev, newContact]);
    setGroupContacts(prev => [...prev, { ...newContact, checked: true }]);
  };

  const updateUsersInBranch = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!branchInfo) return;
    if (isLoading) return;
    if (!canChangeSignergroup) return;

    const signerGroupId = branchInfo.signerGroups[0];
    const users = groupContacts
      .filter(contact => contact.checked)
      .map(contact => contact.user.pk);

    setIsLoading(true);
    apiSigners
      .updateSignersGroup(signerGroupId, { users })
      .then(() => {
        updateDashboard();
        closeModal();
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledModalBox title="Edit Contact Group" onCloseButtonClick={closeModal}>
      <StyledForm onSubmit={updateUsersInBranch}>
        <InfoBlock>
          <p>
            Here you can add or remove contacts from the contact group. Contact
            groups are designed to streamline the process of adding recipient
            groups.
          </p>
        </InfoBlock>

        <Title>Contact Group Name</Title>

        <StyledInput
          disabled={isLoading}
          value={groupName.value}
          onChange={handleInputChange(setGroupName)}
          onBlur={handleInputBlur(groupName, setGroupName)}
          placeholder="Name the group of recipients"
          error={groupName.errorMsg}
        />

        <Title>Contacts In the Group</Title>

        <EditGroupContacts
          groupContacts={groupContacts}
          handleContactCheck={handleContactCheck}
          updateContact={updatedContact =>
            setAllContacts(prev =>
              prev.map(contact =>
                contact.id === updatedContact.id ? updatedContact : contact,
              ),
            )
          }
          addContact={addNewContact}
        />

        <StyledButton
          type="submit"
          text={`Save changes${isLoading ? '...' : ''}`}
        />
      </StyledForm>
    </StyledModalBox>
  );
}

const StyledModalBox = styled(ModalBox)`
  width: 740px;
`;

const StyledForm = styled.form`
  padding: 0 10px 10px;
`;

const Title = styled.p`
  margin: 16px 0;

  font-size: ${rem(12)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};
`;

const StyledInput = styled(Input)`
  margin-top: 10px;
  width: 450px;
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  color: ${Colors.LightBlue};
  margin: 30px auto 0 auto;
`;

export default ModalAddRecipients;
