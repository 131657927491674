import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  dashboard as apiDashboard,
  nda as apiNda,
  projects as apiProjects,
  templates as apiTemplates,
} from '../../api';
import SVG from '../../assets/svg';
import {
  Input,
  Modal,
  ModalBox,
  SelectOption,
  TemplateNda,
  Textarea,
} from '../../atoms';
import CardWrapper from '../../atoms/CardWrapper';
import PdfView from '../../atoms/PdfView';
import SolidButton from '../../atoms/SolidButton';
import { Errors } from '../../const';
import {
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification, usePermissions } from '../../hooks';
import { Loader } from '../../molecules';
import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';
import AddTemplate from '../projects/components/AddTemplate';
import { Nda } from '../projects/components/types';

type Param = {
  projectId: string;
  branchId: string;
};

function ReviewNda() {
  const { projectId, branchId } = useParams<Param>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nda, setNda] = useState<Nda>({
    file: null,
    ...initialValue,
    template: null,
  });
  const [showModal, setShowModal] = useState(false);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [templates, setTemplates] = useState<SelectOption[]>([]);
  const { showError } = useNotification();
  const history = useHistory();
  const { canViewNda } = usePermissions();
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfURL, setPdfURL] = useState('');
  const [subject, setSubject] = useState(initialValue);
  const [description, setDescription] = useState({
    value: '',
    errorMsg: '',
  });

  const handleRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setNda({ ...nda, file: null });
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) return;
    if (isEmptyInput(subject, setSubject, Errors.EnterField)) return;
    if (isEmptyInput(description, setDescription, Errors.EnterField)) return;
    // if (!nda.file && !nda.template) {
    //   showError(`${Errors.ChouseTemplate} or ${Errors.UploadFile} for NDA`);
    //   return;
    // }
    setIsLoading(true);

    if (nda.id) {
      try {
        // if (nda.template || nda.file) {
        //   const formData = new FormData();
        //   formData.append('project_branch', `${branchId}`);
        //   formData.append('name', nda.value);
        //   if (nda.file) {
        //     formData.append('file', nda.file);
        //   }
        //   if (nda.template) {
        //     formData.append('template', nda?.template?.value);
        //     formData.append('file', '');
        //   }
        //   await apiNda.createOneNda(formData);
        // }
        await apiDashboard.sendNdaToAllSigners(Number(branchId), {
          projectBranch: Number(branchId),
          note: description.value,
          subject: subject.value,
        });

        setIsLoading(false);
        history.push(`${Routes.projects}/${projectId}`);
      } catch (error) {
        const { data } = error as any;
        if (data) {
          if (Array.isArray(data.nonFieldErrors)) {
            showError(data.nonFieldErrors[0]);
          }
          if (data.detail) {
            showError(data.detail);
          }
        }
        setIsLoading(false);
      }
    }
  };

  const viewNda = () => {
    if (canViewNda) {
      setPdfModal(true);
      nda.id &&
        apiNda.getNdaPdf(nda.id).then(response => {
          const fileURL = URL.createObjectURL(response.data);
          setPdfURL(fileURL);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    apiProjects
      .getOneProject(Number(projectId))
      .then(({ data: projectData }) => {
        apiNda
          .getNdaByProjectId(Number(projectId))
          .then(({ data }) => {
            if (!data.results[0]) {
              return;
            }
            const { id, name } = data.results[0];
            setNda({
              ...nda,
              id,
              value: name,
            });
            setSubject({ ...subject, value: `${projectData.name} | ${name}` });
            setDescription({ ...description, value: projectData.summary });
          })
          .finally(() => setIsLoading(false));
      });
  }, [projectId]);

  useEffect(() => {
    apiTemplates.getAllTemplates().then(({ data }) => {
      setTemplates(
        data.map(template => ({
          value: template.id.toString(),
          label: template.name,
        })),
      );
    });
  }, []);

  return (
    <PageWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <CardWrapper title="Review and send NDA">
            <>
              <Container>
                <div>
                  <Label>Subject</Label>
                  <Input
                    disabled={isLoading}
                    value={subject.value}
                    onChange={handleInputChange(setSubject)}
                    onBlur={handleInputBlur(subject, setSubject)}
                    placeholder="For example, the name of project."
                    error={subject.errorMsg}
                  />
                </div>
                <div>
                  <Label>Write a note</Label>
                  <Textarea
                    disabled={isLoading}
                    value={description.value}
                    onChange={handleInputChange(setDescription)}
                    onBlur={handleInputBlur(description, setDescription)}
                    placeholder="This note will be sent with the NDA to recipients"
                    error={description.errorMsg}
                    minRows={6}
                  />
                </div>
              </Container>
              <MiddleSection>
                <Title>Modify Your NDA</Title>
                <ViewNdaPdf onClick={viewNda}>
                  <Title>View NDA</Title>
                  <img src={SVG.view} alt="view" />
                </ViewNdaPdf>
              </MiddleSection>
              <TemplateNda
                value={nda}
                onChange={setNda}
                handleRemove={handleRemove}
                fileRef={fileRef}
                handleModal={() => setShowModal(!showModal)}
              />
            </>
          </CardWrapper>
          <ButtonContainer>
            <StyledButton
              type="button"
              text="Back"
              onClick={() => history.push(`${Routes.projects}/${projectId}`)}
            />
            <StyledSubmitButton
              isLoading={isLoading}
              type="submit"
              text={isLoading ? 'Send...' : 'Send'}
            />
          </ButtonContainer>
        </form>
      )}
      <Modal modalIsOpen={showModal} setModalIsOpen={setShowModal}>
        <ModalBox
          title={
            Array.isArray(templates) && templates.length === 0
              ? 'You don’t have a template'
              : 'Choose NDA from templates'
          }
          onCloseButtonClick={() => {
            setShowModal(false);
          }}
        >
          <AddTemplate
            templates={templates}
            value={nda}
            onChange={setNda}
            handleClose={() => setShowModal(!showModal)}
          />
        </ModalBox>
      </Modal>
      <Modal modalIsOpen={pdfModal} setModalIsOpen={setPdfModal}>
        <PdfWrapper>
          <PdfView
            pdfURL={pdfURL}
            onClose={() => {
              setPdfURL('');
              setPdfModal(false);
            }}
          />
        </PdfWrapper>
      </Modal>
    </PageWrapper>
  );
}

const Title = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;
const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
`;

const ViewNdaPdf = styled.div`
  display: flex;
  justify-content: end;
  gap: 5px;
  cursor: pointer;
  align-items: center;
`;

const PdfWrapper = styled.div`
  height: 95vh;
  width: 80vw;
  background-color: ${Colors.White};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Label = styled.p`
  margin-bottom: 10px;
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px;
`;

const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 10px 0px;
`;
export default ReviewNda;
