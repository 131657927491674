import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import SVG from '../../assets/svg';
import { Routes } from '../../routes';
import { selectors } from '../../store';
import { Colors, Fonts, rem } from '../../themes';

function Footer() {
  const isAuth = useSelector(selectors.isAuthenticated);
  return (
    <Wrapper>
      <FooterContainer>
        <Row>
          <Column>
            <HeadingLogo>namda</HeadingLogo>
            <FooterText>
              Second home Holland Park 48-49 Princes Place. London, ENG, W11 4QA
            </FooterText>
            <SocialIconWrraper>
              <FooterLink href="https://www.linkedin.com" target="_blank">
                <img src={SVG.linkedIn} alt="linkedIn" />
              </FooterLink>
              <FooterLink href="https://twitter.com" target="_blank">
                <img src={SVG.twitter} alt="twitter" />
              </FooterLink>
              <FooterLink href="https://www.facebook.com" target="_blank">
                <img src={SVG.facebook} alt="facebook" />
              </FooterLink>
            </SocialIconWrraper>
          </Column>
          <Column>
            <Heading>Info</Heading>
            {isAuth === true ? (
              <>
                <FooterLink href="/#">Home</FooterLink>
                <FooterLink href="/#features">Features</FooterLink>
                <FooterLink href="/#testimonial">Testimonials</FooterLink>
                <FooterLink href="/#contact-us">Contact Us</FooterLink>
              </>
            ) : (
              <>
                <FooterLink href="#">Home</FooterLink>
                <FooterLink href="#features">Features</FooterLink>
                <FooterLink href="#testimonial">Testimonials</FooterLink>
                <FooterLink href="#contact-us">Contact Us</FooterLink>
              </>
            )}
          </Column>
          <Column>
            <Heading>Policies</Heading>
            <FooterLinks to={Routes.privacy}>
              Privacy & Cookies Policy
            </FooterLinks>
            <FooterLinks to={Routes.terms}>Terms & Conditions</FooterLinks>
          </Column>
        </Row>
      </FooterContainer>
      <FooterBottomText>All © by namda All Rights Reserved.</FooterBottomText>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;
  background-color: #012345;
  font-family: ${Fonts.Sofia};

  @media (max-width: 768px) {
    padding: 30px 35px 0 35px;
  }
`;

const FooterContainer = styled.div`
  max-width: 1296px;
  margin: 0 auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 0 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const FooterText = styled.p`
  max-width: 340px;
  width: 100%;
  color: ${Colors.White};
  margin-bottom: 20px;
  margin-top: 0;
  font-size: ${rem(11.25)};
`;
const FooterLink = styled.a`
  color: ${Colors.White};
  margin-bottom: 20px;
  font-size: ${rem(11.25)};
  text-decoration: none;
`;

const FooterLinks = styled(NavLink)`
  color: ${Colors.White};
  margin-bottom: 20px;
  font-size: ${rem(11.25)};
  text-decoration: none;
`;

const Heading = styled.p`
  font-size: ${rem(13.75)};
  color: ${Colors.White};
  margin-bottom: 30px;
  font-weight: 300;
`;

const HeadingLogo = styled(Heading)`
  font-size: ${rem(21.25)};
  margin-top: 15px;
`;
const FooterBottomText = styled.p`
  color: ${Colors.White};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding: 20px;
  margin-bottom: 0;
`;

const SocialIconWrraper = styled.div`
  gap: 20px;
  display: flex;
`;

export default Footer;
