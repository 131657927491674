import styled from 'styled-components';

import { PageWrapper } from '../../templates';
import { Fonts, rem } from '../../themes';

type Props = {};

const PrivacyPolicy = (props: Props) => (
  <PageWrapper>
    <Wrapper>
      <div>
        <Title>1. What&apos;s in this policy?</Title>
        <p>
          This policy is for when you&apos;re in the UK (view policy for when
          you&apos;re outside the UK). It tells you:
        </p>
        <ul>
          <li>what information we might collect about you</li>
          <li>how we might use that information</li>
          <li>when we might use your details to contact you</li>
          <li>what information of yours we might share with others</li>
          <li>your choices about the personal information you give us</li>
        </ul>
      </div>
      <div>
        <Title>2. What does this policy cover?</Title>
        <ul>
          <li>
            This policy covers the services that are offered by the NAMDA Ltd.
          </li>
        </ul>
      </div>
      <div>
        <Title>3. How do we protect my personal information?</Title>
        <p>
          We&apos;re strongly committed to keeping you and your company&apos;s
          information safe. And to do this we design our services with your
          safety in mind. We also have dedicated advisors to look after your
          information security and privacy.
        </p>
        <p>
          At the same time, no service can be completely secure - if you have
          any concerns that your NAMDA account or personal information has been
          put at risk, for example if someone could have found out your
          password, please get in touch straight away.
        </p>
        <SubTitle>Where we store your information</SubTitle>
        <p>
          Some companies that provide services to us run their services from
          outside the UK and the European Economic Area. We only let that happen
          if we are satisfied with their levels of security. Keep in mind that
          when you give us personal information it could be being transferred,
          stored or processed in a location outside the UK and the EEA.
        </p>
      </div>
      <div>
        <Title>
          4. What types of personal information does the NAMDA collect about me?
        </Title>
        <p>
          We&apos;ll give you details about why we need your personal
          information and how we&apos;ll use it before you begin, unless
          it&apos;s obvious.
        </p>
        <SubTitle>a. Information that you give us</SubTitle>
        <p>
          We might ask for your name and contact details or financial details.{' '}
        </p>
        <p>
          When you register for a NAMDA, or update your details or settings we
          ask for some personal information, like your name.
        </p>
        <SubTitle>b. Device information</SubTitle>
        <p>Devices are lots of things like:</p>
        <ul>
          <li>your computer</li>
          <li>your mobile</li>
          <li>your tablet</li>
        </ul>
        <p>
          We automatically collect some technical information from these devices
          and web browsers even when you&apos;re not signed into to a NAMDA
          account. This might include:
        </p>
        <ul>
          <li>IP (internet protocol) address</li>
          <li>device ID</li>
          <li>app ID</li>
          <li>vendor ID</li>
          <li>advertising ID</li>
        </ul>
        <SubTitle>c. Location Information</SubTitle>
        <p>
          We collect information about your location when you use our products
          and services. This helps us to determine whether you&apos;re somewhere
          where are services are available, helps us to ensure we are fulfilling
          our obligations and to offer additional features whilst using our
          products and services.
        </p>
        <p>Your location can be determined at a town or city level by:</p>
        <ul>
          <li>GPS</li>
          <li>IP address</li>
          <li>
            Information about nearby cell towers and Wi-Fi access point
            information
          </li>
        </ul>
        <p>
          What type of location data (for example Country, Region, City or Town)
          is collected depends on the product or service your using, the device
          you&apos;re using (for example Apple or Android) and your devices
          settings (whether permissions are enabled or disabled). You can change
          your permissions on or off any time in your device&apos;s settings.{' '}
        </p>
        <SubTitle>
          d. Information on your activities outside the NAMDA when you talk
          about us
        </SubTitle>
        <p>
          Like if you&apos;ve mentioned us in a Twitter post, we&apos;ll collect
          your Twitter handle.
        </p>
        <SubTitle>e. Information on how you use our services</SubTitle>
        <p>Like the pages you consult on NAMDA </p>
      </div>
      <div>
        <Title>5. How long will the NAMDA keep my personal information?</Title>
        <p>
          When you give us any personal information, we will always stick to
          these principles:
        </p>
        <ul>
          <li>
            we only hold your information for as long as we do the activities we
            told you about or have a valid reason to keep it
          </li>
          <li>
            we think about what type of information it is, the amount collected,
            how sensitive it might be and any legal requirements
          </li>
          <li>
            we design our services so that we don&apos;t hold your information
            any longer than we have to
          </li>
        </ul>
      </div>
      <div>
        <Title>6. How can the NAMDA use my personal information?</Title>
        <p>
          We must have a valid reason to use your personal information.
          It&apos;s called the &quot;lawful basis for processing&quot;.
          Sometimes we might ask your permission to do things. Other times, when
          you&apos;d reasonably expect us to use your personal information, we
          don&apos;t ask your permission, but only when:
        </p>
        <ul>
          <li>the law says it&apos;s fine to use it, and</li>
          <li>it fits with the rights you have</li>
        </ul>
        <div>We use your information for these types of things:</div>
        <ul>
          <p>
            a. to deliver our services and to provide you with information about
            them
          </p>
          <p>b. to deal with your requests, complaints and enquiries</p>
          <p>
            c. to personalise services and give you things more tailored to your
            needs
          </p>
          <p>
            e. to show you relevant advertising on another company&apos;s site
          </p>
        </ul>
        <div>Will I be contacted for marketing purposes?</div>
        <ul>
          <p>
            f. to help us understand what kind of services you might use. And
            sometimes how you might share things with other people
          </p>
          <p>g. to recommend things we think might interest you</p>
          <p>
            h. to research and innovate. You&apos;re not a guinea-pig here, but
            knowing how you use our services could lead to the next big
            technology breakthrough.
          </p>
          <p>j. to contact you about various things</p>
        </ul>
      </div>
      <div>
        <Title>7. When will the NAMDA use my information to contact me?</Title>
        <p>
          We might use your information to contact you about different things,
          like:
        </p>
        <ul>
          <li>
            to update you on any changes to the NAMDA&apos;s policies, practices
            and Terms of Use
          </li>
          <li>
            to check with you about any service or activity you&apos;ve signed
            up for. For example, we might tell you if your NAMDA account has not
            been used in a long time.
          </li>
          <li>
            to answer you when you&apos;ve contacted us, or to respond to a
            comment or complaint
          </li>
          <li>
            to invite you to take part in surveys about NAMDA services, which
            are always voluntary
          </li>
          <li>for marketing purposes</li>
          <li>
            to send you notifications on your device depending on your settings{' '}
          </li>
        </ul>
        <p>
          We&apos;ll only contact you when we need to or when you&apos;ve given
          us permission.
        </p>
        <p>
          We&apos;ll never contact you to ask for your NAMDA account password
        </p>
      </div>
      <div>
        <Title>8. Will I be contacted for marketing purposes?</Title>
        <p>
          We&apos;ll only send you marketing emails or contact you about NAMDA,
          services and your views on issues about the NAMDA if you&apos;ve
          agreed to this.
        </p>
        <p>Keep in mind, even if you unsubscribe, we may still contact you.</p>
        <SubTitle>
          Will my personal information be used when the NAMDA advertises with
          other companies?
        </SubTitle>
        <p>
          If you have a NAMDA account we might use information that we hold
          about you to show you relevant and targeted advertising for NAMDA
          services through other companies&apos; sites, like Linkedin, Google,
          or Twitter for example. This could be showing you a NAMDA sponsored
          where we know you have a NAMDA account and have used NAMDA services.
        </p>
        <p>
          Where we do this, we will share your data in a hashed format with
          these providers so that, where you have an account on the same
          platform, you will see adverts from NAMDA. In these scenarios, we are
          joint controllers with these companies because we jointly determine
          the purposes and means of processing your personal data.
        </p>
        <p>
          We also assess certain characteristics of our audience team members in
          order to find others who are similar to them to provide advertising to
          those individuals.{' '}
        </p>
        <p>
          If you don&apos;t want to see our targeted advertising, you can set ad
          preferences in your social media companies&apos; settings.{' '}
        </p>
      </div>
      <div>
        <Title>
          9. When does the NAMDA share my personal information with others?
        </Title>
        <p>
          We&apos;ll never sell your personal information. We do share it with
          others in these ways:
        </p>
        <SubTitle>
          a. When we use other companies to power our services
        </SubTitle>
        <p>
          In order for us to give you quality experiences and to understand how
          you&apos;re using our services we may use other companies to process
          your personal on our behalf. For example, sending you emails about
          things we think might interest you, to ask you what you think about
          our services, or to analyse data on how people use our digital
          services so we can improve them.
        </p>
        <p>
          We make sure that your personal information is looked after as if we
          were handling it directly. We carefully select these companies, only
          share with them what they need to do the work and we make sure they
          keep your information secure.
        </p>
        <SubTitle>
          b. Sometimes by law we have to pass on your information to other
          organisations
        </SubTitle>
        <p>
          We might also share your information if we have to by law, or when we
          need to protect you or other people from harm.
        </p>
      </div>
      <div>
        <Title>11. Can I delete my information?</Title>
        <p>This depends on what information you&apos;re talking about.</p>
        <SubTitle>a. If you&apos;re talking about NAMDA account</SubTitle>
        <p>
          You can delete your account. Your account information is immediately
          deleted. Keep in mind, we keep a record of how you&apos;ve used our
          services, but this information can&apos;t be linked back to you
        </p>
        <SubTitle>
          b. What about other information I&apos;ve shared with you?
        </SubTitle>
        <p>
          We might have collected other personal information that you might ask
          to be deleted that has nothing to do with NAMDA account.
        </p>
      </div>
      <div>
        <Title>12. What are my rights?</Title>
        <p>Remember, you&apos;re in control of your personal information.</p>
        <p>You have the right to:</p>
        <ul>
          <li>request a copy of your information</li>
          <li>
            to ask us to correct information that&apos;s wrong, to delete it or
            to request that we only use it for certain purposes
          </li>
          <li>
            to change your mind, and ask us to stop using your information{' '}
          </li>
        </ul>
        <p>
          Bear in mind, sometimes we might not be able to help. Like if the law
          tells us we can&apos;t.{' '}
        </p>
      </div>
      <div>
        <Title>13. How does the NAMDA use cookies and similar tracking?</Title>
        <SubTitle>a. What are cookies and tracking technologies?</SubTitle>
        <p>
          Cookies are small text files which are transferred to your computer or
          mobile when you visit a website or app.
        </p>
        <SubTitle>b. Why do we use cookies and other tracking?</SubTitle>
        <p>To do a few different things:</p>
        <ul>
          <li>
            to remember information about you, so you don&apos;t have to give it
            to us again. And again. And again
          </li>
          <li>to keep you signed in, even on different devices</li>
          <li>
            to help us understand how people are using our services, so we can
            make them better
          </li>
          <li>to deliver advertising to websites</li>
          <li>
            to help us personalise the NAMDA to you by remembering your
            preferences and settings. And your progress, so you can pause and
            pick up where you left your process even on a different device
          </li>
          <li>
            to find out if our emails have been read and if you find them useful
          </li>
        </ul>
        <SubTitle>c. What are the types of cookies?</SubTitle>
        <p>
          Some cookies are always on when you visit us, and you can&apos;t turn
          them off unless you change your browser settings. We call these
          “strictly necessary cookies”. We use them to make sure our digital
          services work correctly.
        </p>
        <p>
          We also use functional, performance and advertising cookies to make
          your experience more enjoyable. You can switch these on or off at any
          time and you can always change your mind. We&apos;ll only use them if
          you&apos;ve agreed.
        </p>
        <p>
          Bear in mind there are some other cookies out there from other
          companies. These &quot;third-party cookies&quot; might track how you
          use different websites, including ours. For example, you might get a
          social media company&apos;s cookie when you see the option to share
          something. You can turn them off, but not through us.
        </p>
        <SubTitle>d. How long do cookies last?</SubTitle>
        <p>
          Some are erased when you close the browser on your website or app.
          Others stay longer, sometimes forever, and are saved onto your device
          so that they&apos;re there when you come back.
        </p>
        <SubTitle>e. How do I control my cookies and tracking?</SubTitle>
        <p>
          Strictly necessary cookies are always on when you visit us. When you
          visit us, we&apos;ll tell you about our other types of cookies and ask
          you to choose which cookies we can use.{' '}
        </p>
        <p>
          Stopping all cookies might mean you can&apos;t access some NAMDA
          services, or that some of them might not work properly for you.
        </p>
        <p>
          Another way to control some tracking is in the settings on your
          device.
        </p>
      </div>
      <div>
        <Title>14. How will I find out about changes to this policy?</Title>
        <p>
          We update this policy sometimes. If we make important changes, like
          how we use your personal information, we&apos;ll let you know. It
          might be a notice, an email or a message in your profile.
        </p>
        <p>
          If you don&apos;t agree to the changes, then you can always stop using
          our services, delete your account and stop giving us any more personal
          information. We&apos;d be sorry to see you go.
        </p>
      </div>
      <div>
        <Title>15. How can I contact the NAMDA?</Title>
        <p>Find out more and contact us about your rights.</p>
        <p>
          For any other questions or comments about this policy speak to our
          Data Protection Officer.
        </p>
        <ul>
          <li>
            By email: <a href="mailto:info@namda.io">info@namda.io</a>
          </li>
          <li>
            by post: NAMDA Data Protection Officer, second Home, Holland Park,
            48-49 Princes Pl, London W11 4QA
          </li>
        </ul>
      </div>
    </Wrapper>
  </PageWrapper>
);

const Wrapper = styled.div`
  font-family: ${Fonts.Sofia};
`;

const Title = styled.div`
  font-size: ${rem(13.75)};
  font-weight: 700;
`;

const SubTitle = styled.div`
  font-size: ${rem(11.75)};
  font-weight: 700;
`;

export default PrivacyPolicy;
