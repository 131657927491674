import styled from 'styled-components';

import SVG from '../../../assets/svg';
import { TextButton } from '../../../atoms';
import { usePermissions } from '../../../hooks';
import { BoxShadow, Colors, Fonts, rem } from '../../../themes';

type Props = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

function CommentsActions({ onEditClick, onDeleteClick }: Props) {
  const { canChangeComment, canDeleteComment } = usePermissions();
  return (
    <Container>
      {canChangeComment && (
        <OneAction onClick={onEditClick}>
          <Button src={SVG.pencil} alt="Edit" />
          <StyledButton>Edit comment</StyledButton>
        </OneAction>
      )}
      {canDeleteComment && (
        <OneAction onClick={onDeleteClick}>
          <Button src={SVG.deleteImg} alt="Delete" />
          <StyledButton>Delete comment</StyledButton>
        </OneAction>
      )}
    </Container>
  );
}

export const Container = styled.div`
  background-color: ${Colors.White};
  padding: 10px;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  border-radius: 10px;
  box-shadow: ${BoxShadow.Secondary};
`;

export const OneAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Button = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const StyledButton = styled(TextButton)`
  font-size: ${rem(9)};
`;

export default CommentsActions;
