import styled from 'styled-components';

import IMG from '../../../assets/IMG';
import SVG from '../../../assets/svg';
import { Colors } from '../../../themes';

function Invite() {
  return (
    <Wrraper>
      <ImgSec src={IMG.InviteAndTrack} alt="invite" />
      <ContentWrapper>
        <Title>
          <span style={{ color: `${Colors.LightBlue}` }}>Invite your team</span>{' '}
          and track your firm’s activity
        </Title>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            Provide full transparency to your colleagues on the status of a
            process: your team can simply see where a status stands. How many
            parties are looking at a transaction and what is their status.
          </Details>
        </CheckWrapper>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            Thanks to our reports, track your firm’s activity: how many deals
            have been advised, how many parties have been contacted. You can
            communicate these important data to your customers, to prove the
            efficiency of your reach.
          </Details>
        </CheckWrapper>
      </ContentWrapper>
    </Wrraper>
  );
}

const Wrraper = styled.div`
  display: flex;
  padding: 65px 50px 50px 50px;
  gap: 65px;
  @media (max-width: 768px) {
    display: block;
    gap: 0;
    padding: 0;
  }
`;

const ImgSec = styled.img`
  width: 100%;
`;

const ContentWrapper = styled.div``;

const Title = styled.p`
  color: #000;
  font-family: 'Sofia Pro';
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const Details = styled.div`
  color: #606060;
  font-family: 'Sofia Pro';
  font-size: 18px;
`;

const Img = styled.img`
  margin-top: 7px;
  margin-right: 12px;
`;
export default Invite;
