import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { nda as apiNda, templates as apiTemplates } from '../../../api';
import SVG from '../../../assets/svg';
import {
  Input,
  Modal,
  ModalBox,
  SelectOption,
  TemplateNda,
} from '../../../atoms';
import CardWrapper from '../../../atoms/CardWrapper';
import PdfView from '../../../atoms/PdfView';
import { usePermissions } from '../../../hooks';
import { Colors, Fonts, rem } from '../../../themes';
import AddTemplate from './AddTemplate';
import { Nda } from './types';

type Props = {
  value: Nda;
  onChange: React.Dispatch<React.SetStateAction<Nda>>;
  isLoading: boolean | undefined;
};

function AddNda({ value, onChange, isLoading }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [templates, setTemplates] = useState<SelectOption[]>([]);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [pdfURL, setPdfURL] = useState<string>('');
  const [showPdf, setShowPdf] = useState<boolean>(false);

  const { canViewNda } = usePermissions();

  const handleRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onChange({ ...value, file: null });
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };
  const viewNda = () => {
    canViewNda &&
      value.id &&
      apiNda.getNdaPdf(value.id).then(response => {
        const fileURL = URL.createObjectURL(response.data);
        setShowPdf(true);
        setPdfURL(fileURL);
      });
  };
  useEffect(() => {
    apiTemplates.getAllTemplates().then(({ data }) => {
      setTemplates(
        data.map(template => ({
          value: template.id.toString(),
          label: template.name,
        })),
      );
    });
  }, []);
  return (
    <>
      <CardWrapper
        title={`${
          value.id ? 'Project NDA' : 'Add a NDA template to your project'
        }`}
      >
        <>
          {value.id && (
            <ViewNdaPdf onClick={viewNda}>
              <span>NDA</span>
              <img src={SVG.view} alt="view" />
            </ViewNdaPdf>
          )}
          <NdaName>
            Project NDA Name <span style={{ color: 'red' }}>*</span>
          </NdaName>
          <Input
            disabled={isLoading}
            value={value.value}
            onChange={e =>
              onChange({
                ...value,
                value: e.target.value,
              })
            }
            onBlur={() => {
              onChange({
                ...value,
                errorMsg: '',
              });
            }}
            placeholder=""
            error={value.errorMsg}
            maxLength={30}
          />
          <TemplateNdaContainer>
            <TemplateNda
              value={value}
              onChange={onChange}
              handleRemove={handleRemove}
              fileRef={fileRef}
              handleModal={() => setShowModal(!showModal)}
            />
          </TemplateNdaContainer>
        </>
      </CardWrapper>
      <Modal modalIsOpen={showModal} setModalIsOpen={setShowModal}>
        <ModalBox
          title={
            Array.isArray(templates) && templates.length === 0
              ? 'You don’t have a template'
              : 'Choose NDA from templates'
          }
          onCloseButtonClick={() => {
            setShowModal(false);
            onChange({ ...value, template: null });
          }}
        >
          <AddTemplate
            templates={templates}
            value={value}
            onChange={onChange}
            handleClose={() => setShowModal(!showModal)}
          />
        </ModalBox>
      </Modal>

      {pdfURL && (
        <Modal modalIsOpen={showPdf} setModalIsOpen={setShowPdf}>
          <Wrapper>
            <PdfView
              pdfURL={pdfURL}
              onClose={() => {
                setShowPdf(false);
                setPdfURL('');
              }}
            />
          </Wrapper>
        </Modal>
      )}
    </>
  );
}

const Wrapper = styled.div`
  height: 95vh;
  width: 80vw;
  background-color: ${Colors.White};
`;

const NdaName = styled.p`
  margin-bottom: 10px;
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px;
`;

const TemplateNdaContainer = styled.div`
  padding: 7px;
`;

const ViewNdaPdf = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 5px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
`;
export default AddNda;
