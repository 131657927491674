import {
  memo,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Colors } from '../themes';

type Props = {
  refElem: HTMLElement | HTMLDivElement | null;
  children: ReactElement | string;
  onOverlayClick?: (event: MouseEvent<HTMLElement | HTMLDivElement>) => void;
  className?: string;
  offsetY?: number;
  offsetX?: number;
};

const popoverRoot = document.getElementById('popoverRoot');

function Popover({
  refElem,
  children,
  onOverlayClick,
  className,
  offsetY,
  offsetX,
}: Props) {
  const coordinates = useMemo(
    () => refElem?.getBoundingClientRect(),
    [refElem],
  );
  const rootShowedElement = document.createElement('div');
  rootShowedElement.setAttribute('id', `${Date.now()}`);

  useEffect(() => {
    if (refElem) (popoverRoot as HTMLElement).appendChild(rootShowedElement);

    return () => {
      refElem && (popoverRoot as HTMLElement).removeChild(rootShowedElement);
    };
  }, [refElem, rootShowedElement]);

  return createPortal(
    <Overlay
      className={className}
      onClick={onOverlayClick}
      isClickable={Boolean(onOverlayClick)}
    >
      <Container
        initTop={(coordinates?.y ?? 0) - 40 - (offsetY ?? 0)}
        initLeft={(coordinates?.x ?? 0) + (offsetX ?? 0)}
      >
        {children}
      </Container>
    </Overlay>,

    rootShowedElement as HTMLElement,
  );
}

type ContainerProps = {
  children: ReactElement | string;
  initLeft: number;
  initTop: number;
};

function Container({ children, initLeft, initTop }: ContainerProps) {
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const [showedTop, setShowedTop] = useState(initTop);
  const [showedLeft, setShowedLeft] = useState(initLeft);

  useEffect(() => {
    setTimeout(() => {
      const innerHeight = window?.innerHeight;
      const innerWidth = window?.innerWidth;
      const coords = anchorEl.current?.getBoundingClientRect();
      if (!coords || !innerHeight || !innerWidth) return;

      const leftCoord = coords.x;
      const rightCoord = coords.x + coords.width;
      const topCoord = coords.y;
      const bottomCoord = coords.y + coords.height;

      if (leftCoord < 0 && rightCoord > innerWidth) return;
      if (topCoord < 0 && bottomCoord > innerHeight) return;

      if (leftCoord < 0) {
        setShowedLeft(10);
      }
      if (rightCoord > innerWidth) {
        setShowedLeft(prev => prev - (rightCoord - innerWidth + 10));
      }
      if (topCoord < 0) {
        setShowedTop(10);
      }
      if (bottomCoord > innerHeight) {
        setShowedTop(prev => prev - (bottomCoord - innerHeight + 10));
      }
    }, 1);
  });

  return (
    <ShowedElement ref={anchorEl} top={showedTop} left={showedLeft}>
      {children}
    </ShowedElement>
  );
}

const Overlay = styled.div<{ isClickable: boolean }>`
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  z-index: 1000;
  pointer-events: ${props => (props.isClickable ? 'all' : 'none')};
`;

const ShowedElement = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: fit-content;
  background-color: ${Colors.Transparent};
`;

export default memo(Popover);
