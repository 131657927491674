import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  projects as apiProjects,
  templates as apiTemplates,
  TemplatesPaginationLimit as PaginationLimit,
  TemplateType,
} from '../../api';
import SVG from '../../assets/svg';
import {
  FilterInput,
  Modal,
  Pagination,
  Select,
  SelectOption,
} from '../../atoms';
import { Messages } from '../../const';
import { getPagesCount } from '../../helpers/functions';
import { useNotification, usePagination, usePermissions } from '../../hooks';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { baseTransition, BoxShadow, Colors, Fonts, rem } from '../../themes';
import { TemplateImagePreview, TemplateModal } from './components';

type Template = TemplateType & { baseUrl?: string };

function Templates() {
  const { canViewNdatemplate, canAddNdatemplate, canViewProject } =
    usePermissions();
  const { showSuccess } = useNotification();
  const history = useHistory();

  const [isShowInitMessage, setIsShowInitMessage] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [templates, setTemplates] = useState<Template[]>([]);

  const [allProjects, setAllProjects] = useState<SelectOption[]>([]);
  const [selectedProject, setSelectedProject] = useState<SelectOption | null>(
    null,
  );

  const [filter, setFilter] = useState('');

  const [isShowTemplateModal, setIsShowTemplateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [idShownTemplate, setIdShownTemplate] = useState(0);
  const [nameShownTemplate, setNameShownTemplate] = useState('');

  useEffect(() => {
    if (!canViewNdatemplate) return;

    setIsLoading(true);

    apiTemplates
      .getTemplates(currentPage, selectedProject?.value)
      .then(({ data }) => {
        if (data.next || data.previous) {
          setTotalPages(getPagesCount(data.count, PaginationLimit));
        } else {
          setTotalPages(0);
        }
        setTemplates(data.results);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, canViewNdatemplate, selectedProject]);

  useEffect(() => {
    if (
      Array.isArray(templates) &&
      templates.length === 0 &&
      !isShowInitMessage &&
      !selectedProject
    ) {
      showSuccess(Messages.InitTemplates);
      setIsShowInitMessage(true);
    }
  }, [isShowInitMessage, templates, showSuccess, selectedProject]);

  useEffect(() => {
    canViewProject &&
      apiProjects.getProjects().then(({ data }) =>
        setAllProjects(
          data
            .filter(oneProject => oneProject.status)
            .map(oneProject => ({
              value: oneProject.id.toString(),
              label: oneProject.name,
            })),
        ),
      );
  }, [canViewProject]);

  const isFiltered = (name: string) =>
    filter === '' ? true : name.toLowerCase().includes(filter.toLowerCase());

  const paginationPages = usePagination(totalPages);

  const editName = (templateId: number, name: string) => {
    const edittemplates = [...templates];
    const index = edittemplates.findIndex(
      template => template.id === templateId,
    );
    edittemplates[index].name = name;
    setTemplates(edittemplates);
  };

  return (
    <PageWrapper>
      <Modal
        modalIsOpen={isShowTemplateModal}
        setModalIsOpen={setIsShowTemplateModal}
      >
        <TemplateModal
          templateId={idShownTemplate}
          templateName={nameShownTemplate}
          setIsShowTemplateModal={setIsShowTemplateModal}
          closeModal={() => setIsShowTemplateModal(false)}
          deleteTemplateSuccess={templateId =>
            setTemplates(prev =>
              (prev as TemplateType[]).filter(
                template => template.id !== templateId,
              ),
            )
          }
          editTemplate={(templateId: number, name: string) =>
            editName(templateId, name)
          }
        />
      </Modal>

      <ContentWrapper>
        <SideMenu />
        <StyledFilterInput
          value={filter}
          onChange={e => setFilter(e.target.value)}
          placeholder="Filter templates by name..."
        />

        <Wrapper>
          <TabTitle>All Template</TabTitle>
          <AddTemplateWrapper>
            <StyledSelect
              styles={{
                control: (provided: object) => ({
                  ...provided,
                  height: '60px',
                  borderRadius: '6px',
                  border: `1px solid ${Colors.SkySecondBlue}`,
                  '&:hover': {
                    border: `1px solid ${Colors.SkySecondBlue}`,
                  },
                }),
                indicatorsContainer: (provided: object) => ({
                  ...provided,
                  '& > *': {
                    cursor: 'pointer',
                  },
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                option: (provided: object, state: { isSelected: boolean }) => ({
                  ...provided,
                  color: Colors.DarkGrey,
                  backgroundColor: state.isSelected
                    ? Colors.SkySecondBlue
                    : 'white',
                  '&:hover': {
                    backgroundColor: Colors.SkySecondBlue,
                  },
                }),
              }}
              options={allProjects}
              value={selectedProject}
              onChange={project => {
                setSelectedProject(project);
              }}
              placeholder="Filter by project"
              isClearable
            />
            <NewTemplate
              disabled={!canAddNdatemplate}
              onClick={() =>
                canAddNdatemplate && history.push(Routes.addTemplate)
              }
            >
              <img
                src={SVG.addProject}
                alt="Project"
                style={{ marginRight: '10px' }}
              />
              Add a NDA template
            </NewTemplate>
          </AddTemplateWrapper>
        </Wrapper>
        {!isLoading ? (
          <>
            <CardContainer>
              {templates
                ?.filter(template => isFiltered(template.name))
                .map(template => (
                  <Card key={template.id}>
                    <WrapperCard
                      onClick={() => {
                        setIdShownTemplate(template.id);
                        setNameShownTemplate(template.name);
                        setIsShowTemplateModal(true);
                      }}
                    >
                      <TemplateImagePreviewWrapper>
                        <TemplateImagePreview id={Number(template.id)} />
                      </TemplateImagePreviewWrapper>
                      <FoloderLogoBg>
                        <FolderLogo
                          src={SVG.fileDocument}
                          alt="project folder"
                        />
                      </FoloderLogoBg>
                    </WrapperCard>
                    <ContentInfoWrapper>
                      <Title>
                        {template.name.length > 30
                          ? `${template.name.slice(0, 30)}...`
                          : template.name}
                      </Title>
                    </ContentInfoWrapper>
                  </Card>
                ))}
            </CardContainer>
          </>
        ) : (
          <Loader />
        )}
        {paginationPages.length !== 0 && (
          <Pagination
            onPageClick={page => setCurrentPage(page)}
            paginationPages={paginationPages}
            currentPage={currentPage}
            disabled={isLoading}
          />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
}

const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background-color: ${Colors.White};
  width: calc(25% - 23px);
  height: 220px;
  border-radius: 10px;
  border: 1px solid ${Colors.PaleAquaBlue};
`;

const FoloderLogoBg = styled.div`
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: ${Colors.LightThirdGrey};
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentInfoWrapper = styled.div`
  padding: 0 20px;
`;

const TemplateImagePreviewWrapper = styled.div`
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.34);
  }
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: 1;
    position: relative;
  }
`;
const Title = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  margin-bottom: 8px;
`;

const FolderLogo = styled.img`
  width: 34px;
  height: 34px;
`;

const WrapperCard = styled.div`
  border: 0;
  border-radius: 10px;
  margin: 10px;
  min-height: 170px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledSelect = styled(Select)`
  width: 311px;
  margin-right: 30px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
`;

const StyledFilterInput = styled(FilterInput)`
  width: 100%;
  height: 55px;
`;

const NewTemplate = styled.div<{ disabled?: boolean }>`
  height: 60px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px dashed ${Colors.WeakSecondGrey};
  border-radius: 10px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.LightSecondGrey};
  text-align: center;
  transition: ${baseTransition};
  background-color: ${Colors.White};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props =>
    !props.disabled &&
    `&:hover {
        box-shadow: ${BoxShadow.Base};
    }
  `}
`;

const TabTitle = styled.div`
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkBlack};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  position: relative;
  z-index: 5;
`;

const AddTemplateWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export default Templates;
