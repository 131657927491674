import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { templates as apiTemplates } from '../../../api/index';
import { useNotification } from '../../../hooks';

type Props = {
  id: number;
};

const TemplateImagePreview = ({ id }: Props) => {
  const { showError } = useNotification();

  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiTemplates
      .getTemplateImagePreview(id)

      .then(response => {
        const image = Buffer.from(response.data, 'binary').toString('base64');
        const prefix = `data:${response.headers['content-type']};base64,`;

        return setData(prefix + image);
      })
      .catch(e => {
        showError(e.message);
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <TemplateImg width="140px" height="110px" src={data} alt="Template logo" />
  ) : (
    <h4>Loading...</h4>
  );
};

const TemplateImg = styled.img`
  width: 100%;
`;

export default TemplateImagePreview;
