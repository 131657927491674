import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  members as apiMembers,
  nda as apiNda,
  OneNdaType,
  OneProjectType,
} from '../../api';
import SVG from '../../assets/svg';
import { Modal, ProjectCard } from '../../atoms';
import PdfView from '../../atoms/PdfView';
import { routeTemplate } from '../../helpers';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import { Routes } from '../../routes';
import { selectors } from '../../store';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';
import { TeamMembers } from '../oneProject/components';
import { MemberTypeChecked as Member } from '../oneProject/components/TeamMembers/OneMember';

type Params = {
  projectId: string;
};

function LookupNda() {
  const { projectId } = useParams<Params>();
  const history = useHistory();

  const user = useSelector(selectors.user);

  const isSignerOfProject = user.signerOfProjects.includes(Number(projectId));

  const [allUserMembers, setAllUserMembers] = useState<Member[]>([]);
  const [nda, setNda] = useState<OneNdaType[]>([]);

  const [showPdf, setShowPdf] = useState(false);
  const [pdfURL, setPdfURL] = useState('');
  const [project, setProject] = useState<OneProjectType | null>(null);
  const [projectNda, setProjectNda] = useState<OneNdaType | null>(null);

  useEffect(() => {
    if (projectId) {
      apiNda.getNdaByProjectId(Number(projectId)).then(({ data }) => {
        if (!data.results[0]) {
          return;
        }
        setNda(data.results);
      });
    }
  }, [projectId]);

  useEffect(() => {
    apiMembers.getAllMembers().then(res => {
      const checkedMembers = res.data.map(member => ({
        ...member,
        checked: false,
      }));
      setAllUserMembers(checkedMembers);
    });
  }, []);

  useEffect(() => {
    apiNda.getNdaByProjectId(Number(projectId)).then(({ data }) => {
      if (!data.results[0]) {
        return;
      }
      setProjectNda(data.results[0]);
    });
  }, []);

  const viewNda = () => {
    setShowPdf(true);
    if (projectNda && projectNda.id) {
      apiNda.getNdaPdf(projectNda.id).then(response => {
        const fileURL = URL.createObjectURL(response.data);
        setPdfURL(fileURL);
      });
    }
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SideMenu />
        <>
          {!isSignerOfProject && (
            <Members>
              <ProjectCard
                project={SVG.file}
                visible={
                  <CreatedIcon onClick={() => viewNda()}>
                    <img src={SVG.eye} alt="" width="16px" />
                  </CreatedIcon>
                }
                created={
                  <CreatedIcon
                    onClick={() =>
                      projectNda &&
                      history.push(
                        routeTemplate(Routes.editProjectNda, {
                          projectId,
                        }),
                      )
                    }
                  >
                    <img
                      src={!projectNda ? SVG.add : SVG.pencilSky}
                      alt=""
                      width="16px"
                    />
                  </CreatedIcon>
                }
                cardbg={SVG.folderSkyBg}
                alt="add member"
                title="Project NDA"
                details="project_pdf"
              />
              <ProjectCard
                project={SVG.wordExcel}
                visible={
                  <CreatedIcon>
                    <img
                      src={project?.teaser ? SVG.eye : ''}
                      alt=""
                      width="16px"
                    />
                  </CreatedIcon>
                }
                created={
                  <CreatedIcon
                    onClick={() =>
                      history.push(
                        routeTemplate(Routes.updateTeaser, {
                          projectId,
                        }),
                      )
                    }
                    aria-hidden="true"
                  >
                    <img src={SVG.pencilSky} alt="" width="16px" />
                  </CreatedIcon>
                }
                cardbg={SVG.wordExcelBg}
                alt="add member"
                title="Project Teaser"
                details="project_doc"
              />
              <ProjectCard
                project={SVG.fileDocument}
                visible={
                  <CreatedIcon>
                    <img
                      src={project?.cim ? SVG.eye : ''}
                      alt=""
                      width="16px"
                    />
                  </CreatedIcon>
                }
                created={
                  <CreatedIcon
                    onClick={() =>
                      history.push(
                        routeTemplate(Routes.updateCim, {
                          projectId,
                        }),
                      )
                    }
                    aria-hidden="true"
                  >
                    <img src={SVG.pencilSky} alt="" width="16px" />
                  </CreatedIcon>
                }
                cardbg={SVG.fileDocumentBg}
                alt="add member"
                title="Project CIM"
                details="project_pdf"
              />
              <ProjectCard
                project={SVG.addMember}
                cardbg={SVG.addMemberCardBg}
                title={
                  <TeamMembers
                    projectId={Number(projectId)}
                    membersInProject={allUserMembers.map(
                      (member, index) => index,
                    )}
                  />
                }
              />
            </Members>
          )}
        </>
        <Info>Look for NDA</Info>
        <CardContainer>
          {nda.map(item => (
            <Card key={item.id} onClick={() => viewNda()}>
              <Wrapper>
                <FoloderLogoBg>
                  <FolderLogo
                    src={SVG.fileDocument}
                    width="50px"
                    alt="project folder"
                  />
                </FoloderLogoBg>
              </Wrapper>
              <Content>
                <ContentInfoWrapper>
                  <Title>{item.name}</Title>
                  <Time>Edited 10min ago</Time>
                </ContentInfoWrapper>
              </Content>
            </Card>
          ))}
        </CardContainer>
      </ContentWrapper>
      <>
        {showPdf && (
          <Modal
            modalIsOpen={showPdf}
            setModalIsOpen={() => {
              setShowPdf(false);
              setPdfURL('');
            }}
          >
            <PdfWrapper>
              <PdfView
                pdfURL={pdfURL}
                onClose={() => {
                  setShowPdf(false);
                  setPdfURL('');
                }}
              />
            </PdfWrapper>
          </Modal>
        )}
      </>
    </PageWrapper>
  );
}

const Info = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 25px;
`;

const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background-color: ${Colors.White};
  width: calc(25% - 23px);
  height: 224px;
  border-radius: 10px;
  border: 1px solid ${Colors.PaleAquaBlue};
  cursor: pointer;
`;

const FoloderLogoBg = styled.div`
  border: 1px solid ${Colors.WeakGrey};
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: ${Colors.WeakGrey};
`;
const FolderLogo = styled.img`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  min-height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Colors.WeakGrey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentInfoWrapper = styled.div`
  min-height: 75px;
  padding: 10px 20px;
`;

const Title = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  margin-bottom: 8px;
`;

const Time = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  color: ${Colors.LightSecondGrey};
`;

const Members = styled.div`
  display: flex;
  gap: 20px;
`;

const CreatedIcon = styled.div`
  cursor: pointer;
  margin-right: 5px;
`;

const PdfWrapper = styled.div`
  height: 95vh;
  width: 80vw;
  background-color: ${Colors.White};
`;

export default LookupNda;
