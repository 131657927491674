import { TextField } from '@mui/material';
import { ChangeEvent, FocusEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { BoxShadow, Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string | ReactNode;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error: string;
  disabled?: boolean;
  hint: string;
  required?: boolean;
};

function MuiInputHints({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  disabled,
  hint,
  required,
}: Props) {
  const [isShowHint, setIsShowHint] = useState(false);

  return (
    <InputWrapper>
      <StyledInputHint
        value={value}
        label={
          <span style={{ display: 'flex' }}>
            {placeholder}
            {required ? <Required>*</Required> : null}
          </span>
        }
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder as string}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {error && <Error>{error}</Error>}
      {isShowHint && <Hint>{hint}</Hint>}

      <StyledRevealButton
        onMouseOver={() => setIsShowHint(!isShowHint)}
        onMouseOut={() => setIsShowHint(!isShowHint)}
      >
        <img src={SVG.info} alt="Info" />
      </StyledRevealButton>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInputHint = styled(TextField)`
  width: 100%;
  padding: 10px;

  display: block;
  outline: none;

  border-radius: 10px;
  background-color: transparent;
  border: 1px solid ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkBlack};

  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
    border-width: 1px !important;
  }
  .Mui-focused {
    color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
  }
`;

const StyledRevealButton = styled.div`
  position: absolute;
  top: 18px;
  right: 10px;
  font-size: ${rem(7)};
  font-family: ${Fonts.Sofia};
  cursor: pointer;
  color: ${Colors.DarkBlack};

  & > img {
    width: 20px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;

const Hint = styled.span`
  position: absolute;
  top: -30px;
  right: -150px;
  background-color: ${Colors.White};
  font-size: ${rem(8)};
  font-family: ${Fonts.Sofia};
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${Colors.LightBlue};
  box-shadow: ${BoxShadow.Secondary};
`;

const Required = styled.div`
  color: ${Colors.Red};
  margin-left: 2px;
`;

const Error = styled.div`
  margin-top: 12px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
  position: absolute;
  bottom: -20px;
  @media (max-width: 520px) {
    font-size: ${rem(8.75)};
  }
`;

export default MuiInputHints;
