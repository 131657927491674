import { ChangeEvent, FocusEvent, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { StyledInput } from '../atoms';
import { BoxShadow, Colors, Fonts, rem } from '../themes';
import ErrorText from './ErrorText';

type Props = {
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error: string;
  disabled?: boolean;
  hint: string;
};

function InputHint({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  disabled,
  hint,
}: Props) {
  const [isShowHint, setIsShowHint] = useState(false);

  return (
    <InputWrapper>
      <StyledInputHint
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />

      {error && <ErrorText>{error}</ErrorText>}
      {isShowHint && <Hint>{hint}</Hint>}

      <StyledRevealButton
        onMouseOver={() => setIsShowHint(!isShowHint)}
        onMouseOut={() => setIsShowHint(!isShowHint)}
      >
        <img src={SVG.info} alt="Info" />
      </StyledRevealButton>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledInputHint = styled(StyledInput)`
  padding-right: 50px;
  overflow-x: hidden;
`;

const StyledRevealButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: ${rem(7)};
  font-family: ${Fonts.Sofia};
  cursor: pointer;
  color: ${Colors.DarkBlack};

  & > img {
    width: 20px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;

const Hint = styled.span`
  position: absolute;
  top: -30px;
  right: -150px;
  background-color: ${Colors.White};
  font-size: ${rem(8)};
  font-family: ${Fonts.Sofia};
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${Colors.LightBlue};
  box-shadow: ${BoxShadow.Secondary};
`;

export default InputHint;
