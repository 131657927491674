import { AxiosResponse } from 'axios';

import api from '../authApi';
import { EventsLimitedList } from '../types';
import { EventsList, ReadEvents } from './types';

const getAllEvents = (): Promise<AxiosResponse<EventsList>> =>
  api.get(`/projects/events/?ordering=-id&limit=${EventsLimitedList}`);

const getUnreadEvents = (
  lastReadEventId: number,
): Promise<AxiosResponse<EventsList>> =>
  api.get(
    `/projects/events/?id__gt=${lastReadEventId}&ordering=-id&limit=${EventsLimitedList}`,
  );

const setEventsAsRead = (
  body: ReadEvents,
): Promise<AxiosResponse<ReadEvents>> =>
  api.post('/projects/events/mark_events_read/', body);

const projectEvents = { getAllEvents, getUnreadEvents, setEventsAsRead };

export default projectEvents;
