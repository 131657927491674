/* eslint-disable no-nested-ternary */

import { MemberType } from '../../api';
import { BuyAdditionalProject, ChangePlanQuantity } from '../../organisms';
import BuyAdditionalModal from '../../pages/plan/components/Plans/BuyAdditionalModal';

interface Email {
  value: string;
  errorMsg: string;
}

type Props = {
  email?: Email;
  setMembers?: React.Dispatch<React.SetStateAction<MemberType[]>>;
  setIsOpenAddBox?: React.Dispatch<React.SetStateAction<boolean>>;
  totalPrice?: number;
  selectedProjects?: string;
  selectedMembers?: string;
  projectPrice?: number;
  memberPrice?: number;
  setIsConfirmBuyProject?: (bool: boolean) => void;
  handleUpgrade?: () => void;
  isAdditionalProject?: boolean;
  isAddPlanQuantity?: boolean;
  closeModal?: () => void;
  isConfirmBuyProject?: boolean;
  isShowNote?: boolean;
};

function CommonModal({
  email,
  setMembers,
  setIsOpenAddBox,
  totalPrice,
  selectedProjects,
  selectedMembers,
  projectPrice,
  memberPrice,
  setIsConfirmBuyProject,
  handleUpgrade,
  isAdditionalProject,
  isAddPlanQuantity,
  closeModal,
  isConfirmBuyProject,
  isShowNote,
}: Props) {
  return (
    <>
      {isAdditionalProject ? (
        <BuyAdditionalProject closeModal={closeModal} />
      ) : isConfirmBuyProject ? (
        <BuyAdditionalModal
          totalPrice={totalPrice}
          selectedProjects={selectedProjects}
          selectedMembers={selectedMembers}
          memberPrice={memberPrice as number}
          projectPrice={projectPrice as number}
          handleUpgrade={handleUpgrade}
          setIsConfirmBuyProject={setIsConfirmBuyProject || (() => {})}
          isShowNote={isShowNote}
        />
      ) : (
        <ChangePlanQuantity
          closeModal={closeModal}
          isAddPlanQuantity={isAddPlanQuantity}
          email={email}
          setMembers={setMembers}
          setIsOpenAddBox={setIsOpenAddBox}
        />
      )}
    </>
  );
}

export default CommonModal;
