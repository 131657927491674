import { TextField } from '@mui/material';
import { ChangeEvent, FocusEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string | ReactNode;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error: string;
  disabled?: boolean;
  required?: boolean;
};

function MuiInputPassword({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  disabled,
  required,
}: Props) {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const handleRevealButtonClick = () => {
    setIsHidden(!isHidden);
  };
  return (
    <PasswordInputWrapper>
      <StyledInputPassword
        type={isHidden ? 'password' : 'text'}
        label={
          <span style={{ display: 'flex' }}>
            {placeholder}
            {required ? <Required>*</Required> : null}
          </span>
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder as string}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {error.length > 0 && (
        <Error>
          {error.split('.').map(errString => (
            <span key={errString}>{errString}</span>
          ))}
        </Error>
      )}
      <StyledRevealButton onClick={handleRevealButtonClick}>
        <img
          src={isHidden ? SVG.eye : SVG.hideEye}
          alt={isHidden ? 'SHOW' : 'HIDE'}
          style={{ marginRight: 7 }}
        />
      </StyledRevealButton>
    </PasswordInputWrapper>
  );
}

const PasswordInputWrapper = styled.div`
  position: relative;
`;

const StyledInputPassword = styled(TextField)`
  width: 100%;
  padding: 10px;

  display: block;
  outline: none;

  border-radius: 10px;
  background-color: transparent;
  border: 1px solid ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkBlack};

  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
    border-width: 1px !important;
  }
  .Mui-focused {
    color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
  }
`;

const StyledRevealButton = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: ${rem(7)};
  font-family: ${Fonts.Sofia};
  cursor: pointer;
  color: ${Colors.DarkBlack};
`;

const Required = styled.div`
  color: ${Colors.Red};
  margin-left: 2px;
`;

const Error = styled.div`
  margin-top: 12px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
  position: absolute;
  bottom: -20px;
  @media (max-width: 520px) {
    font-size: ${rem(8.75)};
  }
`;
export default MuiInputPassword;
