import { PaginationResponse } from '../types';

export enum EventEnum {
  None = 'none',
  NdaSent = 'nda_sent',
  NewCommentsThread = 'new_comments_thread',
  NewComment = 'new_comment',
  Approved = 'approved',
  Rejected = 'rejected',
  NdaRecalled = 'nda_recalled',
  ProjectArchived = 'project_archived',
  ProjectUnarchived = 'project_unarchived',
  NdaSigned = 'nda_signed',
  MemberAdded = 'member_added',
  MemberRemoved = 'member_removed',
  NewProject = 'new_project',
  NdaOpened = 'nda_opened',
  RecipientAdded = 'recipient_added',
  RecipientRemoved = 'recipient_removed',
  NDARejected = 'nda_rejected',
}

export type EventType = {
  id: number;
  created: string;
  project: number;
  projectBranch: number;
  status: EventEnum;
  user: string | null;
  userId: number | null;
  author: string | null;
  authorId: number | null;
  isRead: boolean;
};

export type ReadEvents = {
  events: string[];
};

export type EventsList = PaginationResponse<EventType>;
