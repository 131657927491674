import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../../api';
import { useNotification } from '../../hooks';
import { Routes } from '../../routes';
import { verifyEmail } from '../../store';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';

type Props = {
  key: string;
};

function UserActivation() {
  const { showError } = useNotification();

  const { key } = useParams<Props>();
  const dispatch = useDispatch();

  const [isActivation, setIsActivation] = useState<boolean | null>(false);

  useEffect(() => {
    authorization
      .verifyEmail(key)
      .then(() => {
        dispatch(verifyEmail());
        setIsActivation(true);
      })
      .catch(({ data }) => {
        setIsActivation(false);
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  }, [key, dispatch, showError]);

  return (
    <Wrapper>
      <>
        {isActivation && (
          <SuccessActivation>
            Email was successfully activated
          </SuccessActivation>
        )}
        <GoToLoginBlock to={Routes.login}>Go to login</GoToLoginBlock>
      </>
    </Wrapper>
  );
}

const Wrapper = styled(PageWrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${Colors.LightBlue};
`;

const SuccessActivation = styled.div`
  text-align: center;
  margin-bottom: 10px;
  background-color: #ffffff;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(14)};
  line-height: 1.5;
  color: ${Colors.LightBlue};
`;

const GoToLoginBlock = styled(NavLink)`
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 10.5px;
  box-shadow: 0 3.5px 3.5px rgb(89 48 255 / 10%);
  background-color: #ffffff;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12)};
  line-height: 1.5;
  text-decoration: none;
  color: ${Colors.LightBlue};
  cursor: pointer;
`;

export default UserActivation;
