import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UnlimitedList } from '../types';
import {
  AddOrUpdateSubscriptionResponse,
  AddSubscription,
  Invoice,
  OneTimePurchasesList,
  ProductsList,
  Subscription,
  UpdateSubscription,
} from './types';

const getInvoices = (): Promise<AxiosResponse<Invoice[]>> =>
  api.get('/billing/invoice/');

const getProducts = (): Promise<AxiosResponse<ProductsList>> =>
  api.get(`/billing/products/?limit=${UnlimitedList}`);

const getSubscription = (): Promise<AxiosResponse<Subscription>> =>
  api.get('/billing/subscription/');

const addSubscription = (
  body: AddSubscription,
): Promise<AxiosResponse<AddOrUpdateSubscriptionResponse>> =>
  api.post('/billing/subscription/subscribe/', body);

const updateSubscription = (
  body: UpdateSubscription,
): Promise<AxiosResponse<AddOrUpdateSubscriptionResponse>> =>
  api.post('/billing/subscription-edit/upgrade/', body);

const cancelSubscription = (): Promise<AxiosResponse> =>
  api.post('/billing/subscription/cancel/', { immediately: false });

const renewSubscription = (): Promise<AxiosResponse> =>
  api.post('/billing/subscription/renew/');

const getOneTimePurchases = (): Promise<AxiosResponse<OneTimePurchasesList>> =>
  api.get(`/billing/one-time-purchases/?limit=${UnlimitedList}`);

const billing = {
  getInvoices,
  getProducts,
  getSubscription,
  addSubscription,
  updateSubscription,
  cancelSubscription,
  renewSubscription,
  getOneTimePurchases,
};

export default billing;
