import React from 'react';
import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  project: SVGElement | string;
  visible?: React.ReactNode;
  created?: React.ReactNode;
  cardbg: SVGElement | string;
  alt?: string;
  title?: string | React.ReactNode;
  details?: string;
  onClick?: () => void;
};

function ProjectCard({
  project,
  visible,
  created,
  alt,
  title,
  details,
  cardbg,
  onClick,
}: Props) {
  return (
    <Wrapper>
      <CardWrapper
        clickable={!!onClick}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <AddMemberBG>
          <img src={project as string} alt={alt} width="22px" />
        </AddMemberBG>
        <ProjectWrapper>
          <Title>{title}</Title>
          <SubTitle>{details}</SubTitle>
        </ProjectWrapper>

        <CardBg>
          <img src={cardbg as string} alt="folder bg" />
        </CardBg>
      </CardWrapper>
      <IconWrapper>
        <div>{visible}</div>
        {created}
      </IconWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 307px;
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 100px);
`;

const AddMemberBG = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.SkyBlue};
`;

const Title = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(12.5)};
  font-weight: 300;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(11.25)};
  font-weight: 300;
  z-index: 1;
`;

const CardBg = styled.div`
  position: absolute;
  right: -15px;
  bottom: -25px;
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 55px;
  display: flex;
  gap: 7px;
`;

const CardWrapper = styled.div<{ clickable: boolean }>`
  display: flex;
  height: 120px;
  border-radius: 10px;
  border: 1px solid ${Colors.LightSecondBlue};
  background: ${Colors.White};
  align-items: center;
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  flex-direction: row;
  position: relative;
  padding: 0 0 0 16px;
  overflow: hidden;
  margin: 40px 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  & > *:not(:last-child) {
    margin-right: 14px;
  }
`;

export default ProjectCard;
