import styled from 'styled-components';

import SVG from '../assets/svg';
import { Nda } from '../pages/projects/components/types';
import { baseTransition, BoxShadow, Colors, Fonts, rem } from '../themes';
import FileWrapper from './FileWrapper';

type Props = {
  value: Nda;
  onChange: React.Dispatch<React.SetStateAction<Nda>>;
  handleRemove: (event: React.MouseEvent<HTMLDivElement>) => void;
  fileRef: React.RefObject<HTMLInputElement>;
  handleModal: () => void;
};

function TemplateNda({
  value,
  onChange,
  handleRemove,
  fileRef,
  handleModal,
}: Props) {
  return (
    <ChooseContainer>
      <ChoiceOption onClick={handleModal}>
        <Description>
          <ImageContainer color={Colors.Ceramal}>
            <Image src={SVG.fromTemplate} alt="From template" />
          </ImageContainer>
          <p>From your Template Library</p>
          {value.template?.label && <p>({value.template?.label})</p>}
        </Description>
      </ChoiceOption>
      <FileWrapper
        value={value.file}
        onChange={event => {
          if (!event.target.files) return;
          onChange({
            ...value,
            file: event.target?.files[0],
            template: null,
          });
        }}
        removeFile={handleRemove}
        name="file"
        fileRef={fileRef}
      />
    </ChooseContainer>
  );
}

const ChooseContainer = styled.div`
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
`;

const ChoiceOption = styled.div`
  position: relative;
  padding-top: 30px;
`;

const Description = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.25)};
  text-align: center;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: ${Colors.White};
  cursor: pointer;
  transition: ${baseTransition};
  border: 0.5px solid ${Colors.LightestGrey};
  border-radius: 15px;
  box-shadow: ${BoxShadow.Base};
  justify-content: center;
  padding: 42px;
  position: relative;
  flex-direction: column;

  &:hover {
    background-color: ${Colors.LightGrey};
  }
  > p {
    margin: 0;
  }
`;

const ImageContainer = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 56px;
  width: 56px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  margin-left: 30px;
  z-index: 2;

  > img {
    height: 20px;
    width: 20px;
    margin: auto;
  }
`;

const Image = styled.img`
  width: 70px;
`;

export default TemplateNda;
