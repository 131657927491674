import { useRef } from 'react';

import CardWrapper from '../atoms/CardWrapper';
import FileWrapper from '../atoms/FileWrapper';

type Props = {
  value: File | null;
  onChange: React.Dispatch<React.SetStateAction<File | null>>;
  name: string;
};

function TeaserOrCim({ value, onChange, name }: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onChange(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  return (
    <>
      <CardWrapper title={`Project ${name}`}>
        <FileWrapper
          value={value}
          onChange={event => {
            if (!event.target.files) return;
            onChange(event.target?.files[0]);
          }}
          removeFile={handleRemove}
          name={name}
          fileRef={inputRef}
        />
      </CardWrapper>
    </>
  );
}

export default TeaserOrCim;
