import { AxiosResponse } from 'axios';

import api from '../authApi';
import {
  ContactType,
  CreateContactRequest,
  UpdateContactRequest,
} from './types';

const createContact = (
  body: CreateContactRequest,
): Promise<AxiosResponse<ContactType>> => api.post('/contacts/', body);

const updateContact = (
  id: number,
  body: UpdateContactRequest,
): Promise<AxiosResponse<ContactType>> => api.patch(`/contacts/${id}/`, body);

const deleteContact = (id: number): Promise<AxiosResponse> =>
  api.delete(`/contacts/${id}/`);

const getAllContact = (): Promise<AxiosResponse<ContactType[]>> =>
  api.get('/contacts/');

const contacts = { createContact, getAllContact, updateContact, deleteContact };

export default contacts;
