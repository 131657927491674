import { Switch } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../themes';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: 66,
    height: 45,
  },
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: 11,
    left: 13,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: Colors.DarkBlue,
        transition: 'background-color 0.3s ease',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: '50%',
    transition: 'width 0.3s ease',
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    opacity: 1,
    backgroundColor: Colors.PaleAquaBlue,
    boxSizing: 'border-box',
  },
}));

export default StyledSwitch;
