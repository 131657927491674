import { TextField } from '@mui/material';
import {
  ChangeEvent,
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react';
import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string | ReactNode;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  maxLength?: number;
  required?: boolean;
};

function MuiInput({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  className,
  disabled,
  type,
  maxLength,
  required,
}: Props) {
  return (
    <>
      <MuiStyledInput
        type={type ?? 'text'}
        label={
          <span style={{ display: 'flex' }}>
            {placeholder}
            {required ? <Required>*</Required> : null}
          </span>
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onBlur}
        placeholder={placeholder as string}
        className={className}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        {...((type === 'text' || !type) && { maxLength })}
      />
      {error && <Error>{error}</Error>}
    </>
  );
}

export const MuiStyledInput = styled(TextField)`
  width: 100%;
  padding: 10px;

  display: block;
  outline: none;

  border-radius: 10px;
  background-color: transparent;
  border: 1px solid ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkBlack};

  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
    border-width: 1px !important;
  }
  .Mui-focused {
    color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.DarkBlue} !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.LightForthGrey};
  }
`;

const Required = styled.div`
  color: ${Colors.Red};
  margin-left: 2px;
`;

const Error = styled.div`
  margin-top: 12px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
  position: absolute;
  bottom: -20px;
  @media (max-width: 520px) {
    font-size: ${rem(8.75)};
  }
`;

export default MuiInput;
