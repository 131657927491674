import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UpdateSignership } from './types';

const updateSignership = (
  id: number,
  body: UpdateSignership,
): Promise<AxiosResponse> => api.patch(`/projects/signership/${id}/`, body);

const signership = { updateSignership };

export default signership;
