import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { MemberType } from '../../../../api';
import { Checkbox, UserLogo } from '../../../../atoms';
import { Fonts, rem } from '../../../../themes';

export interface MemberTypeChecked extends MemberType {
  checked: boolean;
}

type Props = {
  member: MemberTypeChecked;
  checked: boolean;
  onChangeChecked: (event: ChangeEvent<HTMLInputElement>) => void;
};

function OneMember({ member, checked, onChangeChecked }: Props) {
  const { id, avatar, firstName, lastName, email } = member;
  const namesLetters = `${
    firstName && lastName ? firstName[0] + lastName[0] : email[0]
  }`;

  return (
    <MemberBox>
      <InfoBlock>
        {checked !== undefined && onChangeChecked !== undefined && (
          <Checkbox
            id={id.toString()}
            checked={checked}
            onChange={onChangeChecked}
          />
        )}
        <UserLogo logo={avatar} width={20} namesLetters={namesLetters} />
        <MemberInfo>
          {firstName} {lastName}
        </MemberInfo>
        <MemberInfo>{email}</MemberInfo>
      </InfoBlock>
    </MemberBox>
  );
}

const MemberBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const MemberInfo = styled.span`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7)};
  white-space: nowrap;
`;

export default OneMember;
