import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Colors } from '../themes';

type Props = {
  id?: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
};

function Checkbox({ onChange, id, checked, className, disabled }: Props) {
  return (
    <CheckboxWrapper className={className}>
      <Input
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        id={id}
        type="checkbox"
        name={id}
      />
      <Check isChecked={checked} />
    </CheckboxWrapper>
  );
}

const CheckboxWrapper = styled.div`
  position: relative;
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
`;

const Check = styled.div<{ isChecked: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px solid
    ${props => (props.isChecked ? Colors.LightBlue : Colors.WeakGrey)};
  background-color: none;
  border-radius: 2px;
  pointer-events: none;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    border-radius: 1px;
    background-color: ${props => (props.isChecked ? Colors.LightBlue : 'none')};
    transform: rotate(-45deg);
    transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    cursor: pointer;
  }

  &::before {
    top: 5px;
    left: 4px;
    width: 2px;
    height: 6px;
  }

  &::after {
    top: 6px;
    left: 5px;
    width: 9px;
    height: 2px;
  }
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

export default Checkbox;
