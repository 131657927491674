enum Colors {
  Overlay = '#4d4d4d',
  Blue = '#208EDD',
  LightBlue = '#0A437C',
  White = '#FFFFFF',
  LightGrey = '#F2F2F2',
  LightSecondGrey = '#606060',
  LightSecondBlue = '#d5e4f1',
  BackgroundGrey = '#F5FAFF',
  WeakGrey = '#E5E5E5',
  MiddleGrey = '#F4F4F4',
  MiddleSecondGrey = '#5A5A5A',
  Grey = '#EFEFEF',
  WeakSecondGrey = '#60606087',
  DarkGrey = '#737373',
  StrongGrey = '#9b99af',
  SkyBlue = '#DFF0FF',
  SkySecondBlue = '#D5E4F1',
  DarkBlue = '#023061',
  TextGrey = '#424242',
  LightTextGrey = '#c9c9c9',
  Green = '#39D782',
  Yellow = '#EED26E',
  LightRed = '#FF4330',
  Red = '#fc5b5b',
  DarkRed = '#E74C3C',
  LightBlack = '#111111',
  Black = '#24252A',
  WeakBlack = '#1D1D1D',
  DarkBlack = '#000000',
  Transparent = 'transparent',
  InfoBlue = '#C4E1F6',
  PaleAquaBlue = '#D5E4F1',
  Ceramal = '#BEA5F4',
  NavyBlue = '#27629E',
  LightestBlue = '#ebeef2',
  LightestGrey = '#ebebeb',
  OffGrey = '#606060',
  offBlue = '#e3f2ff',
  offBlack = '#f2f5f7',
  offSecondaryBlue = '#c0d5e9',
  offSecondaryGrey = '#e0e0e0',
  semiOffGrey = '#bebebe',
  MoreLightGrey = '#C8C8C8',
  DarkSkyBlue = '#c9ddef',
  GreyThird = '#B1B1B1',
  GreyForth = '#D2D2D2',
  LightForthGrey = '#B7CDE3',
  SkySecondaryBlue = '#d5e4f1',
  SecondaryRed = '#f8e6e6',
  LightThirdGrey = '#DADBDC',
  LightFifthGrey = '#D9D9D9',
  SkyBlueThird = '#EFF5FA',
  LightGrayishBlue = '#F1F6FA',
  DarkGrayishBlue = '#9095A4',
  VeryPaleBlue = '#F9FCFF',
  CrystalEnigma = '#E7DFFF',
  Silver = '#BCBCBC',
}

enum Fonts {
  Sofia = 'Sofia Pro, sans-serif',
  Circular = 'Circular Std, sans-serif',
  Roboto = 'Roboto, sans-serif',
}

const baseTransition = 'all 150ms linear';

enum BoxShadow {
  Base = '-4px 4px 8px rgba(31, 31, 31, 0.03), 4px 4px 9px rgba(31, 31, 31, 0.02)',
  Secondary = '-4px 4px 8px rgba(31, 31, 31, 0.03), 4px 4px 9px rgba(31, 31, 31, 0.03)',
  Third = '0px 0px 14px rgba(116, 185, 255, 0.32)',
}

const rootFontSize = 10;

const rootBorderRadius = '10px';

const rem = (size: number): string => `${size / rootFontSize}rem`;

const removeScrollStyles = `
-ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }`;

const scrollBarStyles = `
  &::-webkit-scrollbar {
    width: 5px;
    border-radius:10px;
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.offBlack};
  }
  &::-webkit-scrollbar-thumb {
    background:${Colors.offSecondaryGrey};
    border-radius:10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background:${Colors.offSecondaryGrey};
  }
`;
export {
  BoxShadow,
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  baseTransition,
  rootBorderRadius,
  scrollBarStyles,
};
