import styled from 'styled-components';

import { baseTransition, BoxShadow, Colors, Fonts, rem } from '../../themes';

type Props = {
  onClick: () => void;
  imageSrc: string;
  description: string;
};

function ChoiceOption({ onClick, imageSrc, description }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <Image src={imageSrc} alt={description} />
      <Description>{description}</Description>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: ${Colors.White};
  cursor: pointer;
  transition: ${baseTransition};
  border: 0.5px solid ${Colors.LightestGrey};
  border-radius: 15px;
  box-shadow: ${BoxShadow.Base};

  &:hover {
    background-color: ${Colors.LightGrey};
  }
`;

const Image = styled.img`
  width: 70px;
`;

const Description = styled.p`
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(13)};
  margin-bottom: 0;
`;

export default ChoiceOption;
