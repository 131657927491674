import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { Textarea } from '../../atoms';
import SolidButton from '../../atoms/SolidButton';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

interface FormData {
  firstName: string;
  email: string;
  contactNo: string;
  messages: string;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  contactNo: yup.string().required('Contact number is required'),
  messages: yup.string().required('Message is required'),
});

function ContactUs() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });
  const history = useHistory();

  const onSubmit = async (data: FormData) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      console.log('data', data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRegisterClick = async (data: FormData) => {
    try {
      history.push({
        pathname: Routes.register,
        search: `?email=${encodeURIComponent(data.email)}`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BgWrapper id="contact-us">
      <Wrapper>
        <ContactTitle>Do you have a question? Contact us</ContactTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RowWrapper>
            <RowColWrapper>
              <InputLabel>
                First Name<span style={{ color: `${Colors.Red}` }}>*</span>
              </InputLabel>
              <InputWrapper>
                <StyledInput
                  placeholder="Enter First Name"
                  maxLength={30}
                  {...register('firstName')}
                  name="firstName"
                />
              </InputWrapper>
              <Error>{errors.firstName?.message}</Error>
            </RowColWrapper>
            <RowColWrapper>
              <InputLabel>
                Email<span style={{ color: `${Colors.Red}` }}>*</span>
              </InputLabel>
              <InputWrapper>
                <StyledInput
                  {...register('email')}
                  placeholder="Enter Email"
                  name="email"
                />
              </InputWrapper>
              <Error>{errors.email?.message}</Error>
            </RowColWrapper>
            <RowColWrapper>
              <InputLabel>
                Contact No<span style={{ color: `${Colors.Red}` }}>*</span>
              </InputLabel>
              <InputWrapper>
                <StyledInput
                  {...register('contactNo')}
                  name="contactNo"
                  placeholder="Enter Contact No"
                  type="number"
                />
              </InputWrapper>
              <Error>{errors.contactNo?.message}</Error>
            </RowColWrapper>
          </RowWrapper>
          <RowColTextAreaWrapper>
            <InputLabel>
              Enter Message<span style={{ color: `${Colors.Red}` }}>*</span>
            </InputLabel>
            <Textarea
              {...register('messages')}
              value={getValues('messages')}
              onChange={e => setValue('messages', e.target.value)}
              onBlur={e => console.log(e.target.value)}
              placeholder="Enter Message..."
              name="messages"
              minRows={6}
            />
            <Error>{errors.messages?.message}</Error>
          </RowColTextAreaWrapper>
          <ButtonWrapper>
            <EditButtons>
              <SendButton text="Send" type="submit" />
            </EditButtons>
            <EditButtons>
              <StyledButton
                type="submit"
                text="Register for free"
                onClick={handleSubmit(data => handleRegisterClick(data))}
              />
            </EditButtons>
          </ButtonWrapper>
        </form>
      </Wrapper>
      <Note>
        <span style={{ color: `${Colors.Red}` }}>*</span>free offer limited to
        two projects, register to discover our full pricing
      </Note>
    </BgWrapper>
  );
}

const BgWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1320px;
  font-family: ${Fonts.Sofia};
  margin: auto;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    z-index: -3;
    width: 1320px;
    height: 162px;
    border-radius: 15px;
    background: ${Colors.LightBlue};

    @media (max-width: 1440px) {
      max-width: 100%;
      right: 0;
    }
  }
  @media (max-width: 1024px) {
    max-width: 1080px;
  }
`;

const Wrapper = styled.div`
  width: 1320px;
  height: 652px;
  margin: auto;
  margin: 100px 0 40px 0;
  border-radius: 12px;
  background: ${Colors.White};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 60px;
  @media (max-width: 1440px) {
    max-width: 100%;
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    height: 100%;
    padding: 30px;
  }
`;

const ContactTitle = styled.div`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(18.75)};
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    font-size: ${rem(16.25)};
  }
`;

const RowWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RowColWrapper = styled.div`
  width: calc(33.33% - 15px);
  padding: 15px 0;
  @media (max-width: 768px) {
    width: calc(50% - 15px);
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const RowColTextAreaWrapper = styled(RowColWrapper)`
  width: 100%;
`;

const Text = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const InputLabel = styled(Text)`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled.input`
  font-size: ${rem(11)};
  padding: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  height: 52px;
  width: 380px;
  color: ${Colors.LightSecondGrey};
  ::placeholder {
    color: ${Colors.LightSecondGrey};
    font-size: ${rem(10)};
  }
  :disabled {
    background-color: transparent;
  }
  border-radius: 7px;
  border: 1px solid #d8d8d8;
`;

const EditButtons = styled.div`
  margin: 30px 15px;
  @media (max-width: 425px) {
    margin: 5px 0;
  }
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
  height: auto;
  width: auto;
  padding: 13px 41px;
  @media (max-width: 768px) {
    padding: 8px 31px;
  }
`;

const SendButton = styled(StyledButton)`
  background-color: ${Colors.SkySecondBlue};
  color: ${Colors.Black};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Error = styled.div`
  color: ${Colors.Red};
  margin-top: 10px;
`;
const Note = styled.div`
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export default ContactUs;
