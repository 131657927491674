import styled from 'styled-components';

import { Colors } from '../themes';

// !!!  It's template for mini input-loader until designer creates loader or notification
function DotsLoading() {
  return (
    <Container>
      <DotFlashing />
      <DotFlashing />
      <DotFlashing />
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  background-color: ${Colors.LightGrey};
  opacity: 0.5;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const DotFlashing = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${Colors.DarkBlack};
  animation: dotFlashing 1s infinite linear alternate;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 500ms;
  }
  &:nth-child(1) {
    animation-delay: 1000ms;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${Colors.DarkBlack};
    }
    50%,
    100% {
      background-color: ${Colors.LightGrey};
    }
  }
`;

export default DotsLoading;
