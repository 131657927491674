/* eslint-disable camelcase */
import comment from './Comment.svg';
import received from './Received.svg';
import refused from './Refused.svg';
import nda_sent from './Sent-2.svg';
import nda_signed from './Signed-2.svg';

const RECIPIENT_STATUS = { comment, received, refused, nda_sent, nda_signed };

export default RECIPIENT_STATUS;
