import Slider from 'react-slick';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';

import { LandingPageData } from '../../const';
import { Colors, Fonts, rem } from '../../themes';

function Testimonials() {
  const { testimonials } = LandingPageData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Wrapper id="testimonial">
      <Title>Our Testimonials</Title>
      <SliderContainer>
        <CustomSlider {...settings}>
          {testimonials.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index}>
              <div>
                <BoxWrapper>
                  <Icon>
                    <img src={item.avtar} alt={item.avtar} />
                  </Icon>
                  <AvtarDetails>
                    <Name>{item.avtarName}</Name>
                    <Location>{item.location}</Location>
                  </AvtarDetails>
                  <Details>{item.content}</Details>
                  <StarRatings
                    rating={item.rating}
                    starDimension="20px"
                    starSpacing="6px"
                    starRatedColor="#F2C12B"
                    starEmptyColor="#D8D8D8"
                  />
                </BoxWrapper>
              </div>
            </Box>
          ))}
        </CustomSlider>
      </SliderContainer>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  background: ${Colors.VeryPaleBlue};
  min-height: 636px;
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  @media (max-width: 768px) {
    min-height: 565px;
  }
`;

const SliderContainer = styled.div`
  max-width: 1296px;
  margin: 0 auto;
`;

const CustomSlider = styled(Slider)`
  .slick-list {
    padding: 35px 0;
  }
  .slick-slide {
    padding: 10px;
  }
`;

const Box = styled.div`
  height: 314px;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background: ${Colors.White};
  color: ${Colors.DarkBlack};
  font-size: ${rem(13.75)};
  line-height: 32px;
  padding: 20px;
  position: relative;
  @media (max-width: 425px) {
    min-height: 350px;
  }
`;

const Icon = styled.div`
  margin-bottom: 10px;
  position: absolute;
  top: -10%;
  border: 2px solid ${Colors.White};
  border-radius: 50%;
`;

const AvtarDetails = styled.div`
  margin-top: 40px;
`;

const Name = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(11.25)};
  line-height: normal;
`;

const Location = styled.div`
  color: ${Colors.OffGrey};
  font-size: ${rem(10)};
  line-height: normal;
`;

const Title = styled.div`
  color: ${Colors.DarkBlack};
  text-align: center;
  font-size: ${rem(26.25)};
  line-height: normal;
  padding-top: 80px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: ${rem(20.25)};
    padding-top: 40px;
    margin-bottom: 10px;
  }
`;

const Details = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(10)};
  line-height: 24px;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default Testimonials;
