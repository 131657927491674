import styled from 'styled-components';

import { LandingPageData } from '../../const';
import { Colors, Fonts, rem } from '../../themes';

function WhyChoose() {
  const { whyChooseUs } = LandingPageData;
  return (
    <Wrapper>
      <BoxContainer>
        <Title>Why Choose Us</Title>
        <BoxWrapper>
          {whyChooseUs.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index}>
              <Icon>
                <img src={item.icon} alt={item.icon} />
              </Icon>
              {item.title}
              <Details>{item.content}</Details>
            </Box>
          ))}
        </BoxWrapper>
      </BoxContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #eef5fb;
  min-height: 595px;
`;

const BoxContainer = styled.div`
  max-width: 1296px;
  margin: 0 auto;
`;

const Title = styled.div`
  color: ${Colors.DarkBlack};
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(26.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 80px;
  padding-bottom: 45px;
  @media (max-width: 768px) {
    font-size: ${rem(20.25)};
    padding-top: 40px;
    margin-bottom: 10px;
    padding-bottom: 25px;
  }
`;

const BoxWrapper = styled.div`
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 20px;

  @media (max-width: 1024px) {
    overflow: scroll;
  }
`;

const Box = styled.div`
  height: 404px;
  width: 308px;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
  background: var(--White, #fff);
  color: #000;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 145.455% */
  padding: 20px;
`;

const Icon = styled.div`
  margin-bottom: 10px;
`;

const Details = styled.div`
  color: #606060;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 155.556% */
  margin-top: 15px;
`;
export default WhyChoose;
