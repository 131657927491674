import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components';

import { EventEnum } from '../api';
// import MEMBERS_ACTIVITY from '../assets/svg/MEMBERS_ACTIVITY';
import { HidenBoxHovered } from '../atoms';
import { Colors, Fonts, rem } from '../themes';

const ActiveMessages = {
  none: <></>,
  member_added: (
    <>
      New team members were <span style={{ color: '#31C16B' }}>added</span>:
    </>
  ),
  nda_sent: <>NDA was sent by:</>,
  rejected: <>Recipients rejected the comment</>,
  nda_signed: <>NDA was signed by:</>,
  new_comments_thread: <>New comments thread was added by</>,
  new_comment: <>New comments were added by:</>,
  approved: <>Comment was approved by:</>,
  nda_recalled: <>NDA was recalled by:</>,
  nda_restored: <>NDA was restored by:</>,
  project_archived: <>Project was archived by:</>,
  project_unarchived: <>Project was unarchived by:</>,
  member_removed: (
    <>
      Following team members were{' '}
      <span style={{ color: '#CB3636' }}>removed</span> from the project:
    </>
  ),
  new_project: (
    <>
      New project was <span style={{ color: '#DB722B' }}>created</span> by
    </>
  ),
  nda_opened: <>NDA was opened by:</>,
  recipient_added: <>New recipients were added:</>,
  recipient_removed: <>Recipients were removed:</>,
  comments_thread_deleted: <>Comments thread was deleted by:</>,
  nda_rejected: <>Recipients rejected the NDA</>,
};

type Props = {
  type: EventEnum;
  authorsCount: number;
  sameAuthorCount: number;
  author: string | null;
  time: string;
  className?: string;
};

function numberToWord(num: number): string {
  const words = ['one', 'twice', 'thrice'];
  if (num > 1 && num <= words.length) {
    return words[num - 1];
  }
  return `${num} times`;
}

function Activity({
  type,
  authorsCount,
  sameAuthorCount,
  author,
  time,
  className,
}: Props) {
  const date = formatDistanceToNow(new Date(time), {
    includeSeconds: true,
  });

  let messageColor = '#424242';
  if (type === 'member_added') {
    messageColor = '#D244E9';
  } else if (type === 'member_removed') {
    messageColor = '#8774FF';
  } else if (type === 'new_project') {
    messageColor = '#127BF8';
  }

  const MessageSpan = (
    <span style={{ color: messageColor, marginLeft: '2px' }}>{author}</span>
  );

  const message = (
    <>
      {ActiveMessages[type]}
      {sameAuthorCount > 1
        ? `NDA has been sent ${numberToWord(sameAuthorCount)} by`
        : ''}
      {MessageSpan ?? ''}
      {authorsCount > 1 ? ` +${authorsCount - 1} others` : ''}
    </>
  );

  return (
    <Container className={className}>
      <Message value={message} />

      <Time>{`${date}`}</Time>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 25px 30px;
  border-bottom: 1px solid rgba(2, 48, 97, 0.14);
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7)};
  white-space: nowrap;
`;

const Message = styled(HidenBoxHovered)`
  flex-grow: 2;
  white-space: nowrap;
  font-size: ${rem(10)};
`;

const Time = styled.p`
  margin: 0 0 0 20px;
  font-size: ${rem(8.75)};
`;

export default Activity;
