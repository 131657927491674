import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Errors } from '../const';
import ErrorText from '../molecules/ErrorText';
import { BoxShadow, Colors, Fonts, rem } from '../themes';
import FileInput from './FileInput';

const ImageContainer = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 56px;
  width: 56px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  margin-left: 30px;
  z-index: 2;

  > img {
    height: 20px;
    width: 20px;
    margin: auto;
  }
`;

const ChoiceOption = styled.div`
  position: relative;
  padding-top: 30px;
`;
const Image = styled.img`
  width: 70px;
`;

const StyledFileInput = styled(FileInput)`
  margin: 0 auto;
`;
const FileContainer = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  text-align: center;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: ${Colors.White};
  cursor: pointer;
  border: 0.5px solid ${Colors.LightestGrey};
  border-radius: 15px;
  box-shadow: ${BoxShadow.Base};
  justify-content: center;
  padding: 42px;
  position: relative;
`;

type Props = {
  value: File | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  removeFile: (event: React.MouseEvent<HTMLDivElement>) => void;
  name: string;
  fileRef: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  setSizeError?: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  sizeError?: { [key: string]: boolean };
};

function FileWrapper({
  value,
  onChange,
  removeFile,
  name,
  fileRef,
  placeholder,
  setSizeError,
  sizeError,
}: Props) {
  const [error, setError] = useState('');
  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const clearError = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setSizeError &&
      setSizeError(prev => ({ ...prev, [name as string]: false }));
    setError('');
  };
  return (
    <ChoiceOption>
      <FileContainer onClick={handleClick}>
        <ImageContainer color={Colors.NavyBlue}>
          <Image src={SVG.downloadNDA} alt="From file" />
        </ImageContainer>
        <StyledFileInput
          file={value}
          onChange={event => {
            if (!event.target.files) return;
            if (event.target?.files[0]?.size / 1024 / 1024 > 3) {
              setError(Errors.UploadFileSize);
              removeFile(event);
              setSizeError && setSizeError(prev => ({ ...prev, [name]: true }));
              return;
            }
            onChange(event);
            setError('');
          }}
          removeFile={removeFile}
          name={name}
          inputRef={fileRef}
          placeholder={placeholder}
          clearFile={clearError}
          sizeError={sizeError}
        />
      </FileContainer>
      {error && <Error>{error}</Error>}
    </ChoiceOption>
  );
}

const Error = styled(ErrorText)`
  text-align: center;
`;
export default FileWrapper;
