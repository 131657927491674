import { useState } from 'react';
import styled from 'styled-components';

import { DotsLoading, Input } from '../atoms';
import { Errors } from '../const';
import { handleInputBlur, handleInputChange, isEmptyInput } from '../helpers';
import { SaveOrDiscard } from '../molecules';
import { BoxShadow, Colors, rem } from '../themes';

type Props = {
  isLoading: boolean;
  name: string;
  hasLastName?: boolean;
  confirmEditName: (newName: string) => void;
  cancelEditName: () => void;
};

function EditNameBox({
  isLoading,
  name,
  hasLastName,
  confirmEditName,
  cancelEditName,
}: Props) {
  const [newName, setNewName] = useState({ errorMsg: '', value: name });

  const onSaveClick = () => {
    if (isEmptyInput(newName, setNewName, Errors.EnterName)) return;

    if (hasLastName) {
      const [, lastName] = newName.value.split(' ');
      if (isEmptyInput(lastName, setNewName, Errors.EnterLastName)) return;
    }

    confirmEditName(newName.value);
  };

  return (
    <EditForm>
      <InputBox>
        {isLoading && <DotsLoading />}
        <StyledInput
          disabled={isLoading}
          value={newName.value}
          onChange={handleInputChange(setNewName)}
          onBlur={handleInputBlur(newName, setNewName)}
          placeholder="Name"
          error={newName.errorMsg}
        />
      </InputBox>

      <SaveOrDiscard
        onSaveClick={onSaveClick}
        onDiscardClick={cancelEditName}
      />
    </EditForm>
  );
}

const EditForm = styled.div`
  width: 200px;
  padding: 10px;
  background-color: ${Colors.White};
  border-radius: 5px;
  box-shadow: ${BoxShadow.Secondary};
`;

const InputBox = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledInput = styled(Input)`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${Colors.WeakGrey};
  font-size: ${rem(12)};
  text-align: center;
`;

export default EditNameBox;
