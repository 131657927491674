import { AxiosResponse } from 'axios';

import api from '../authApi';

const getTeaserPdf = (id: number): Promise<AxiosResponse> =>
  api.get(`/projects/projects/${id}/get_teaser_pdf/`, {
    responseType: 'blob',
  });
const teaser = {
  getTeaserPdf,
};

export default teaser;
