import { ReactElement } from 'react';
import styled from 'styled-components';

import SVG from '../../assets/svg';
import { Colors, Fonts, rem, removeScrollStyles } from '../../themes';

type Props = {
  children: ReactElement | ReactElement[];
  title?: string;
  className?: string;
  onCloseButtonClick?: () => void;
};

function ModalBox({ children, title, className, onCloseButtonClick }: Props) {
  return (
    <Container className={className}>
      {Boolean(title) && <Title>{title}</Title>}
      <Content>{children}</Content>
      {onCloseButtonClick && (
        <CloseImg
          onClick={onCloseButtonClick}
          src={SVG.rejected}
          alt="close button"
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 600px;
  max-height: 90vh;
  background-color: ${Colors.White};
  border-radius: 18px;
  overflow: scroll;

  ${removeScrollStyles}
`;

const Title = styled.p`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(18)};
  text-align: center;
  color: ${Colors.WeakBlack};
  margin: 0;
  padding: 18px;
  border-bottom: 1px solid ${Colors.LightGrey};
`;

const Content = styled.div`
  padding: 10px;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 150ms linear;

  &:hover {
    transform: scale(1.1);
  }
`;

export default ModalBox;
