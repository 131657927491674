import { Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { BoxShadow, Colors, Fonts, rem } from '../themes';

type Props = {
  value: React.ReactNode | string | null;
  width?: string;
  popoverWidth?: string;
  className?: string;
};

function HidenBoxHovered({ value, width, popoverWidth, className }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isEllipsisActive = (e: HTMLElement) => {
    if (value === '-') return true;
    return e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight;
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <DataContainer
        className={className}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        width={width ?? '100%'}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {value}
      </DataContainer>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={anchorEl && isEllipsisActive(anchorEl) ? open : false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <PopoverBox width={popoverWidth}>
          {value === '-' ? 'No activities yet!' : value}
        </PopoverBox>
      </Popover>
    </>
  );
}

const DataContainer = styled.div<{ width: string }>`
  width: unset;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopoverBox = styled.div<{ width?: string }>`
  padding: 10px;
  width: ${props => props.width ?? '100%'};
  height: fit-content;
  background-color: ${Colors.White};
  border-radius: 5px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8)};
  color: ${Colors.TextGrey};
  box-shadow: ${BoxShadow.Secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 100000;
`;

export default HidenBoxHovered;
