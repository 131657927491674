import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';

import { me as apiMe, UserData } from '../api';
import { ButtonEllipse, DotsLoading } from '../atoms';
import { dataURItoImageFile } from '../helpers';
import { useNotification } from '../hooks';
import { BoxShadow, Colors, rem, rootBorderRadius } from '../themes';

type Props = {
  className?: string;
  updateUser?: (newUser: UserData) => void;
};

function Signature({ className, updateUser }: Props) {
  const { showError } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const sigCanvas = useRef<SignatureCanvas | null>(null);

  const updateUserSign = async () => {
    if (isLoading) return;
    if (!sigCanvas.current) return;

    const imageSrc = sigCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL('image/png');
    const newSign = await dataURItoImageFile(imageSrc);

    setIsLoading(true);
    const form = new FormData();
    form.append('sign', newSign);

    apiMe
      .updateUserSign(form)
      .then(({ data }) => {
        sigCanvas.current?.clear();
        setIsLoading(false);
        updateUser && updateUser(data);
      })
      .catch(({ data }) => {
        setIsLoading(false);
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  };

  return (
    <Container className={className}>
      <SignatureBox>
        {isLoading && <DotsLoading />}
        <SignatureCanvas
          ref={sigCanvas}
          penColor={Colors.DarkGrey}
          canvasProps={{ height: 140, width: 400 }}
        />
      </SignatureBox>

      <ButtonBox>
        <CleanButton onClick={() => sigCanvas.current?.clear()} text="Clean" />
        <SaveButton disabled={isLoading} onClick={updateUserSign} text="Save" />
      </ButtonBox>
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  border: 0.5px solid ${Colors.MiddleGrey};
  border-radius: ${rootBorderRadius};
  box-shadow: ${BoxShadow.Base};
`;

const SignatureBox = styled.div`
  position: relative;
  width: fit-content;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const CleanButton = styled(ButtonEllipse)`
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-size: ${rem(9)};
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  color: ${Colors.TextGrey};
`;

const SaveButton = styled(ButtonEllipse)`
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-size: ${rem(9)};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
`;

export default Signature;
