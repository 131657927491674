import Geosuggest, { Suggest } from 'react-geosuggest';
import styled from 'styled-components';

import ErrorText from '../molecules/ErrorText';
import {
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../themes';

type Props = {
  location: {
    value: string;
    errorMsg: string;
  };
  setLocation: (value: any) => void;
  placeholder?: string;
};

const GeoInput = ({ location, setLocation, placeholder }: Props) => {
  const onSuggestSelect = (place: Suggest) => {
    if (place) {
      setLocation({ ...location, value: place.label });
    }
  };

  return (
    <>
      <StyledGeosuggest
        placeholder={placeholder ?? 'Location'}
        onChange={value => setLocation({ ...location, value })}
        onSuggestSelect={onSuggestSelect}
        location={new google.maps.LatLng(53.558572, 9.9278215)}
        radius={20}
        maxLength={512}
        initialValue={location.value}
      />
      {location.errorMsg && <ErrorText>{location.errorMsg}</ErrorText>}
    </>
  );
};

export const StyledGeosuggest = styled(Geosuggest)`
  position: relative;
  width: 100%;
  .geosuggest__input {
    width: 100%;
    height: 52px;
    padding: 7px;

    outline: none;
    border: none;

    border-radius: 6px;
    background-color: ${Colors.White};
    border: 0.5px solid ${Colors.WeakGrey};
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.DarkBlack};
    font-weight: 300;

    ::placeholder {
      font-family: ${Fonts.Sofia};
      font-size: ${rem(11)};
      color: ${Colors.StrongGrey};
    }
  }

  .geosuggest__suggests {
    top: 100%;
    left: 0;
    right: 0;
    max-height: 22em;
    padding: 0;
    margin-top: -8px;

    position: absolute;
    overflow-y: auto;
    transition: max-height 0.2s, border 0.2s;
    text-overflow: ellipsis;
    z-index: 100;

    list-style: none;
    background: #fff;
    border: 1px solid ${Colors.WeakGrey};
    border-radius: 0px 0px 10px 10px;
    border-top-width: 0;

    ${removeScrollStyles}
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    padding: 0.5em 0.65em;

    cursor: pointer;
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.DarkBlack};
    font-weight: 300;
    border-radius: ${rootBorderRadius};
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background-color: ${Colors.LightBlue};
    border-radius: ${rootBorderRadius};
    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item--active {
    background-color: ${Colors.LightBlue};

    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background-color: ${Colors.LightBlue};
    border-radius: ${rootBorderRadius};
    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item__matched-text {
    font-weight: 300;
  }
`;

export default GeoInput;
