import { useMemo } from 'react';

const usePagination = (totalPages: number) =>
  useMemo(
    () =>
      Array(totalPages)
        .fill(0)
        .map((_, index) => index + 1),
    [totalPages],
  );

export default usePagination;
