/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { company as apiCompany } from '../../../api';
import { GeoInput } from '../../../atoms';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  isEmptyInput,
  setInitialValue,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import { selectors, updateCompaty } from '../../../store';
import { Colors, rem } from '../../../themes';
import {
  CancelButton,
  EditButtons,
  InputLabel,
  InputValue,
  InputWrapper,
  RowWrapper,
  StyledButton,
  StyledInput,
} from './MyProfile';

function MyCompany() {
  const { showError } = useNotification();
  const { company, isMember } = useSelector(selectors.user);

  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const [companyName, setCompanyName] = useState(
    setInitialValue(company?.name ?? ''),
  );
  const [companyAddress, setCompanyAddress] = useState(
    setInitialValue(company?.address ?? ''),
  );
  const [companyRegNumber, setCompanyRegNumber] = useState(
    setInitialValue(company?.registrationNumber ?? ''),
  );
  const [companyVat, setCompanyVat] = useState(
    setInitialValue(company?.vat ?? ''),
  );

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(companyName, setCompanyName, Errors.EnterField)) {
      isValid = false;
    }

    if (isEmptyInput(companyAddress, setCompanyAddress, Errors.EnterField)) {
      isValid = false;
    }

    return isValid;
  };

  const editCompany = () => {
    if (!company) return;

    if (!validate()) return;

    apiCompany
      .updateCompany(company.id, {
        name: companyName.value,
        address: companyAddress.value,
        registrationNumber: companyRegNumber.value,
        vat: companyVat.value,
      })
      .then(({ data }) => {
        dispatch(updateCompaty(data));
        setEditMode(false);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.name, setCompanyName);
        apiFieldErrorHandler(data.address, setCompanyAddress);
        apiFieldErrorHandler(data.registrationNumber, setCompanyRegNumber);
        apiFieldErrorHandler(data.vat, setCompanyVat);
      });
  };

  const cancelEdit = () => {
    setCompanyName(setInitialValue(company?.name ?? ''));
    setCompanyAddress(setInitialValue(company?.address ?? ''));
    setCompanyRegNumber(setInitialValue(company?.registrationNumber ?? ''));
    setCompanyVat(setInitialValue(company?.vat ?? ''));

    setEditMode(false);
  };

  return (
    <>
      <RowWrapper>
        <RowColWrapper>
          <InputLabel>Company Name</InputLabel>
          <MyCompanyInputWrapper>
            <StyledInput
              value={companyName.value}
              onChange={handleInputChange(setCompanyName)}
              onBlur={handleInputBlur(companyName, setCompanyName)}
              placeholder="-"
              error={companyName.errorMsg}
              disabled={!editMode}
            />
          </MyCompanyInputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Address</InputLabel>
          <MyCompanyInputWrapper isBorder={!editMode && !companyAddress.value}>
            {editMode ? (
              <GeoSuggetionInput
                location={companyAddress}
                setLocation={setCompanyAddress}
              />
            ) : companyAddress.value ? (
              <InputValueText>
                <AddressValue>{companyAddress.value}</AddressValue>
              </InputValueText>
            ) : (
              <Empty>-</Empty>
            )}
          </MyCompanyInputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>Registration number</InputLabel>
          <MyCompanyInputWrapper>
            <StyledInput
              value={companyRegNumber.value}
              onChange={handleInputChange(setCompanyRegNumber)}
              onBlur={handleInputBlur(companyRegNumber, setCompanyRegNumber)}
              placeholder="-"
              error={companyRegNumber.errorMsg}
              disabled={!editMode}
            />
          </MyCompanyInputWrapper>
        </RowColWrapper>
        <RowColWrapper>
          <InputLabel>VAT</InputLabel>
          <MyCompanyInputWrapper>
            <StyledInput
              value={companyVat.value}
              onChange={handleInputChange(setCompanyVat)}
              onBlur={handleInputBlur(companyVat, setCompanyVat)}
              placeholder="-"
              disabled={!editMode}
              error={companyVat.errorMsg}
            />
          </MyCompanyInputWrapper>
        </RowColWrapper>
      </RowWrapper>
      <EditButtons>
        {editMode ? (
          <>
            <CancelButton text="Cancel" onClick={cancelEdit} />
            <StyledButton text="Save" onClick={editCompany} />
          </>
        ) : (
          <StyledButton
            disabled={isMember}
            text="Edit"
            onClick={() => setEditMode(true)}
          />
        )}
      </EditButtons>
    </>
  );
}

const Empty = styled.div`
  font-size: ${rem(11)};
  padding: 14px;
  text-align: left;
  width: 100%;
`;

const MyCompanyInputWrapper = styled(InputWrapper)<{ isBorder?: boolean }>`
  flex-direction: column;
  border: ${props =>
    props.isBorder ? `0.5px solid ${Colors.WeakGrey}` : 'none'};
  border-radius: 6px;
`;

const RowColWrapper = styled.div`
  width: calc(50% - 15px);
  padding: 15px 0;
`;

const GeoSuggetionInput = styled(GeoInput)`
  height: 52px;
  display: flex;
  align-items: center;
`;

const InputValueText = styled(InputValue)`
  height: 52px;
  display: flex;
  align-items: center;
  overflow: auto;
`;

const AddressValue = styled.div`
  height: 100%;
`;

export default MyCompany;
