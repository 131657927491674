/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  // company as apiCompany,
  EventType,
  me as apiMe,
  members as apiMember,
  MemberType,
  OneProjectType,
  projectBranches as apiProjectBranches,
  ProjectBranchType,
  projects as apiProjects,
  TemplatesPaginationLimit as PaginationLimit,
} from '../../api';
import SVG from '../../assets/svg';
import {
  FilterInput,
  Modal,
  ModalBox,
  Pagination,
  SelectOption,
  TabNav,
} from '../../atoms';
import CommonModal from '../../atoms/Modal/CommonModal';
import { Errors, Messages, projectOptions, ProjectsEnum } from '../../const';
import { getPagesCount } from '../../helpers/functions';
import {
  useDebounce,
  useNotification,
  usePagination,
  usePermissions,
} from '../../hooks';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import ChooseSignatureModal from '../../organisms/ChooseSignatureModal';
import { Routes } from '../../routes';
import { refreshUser, selectors } from '../../store';
import { PageWrapper } from '../../templates';
import { Fonts, rem } from '../../themes';
import { ItemsLayout } from '../../themes/StyledComponents';
import { AddNewProject, OneProject } from './components';

interface UnreadCountsType {
  [key: string]: number;
}

export type FiltersType = {
  page: number;
  search?: string;
};

function Projects() {
  const { canViewProject, canAddProject } = usePermissions();
  const { showError, showSuccess, showInfo } = useNotification();
  const debouncedSearch = useDebounce(getAllProjects, 500);

  const { product: currentUserPlan } = useSelector(selectors.subscription);

  const dispatch = useDispatch();
  const user = useSelector(selectors.user);
  const history = useHistory();
  const maxAllowedProjects = useSelector(selectors.maxAllowedProjects);

  const [isShowInitMessage, setIsShowInitMessage] = useState(false);

  const [userMembers, setUserMembers] = useState<MemberType[]>([]);
  const [projects, setProjects] = useState<OneProjectType[] | null>(null);
  const [allProjects, setAllProjects] = useState<OneProjectType[] | null>(null);

  const [option, setOption] = useState<SelectOption | null>(projectOptions[0]);
  const [searchValue, setSearchValue] = useState('');

  const [isOpenUpdateSign, setIsOpenUpdateSign] = useState(false);
  const [isAdditionalProject, setIsAdditionalProject] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [unreadEvents, setUnreadEvents] = useState<EventType[]>([]);

  const [unreadCounts, setUnreadCounts] = useState<UnreadCountsType>({});

  const [branches, setBranches] = useState<ProjectBranchType[]>([]);
  const [showedBranches, setShowedBranches] = useState<ProjectBranchType[]>([]);

  // const [companyInfo, setCompanyInfo] = useState<GetCompanyOwnerType | null>(
  //   null,
  // );

  const companyId = user.company?.id;

  const getSelectedFilter = (filter: string, company: number) => {
    let selectedFilter = '';

    switch (filter) {
      case ProjectsEnum.All:
        selectedFilter = '';
        break;
      case ProjectsEnum.Initiated:
        selectedFilter = `&company=${company}&is_archived=false`;
        break;
      case ProjectsEnum.Received:
        selectedFilter = `&company!=${company}&is_archived=false`;
        break;
      case ProjectsEnum.Archived:
        selectedFilter = '&is_archived=true';
        break;

      default:
        selectedFilter = '&is_archived=false';
        break;
    }

    return selectedFilter;
  };

  function getAllProjects({ page, search }: FiltersType) {
    if (companyId && option) {
      const searchTerm = search ? `&search=${search}` : '';
      const filter = getSelectedFilter(option.value, companyId);

      setIsLoading(true);
      apiProjects
        .getAllProjects(page, filter, searchTerm)
        .then(({ data }) => {
          if (data.next || data.previous) {
            setTotalPages(getPagesCount(data.count, PaginationLimit));
          } else {
            setTotalPages(0);
          }
          return data.results;
        })
        .then(setProjects)
        .finally(() => setIsLoading(false));
    }
  }

  useEffect(() => {
    apiProjectBranches
      .getAllProjectBranches()
      .then(({ data }) => setBranches(data));

    apiMember.getAllMembers().then(({ data }) => {
      setUserMembers(data);
    });

    apiProjects.getProjects().then(({ data }) => {
      setAllProjects(data);
    });
  }, []);

  // useEffect(() => {
  //   apiCompany.getCompany().then(({ data }) => setCompanyInfo(data[0]));
  // }, []);

  useEffect(() => {
    setCurrentPage(1);
    setSearchValue('');
  }, [option]);

  useEffect(() => {
    if (!canViewProject) return;

    setIsLoading(true);

    if (companyId && option) {
      getAllProjects({ page: currentPage });
    }
  }, [canViewProject, currentPage, option, user]);

  useEffect(() => {
    if (
      Array.isArray(projects) &&
      projects.length === 0 &&
      option?.value === ProjectsEnum.Active &&
      !isShowInitMessage
    ) {
      showSuccess(Messages.InitProjects);
      setIsShowInitMessage(true);
    }
  }, [isShowInitMessage, option?.value, projects]);

  useEffect(() => {
    searchValue ? setIsShowInitMessage(true) : setIsShowInitMessage(false);
  }, [searchValue]);

  useEffect(() => {
    if (!projects) return;

    const projectsIds = projects.map(project => project.id);
    setShowedBranches(
      branches.filter(branch => !projectsIds.includes(branch.project)),
    );
  }, [branches, projects]);

  useEffect(() => {
    const branchesIds = showedBranches.map(branch => branch.id);
    const projectsIds = projects ? projects.map(project => project.id) : [];

    const unread = unreadEvents.reduce<UnreadCountsType>((acc, event) => {
      if (event.projectBranch && branchesIds.includes(event.projectBranch)) {
        const property = `branch${event.projectBranch}`;
        if (acc[property]) {
          return { ...acc, [property]: acc[property] + 1 };
        }
        return { ...acc, [property]: 1 };
      }

      if (event.project && projectsIds.includes(event.project)) {
        const property = `project${event.project}`;
        if (acc[property]) {
          return { ...acc, [property]: acc[property] + 1 };
        }
        return { ...acc, [property]: 1 };
      }

      return acc;
    }, {});

    setUnreadCounts(unread);
  }, [unreadEvents, showedBranches, projects]);

  const addProject = () => {
    if (!user.sign && !user.isMember) {
      setIsOpenUpdateSign(true);
      return;
    }

    const ownProjects = allProjects?.filter(project => project.created);

    if (ownProjects && maxAllowedProjects <= ownProjects.length) {
      setIsAdditionalProject(true);
      showError(Errors.NotEnoughProjects);
      return;
    }

    if (currentUserPlan.status === null) {
      if (ownProjects && ownProjects.length === 1) {
        showInfo(Messages.ProjectPlanConfirmation);
      }
    }

    // if (companyInfo && !companyInfo.restOfAllowedProjectsUnderLimit) {
    //   showError(Errors.ProjectsLimit);
    //   return;
    // }

    // canAddProject && setModalIsOpen(true);
    canAddProject && history.push(Routes.addProject);
  };

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    if (companyId && option) {
      debouncedSearch({ page: currentPage, search: e.target.value });
    }
  };

  const refreshSignature = () => {
    apiMe
      .getMySign()
      .then(({ data }) => URL.createObjectURL(data))
      .then(newImageUrl => {
        dispatch(refreshUser({ ...user, sign: newImageUrl }));
      })
      .catch(error => {
        showError(error.message);
      })
      .finally(() => {
        setIsOpenUpdateSign(false);
      });
  };

  const paginationPages = usePagination(totalPages);

  const closeModal = () => {
    history.push(Routes.projects);
    setIsAdditionalProject(false);
  };

  return (
    <PageWrapper>
      <ChooseSignatureModal
        isOpenChoiceModal={isOpenUpdateSign}
        setIsOpenChoiceModal={setIsOpenUpdateSign}
        refreshSignature={refreshSignature}
        userName={`${user.firstName} ${user.lastName}`}
        title="You do not have a signature saved yet, please add one"
        onAddSignature={() => {
          history.push(Routes.addProject);
        }}
      />

      <Modal
        modalIsOpen={isAdditionalProject}
        setModalIsOpen={setIsAdditionalProject}
      >
        <ModalBox
          title={
            currentUserPlan.product?.name
              ? 'Buy Additional Project'
              : 'Subscribe'
          }
          onCloseButtonClick={() => setIsAdditionalProject(false)}
        >
          <CommonModal
            closeModal={closeModal}
            isAdditionalProject={isAdditionalProject}
          />
        </ModalBox>
      </Modal>

      <ContentWrapper>
        <SideMenu setAllUnreadEvents={setUnreadEvents} />

        <TopSearchBlock>
          <CustomTab
            options={projectOptions}
            value={option}
            onChange={setOption}
          />
          <StyledFilterInput
            value={searchValue}
            onChange={handleSearchValue}
            placeholder="Filter projects by name..."
          />
        </TopSearchBlock>

        <AddProjectWrapper>
          <TabTitle>{option && `${option.label} Projects`}</TabTitle>
          <AddNewProject disabled={!canAddProject} onClick={addProject}>
            <img
              src={SVG.addProject}
              alt="Project"
              style={{ marginRight: '10px' }}
            />
            Add project
          </AddNewProject>
        </AddProjectWrapper>
        <ItemsLayout>
          {!isLoading ? (
            <>
              {projects?.map(project => (
                <OneProject
                  id={project.id}
                  key={project.id}
                  name={project.name}
                  created={project.created}
                  userMembers={userMembers}
                  projectMembers={project.members}
                  description={project.summary}
                  unreadEvents={unreadCounts[`project${project.id}`] ?? 0}
                  projectBranches={project.projectBranches}
                  option={option}
                />
              ))}
            </>
          ) : (
            <Loader />
          )}
        </ItemsLayout>

        {paginationPages.length !== 0 && (
          <Pagination
            onPageClick={page => setCurrentPage(page)}
            paginationPages={paginationPages}
            currentPage={currentPage}
            disabled={isLoading}
          />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
}

const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const AddProjectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TabTitle = styled.div`
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
`;

const TopSearchBlock = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const CustomTab = styled(TabNav)`
  width: 100%;
`;
const StyledFilterInput = styled(FilterInput)`
  width: 100%;
  height: 55px;
  margin-bottom: 20px;
`;

export default Projects;
