import styled from 'styled-components';

import { ButtonEllipse } from '../../../atoms';
import { BoxShadow, Colors, rem, rootBorderRadius } from '../../../themes';

type Props = {
  isNewThreadMode: boolean;
  setNewPointerMode: () => void;
  removeNewPointerMode: () => void;
};

function NewThreadButton({
  isNewThreadMode,
  setNewPointerMode,
  removeNewPointerMode,
}: Props) {
  const toggleNewThreadMode = () => {
    if (isNewThreadMode) {
      removeNewPointerMode();
    } else {
      setNewPointerMode();
    }
  };

  return <Button onClick={toggleNewThreadMode} text="Comment +" />;
}

const Button = styled(ButtonEllipse)`
  width: 140px;

  cursor: pointer;
  background-color: ${Colors.White};
  box-shadow: ${BoxShadow.Secondary};
  color: ${Colors.TextGrey};
  font-size: ${rem(12)};
  transition: all 150ms linear;
  border: 1px solid ${Colors.LightBlue};
  border-radius: ${rootBorderRadius};
`;

export default NewThreadButton;
