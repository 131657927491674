import GeoSuggest, { Suggest } from '@ubilabs/react-geosuggest';
import { useState } from 'react';
import styled from 'styled-components';

import ErrorText from '../molecules/ErrorText';
import {
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../themes';

type Props = {
  location: {
    value: string;
    errorMsg: string;
  };
  country: {
    value: string;
    errorMsg: string;
  };
  setCountry: (value: any) => void;
  setLocation: (value: any) => void;
  placeholder?: string;
  required?: boolean;
};

const AuthGeoInput = ({
  location,
  setLocation,
  placeholder,
  required,
  country,
  setCountry,
}: Props) => {
  const [onFocus, SetOnFocus] = useState(false);

  const onSuggestSelect = (place: any) => {
    if (place) {
      const countryCode = place.gmaps.address_components.find(
        (component: any) => component.types.includes('country'),
      )?.short_name;
      setLocation({ ...location, value: place.label, countryCode });
      setCountry({ ...country, value: countryCode });
    }
  };

  return (
    <Wrapper onFocus={() => SetOnFocus(true)} onBlur={() => SetOnFocus(false)}>
      <StyledGeosuggest
        placeholder={placeholder ?? 'Location'}
        onChange={value => setLocation({ errorMsg: '', value })}
        onSuggestSelect={onSuggestSelect}
        location={new google.maps.LatLng(53.558572, 9.9278215)}
        radius={20}
        // maxLength={512}
        initialValue={location.value}
        className={onFocus ? 'onFocusL' : ''}
      />
      <span>
        Location
        {required ? <Required>*</Required> : null}
      </span>
      {location.errorMsg && <ErrorText>{location.errorMsg}</ErrorText>}
    </Wrapper>
  );
};

const Wrapper = styled.fieldset`
  position: relative;
  padding: 0;
  border: 0;
  > span {
    color: rgba(0, 0, 0, 0.6);
    font-family: ${Fonts.Roboto};
    font-size: ${rem(7.5)};
    font-style: normal;
    font-weight: 300;
    line-height: 1.43rem;
    margin-left: 12px;
    background-color: ${Colors.SkyBlue};
    position: absolute;
    z-index: 1;
    top: -10px;
    padding: 0 5px;
    display: flex;
  }
  > .onFocusL + span {
    color: ${Colors.LightBlue};
  }
`;

const Required = styled.div`
  color: ${Colors.Red};
  margin-left: 2px;
`;

const StyledGeosuggest = styled(GeoSuggest)`
  position: relative;
  .geosuggest__input {
    width: 100%;
    outline: none;
    border: 1px solid ${Colors.LightForthGrey};
    background-color: ${Colors.SkyBlue};
    font-size: ${rem(10)};
    padding: 18.5px 14px 16.5px 14px;
    font-family: ${Fonts.Roboto};
    color: ${Colors.DarkBlack};
    font-weight: 200;
    border-radius: 4px;
    ::placeholder {
      font-family: ${Fonts.Sofia};
      font-size: ${rem(11)};
      color: ${Colors.StrongGrey};
    }
    &:focus {
      border-color: ${Colors.LightBlue};
      color: ${Colors.LightBlue};
    }
  }

  .geosuggest__suggests {
    top: 100%;
    left: 0;
    right: 0;
    max-height: 22em;
    padding: 0;
    margin-top: -8px;

    position: absolute;
    overflow-y: auto;
    transition: max-height 0.2s, border 0.2s;
    text-overflow: ellipsis;
    z-index: 100;

    list-style: none;
    background: ${Colors.White};
    border: 1px solid ${Colors.WeakGrey};
    border-radius: 0px 0px 10px 10px;
    border-top-width: 0;

    ${removeScrollStyles}
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    padding: 0.5em 0.65em;

    cursor: pointer;
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.DarkBlack};
    font-weight: 300;
    border-radius: ${rootBorderRadius};
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background-color: ${Colors.LightBlue};
    border-radius: ${rootBorderRadius};
    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item--active {
    background-color: ${Colors.LightBlue};

    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background-color: ${Colors.LightBlue};
    border-radius: ${rootBorderRadius};
    color: ${Colors.White};
    transition: 0.2s ease-in-out;
  }
  .geosuggest__item__matched-text {
    font-weight: 300;
  }
`;

export default AuthGeoInput;
