import styled from 'styled-components';

import { Colors, Fonts, rem } from '../../themes';

function NavigationLinks() {
  return (
    <Wrapper>
      <Link href="#">
        <Title>Home</Title>
      </Link>
      <Separator />
      <Link href="#features">
        <Title>Features</Title>
      </Link>
      <Separator />
      <Link href="#testimonial">
        <Title>Testimonial</Title>
      </Link>
      <Separator />
      <Link href="#contact-us">
        <Title>Contact Us</Title>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Link = styled.a`
  padding: 12px 0;

  position: relative;
  display: flex;
  align-items: center;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.3)};
  color: ${Colors.LightTextGrey};
  text-decoration: none;
  margin: 0 25px;
  transition: all 0.6s ease;

  & > *:first-child {
    margin-right: 10px;
    width: 90px;
  }
  &:before {
    content: '';
    width: 0px;
    height: 1px;
    background-color: ${Colors.White};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.6s ease;
  }
  &.active:before,
  &:hover:before {
    width: 30px;
  }
  &.active,
  &:hover {
    color: ${Colors.White};
  }
`;

const Title = styled.div`
  font-size: ${rem(11.3)};
`;

const Separator = styled.div`
  width: 100%;
  height: 1.75px;
`;

export default NavigationLinks;
