import { AxiosResponse } from 'axios';

import api from '../authApi';
import { ContentTypes } from '../guestApi';
import { UnlimitedList } from '../types';
import {
  GetAllTemplatesList,
  GetTemplatesList,
  TemplatesPaginationLimit as Limit,
  TemplateType,
  UpdateTemplateRequest,
} from './types';

const getAllTemplates = (): Promise<AxiosResponse<GetAllTemplatesList>> =>
  api.get(`/projects/nda_templates/?limit=${UnlimitedList}`);

const getTemplates = (
  page: number,
  projectId: string | undefined,
): Promise<AxiosResponse<GetTemplatesList>> =>
  api.get(
    `/projects/nda_templates/?limit=${Limit}&offset=${(page - 1) * Limit}${
      projectId ? `&projects=${projectId}` : ''
    }`,
  );

const getTemplatesPdf = (id: number): Promise<AxiosResponse> =>
  api.get(`/projects/nda_templates/${id}/get_nda_template_pdf/`, {
    responseType: 'blob',
  });

const createOneTemplate = (
  form: FormData,
): Promise<AxiosResponse<TemplateType>> =>
  api.post('/projects/nda_templates/', form, {
    headers: {
      'Content-Type': ContentTypes.formData,
    },
  });

const updateTemplate = (
  id: number,
  body: UpdateTemplateRequest,
): Promise<AxiosResponse<TemplateType>> =>
  api.patch(`/projects/nda_templates/${id}/`, body);

const deleteTemplate = (id: number): Promise<AxiosResponse> =>
  api.delete(`/projects/nda_templates/${id}/`);

const getTemplateImagePreview = (id: number): Promise<AxiosResponse> =>
  api.get(`/projects/nda_templates/${id}/get_preview/`, {
    responseType: 'arraybuffer',
  });

const templates = {
  getAllTemplates,
  getTemplates,
  getTemplatesPdf,
  createOneTemplate,
  updateTemplate,
  deleteTemplate,
  getTemplateImagePreview,
};

export default templates;
