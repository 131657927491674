import { useMachine } from '@xstate/react';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { assign, createMachine } from 'xstate';

import {
  contacts as apiContacts,
  ContactType,
  ErrorResponse,
  nda as apiNda,
  OneNdaType,
  OneSignerGroup,
  projectBranches as apiProjectBranches,
  ProjectBranchType,
  signers as apiSigners,
  templates as apiTemplates,
  TemplateType,
} from '../../../../api';
import SVG from '../../../../assets/svg';
import { ButtonEllipse, DotsLoading, Input, ModalBox } from '../../../../atoms';
import { Errors } from '../../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../../../helpers';
import { useNotification, usePermissions } from '../../../../hooks';
import { Contacts } from '../../../../molecules';
import { AddNda, SendMailForm } from '../../../../organisms';
import UploadNda from '../../../../organisms/AddNda/UploadNda';
import { Colors, Fonts, rem } from '../../../../themes';
import { ContextTable } from '../Table';
import ChoiceExistingGroup from './ChoiceExistingGroup';
import CreateGroupBlock from './CreateGroupBlock';
import EditGroupBlock from './EditGroupBlock';

export enum CurrentForm {
  New = 'new',
  Existing = 'existing',
  EditGroup = 'editCreatedGroup',
  FinalForm = 'finalForm',
  AddNdaForm = 'addNdaForm',
  SendMail = 'sendMail',
}

const choiseMachine = createMachine({
  id: 'currentForm',
  initial: CurrentForm.New,
  context: { prevForm: CurrentForm.New },
  states: {
    [CurrentForm.New]: {},
    [CurrentForm.Existing]: {},
    [CurrentForm.EditGroup]: {},
    [CurrentForm.FinalForm]: {},
    [CurrentForm.AddNdaForm]: {},
    [CurrentForm.SendMail]: {},
  },
  on: {
    [CurrentForm.New]: {
      target: CurrentForm.New,
      actions: assign({ prevForm: () => CurrentForm.New }),
    },
    [CurrentForm.Existing]: {
      target: CurrentForm.Existing,
      actions: assign({ prevForm: () => CurrentForm.Existing }),
    },
    [CurrentForm.EditGroup]: {
      target: CurrentForm.EditGroup,
      actions: assign({ prevForm: () => CurrentForm.EditGroup }),
    },
    [CurrentForm.FinalForm]: {
      target: CurrentForm.FinalForm,
    },
    [CurrentForm.AddNdaForm]: {
      target: CurrentForm.AddNdaForm,
    },
    [CurrentForm.SendMail]: {
      target: CurrentForm.SendMail,
    },
  },
});

type Props = {
  updateTable: () => void;
  closeModal: () => void;
  projectId: number;
  projectNda: OneNdaType | null;
  projectName: string | undefined;
};

function AddProjectBranch({
  updateTable,
  closeModal,
  projectId,
  projectNda,
  projectName,
}: Props) {
  const { showError } = useNotification();
  const { canAddProjectbranch, canViewSignergroup } = usePermissions();
  const { updateDashboard } = useContext(ContextTable);

  const [{ value: currentForm, context: machineContext }, sendChoise] =
    useMachine(choiseMachine);

  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [allGroups, setAllGroups] = useState<OneSignerGroup[]>([]);

  const [name, setName] = useState(initialValue);
  const [contactGroup, setContactGroup] = useState<OneSignerGroup | null>(null);

  const [ndaName, setNdaName] = useState('');
  const [ndaTemplate, setNdaTemplate] = useState<TemplateType | null>(null);
  const [fileNda, setFileNda] = useState<File | null>(null);

  const [templatesLength, setTemplatesLength] = useState(0);

  const [newBranch, setNewBranch] = useState<ProjectBranchType | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    apiContacts.getAllContact().then(({ data }) => setAllContacts(data));
  }, []);

  useEffect(() => {
    apiTemplates
      .getAllTemplates()
      .then(({ data }) => setTemplatesLength(data.length));
  }, []);

  useEffect(() => {
    canViewSignergroup &&
      apiSigners.getSignersGroups().then(({ data }) => setAllGroups(data));
  }, [canViewSignergroup]);

  useEffect(() => {
    if (currentForm === CurrentForm.FinalForm && contactGroup) {
      setName({
        value: contactGroup.name,
        errorMsg: '',
      });
    }
  }, [currentForm, contactGroup]);

  const createNewBranch = async (): Promise<boolean> => {
    if (!canAddProjectbranch) return false;
    if (isEmptyInput(name, setName, Errors.EnterName)) return false;
    if (contactGroup === null) return false;

    try {
      const { data: createdBranch } =
        await apiProjectBranches.createProjectBranch({
          name: name.value,
          project: projectId,
          signerGroups: [contactGroup.id],
          inheritNdaFromProject:
            !(fileNda || ndaTemplate) && Boolean(projectNda),
        });

      setNewBranch(createdBranch);

      if (fileNda || ndaTemplate) {
        try {
          const formNda = new FormData();
          formNda.append('project_branch', `${createdBranch.id}`);
          formNda.append('name', ndaName);

          if (fileNda) {
            formNda.append('file', fileNda);
          }
          if (ndaTemplate) {
            formNda.append('template', ndaTemplate.id.toString());
            formNda.append('file', '');
          }

          await apiNda.createOneNda(formNda);

          updateTable();

          return true;
        } catch (error) {
          const { data } = error as ErrorResponse;

          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail as string);
          if (data.file) showError(data.file[0]);
          if (data.name) showError(data.name[0]);

          return false;
        }
      } else {
        updateTable();
        return true;
      }
    } catch (error) {
      const { data } = error as ErrorResponse;

      if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
      if (data.detail) showError(data.detail as string);

      apiFieldErrorHandler(data.name, setName);
      return false;
    }
  };

  const onSubmitSendForm = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) return;

    if (!(fileNda || ndaTemplate) && !projectNda) {
      showError(Errors.CantSendWithoutNda);
      return;
    }

    setIsLoading(true);
    (await createNewBranch()) && sendChoise(CurrentForm.SendMail);
    setIsLoading(false);
  };

  const onSaveFormWithoutSend = async () => {
    if (isLoading) return;

    setIsLoading(true);
    (await createNewBranch()) && closeModal();
    setIsLoading(false);
  };

  if (currentForm === CurrentForm.Existing) {
    return (
      <ModalBox onCloseButtonClick={closeModal} title="Select group:">
        <ChoiceExistingGroup
          allGroups={allGroups}
          backToForm={() => sendChoise(CurrentForm.New)}
          editOneGroup={oneGroupe => {
            setContactGroup(oneGroupe);
            sendChoise(CurrentForm.EditGroup);
          }}
          selectGroup={oneGroup => {
            setContactGroup(oneGroup);
            sendChoise(CurrentForm.FinalForm);
          }}
        />
      </ModalBox>
    );
  }

  if (currentForm === CurrentForm.EditGroup) {
    return (
      <StyledModalBox onCloseButtonClick={closeModal} title="Edit group">
        <EditGroupBlock
          backToForm={() => sendChoise(CurrentForm.Existing)}
          updateGroup={newGroup =>
            setAllGroups(prevGroups =>
              prevGroups.map(group =>
                group.id === newGroup.id ? newGroup : group,
              ),
            )
          }
          addContact={contact => setAllContacts(prev => [...prev, contact])}
          updateContact={updatedContact =>
            setAllContacts(prev =>
              prev.map(contact =>
                contact.id === updatedContact.id ? updatedContact : contact,
              ),
            )
          }
          allUserContacts={allContacts}
          editedGroup={contactGroup as OneSignerGroup}
        />
      </StyledModalBox>
    );
  }

  if (currentForm === CurrentForm.SendMail) {
    return (
      <ModalBox onCloseButtonClick={closeModal} title="Enter Recipient info">
        <SendMailForm
          projectBranchId={newBranch?.id}
          ndaName={
            !fileNda && Boolean(projectNda) ? projectNda?.name ?? '' : ndaName
          }
          allContacts={allContacts}
          groupUsers={contactGroup?.users ?? []}
          closeModal={() => {
            updateTable();
            closeModal();
          }}
          projectName={projectName}
        />
      </ModalBox>
    );
  }

  if (currentForm === CurrentForm.AddNdaForm) {
    return templatesLength === 0 ? (
      <ModalBox title="Upload NDA from your files">
        <UploadNda
          addNda={() => {
            updateDashboard();
          }}
          projectId={Number(projectId)}
          goToChoices={() => sendChoise(CurrentForm.FinalForm)}
          addUploadedNda={(file, fileName) => {
            setNdaTemplate(null);
            setNdaName(fileName);
            setFileNda(file);
            sendChoise(CurrentForm.FinalForm);
          }}
        />
      </ModalBox>
    ) : (
      <AddNda
        addNda={() => {
          updateDashboard();
        }}
        closeModal={() => sendChoise(CurrentForm.FinalForm)}
        addTemplateNda={(template, templateName) => {
          setFileNda(null);
          setNdaName(templateName);
          setNdaTemplate(template);
          sendChoise(CurrentForm.FinalForm);
        }}
        addUploadedNda={(file, fileName) => {
          setNdaTemplate(null);
          setNdaName(fileName);
          setFileNda(file);
          sendChoise(CurrentForm.FinalForm);
        }}
      />
    );
  }

  return (
    <ModalBox onCloseButtonClick={closeModal} title="Create project branch">
      <StyledForm onSubmit={onSubmitSendForm}>
        <IconButton
          src={SVG.goBack}
          alt="go back button"
          onClick={() => sendChoise(machineContext.prevForm)}
        />

        <InputBox>
          {isLoading && <DotsLoading />}
          <Label>
            <div>Group name:</div>
            <StyledInput
              disabled={isLoading}
              value={name.value}
              onChange={handleInputChange(setName)}
              onBlur={handleInputBlur(name, setName)}
              placeholder="Name"
              error={name.errorMsg}
            />
          </Label>
        </InputBox>

        {contactGroup && (
          <ContactsBox>
            <Text>
              Contacts group name: <DarkText>{contactGroup?.name}</DarkText>
            </Text>
            <Text>Contacts: </Text>
            <Contacts
              allContacts={allContacts}
              usersInGroup={contactGroup?.users}
            />
          </ContactsBox>
        )}

        {!fileNda &&
          !ndaTemplate &&
          (projectNda ? (
            <InfoText>Default project NDA: {projectNda.name}</InfoText>
          ) : (
            <InfoText>This project don&apos;t have default NDA.</InfoText>
          ))}

        <AddNdaButton
          type="button"
          onClick={() => sendChoise(CurrentForm.AddNdaForm)}
        >
          <img src={SVG.downloadNDA} alt="upload input" />
          <span>
            {fileNda || ndaTemplate || projectNda
              ? 'Select another NDA to send'
              : 'Click to upload NDA'}
          </span>
        </AddNdaButton>

        {(fileNda || ndaTemplate) && (
          <NdaInfo>
            <img src={SVG.attachment} width="18px" alt="NDA" />
            <span>{ndaName}</span>
            <RemoveNda
              onClick={() => {
                setNdaTemplate(null);
                setFileNda(null);
              }}
            >
              remove
            </RemoveNda>
          </NdaInfo>
        )}

        <FinalButtonsBox>
          <StyledBlueButton type="submit" text="Send" />
          <StyledButton text="Save" onClick={onSaveFormWithoutSend} />
        </FinalButtonsBox>
      </StyledForm>
    </ModalBox>
  );
}

const StyledModalBox = styled(ModalBox)`
  width: 760px;
`;

const StyledForm = styled.form`
  padding: 20px;
`;

const IconButton = styled.img`
  width: 20px;
  cursor: pointer;
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0;
`;

const Label = styled.label`
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};

  & > *:last-child {
    margin-top: 10px;
  }
`;

const StyledInput = styled(Input)`
  width: 350px;
`;

const Text = styled.p`
  color: ${Colors.MiddleSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12)};
  margin: 0;
`;

const DarkText = styled.span`
  color: ${Colors.WeakBlack};
`;

const InfoText = styled(Text)`
  margin: 15px 0;
  text-align: center;
  font-size: ${rem(10)};
`;

const NdaInfo = styled(Text)`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 15px auto;
  text-align: center;
  font-size: ${rem(10)};
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const RemoveNda = styled.span`
  color: ${Colors.DarkRed};
  text-decoration: underline;
  cursor: pointer;
`;

const ContactsBox = styled.div`
  width: fit-content;
  padding: 15px;
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 15px;

  & > *:nth-child(1) {
    margin-bottom: 20px;
  }

  & > *:nth-child(2) {
    margin-bottom: 10px;
  }
`;

const FinalButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const AddNdaButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 5px 20px;
  margin: 10px auto;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkGrey};
  cursor: pointer;

  & > *:first-child {
    margin-right: 10px;
  }
`;

const StyledButton = styled(ButtonEllipse)`
  width: 100px;
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  color: ${Colors.LightBlue};
`;

const StyledBlueButton = styled(StyledButton)`
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
`;

export default AddProjectBranch;
