import styled from 'styled-components';

import { Fonts, rem } from '../themes';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 95px;
  height: 35px;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
`;
type Props = {
  className?: string;
  text: string;
  type?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  onDoubleClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};
function SolidButton({
  className,
  text,
  onClick,
  onDoubleClick,
  type,
  disabled,
  ...props
}: Props) {
  return (
    <Button
      type={type === 'submit' ? 'submit' : 'button'}
      className={className}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      disabled={disabled}
      {...props}
    >
      {text}
    </Button>
  );
}

export default SolidButton;
