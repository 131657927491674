import { AxiosResponse } from 'axios';

import api from '../authApi';
import { Onboarding } from './types';

const getOnboarding = (): Promise<AxiosResponse<Onboarding>> =>
  api.get('/onboarding/');

const onboarding = { getOnboarding };

export default onboarding;
