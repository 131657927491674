/* eslint-disable react-hooks/exhaustive-deps */
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  company as apiCompany,
  dashboard as apiDashboard,
  ErrorResponse,
  me as apiMe,
} from '../../../api';
import { ButtonEllipse, GeoInput, Input } from '../../../atoms';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  isEmptyInput,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import ChooseSignatureModal from '../../../organisms/ChooseSignatureModal';
import { refreshUser, selectors } from '../../../store';
import { BoxShadow, Colors, Fonts, rem } from '../../../themes';

type Props = {
  groupId: number;
  signedNda: () => void;
};

function SignConfirm({ groupId, signedNda }: Props) {
  const { showError } = useNotification();

  const dispatch = useDispatch();
  const user = useSelector(selectors.user);

  const [imageUrl, setImageUrl] = useState('');
  const [isAddedSign, setIsAddedSign] = useState(false);

  const [isOpenChoiceModal, setIsOpenChoiceModal] = useState(false);

  const [companyName, setCompanyName] = useState({
    value: user.company?.name ?? '',
    errorMsg: '',
  });
  const [companyAddress, setCompanyAddress] = useState({
    value: user.company?.address ?? '',
    errorMsg: '',
  });
  const [registrationNumber, setRegistrationNumber] = useState({
    value: user.company?.registrationNumber ?? '',
    errorMsg: '',
  });
  const [vat, setVat] = useState({
    value: user.company?.vat ?? '',
    errorMsg: '',
  });

  const [isUpdateCompanyMode, setIsUpdateCompanyMode] = useState(false);
  useEffect(() => {
    if (isUpdateCompanyMode) {
      setIsUpdateCompanyMode(false);
    }
  }, [isUpdateCompanyMode]);

  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(companyName, setCompanyName, Errors.EnterName)) {
      isValid = false;
    }

    if (isEmptyInput(companyAddress, setCompanyAddress, Errors.EnterField)) {
      isValid = false;
    }

    // if (
    //   isEmptyInput(registrationNumber, setRegistrationNumber, Errors.EnterField)
    // ) {
    //   isValid = false;
    // }

    return isValid;
  };

  const confirmSign = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) return;
    if (!user.company?.owner.sign) {
      showError(Errors.AddSignatureBeforeSign);
      return;
    }

    if (!validate()) return;

    if (
      companyName.value !== user.company?.name ||
      companyAddress.value !== user.company?.address ||
      registrationNumber.value !== user.company?.registrationNumber ||
      vat.value !== user.company?.vat
    ) {
      try {
        setIsLoading(true);
        await apiCompany.updateCompany(user.company?.id, {
          name: companyName.value,
          address: companyAddress.value,
          registrationNumber: registrationNumber.value,
          vat: vat.value,
        });
      } catch (error) {
        const { data } = error as ErrorResponse;

        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail as string);

        apiFieldErrorHandler(data.name, setCompanyName);
        apiFieldErrorHandler(data.address, setCompanyAddress);
        apiFieldErrorHandler(data.registrationNumber, setRegistrationNumber);
        apiFieldErrorHandler(data.vat, setCompanyName);
        setIsLoading(false);
        return;
      }
    }

    try {
      await apiDashboard.signNdaBySigner(groupId);
      signedNda();
    } catch (error) {
      const { data } = error as ErrorResponse;
      if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
      if (data.detail) showError(data.detail as string);
    } finally {
      setIsLoading(false);
    }
  };
  const refreshSignature = () => {
    apiMe
      .getMySign()
      .then(({ data }) => URL.createObjectURL(data))
      .then(newImageUrl => {
        setIsAddedSign(true);

        dispatch(refreshUser({ ...user, sign: newImageUrl }));
        setImageUrl(newImageUrl);
      })
      .catch(error => {
        setIsAddedSign(false);
      });
  };

  useEffect(() => {
    refreshSignature();
  }, []);

  useEffect(() => {
    apiMe.refreshUser().then(({ data }) => dispatch(refreshUser(data)));
  }, [dispatch, imageUrl]);

  return (
    <form onSubmit={confirmSign}>
      <ChooseSignatureModal
        isOpenChoiceModal={isOpenChoiceModal}
        setIsOpenChoiceModal={setIsOpenChoiceModal}
        refreshSignature={refreshSignature}
        userName={`${user.firstName} ${user.lastName}`}
      />

      <Container>
        <Label>
          <div>Company Name:</div>
          <div>
            <StyledInput
              disabled={isLoading}
              value={companyName.value}
              onChange={handleInputChange(setCompanyName)}
              onBlur={handleInputBlur(companyName, setCompanyName)}
              placeholder="Company Name"
              error={companyName.errorMsg}
            />
          </div>
        </Label>

        <Label>
          <div>Company Address</div>
          <div>
            <GeoInput
              location={companyAddress}
              setLocation={setCompanyAddress}
              placeholder="-"
            />
          </div>
        </Label>

        <Label>
          <div>Registration Number:</div>
          <div>
            <StyledInput
              disabled={isLoading}
              value={registrationNumber.value}
              onChange={handleInputChange(setRegistrationNumber)}
              onBlur={handleInputBlur(
                registrationNumber,
                setRegistrationNumber,
              )}
              placeholder="-"
              error={registrationNumber.errorMsg}
            />
          </div>
        </Label>

        <Label>
          <div>VAT:</div>
          <div>
            <StyledInput
              disabled={isLoading}
              value={vat.value}
              onChange={handleInputChange(setVat)}
              onBlur={handleInputBlur(vat, setVat)}
              placeholder="-"
              error={vat.errorMsg}
            />
          </div>
        </Label>

        <SignatureBlock>
          {isAddedSign && (
            <img width="240px" height="80px" src={imageUrl} alt="Signature" />
          )}

          <UpdateSignature
            text={isAddedSign ? 'Update the signature' : 'Add a signature'}
            onClick={() => setIsOpenChoiceModal(true)}
          />
        </SignatureBlock>

        {user.company?.owner.sign ? (
          <SignButton
            isLoading={isLoading}
            type="submit"
            text={isLoading ? 'Sign...' : 'Sign'}
          />
        ) : (
          <Info>
            Your company dont have signature for sign this NDA. Please, add
            signature
          </Info>
        )}
      </Container>
    </form>
  );
}

const Container = styled.div`
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  padding: 20px 15px;
  margin-top: 10px;
  border-radius: 10px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  font-size: ${rem(8)};

  & > *:first-child {
    flex-basis: 35%;
    margin-right: 10px;
  }
`;

const StyledInput = styled(Input)`
  :disabled {
    border-color: ${Colors.Transparent};
  }
`;

const Info = styled.p`
  text-align: center;
`;

const SignatureBlock = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const UpdateSignature = styled(ButtonEllipse)`
  height: 30px;
  border-radius: 15px;
  box-shadow: ${BoxShadow.Secondary};
  color: ${Colors.LightBlue};
  font-size: ${rem(8)};
  margin: 10px;
`;

const SignButton = styled(ButtonEllipse)<{ isLoading: boolean }>`
  margin: 15px auto;
  border: 1px solid ${Colors.White};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
`;

export default SignConfirm;
