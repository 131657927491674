import styled from 'styled-components';

import { Spiner } from '../atoms';

function Loader() {
  return (
    <Overlay>
      <Spiner />
    </Overlay>
  );
}

const Overlay = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000;

  background-color: rgba(17, 17, 17, 0.2);
`;

export default Loader;
