import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { contacts as apiContacts, ContactType } from '../../../../api';
import SVG from '../../../../assets/svg';
import { Checkbox, HidenBoxHovered, Input } from '../../../../atoms';
import { isEmail } from '../../../../helpers';
import { useNotification } from '../../../../hooks';
import {
  BoxShadow,
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../../../../themes';

type Contact = ContactType & { checked: boolean };

type Props = {
  filteredContacts: Contact[];
  handleContactCheck: (
    props: ChangeEvent<HTMLInputElement> | Contact[],
  ) => void;
  updateContact: (contact: ContactType) => void;
};

type EditDataProps = {
  firstName: string;
  lastName: string;
  email: string;
  id: number | null;
};

const validate = ({ firstName, lastName, email }: EditDataProps) => {
  let isValid = true;

  if (!firstName || firstName.trim() === '') {
    isValid = false;
  }

  if (!lastName || lastName.trim() === '') {
    isValid = false;
  }
  if (!email || email.trim() === '') {
    isValid = false;
  }

  if (!isEmail(email)) {
    isValid = false;
  }

  return isValid;
};

function Table({ filteredContacts, handleContactCheck, updateContact }: Props) {
  const { showError } = useNotification();

  const [editedContact, setEditedContact] = useState<number | null>(null);

  const [editData, setEditData] = useState<EditDataProps>({
    firstName: '',
    lastName: '',
    email: '',
    id: null,
  });

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editedContact) {
      const item = filteredContacts.find(
        contact => contact.user.pk === editedContact,
      );

      if (item) {
        setEditData({
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.user.email,
          id: item.id,
        });
      }
    } else {
      setEditData({ firstName: '', lastName: '', email: '', id: null });
    }
  }, [editedContact, filteredContacts]);

  useEffect(() => {
    const items = filteredContacts.filter(contact => !contact.checked);

    if (!items.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [filteredContacts]);

  const handleSelectAll = () => {
    if (!isSelectAll) {
      handleContactCheck(
        filteredContacts.map(contact => ({ ...contact, checked: true })),
      );

      setIsSelectAll(true);
    } else {
      handleContactCheck(
        filteredContacts.map(contact => ({ ...contact, checked: false })),
      );
      setIsSelectAll(false);
    }
  };

  const saveEditedContact = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!validate(editData)) return;

    if (isLoading) return;

    if (editData.id) {
      setIsLoading(true);
      apiContacts
        .updateContact(editData.id, {
          email: editData.email,
          firstName: editData.firstName,
          lastName: editData.lastName,
        })
        .then(({ data }) => {
          updateContact(data);
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);
          if (data.firstName) showError(data.firstName[0]);
        })
        .finally(() => {
          setIsLoading(false);
          setEditedContact(null);
        });
    }
  };

  const handleDeleteContact = (id: number) => {
    setIsLoading(true);
    apiContacts
      .deleteContact(id)
      .then(() => {
        handleContactCheck(
          filteredContacts.filter(contact => contact.id !== id),
        );
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.firstName) showError(data.firstName[0]);
      })
      .finally(() => {
        setIsLoading(false);
        setEditedContact(null);
      });
  };

  return (
    <ContactsTable>
      <Header>
        <Checkbox onChange={handleSelectAll} checked={isSelectAll} />

        <HeaderItem>First Name</HeaderItem>
        <HeaderItem>Last Name</HeaderItem>
        <HeaderItem>Email</HeaderItem>
        <HeaderItem>Actions</HeaderItem>
      </Header>

      <Body>
        {filteredContacts.map(contact => (
          <ContactInfoBlock
            key={contact.user.pk}
            htmlFor={`${contact.user.pk}`}
            isError={contact.user.pk === editedContact && !validate(editData)}
          >
            <Checkbox
              id={`${contact.user.pk}`}
              checked={contact.checked}
              onChange={e => {
                // if every contact was selected and we click to unselect one of them, checkbox "all checkbox" will be false
                isSelectAll && setIsSelectAll(false);
                !editedContact && handleContactCheck(e);
              }}
            />

            {editedContact && contact.user.pk === editedContact ? (
              <StyledInput
                value={editData.firstName}
                onChange={e =>
                  setEditData({ ...editData, firstName: e.target.value })
                }
                onBlur={() => ''}
                placeholder="First Name"
              />
            ) : (
              <BodyItem value={contact.firstName} />
            )}

            {editedContact && contact.user.pk === editedContact ? (
              <StyledInput
                value={editData.lastName}
                onChange={e =>
                  setEditData({ ...editData, lastName: e.target.value })
                }
                onBlur={() => ''}
                placeholder="Last Name"
              />
            ) : (
              <BodyItem value={contact.lastName} />
            )}

            <BodyItem value={contact.user.email} width="200px !important" />

            <ButtonsBox>
              {contact.user.pk === editedContact ? (
                <EditButton
                  src={SVG.done}
                  onClick={e => saveEditedContact(e)}
                  alt="Done button"
                />
              ) : (
                <EditButton
                  src={SVG.pencil}
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    setEditedContact(contact.user.pk);
                  }}
                  alt="Edit button"
                />
              )}

              <DeleteButton
                src={SVG.deleteImg}
                onClick={(e: MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  handleDeleteContact(contact.id);
                }}
                alt="Delete button"
              />
            </ButtonsBox>
          </ContactInfoBlock>
        ))}
      </Body>
    </ContactsTable>
  );
}

const ContactsTable = styled.div`
  margin-top: 20px;

  border: 1px solid ${Colors.LightBlue};
  border-radius: ${rootBorderRadius};
`;

type InfoBlock = {
  isError: boolean;
};

const ContactInfoBlock = styled.label<InfoBlock>`
  padding: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  border: 1.5px solid ${({ isError }) => (isError ? 'red' : 'transparent')};
  border-radius: ${rootBorderRadius};
  transition: 0.1s ease-in;

  &:hover {
    border: 1.5px solid ${Colors.LightBlue};
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 1.5px solid ${Colors.LightRed};

      &:hover {
        border: 1.5px solid ${Colors.LightRed};
      }
    `}
`;

const BodyItem = styled(HidenBoxHovered)`
  width: 140px;
  padding: 2px;

  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};
  border: 1px solid transparent;

  &:nth-child(4) {
    width: 200px;
  }
`;

const StyledInput = styled(Input)`
  width: 140px;
  padding: 2px;

  border: 1px solid ${Colors.LightBlue};
  border-radius: 5px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};

  &:nth-child(4) {
    width: 200px;
  }

  ::placeholder {
    font-size: ${rem(10)};
    font-family: ${Fonts.Sofia};
    color: ${Colors.MiddleSecondGrey};
  }
`;

const Body = styled.div`
  max-height: 260px;
  padding: 8px;

  overflow-y: scroll;

  ${removeScrollStyles}
`;

const Header = styled.div`
  padding: 14px;

  display: flex;
  justify-content: space-between;

  box-shadow: ${BoxShadow.Secondary};
`;

const HeaderItem = styled.div`
  width: 140px;

  font-size: ${rem(11)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.MiddleSecondGrey};

  &:last-child {
    width: 60px;
  }

  &:nth-child(4) {
    width: 200px;
  }
`;

const ButtonsBox = styled.div`
  width: 60px;

  display: flex;

  align-items: center;
`;

const EditButton = styled.img`
  width: 18px;
  margin-right: 20px;

  cursor: pointer;
`;

const DeleteButton = styled.img`
  width: 18px;

  cursor: pointer;
`;

export default Table;
