import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { billing as apiBilling } from '../../../../api';
import { formatDateMode } from '../../../../const';
import { StoreSubscription, updateSubscription } from '../../../../store';
import { Colors, rem } from '../../../../themes';
import { StyledButton } from './PlanStatus';

type Props = {
  subscription: StoreSubscription;
  cancelAt: Date;
};

const CancelTime = ({ subscription, cancelAt }: Props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleRenewSubscription = () => {
    setIsLoading(true);
    apiBilling
      .renewSubscription()
      .then(() => {
        dispatch(
          updateSubscription({
            ...subscription,
            product: {
              ...subscription.product,
              cancelAtPeriodEnd: false,
              cancelAt: null,
            },
          }),
        );
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <Wrapper>
      <Time>
        Your subscription will be <br /> canceled at:{' '}
        <span>{format(parseISO(`${cancelAt}`), formatDateMode.dateTime)}</span>
      </Time>

      <StyledButton
        text={isLoading ? 'Loading...' : 'Renew'}
        onClick={handleRenewSubscription}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Time = styled.div`
  text-align: center;

  font-size: ${rem(9)};
  color: ${Colors.White};

  & > span {
    text-decoration: underline;
    color: ${Colors.DarkRed};
  }
`;

export default CancelTime;
