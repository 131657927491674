import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import ErrorText from '../molecules/ErrorText';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  maxLength?: number;
};

function Input({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  className,
  disabled,
  type,
  maxLength,
}: Props) {
  return (
    <>
      <StyledInput
        type={type ?? 'text'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onBlur}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        {...((type === 'text' || !type) && { maxLength })}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}

export const StyledInput = styled.input`
  width: 100%;
  padding: 10px;

  display: block;
  outline: none;

  border-radius: 10px;
  background-color: ${Colors.White};
  border: 0.5px solid ${Colors.WeakGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkBlack};

  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
`;

export default Input;
