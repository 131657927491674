/* eslint-disable no-param-reassign */
import Picker, { EmojiClickData } from 'emoji-picker-react';
import { memo, MouseEvent, SyntheticEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  Pointer,
  Thread,
  thread as apiThread,
  threadPointer as apiPointer,
} from '../../../api';
import SVG from '../../../assets/svg';
import { DotsLoading, Popover, Textarea, TextButton } from '../../../atoms';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import { Colors, rem } from '../../../themes';

export type NewPointerType = Pick<Pointer, 'page' | 'x' | 'y'>;
type Props = {
  ndaId?: number;
  newPointer: NewPointerType | null;
  addThread: (newThread: Thread) => void;
};
const MemoPicker = memo(Picker);

function NewThread({ ndaId, newPointer, addThread }: Props) {
  const { showError } = useNotification();
  const [thread, setThread] = useState(initialValue);
  const [anchorEmoji, setAnchorEmoji] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (!newPointer) {
      showError(Errors.SetPointer);
      isValid = false;
    }

    if (isEmptyInput(thread, setThread, Errors.EnterField)) {
      isValid = false;
    }

    if (thread.errorMsg) isValid = false;
    return isValid;
  };
  const addNewThread = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) return;
    if (!validate()) return;
    if (!newPointer) return;

    setIsLoading(true);
    if (ndaId) {
      apiThread
        .createThread({ nda: ndaId, text: thread.value, pointers: [] })
        .then(({ data }) => {
          apiPointer
            .createPointer({ ...newPointer, commentThread: data.id })
            .then(({ data: pointerData }) => {
              pointerData.text = data.text;
              pointerData.threadId = data.id;
              addThread({ ...data, pointers: [pointerData] });
            })
            .catch(({ data: pData }) => {
              if (pData.nonFieldErrors) showError(pData.nonFieldErrors[0]);
              if (pData.detail) showError(pData.detail);
              if (pData.page) showError(pData.nonFieldErrors[0]);
              if (pData.x) showError(pData.nonFieldErrors[0]);
              if (pData.y) showError(pData.nonFieldErrors[0]);
            })
            .finally(() => setIsLoading(false));
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);

          apiFieldErrorHandler(data.text, setThread);
        })
        .finally(() => setIsLoading(false));
    }
  };
  const handleCloseEmoji = useCallback(
    (event: MouseEvent<HTMLElement | HTMLDivElement>) => {
      if (event.currentTarget !== event.target) return;
      setAnchorEmoji(null);
    },
    [setAnchorEmoji],
  );

  const onEmojiClick = useCallback(
    (emoji: EmojiClickData) =>
      setThread(prevState => ({
        ...prevState,
        value: prevState.value + emoji.emoji,
      })),
    [setThread],
  );

  return (
    <StyledCard>
      <form onSubmit={addNewThread}>
        <InputBox>
          {isLoading && <StyledLoading />}
          <Container>
            <StyledTextarea
              disabled={isLoading}
              value={thread.value}
              onChange={handleInputChange(setThread)}
              onBlur={handleInputBlur(thread, setThread)}
              placeholder="Add a comment..."
              error={thread.errorMsg}
              minRows={4}
            />
          </Container>
        </InputBox>
        <BottomSection>
          <InnerSection>
            <Image
              src={SVG.smileyEmoji}
              alt="smile"
              onClick={(e: MouseEvent<HTMLElement>) => {
                setAnchorEmoji(e.currentTarget);
              }}
            />
          </InnerSection>
          <StyledButton type="submit">
            <img src={SVG.rightArrow} alt="right arrow" />
          </StyledButton>
        </BottomSection>
      </form>
      {anchorEmoji && (
        <Popover refElem={anchorEmoji} onOverlayClick={handleCloseEmoji}>
          <MemoPicker onEmojiClick={onEmojiClick} />
        </Popover>
      )}
    </StyledCard>
  );
}

const InputBox = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLoading = styled(DotsLoading)`
  z-index: 1px;
  background-color: ${Colors.Transparent};
`;

const StyledCard = styled.div`
  border-radius: 5px;
  background-color: ${Colors.offBlue};
`;

const StyledTextarea = styled(Textarea)`
  font-size: ${rem(9)};
  border: none;
  background-color: ${Colors.offBlue};
  border-bottom: 1px solid ${Colors.offSecondaryBlue};
  border-radius: 0px;

  ::placeholder {
    font-size: ${rem(9)};
  }
`;

const BottomSection = styled.div`
  display: flex;
  padding: 0px 6px 6px;
  justify-content: space-between;
`;

const InnerSection = styled.div`
  display: flex;
  margin: auto 0px;
`;

const Image = styled.img`
  margin: 4px;
  height: 45%;
`;

const StyledButton = styled(TextButton)`
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: ${Colors.offSecondaryBlue};
  > img {
    padding: 6px 2px;
    display: flex;
    align-items: center;
  }
`;

const Container = styled.div`
  > div > div {
    margin-top: 0px;
    font-size: ${rem(8.75)};
  }
`;

export default NewThread;
