import { AxiosResponse } from 'axios';

import api from '../authApi';
import {
  CompanyType,
  GetCompanyOwnerType,
  UpdateCompanyRequest,
} from './types';

const getCompany = (): Promise<AxiosResponse<GetCompanyOwnerType[]>> =>
  api.get('company/');

const updateCompany = (
  companyId: number,
  body: UpdateCompanyRequest,
): Promise<AxiosResponse<CompanyType>> =>
  api.patch(`/company/${companyId}/`, body);

const company = {
  getCompany,
  updateCompany,
};

export default company;
