import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import humps from 'humps';

export type ResponseData = {
  [key: string]: any;
};

export type ErrorResponse = {
  status: number;
  data: {
    [key: string]: string | string[];
  };
};

export enum ContentTypes {
  json = 'application/json',
  formData = 'multipart/form-data',
}

export enum TokenTimes {
  Access = 900000, // 15 mins
  Refresh = 86400000, // 24 hours
}

export enum TokenKeys {
  Access = 'accessTokenExiredTime',
  Refresh = 'refreshTokenExiredTime',
}

export type ReqInterceptorType = (
  request: AxiosRequestConfig,
) => Promise<AxiosRequestConfig>;

export const requestSnakeCased: ReqInterceptorType = async request => {
  if (request.headers['Content-Type'] === ContentTypes.formData) {
    return request;
  }

  const data = request?.data;
  const snakeCasedData = humps.decamelizeKeys(data);
  return { ...request, data: snakeCasedData };
};

export const responseCamelCasedSuccess = (
  response: AxiosResponse,
): AxiosResponse => {
  if (response.headers['content-type'] !== ContentTypes.json) {
    return response;
  }

  const data: ResponseData = response?.data;
  const camelCasedData: ResponseData = humps.camelizeKeys(data);
  return { ...response, data: camelCasedData };
};

export const responseCamelCasedError = (
  error: AxiosError,
): Promise<ErrorResponse> => {
  const data: ResponseData = error.response?.data;
  const camelCasedData = humps.camelizeKeys(data);
  const errorResponse = {
    status: error.response?.status,
    data: camelCasedData,
  };

  return Promise.reject(errorResponse);
};

export const baseApiURL = '/api/v1';

const guestApi = axios.create({
  baseURL: baseApiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

guestApi.interceptors.request.use(requestSnakeCased);
guestApi.interceptors.response.use(
  responseCamelCasedSuccess,
  responseCamelCasedError,
);

export default guestApi;
