import { SyntheticEvent, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../../api';
import { ButtonEllipse } from '../../atoms';
import AuthWrapper from '../../atoms/AuthWrapper';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import MuiInputPassword from '../../molecules/MuiInputPassword';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

type Params = {
  userId: string;
  token: string;
};

function PasswordReset() {
  const { showError } = useNotification();

  const [password, setPassword] = useState(initialValue);
  const [passwordConfirm, setPasswordConfirm] = useState(initialValue);

  const [isReset, setIsReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userId, token } = useParams<Params>();

  const validate = () => {
    if (isEmptyInput(password, setPassword, Errors.EnterPassword)) return false;

    if (password.value !== passwordConfirm.value) {
      setPasswordConfirm(prevState => ({
        ...prevState,
        errorMsg: Errors.MatchPasswords,
      }));
      return false;
    }

    if (password.errorMsg || passwordConfirm.errorMsg) return false;

    return true;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isReset) return;
    if (!validate()) return;

    setIsLoading(true);

    authorization
      .passwordResetConfirm({
        newPassword1: password.value,
        newPassword2: passwordConfirm.value,
        uid: userId,
        token,
      })
      .then(() => setIsReset(true))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.uid) showError(data.nonFieldErrors[0]);
        if (data.token) showError(data.nonFieldErrors[0]);

        apiFieldErrorHandler(data.newPassword1, setPassword);
        apiFieldErrorHandler(data.newPassword2, setPasswordConfirm);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthWrapper isLoading={isLoading}>
      <>
        {!isReset ? (
          <>
            <FromSubmit onSubmit={handleSubmit}>
              <Text>Write a new password</Text>
              <InputWrapper>
                <MuiInputPassword
                  value={password.value}
                  onChange={handleInputChange(setPassword)}
                  onBlur={handleInputBlur(password, setPassword)}
                  placeholder="Password"
                  error={password.errorMsg}
                />
              </InputWrapper>

              <InputWrapper>
                <MuiInputPassword
                  value={passwordConfirm.value}
                  onChange={handleInputChange(setPasswordConfirm)}
                  onBlur={handleInputBlur(passwordConfirm, setPasswordConfirm)}
                  placeholder="Password"
                  error={passwordConfirm.errorMsg}
                />
              </InputWrapper>
              <ButtonWrapper>
                <StyledButton type="submit" text="Change password" />
              </ButtonWrapper>
            </FromSubmit>
            <SignUpText>
              Already have an account?
              <StyledLink to={Routes.login}>Log in</StyledLink>
            </SignUpText>
          </>
        ) : (
          <SuccessPasswordReset>
            Password changed successfully! <br />
            <GoToLogin to={Routes.login}>Go to Login page</GoToLogin>
          </SuccessPasswordReset>
        )}
      </>
    </AuthWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 31px;
  position: relative;
`;

const Text = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
`;

const FromSubmit = styled.form`
  width: 100%;
`;

const SuccessPasswordReset = styled.div`
  text-align: center;
  padding: 20px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(14)};
  line-height: 1.5;
  color: ${Colors.LightBlue};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(ButtonEllipse)`
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  height: 48px;
  border-radius: 10px;
  border: none;

  &:hover {
    border: none;
  }
`;

const SignUpText = styled.div`
  color: ${Colors.LightSecondGrey};
  margin-top: 30px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Roboto};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-left: 5px;
`;

const GoToLogin = styled(NavLink)`
  text-decoration: none;
`;

export default PasswordReset;
