import { AxiosResponse } from 'axios';

import { store } from '../../store';
import api from '../authApi';
import { UserData } from '../authorization';
import { ContentTypes } from '../guestApi';
import { EditUserRequest } from './types';

const refreshUser = (): Promise<AxiosResponse<UserData>> => {
  const { user } = store.getState();

  return api.get(`/me/${user.id}/`);
};

const getMySign = (): Promise<AxiosResponse<Blob>> =>
  api.get('/me/get_my_sign/', { responseType: 'blob' });

const updateUserSign = (form: FormData): Promise<AxiosResponse<UserData>> =>
  api.patch('/me/1/', form, {
    headers: {
      'Content-Type': ContentTypes.formData,
    },
  });

const updateUserAvatar = (form: FormData): Promise<AxiosResponse<UserData>> =>
  api.patch('/me/1/', form, {
    headers: {
      'Content-Type': ContentTypes.formData,
    },
  });

const editUser = (body: EditUserRequest): Promise<AxiosResponse<UserData>> =>
  api.patch('/me/1/', body);

const me = {
  refreshUser,
  updateUserSign,
  updateUserAvatar,
  editUser,
  getMySign,
};

export default me;
