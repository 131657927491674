/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import {
  compareDesc,
  differenceInDays,
  format,
  formatDistanceToNow,
} from 'date-fns';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  baseApiURL,
  nda as apiNda,
  OneNdaType,
  Pointer,
  projectBranches as apiBranch,
  ProjectBranchType,
  Thread,
  thread as apiThread,
} from '../../api';
import SVG from '../../assets/svg';
import { Popover } from '../../atoms';
import { usePermissions } from '../../hooks';
import useCancellableDebounce from '../../hooks/useCancellableDebounce';
import { Loader } from '../../molecules';
import { selectors } from '../../store';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem, scrollBarStyles } from '../../themes';
import {
  CommentHeader,
  NewComment,
  NewPointerType,
  NewThread,
  OneThread,
  Pages,
  SignConfirm,
} from './components';
import { AccentPointer } from './components/NewComment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type UrlParams = {
  branchId: string;
  projectId: string;
};

type OnLoadPdf = {
  numPages: number;
};

function NdaComment() {
  const pdfRef = useRef<HTMLDivElement>(null);
  const { canViewProjectbranch, canViewCommentthread } = usePermissions();
  const { branchId, projectId } = useParams<UrlParams>();
  const token = useSelector(selectors.accessToken);
  const user = useSelector(selectors.user);

  const [currentNda, setCurrentNda] = useState<OneNdaType | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [threads, setThreads] = useState<Thread[]>([]);
  const [pointers, setPointers] = useState<Pointer[]>([]);
  const [isAddPointerMode, setIsAddPointerMode] = useState(false);
  const [newPointer, setNewPointer] = useState<NewPointerType | null>(null);
  const [accentPointer, setAccentPointer] = useState<AccentPointer | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [anchorThread, setAnchorThread] = useState(null);
  const [anchorComment, setAnchorComment] = useState<HTMLElement | null>(null);
  const [isOpenSignConfirm, setIsOpenSignConfirm] = useState(false);
  const isSignerOfProject = user.signerOfProjects.includes(Number(projectId));
  const [branchInfo, setBranchInfo] = useState<ProjectBranchType | null>(null);

  const onRefChange = useCallback(node => {
    setAnchorThread(node);
  }, []);

  const handleTime = (d: Thread) => ({
    ...d,
    timeAgo: formatDistanceToNow(new Date(d.created), {
      addSuffix: true,
    }),
  });
  const modifyThreadArr = (data: Thread[]) => {
    const obj: { [key: string]: Thread[] } = {};
    const sortThread = data.sort((a, b) =>
      compareDesc(new Date(a.created), new Date(b.created)),
    );
    sortThread.map(d => {
      if (differenceInDays(new Date(), new Date(d.created)) === 0) {
        if (!obj.hasOwnProperty('Today')) {
          obj.Today = [handleTime(d)];
        } else {
          obj.Today.push(handleTime(d));
        }
      } else if (differenceInDays(new Date(), new Date(d.created)) === 1) {
        if (!obj.hasOwnProperty('Yesterday')) {
          obj.Yesterday = [handleTime(d)];
        } else {
          obj.Yesterday.push(handleTime(d));
        }
      } else {
        const formateDate = format(new Date(d.created), 'do MMMM, yyyy');
        if (!obj.hasOwnProperty(formateDate)) {
          obj[formateDate] = [handleTime(d)];
        } else {
          obj[formateDate].push(handleTime(d));
        }
      }
    });
    return obj;
  };

  const formateThread = useMemo(() => modifyThreadArr(threads), [threads]);

  useEffect(() => {
    if (!currentNda) return;

    setIsLoading(true);

    apiThread
      .getThreadsByNdaId(currentNda.id)
      .then(({ data }) => {
        setThreads(
          data.map(obj => ({
            ...obj,
            show: false,
          })),
        );
        setPointers(
          data.reduce<Pointer[]>((acc, oneThread) => {
            if (oneThread.pointers.length > 0) {
              oneThread.pointers[0].text = oneThread.text;
              oneThread.pointers[0].negotiationStatus =
                oneThread.negotiationStatus;
              oneThread.pointers[0].threadId = oneThread.id;
            }
            return [...acc, ...oneThread.pointers];
          }, []),
        );
      })
      .finally(() => setIsLoading(false));
  }, [currentNda]);

  useEffect(() => {
    setIsLoading(true);
    canViewProjectbranch &&
      apiNda
        .getProjectBranchNda(Number(branchId))
        .then(({ data }) => {
          if (!data.results[0]) return;

          setCurrentNda(data.results[0]);
        })
        .finally(() => setIsLoading(false));
  }, [branchId, canViewProjectbranch]);

  useEffect(() => {
    if (branchId) {
      apiBranch
        .getBranchById(parseInt(branchId, 10))
        .then(({ data }) => setBranchInfo(data));
    }
  }, [branchId]);

  useEffect(() => {
    if (accentPointer && accentPointer.page !== pageNumber) {
      setPageNumber(accentPointer.page);
    }
  }, [accentPointer, pageNumber]);

  const onPdfPageClick = (e: MouseEvent) => {
    if (!isAddPointerMode) return;

    const { x, y } = e.currentTarget.getBoundingClientRect();
    const xCoord = e.clientX - x > 0 ? e.clientX - x : 0;
    const yCoord = e.clientY - y > 0 ? e.clientY - y : 0;

    setNewPointer({ x: xCoord, y: yCoord, page: pageNumber });
    setIsAddPointerMode(false);
  };

  const onPdfPageDoubleClick = () => {
    const { width, height } = pdfRef.current!.getBoundingClientRect();

    const xCoord = width / 2;
    const yCoord = height / 2;

    setNewPointer({ x: xCoord, y: yCoord, page: pageNumber });
    setIsAddPointerMode(false);
  };

  const updateThreads = () => {
    if (!currentNda) return;

    setIsLoading(true);
    apiThread
      .getThreadsByNdaId(currentNda.id)
      .then(({ data }) => {
        setThreads(data);
        setPointers(
          data.reduce<Pointer[]>(
            (acc, oneThread) => [...acc, ...oneThread.pointers],
            [],
          ),
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onLoadError = () => {
    setIsLoading(true);
    apiNda
      .getProjectBranchNda(Number(branchId))
      .then(({ data }) => {
        if (!data.results[0]) return;
        setCurrentNda(data.results[0]);
      })
      .finally(() => setIsLoading(false));
  };

  const addThread = (newThread: Thread) => {
    const thread = [...threads];
    thread.unshift(newThread);
    setThreads(thread);
  };

  const pdfFile = useMemo(
    () =>
      currentNda
        ? {
            url: `${baseApiURL}/projects/nda/${currentNda.id}/get_nda_pdf/`,
            httpHeaders: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        : null,
    [currentNda, token],
  );

  const { run: closeHoverComment, cancel: cancelCloseComment } =
    useCancellableDebounce(() => {
      setAccentPointer(null);
      setAnchorComment(null);
    }, 200);

  const handleEditOneThread = (threadId: number, text: string) => {
    const modifyThread = [...threads];
    const threadIndex = modifyThread.findIndex(obj => obj.id === threadId);
    modifyThread[threadIndex].text = text;
    setThreads(modifyThread);
    const modifyPointer = [...pointers];
    const pointerIndex = modifyPointer.findIndex(obj => obj.id === threadId);
    modifyPointer[pointerIndex].text = text;
    setPointers(modifyPointer);
  };

  const handleDeleteOneThread = (threadId: number) => {
    setThreads(prev => prev.filter(oneThread => oneThread.id !== threadId));
    setPointers(prev => prev.filter(pointer => pointer.id !== threadId));
  };

  const handleSign = () => {
    setIsOpenSignConfirm(false);
    if (!currentNda) return;
    setCurrentNda({ ...currentNda, isSigned: true });
  };

  return (
    <MainWrapper>
      <PageWrapper>
        <CommentHeader
          currentNda={currentNda}
          handleSignClick={() => setIsOpenSignConfirm(prev => !prev)}
          projectId={projectId}
          onDoubleClick={onPdfPageDoubleClick}
          setIsAddPointerMode={setIsAddPointerMode}
        />
        {!isLoading ? (
          <>
            <ContentWrapper>
              <PdfBlock>
                {currentNda && (
                  <>
                    <DocumentContainer
                      isAddMode={isAddPointerMode}
                      onClick={onPdfPageClick}
                      ref={pdfRef}
                    >
                      <Document
                        file={pdfFile}
                        loading={<Loader />}
                        onLoadSuccess={({ numPages }: OnLoadPdf) => {
                          setTotalPages(numPages);
                          setIsLoading(false);
                        }}
                      >
                        <Page
                          width={950}
                          pageNumber={pageNumber}
                          renderAnnotationLayer
                          onLoadError={onLoadError}
                        />
                        {pointers
                          .filter(onePoint => onePoint?.page === pageNumber)
                          .map(
                            onePoint =>
                              onePoint && (
                                <div
                                  onMouseLeave={e => {
                                    closeHoverComment();
                                  }}
                                  key={onePoint.id}
                                >
                                  <PointerImgContainer
                                    x={onePoint.x}
                                    y={onePoint.y}
                                    accent={onePoint.id === accentPointer?.id}
                                    onMouseEnter={(
                                      e: MouseEvent<HTMLElement>,
                                    ) => {
                                      setAccentPointer(onePoint);
                                      setAnchorComment(e.currentTarget);
                                    }}
                                  >
                                    <img src={SVG.profileAvtar} alt="" />
                                  </PointerImgContainer>
                                </div>
                              ),
                          )}
                        {accentPointer && (
                          <Popover
                            refElem={anchorComment}
                            onOverlayClick={(
                              event: MouseEvent<HTMLElement | HTMLDivElement>,
                            ) => {
                              if (event.currentTarget !== event.target) return;
                              setAccentPointer(null);
                              setAnchorComment(null);
                            }}
                          >
                            <div
                              onMouseEnter={() => {
                                cancelCloseComment();
                              }}
                              onMouseLeave={() => {
                                closeHoverComment();
                              }}
                            >
                              <NewComment
                                value={accentPointer}
                                onChange={setAccentPointer}
                                deleteOneThread={handleDeleteOneThread}
                                editOneThread={handleEditOneThread}
                                nda={currentNda}
                              />
                            </div>
                          </Popover>
                        )}
                        {newPointer && (
                          <>
                            <PointerImgContainer
                              x={newPointer.x}
                              y={newPointer.y}
                              ref={onRefChange}
                              accent
                            >
                              <img src={SVG.profileAvtar} alt="" />
                            </PointerImgContainer>
                            <Popover
                              refElem={anchorThread}
                              onOverlayClick={(
                                event: MouseEvent<HTMLElement | HTMLDivElement>,
                              ) => {
                                if (event.currentTarget !== event.target)
                                  return;
                                setNewPointer(null);
                              }}
                            >
                              <NewThread
                                ndaId={currentNda?.id}
                                newPointer={newPointer}
                                addThread={newThread => {
                                  addThread(newThread);
                                  setPointers(prev => [
                                    ...prev,
                                    ...newThread.pointers,
                                  ]);
                                  setIsAddPointerMode(false);
                                  setNewPointer(null);
                                }}
                              />
                            </Popover>
                          </>
                        )}
                      </Document>

                      {isAddPointerMode && <Overlay />}
                    </DocumentContainer>

                    {totalPages && (
                      <Pages
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setAccentPointer={setAccentPointer}
                        totalPages={totalPages}
                      />
                    )}
                  </>
                )}
              </PdfBlock>
              <RightBlock>
                {currentNda && (
                  <ThreadsBox>
                    {isOpenSignConfirm && (
                      <SignConfirm
                        groupId={Number(branchId)}
                        signedNda={handleSign}
                      />
                    )}
                  </ThreadsBox>
                )}
                {branchInfo?.allowComments && currentNda && (
                  <>
                    <ThreadsBox>
                      <Text>Comments</Text>
                      <ThreadContainer>
                        {canViewCommentthread &&
                          Object.keys(formateThread).map(key => (
                            <div key={key}>
                              <StyledText>{key}</StyledText>
                              {formateThread[key].map(thread => (
                                <OneThread
                                  key={thread.id}
                                  thread={thread}
                                  isAccented={
                                    accentPointer?.commentThread === thread.id
                                  }
                                  updateThread={updateThreads}
                                  nda={currentNda}
                                  isSignerOfProject={isSignerOfProject}
                                />
                              ))}
                            </div>
                          ))}
                        {Object.keys(formateThread).length === 0 && (
                          <StyledContainer>No comment</StyledContainer>
                        )}
                      </ThreadContainer>
                    </ThreadsBox>
                  </>
                )}
              </RightBlock>
            </ContentWrapper>
          </>
        ) : (
          <Loader />
        )}
      </PageWrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  > div {
    > div {
      &:nth-child(2) {
        padding-inline: 0px;
      }
    }
  }
`;

const StyledText = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  background-color: ${Colors.offBlack};
  padding: 10px;
  border-bottom: 1px solid ${Colors.offBlue};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const PdfBlock = styled.div`
  position: relative;
  width: 100%;
  margin: 0 10px;
`;

const DocumentContainer = styled.div<{ isAddMode: boolean }>`
  min-height: 600px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;

  ${props => props.isAddMode && `cursor: url(${SVG.point}) 12 22, move;`}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Black};
  opacity: 0.3;
`;

const PointerImgContainer = styled.div<{
  x: number;
  y: number;
  accent: boolean;
}>`
  position: absolute;
  top: ${props => props.y - 14}px;
  left: ${props => props.x - 12}px;
  transition: all 150ms linear;
  cursor: pointer;
  border-radius: 50%;
  border-bottom-left-radius: 0px;
  background-color: ${Colors.offBlue};
  > img {
    padding: 7px;
  }
`;

const RightBlock = styled.div`
  min-width: 350px;
  flex-basis: 350px;
  height: 100%;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkGrey};
`;

const ThreadsBox = styled.div`
  border-left: 1px solid ${Colors.offBlue};
`;
const ThreadContainer = styled.div`
  max-height: 80vh;
  overflow-y: auto;

  ${scrollBarStyles}
`;
const Text = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.24)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 13px 8px;
  border-bottom: 1px solid ${Colors.offBlue};
`;

const StyledContainer = styled.div`
  text-align: center;
  font-size: ${rem(11.25)};
  margin-top: 5px;
`;

export default NdaComment;
