import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { UnreadEventsCount } from '../atoms';
import { Routes } from '../routes';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  unreadEvents: number;
};

function NavigationList({ unreadEvents }: Props) {
  return (
    <Wrapper>
      <Link to={Routes.account}>
        <Title>Home</Title>
      </Link>
      <Link to={Routes.projects}>
        <Title>Projects</Title>
        <UnreadEvents unreadEvents={unreadEvents} />
      </Link>
      <Link to={Routes.templates}>
        <Title>Templates</Title>
      </Link>
      <Link to={Routes.users}>
        <Title>My Network</Title>
      </Link>
      <Link to={Routes.plan}>
        <Title>Plan</Title>
      </Link>
      <Link to={Routes.reports}>
        <Title>Reports</Title>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Link = styled(NavLink)`
  padding: 12px 0;
  height: 44px;

  position: relative;
  display: flex;
  align-items: center;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.3)};
  color: ${Colors.LightTextGrey};
  text-decoration: none;
  margin: 0 25px;
  transition: all 0.6s ease;

  & > *:first-child {
    margin-right: 10px;
  }
  &:before {
    content: '';
    width: 0px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.6s ease;
  }
  &.active:before,
  &:hover:before {
    width: 30px;
  }
  &.active,
  &:hover {
    color: #fff;
  }
`;

const Title = styled.div`
  font-size: ${rem(11.3)};
`;

const UnreadEvents = styled(UnreadEventsCount)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1.75px;
  background-color: ${Colors.LightGrey};
`;

export default NavigationList;
