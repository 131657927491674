import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonEllipse } from '../../../../atoms';
import { useSubscription } from '../../../../hooks';
import { selectors } from '../../../../store';
import { Colors, Fonts, rem } from '../../../../themes';
import { StyledButton } from './PlanStatus';

type Props = {
  totalPrice?: number;
  selectedProjects?: string;
  selectedMembers?: string;
  projectPrice: number;
  memberPrice: number;
  setIsConfirmBuyProject: (bool: boolean) => void;
  handleUpgrade: (() => void) | undefined;
  isShowNote?: boolean;
};

const BuyAdditionalModal = ({
  totalPrice,
  selectedProjects,
  selectedMembers,
  projectPrice,
  memberPrice,
  setIsConfirmBuyProject,
  handleUpgrade,
  isShowNote,
}: Props) => {
  const subscriptionName = useSubscription();
  const user = useSelector(selectors.user);

  return (
    <>
      <Info>
        You selected
        {subscriptionName !== 'Bulge-Bracket' && (
          <ProductInfo>
            <span style={{ fontWeight: 'bold' }}>
              {Number(selectedProjects) === 1 ? 'Project' : 'Projects'}
            </span>
            <PriceInfo>
              <TotalPriceInfo>
                {selectedProjects || 0}
                <ProjectName>
                  {Number(selectedProjects) === 1 ? 'project' : 'projects'}
                </ProjectName>
                <ProjectName>|</ProjectName>
                {user.currencySymbol} {projectPrice}
                <ProjectName>per project</ProjectName>
              </TotalPriceInfo>
              <span
                style={{
                  marginLeft: '5px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                {' '}
                {user.currencySymbol}{' '}
                {(Number(selectedProjects) * projectPrice).toFixed(2)}
              </span>
            </PriceInfo>
          </ProductInfo>
        )}
        <MemberInfo>
          <span style={{ fontWeight: 'bold' }}>
            {Number(selectedMembers) === 1 ? 'Team Member' : 'Team Members'}
          </span>
          <PriceInfo>
            <TotalPriceInfo>
              {selectedMembers || 0}
              <ProjectName>
                {Number(selectedMembers) === 1 ? 'Team Member' : 'Team Members'}
              </ProjectName>
              <ProjectName>|</ProjectName>
              {user.currencySymbol}
              {memberPrice}
              <ProjectName>per Team Member</ProjectName>
            </TotalPriceInfo>
            <span
              style={{
                marginLeft: '5px',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {' '}
              {user.currencySymbol}
              {(
                (Number(selectedMembers) < 0 ? 0 : Number(selectedMembers)) *
                memberPrice
              ).toFixed(2)}
            </span>
          </PriceInfo>
        </MemberInfo>
        <Total>
          Total Price:{' '}
          <span>
            {user.currencySymbol} {(totalPrice || 0).toFixed(2)}
          </span>
        </Total>
        {isShowNote && (
          <NoteText>
            <Note>Info :</Note>
            Adding that many projects will cost you more than upgrading to
            Bulge-Bracket membership. Start saving money and upgrade directly to
            Bulge-Bracket to enjoy an unlimited number of projects
          </NoteText>
        )}
      </Info>
      <ButtonsBox>
        <CancelButton
          text="Cancel"
          onClick={() => setIsConfirmBuyProject(false)}
        />
        <StyledButton text="Confirm" onClick={handleUpgrade} />
      </ButtonsBox>
    </>
  );
};
const Note = styled.span`
  color: ${Colors.Black};
  font-weight: bold;
  margin-right: 2px;
`;
const NoteText = styled.div`
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  font-size: ${rem(10)};
  padding: 10px;
  padding-bottom: 0px;
`;
const Info = styled.div`
  padding: 10px;

  list-style: none;
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  font-size: ${rem(13)};
`;
const ProductInfo = styled.div`
  margin-top: 6px;
  padding: 10px;
`;

const MemberInfo = styled.div`
  margin-top: 0;
  padding: 0 10px 10px 10px;
`;
const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  justify-content: space-between;
`;

const TotalPriceInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(11.25)};
`;

const ProjectName = styled.span`
  margin: 5px;
`;
const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }

  & > button {
    width: auto;
    height: auto;
    padding: 13px 41px;
    border-radius: 6px;
    font-size: ${rem(11.25)};
    white-space: nowrap;
    cursor: pointer;
    margin-top: 20px;
  }
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
`;
const CancelButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.SkySecondBlue};
  background-color: ${Colors.SkySecondBlue};

  color: ${Colors.DarkBlue};

  &:hover {
    background-color: ${Colors.SkySecondBlue};
    border: 1px solid ${Colors.SkySecondBlue};
    color: ${Colors.DarkBlue};
  }
`;

export default BuyAdditionalModal;
