import { configureStore, createAction, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import {
  CompanyType,
  LoginDataType,
  Permissions,
  Subscription,
  UserData,
} from '../api';
import { DefaultFreePlan } from '../const';
import { middleware, persistConfig } from './persistConfig';

// Actions
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const REFRESH_USER = 'REFRESH_USER';
const UPDATE_COMPANY = 'UPDATE_COMPANY';
const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
const GET_PERMISSIONS = 'GET_PERMISSIONS';
const REFRESH_ACCESS = 'REFRESH_ACCESS_TOKEN';
const LOGOUT = 'LOGOUT';
const VERIFY_EMAIL = 'VERIFY_EMAIL';

const initialUserState = {
  id: null,
  email: null,
  firstName: '',
  lastName: '',
  avatar: null,
  createdAt: null,
  language: null,
  location: null,
  company: null,
  linkedinLink: null,
  referralKey: null,
  sign: '',
  isMember: false,
  ownerOfProjects: [],
  memberOfProjects: [],
  signerOfProjects: [],
  lastSeenEventId: null,
};

const initSubscription = {
  product: {
    quantity: null,
    status: null,
    stripeId: '',
    cancelAtPeriodEnd: false,
    cancelAt: null,
    memberAllowed: null,
    projectAllowed: null,
  },
  additionalProjects: 0,
};

export type StoreSubscription = {
  product: Subscription;
  additionalProjects: number;
};

// Reducers
const user = createReducer<UserData>(initialUserState, {
  [LOGIN_SUCCESS]: (_, { payload }) => ({ ...payload.user }),
  [REFRESH_USER]: (_, { payload }) => payload,
  [UPDATE_COMPANY]: (store, { payload }) => ({ ...store, company: payload }),
  [LOGOUT]: (_, __) => initialUserState,
});

const subscription = createReducer<StoreSubscription>(initSubscription, {
  [UPDATE_SUBSCRIPTION]: (store, { payload }) => ({ ...store, ...payload }),
  [CANCEL_SUBSCRIPTION]: () => initSubscription,
});

const permissions = createReducer<null | string[]>(null, {
  [GET_PERMISSIONS]: (_, { payload }) => payload,
  [LOGOUT]: (_, __) => null,
});

const accessToken = createReducer(null, {
  [LOGIN_SUCCESS]: (_, { payload }) => payload.accessToken,
  [REFRESH_ACCESS]: (_, { payload }) => payload,
  [LOGOUT]: (_, __) => null,
});

const refreshToken = createReducer(null, {
  [LOGIN_SUCCESS]: (_, { payload }) => payload.refreshToken,
  [LOGOUT]: (_, __) => null,
});

const isAuthenticated = createReducer(true, {
  [LOGIN_SUCCESS]: (_, __) => true,
  [REFRESH_ACCESS]: (_, __) => true,
  [LOGOUT]: (_, __) => false,
  [VERIFY_EMAIL]: (_, __) => false,
});

// Action Creators
export const loginUser = createAction<LoginDataType>(LOGIN_SUCCESS);
export const refreshUser = createAction<UserData>(REFRESH_USER);
export const updateCompaty = createAction<CompanyType>(UPDATE_COMPANY);
export const updateSubscription =
  createAction<Partial<StoreSubscription>>(UPDATE_SUBSCRIPTION);
export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION);
export const getPermissions = createAction<Permissions[]>(GET_PERMISSIONS);
export const refreshAccessToken = createAction<string>(REFRESH_ACCESS);
export const logout = createAction(LOGOUT);
export const verifyEmail = createAction(VERIFY_EMAIL);

// Main Store
export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      user,
      subscription,
      permissions,
      accessToken,
      refreshToken,
      isAuthenticated,
    }),
  ),
  middleware,
});

type StoreType = ReturnType<typeof store.getState>;

// Persist Store
export const persistor = persistStore(store);

// Store Selectors
export const selectors = {
  user: (mainStore: StoreType) => mainStore.user,

  subscription: (mainStore: StoreType) => mainStore.subscription,

  maxAllowedMembers: (mainStore: StoreType) =>
    mainStore.subscription.product.quantity ?? DefaultFreePlan.members,

  defaultMembers: (mainStore: StoreType) =>
    mainStore.subscription.product.memberAllowed ?? DefaultFreePlan.members,

  subscriptionCost: (mainStore: StoreType) => {
    const quantity =
      mainStore.subscription.product.quantity ?? DefaultFreePlan.members;
    const cost =
      (mainStore.subscription.product.product?.price.unitAmount ??
        DefaultFreePlan.cost) / 100;
    return quantity * cost;
  },

  subscriptionInterval: (mainStore: StoreType) =>
    mainStore.subscription.product.product?.price.interval ??
    DefaultFreePlan.interval,

  maxAllowedProjects: (mainStore: StoreType) => {
    const { product, additionalProjects } = mainStore.subscription;
    const projectsAllowed = product.projectAllowed ?? DefaultFreePlan.projects;
    return projectsAllowed === -1
      ? Infinity
      : projectsAllowed + additionalProjects;
  },

  permissions: (mainStore: StoreType) => mainStore.permissions,

  accessToken: (mainStore: StoreType) => mainStore.accessToken,

  refreshToken: (mainStore: StoreType) => mainStore.refreshToken,

  isAuthenticated: (mainStore: StoreType) => mainStore.isAuthenticated,
};
