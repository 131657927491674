import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  me as apiMe,
  members as apiMembers,
  OneNdaType,
  OneProjectType,
  projects as apiProjects,
} from '../../../api';
import SVG from '../../../assets/svg';
import SolidButton from '../../../atoms/SolidButton';
import { usePermissions } from '../../../hooks';
import { UsersLogos } from '../../../molecules';
import { Routes } from '../../../routes';
import { refreshUser, selectors } from '../../../store';
import { Colors, Fonts, rem } from '../../../themes';
import { MemberTypeChecked as Member } from '../../oneProject/components/TeamMembers/OneMember';

type Props = {
  currentNda: OneNdaType | null;
  handleSignClick: () => void;
  projectId: string;
  onDoubleClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  setIsAddPointerMode: React.Dispatch<React.SetStateAction<boolean>>;
};

function CommentHeader({
  currentNda,
  handleSignClick,
  projectId,
  onDoubleClick,
  setIsAddPointerMode,
}: Props) {
  const [allUserMembers, setAllUserMembers] = useState<Member[]>([]);
  const [project, setProject] = useState<OneProjectType | null>(null);
  const { canAddPointer, canAddCommentthread } = usePermissions();
  const user = useSelector(selectors.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const isSignerOfProject = user.signerOfProjects.includes(Number(projectId));

  const projectBranch = project?.projectBranches.find(t =>
    t.signers.includes(user.id as number),
  );

  useEffect(() => {
    // refresh user for getting current user projects
    apiMe.refreshUser().then(({ data }) => dispatch(refreshUser(data)));
  }, [dispatch]);

  useEffect(() => {
    apiProjects
      .getOneProject(Number(projectId))
      .then(response => setProject(response.data))
      .catch(error => error.status === '404');
  }, [projectId]);

  useEffect(() => {
    apiMembers.getAllMembers().then(res => {
      const checkedMembers = res.data.map(member => ({
        ...member,
        checked: false,
      }));
      setAllUserMembers(checkedMembers);
    });
  }, []);

  useEffect(() => {
    apiMe.refreshUser().then(({ data }) => dispatch(refreshUser(data)));
  }, [dispatch]);

  return (
    <Wrapper>
      <LeftSection>
        <BackImage
          src={SVG.left}
          alt="left"
          onClick={() => history.push(`${Routes.projects}/${projectId}`)}
        />
        <ImageContainer>
          <Image src={SVG.document} alt="document" />
        </ImageContainer>
        {currentNda && (
          <FlexBox>
            <StyledName>{currentNda && currentNda.name}</StyledName>
            <StyledText>Edited 10 min ago</StyledText>
          </FlexBox>
        )}
      </LeftSection>
      <RightSection>
        <InfoSection>
          <ImageContainer>
            {currentNda &&
              !currentNda?.isRecalled &&
              !currentNda?.isSigned &&
              canAddPointer &&
              canAddCommentthread &&
              (projectBranch?.allowComments === true ||
                project?.user === user.id) && (
                <CommentImage
                  src={SVG.comment}
                  alt="comment"
                  onClick={() => setIsAddPointerMode(true)}
                  onDoubleClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                    onDoubleClick(e)
                  }
                />
              )}
          </ImageContainer>
          <UsersLogos
            showedAvatars={3}
            users={allUserMembers.map(member => ({
              logo: member.avatar,
              namesLetters: `${
                member.firstName && member.lastName
                  ? member.firstName[0] + member.lastName[0]
                  : member.email[0]
              }`,
            }))}
          />
        </InfoSection>
        {currentNda &&
          !currentNda?.isRecalled &&
          !currentNda?.isSigned &&
          canAddPointer &&
          canAddCommentthread &&
          (projectBranch?.allowComments === true ||
            project?.user === user.id) && (
            <StyledButton
              type="button"
              text="Comment"
              onClick={() => setIsAddPointerMode(true)}
              onDoubleClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                onDoubleClick(e)
              }
            />
          )}

        {isSignerOfProject &&
          !currentNda?.isSigned &&
          !currentNda?.isRecalled && (
            <StyledButton
              type="button"
              text="Sign"
              onClick={() => handleSignClick()}
            />
          )}
      </RightSection>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 3px 15px;
  border-bottom: 1px solid ${Colors.offBlue};
  display: flex;
  align-item: center;
`;

const LeftSection = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const RightSection = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`;
const Image = styled.img`
  padding: 13px;
  display: flex;
  align-items: center;
`;

const CommentImage = styled(Image)`
  cursor: pointer;
`;
const BackImage = styled(Image)`
  &:hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: ${Colors.offBlue};
`;

const StyledName = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const StyledText = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(7.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(10)};
  padding: 20px;
`;
export default CommentHeader;
