import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { isExpiredAccessToken } from '../api/authApi';
import { selectors } from '../store';

type RouteProps = {
  component: ReactElement;
  path: string;
  redirectTo: string;
  restricted?: boolean;
  exact?: boolean;
};

export default function PublicRoute({
  component,
  redirectTo,
  restricted,
  exact,
  path,
}: RouteProps) {
  const isAuth = useSelector(selectors.isAuthenticated);
  return (
    <>
      {isAuth && !isExpiredAccessToken() && restricted ? (
        <Redirect to={redirectTo} />
      ) : (
        <Route exact={exact} path={path}>
          {component}
        </Route>
      )}
    </>
  );
}
