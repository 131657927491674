import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Comment,
  comments as apiComments,
  NegotiationStatus,
  UserData,
} from '../../../api';
import { Textarea, TextButton } from '../../../atoms';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  isEmptyInput,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import { ActionsButton } from '../../../organisms';
import { selectors } from '../../../store';
import { Colors, Fonts, rem } from '../../../themes';
import CommentsActions from './CommentsActions';

type Props = {
  comment: Comment;
  threadStatus: NegotiationStatus;
  updateComment: (newComment: Comment) => void;
  deleteOneComment: (commentId: number) => void;
};

function OneComment({
  comment,
  threadStatus,
  updateComment,
  deleteOneComment,
}: Props) {
  const { showError } = useNotification();

  const [isEditMode, setIsEditMode] = useState(false);
  const [prevCommentText, setPrevCommentText] = useState('');
  const [editedComment, setEditedComment] = useState({
    value: comment.text,
    errorMsg: '',
  });
  const user: UserData = useSelector(selectors.user);

  const [isLoading, setIsLoading] = useState(false);

  const [isHidePopover, setIsHidePopover] = useState(false);
  useEffect(() => {
    isHidePopover && setIsHidePopover(false);
  }, [isHidePopover]);

  const editComment = () => {
    if (isLoading) return;
    if (isEmptyInput(editedComment, setEditedComment, Errors.EnterField))
      return;

    setIsLoading(true);
    apiComments
      .updateComment(comment.id, { text: editedComment.value })
      .then(({ data }) => {
        updateComment(data);
        setIsEditMode(false);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.text, setEditedComment);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteComment = () => {
    apiComments
      .deleteComment(comment.id)
      .then(() => deleteOneComment(comment.id))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      });
  };

  const isShowActionsButton =
    threadStatus !== NegotiationStatus.ApprovedByBothParties &&
    threadStatus !== NegotiationStatus.RejectedByBothParties;

  return (
    <Container>
      <FlexBox>
        <NameText>
          {comment.author}
          <StyledSpan>
            {formatDistanceToNow(new Date(comment.created), {
              addSuffix: true,
            }).replace('about', '')}
          </StyledSpan>
        </NameText>

        {isShowActionsButton && user && user.id === Number(comment.authorId) && (
          <ThreadActionsButton
            isHidePopover={isHidePopover}
            offsetX={-150}
            offsetY={20}
          >
            <CommentsActions
              onEditClick={() => {
                setIsHidePopover(true);
                setPrevCommentText(editedComment.value);
                setIsEditMode(true);
              }}
              onDeleteClick={deleteComment}
            />
          </ThreadActionsButton>
        )}
      </FlexBox>
      <StyledTextarea
        disabled={!isEditMode || isLoading}
        value={editedComment.value}
        onChange={handleInputChange(setEditedComment)}
        onBlur={handleInputBlur(editedComment, setEditedComment)}
        placeholder="Write your comment"
        error={editedComment.errorMsg}
      />
      {isEditMode && (
        <>
          <StyledTextButton type="button" onClick={editComment}>
            {isLoading ? 'save changes...' : 'save changes'}
          </StyledTextButton>
          <StyledTextButton
            type="button"
            onClick={() => {
              if (isLoading) return;
              setEditedComment(prev => ({ ...prev, value: prevCommentText }));
              setIsEditMode(false);
            }}
          >
            cancel changes
          </StyledTextButton>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  background-color: ${Colors.offBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(9)};
  padding: 4px 12px 0px 0px;
  border-bottom: 1px solid ${Colors.DarkSkyBlue};
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-left: 5px;
  }
  /* margin-bottom: 10px; */
`;

const StyledTextarea = styled(Textarea)`
  font-size: ${rem(9)};
  background-color: ${Colors.DarkSkyBlue};

  ::placeholder {
    font-size: ${rem(9)};
  }

  :disabled {
    background-color: ${Colors.Transparent};
    color: ${Colors.DarkGrey};
    border-color: ${Colors.Transparent};
  }
`;

const StyledTextButton = styled(TextButton)`
  font-size: ${rem(9)};

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const ThreadActionsButton = styled(ActionsButton)`
  transform: rotate(90deg);
  & > * {
    width: 2px;
    height: 3px;
  }
`;

const NameText = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8.75)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledSpan = styled.span`
  color: ${Colors.semiOffGrey};
  font-size: ${rem(7.5)};
  margin-left: 6px;
`;
export default OneComment;
