import { AxiosResponse } from 'axios';

import api from '../authApi';
import { CreateMembersRequest, MemberType } from './types';

const getAllMembers = (): Promise<AxiosResponse<MemberType[]>> =>
  api.get('/members/?is_deleted=False');

const createMember = (
  body: CreateMembersRequest,
): Promise<AxiosResponse<MemberType>> => api.post('/members/', body);

const deleteMember = (id: number): Promise<AxiosResponse> =>
  api.delete(`/members/${id}/`);

const members = { getAllMembers, createMember, deleteMember };

export default members;
