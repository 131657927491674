import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

const RandomColor = [
  '#e90000',
  '#e98c00',
  '#e9e500',
  '#55e900',
  '#00e99b',
  '#00cee9',
  '#0023e9',
  '#8000e9',
  '#e100e9',
  '#e90000',
];

type Props = {
  logo: string | null;
  namesLetters: string;
  width?: number;
};

function UserLogo({ logo, namesLetters, width }: Props) {
  const randomNumber = namesLetters.charCodeAt(0) % RandomColor.length;

  return (
    <Container width={width}>
      {logo ? (
        <img src={logo} alt="logo" />
      ) : (
        <ColoredName color={RandomColor[randomNumber]}>
          {namesLetters}
        </ColoredName>
      )}
    </Container>
  );
}

const Container = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => props.width ?? 47}px;
  height: ${props => props.width ?? 47}px;
  border-radius: 50%;
  overflow: hidden;

  & > * {
    font-family: ${Fonts.Sofia};
    font-size: ${props => (props.width ? rem(props.width / 3) : rem(10))};
    color: ${Colors.White};

    width: ${props => props.width ?? 47}px;
    height: ${props => props.width ?? 47}px;
  }
`;

const ColoredName = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
  padding-top: 2px;
`;

export default UserLogo;
