import { useCallback, useRef } from 'react';

import { FiltersType } from '../pages/projects';

function useDebounce(callback: (arg: FiltersType) => void, delay: number) {
  const timer = useRef();

  const debouncedCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      // @ts-ignore: Unreachable code error
      timer.current = window.setTimeout(() => {
        // @ts-ignore: Unreachable code error
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return debouncedCallback;
}

export default useDebounce;
