import { useMachine } from '@xstate/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createMachine } from 'xstate';

import { OneNdaType, templates as apiTemplates, TemplateType } from '../../api';
import SVG from '../../assets/svg';
import { ModalBox, SelectOption } from '../../atoms';
import { baseTransition, BoxShadow, Colors, Fonts, rem } from '../../themes';
import FromTemplate from './FromTemplate';
import UploadNda from './UploadNda';

enum Choice {
  NotSelected = 'notSelected',
  Template = 'template',
  New = 'new',
}

const choiseMachine = createMachine({
  id: 'choise',
  initial: Choice.NotSelected,
  states: {
    [Choice.NotSelected]: {},
    [Choice.Template]: {},
    [Choice.New]: {},
  },
  on: {
    [Choice.NotSelected]: { target: Choice.NotSelected },
    [Choice.New]: { target: Choice.New },
    [Choice.Template]: { target: Choice.Template },
  },
});

type Props = {
  addNda: (nda: OneNdaType) => void;
  branchId?: number;
  closeModal: () => void;
  addTemplateNda?: (template: TemplateType, templateName: string) => void;
  addUploadedNda?: (file: File, fileName: string) => void;
};

function AddNda({
  addNda,
  branchId,
  closeModal,
  addTemplateNda,
  addUploadedNda,
}: Props) {
  const [{ value: choise }, sendChoise] = useMachine(choiseMachine);

  const [templates, setTemplates] = useState<SelectOption[] | null>(null);
  const [allTemplates, setAllTemplates] = useState<TemplateType[]>([]);

  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    apiTemplates.getAllTemplates().then(({ data }) => {
      setAllTemplates(data);
      setTemplates(
        data.map(template => ({
          value: template.id.toString(),
          label: template.name,
        })),
      );
    });
  }, [projectId]);

  if (choise === Choice.Template) {
    return (
      <ModalBox
        title={
          Array.isArray(templates) && templates.length === 0
            ? 'You don’t have a template'
            : 'Choose NDA from templates'
        }
      >
        <FromTemplate
          addNda={addNda}
          projectId={Number(projectId)}
          branchId={branchId}
          goToChoices={() => sendChoise(Choice.NotSelected)}
          addTemplateNda={addTemplateNda}
          templates={templates}
          allTemplates={allTemplates}
        />
      </ModalBox>
    );
  }

  if (choise === Choice.New) {
    return (
      <ModalBox title="Upload NDA from your files">
        <UploadNda
          addNda={addNda}
          projectId={Number(projectId)}
          branchId={branchId}
          goToChoices={() => sendChoise(Choice.NotSelected)}
          addUploadedNda={addUploadedNda}
        />
      </ModalBox>
    );
  }

  return (
    <ModalBox title="Choose an NDA" onCloseButtonClick={closeModal}>
      <>
        <Info>
          You can add an NDA from templates saved in the service or upload one
          from your computer.
        </Info>

        <ChooseContainer>
          <ChoiseOption onClick={() => sendChoise(Choice.Template)}>
            <Image src={SVG.fromTemplate} alt="From template" />
            <Description>From template</Description>
          </ChoiseOption>
          <ChoiseOption onClick={() => sendChoise(Choice.New)}>
            <Image src={SVG.upload} alt="Upload a file" />
            <Description>Upload a file</Description>
          </ChoiseOption>
        </ChooseContainer>
      </>
    </ModalBox>
  );
}

const ChooseContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
`;

const Info = styled.div`
  text-align: center;
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
`;

const ChoiseOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: ${Colors.White};
  cursor: pointer;
  transition: ${baseTransition};
  border: 0.5px solid ${Colors.LightestGrey};
  border-radius: 15px;
  box-shadow: ${BoxShadow.Base};

  &:hover {
    background-color: ${Colors.LightGrey};
  }
`;

const Image = styled.img`
  width: 70px;
`;

const Description = styled.p`
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(13)};
  margin-bottom: 0;
`;

export default AddNda;
