import ReactSelect from 'react-select';

import { Colors, Fonts, rem } from '../themes';

export const customSelectStyles = {
  container: (provided: object) => ({
    ...provided,
    fontSize: rem(11),
    fontFamily: Fonts.Sofia,
    border: '0.5px solid #E5E5E5',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    width: 70,
    padding: '0 0 0 8.75px',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
  control: (provided: object) => ({
    ...provided,
    border: 'none',
    minHeight: '20px',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided: object) => ({
    ...provided,
    color: Colors.DarkGrey,
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: object) => ({
    ...provided,
    '& > *': {
      cursor: 'pointer',
      ':active': {
        padding: 0,
      },
    },
  }),
};

type SelectOptions = { value: string; label: string };

type SelectProps = {
  value: SelectOptions | null;
  onChange: (value: SelectOptions | null) => void;
  options: SelectOptions[];
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
};

function Select({
  value,
  onChange,
  options,
  className,
  placeholder,
  isClearable,
  isDisabled,
  ...props
}: SelectProps) {
  return (
    <ReactSelect
      isClearable={isClearable}
      value={value}
      onChange={onChange}
      styles={customSelectStyles}
      options={options}
      className={className}
      placeholder={placeholder}
      isDisabled={isDisabled}
      // remove border  onFocus
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: 'neutral0',
        },
      })}
      {...props}
    />
  );
}

export default Select;
