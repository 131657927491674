import { useState } from 'react';
import styled from 'styled-components';

import { DashboardGroupType, EventType } from '../../../api';
import { SelectOption } from '../../../atoms';
import { ActivitiesBox } from '../../../organisms';

type Props = {
  projectId: number;
  branchId: number;
  allBranches: DashboardGroupType[];
  events: EventType[];
};

const allOption = {
  value: 'all',
  label: 'All',
};

function ProjectActivity({ projectId, branchId, allBranches, events }: Props) {
  const [oneBranch] = useState<SelectOption | null>(allOption);
  // const [branches, setBranches] = useState<SelectOption[]>([]);

  // useEffect(() => {
  //   // const eventBranches = events
  //   //   .filter(event => event.project === projectId)
  //   //   .reduce<number[]>(
  //   //     (acc, event) =>
  //   //       acc.includes(event.projectBranch)
  //   //         ? acc
  //   //         : [...acc, event.projectBranch],
  //   //     [],
  //   //   )
  //   //   .filter(oneBranchId => typeof oneBranchId === 'number')
  //   //   .map(oneBranchId => ({
  //   //     value: `${oneBranchId}`,
  //   //     label:
  //   //       allBranches.find(branch => branch.id === oneBranchId)?.name ?? '',
  //   //   }));

  //   // setBranches([allOption, ...eventBranches]);
  //   setOneBranch(allOption);
  // }, [projectId, allBranches, branchId, events]);

  const filteredEvents =
    oneBranch?.value === 'all'
      ? events.filter(e => e.project === projectId)
      : events
          .filter(e => e.project === projectId)
          .filter(e => e.projectBranch === Number(oneBranch?.value));

  const shownEvents = Number.isNaN(branchId)
    ? filteredEvents
    : events.filter(e => e.projectBranch === branchId);

  return (
    <>
      {/* {branches.length >= 2 && Number.isNaN(branchId) && (
        <StyledSelect
          options={branches}
          value={oneBranch}
          onChange={setOneBranch}
        />
      )} */}
      <StyledActivitiesBox events={shownEvents} />
    </>
  );
}

const StyledActivitiesBox = styled(ActivitiesBox)`
  top: 0px;
  right: 0px;
  position: relative;
  width: 100%;
`;

// const StyledSelect = styled(Select)`
//   box-shadow: ${BoxShadow.Base};
// `;

export default ProjectActivity;
