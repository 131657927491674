import { ReactElement } from 'react';
import Modal from 'react-modal';

import { Colors } from '../../themes';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(17, 17,	17, 0.2)',
    zIndex: '100',
  },

  content: {
    borderRadius: '18px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.White,
    border: 'none',
    padding: '0',
  },
};

Modal.setAppElement('#modalRoot');

type Props = {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  children: ReactElement;
  className?: string;
  disabledOverlayClick?: boolean;
};

function CustomMadal({
  modalIsOpen,
  setModalIsOpen,
  children,
  className,
  disabledOverlayClick,
}: Props) {
  return (
    <Modal
      className={className}
      isOpen={modalIsOpen}
      onRequestClose={() => !disabledOverlayClick && setModalIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
}

export default CustomMadal;
