import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { EventType } from '../api';
import { Logo } from '../atoms';
import { NavigationList, Profile } from '../molecules';
import Footer from '../pages/landing/Footer';
import { Routes } from '../routes';
import { selectors } from '../store';
import { Colors } from '../themes';

type Props = {
  className?: string;
  children: ReactElement | ReactElement[];
};

function PageWrapper({ className, children }: Props) {
  const user = useSelector(selectors.user);
  const isAuth = useSelector(selectors.isAuthenticated);
  const [unreadEvents, setUnreadEvents] = useState<EventType[]>([]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderContainer>
          <NavLink to={isAuth ? Routes.account : Routes.main}>
            <Logo size="small" />
          </NavLink>
          {isAuth && (
            <>
              <NavigationList unreadEvents={unreadEvents.length} />
              <Profile
                avatar={user.avatar}
                firstName={user.firstName}
                lastName={user.lastName}
                companyName={user.company?.name ?? ''}
              />
            </>
          )}
        </HeaderContainer>
      </HeaderWrapper>
      <Container className={className}>{children}</Container>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 1200px;
  background-color: ${Colors.BackgroundGrey};
`;

const Container = styled.div`
  width: 1319px;
  min-height: calc(100vh - 60px);
  margin: 0 auto;
  padding: 15px 0;
`;

const HeaderWrapper = styled.div`
  background-color: ${Colors.LightBlue};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1320px;
  height: 87px;
  margin: 0 auto;
  justify-content: space-between;
`;

export default PageWrapper;
