import { format } from 'date-fns';
import { MouseEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { billing as apiBilling, Invoice as InvoiceType } from '../../../api';
import { Popover } from '../../../atoms';
import { formatDateMode } from '../../../const';
import { useNotification } from '../../../hooks';
import { Loader } from '../../../molecules';
import { selectors } from '../../../store';
import { BoxShadow, Colors, Fonts, rem } from '../../../themes';

function Invoices() {
  const { showError } = useNotification();

  const user = useSelector(selectors.user);
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);

  const [showInvoices, setShowInvoices] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const getInvoices = () => {
    setIsLoading(true);
    apiBilling
      .getInvoices()
      .then(({ data }) => {
        setInvoices(data.filter(invoice => invoice.paid));
        setShowInvoices(true);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {showInvoices && (
        <Popover
          onOverlayClick={(event: MouseEvent) => {
            if (event.currentTarget !== event.target) return;
            setShowInvoices(false);
          }}
          refElem={anchorEl.current}
          offsetY={-60}
          offsetX={-90}
        >
          <Container>
            {invoices.length ? (
              invoices.map(invoice => (
                <p key={invoice.id}>
                  <a
                    href={invoice.hostedInvoiceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.currencySymbol} {invoice.total / 100}. Date:
                    {format(new Date(invoice.created), formatDateMode.dateTime)}
                  </a>
                </p>
              ))
            ) : (
              <h3>You don&lsquo;t have any invoices</h3>
            )}
          </Container>
        </Popover>
      )}

      {isLoading && <Loader />}

      <InvoicesButton ref={anchorEl} onClick={getInvoices}>
        View Invoices
      </InvoicesButton>
    </>
  );
}

const InvoicesButton = styled.div`
  color: ${Colors.DarkBlue};
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-flex;
  padding: 15px 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${Colors.LightSecondBlue};
`;

const Container = styled.div`
  max-height: 400px;
  padding: 10px 20px;

  overflow: auto;

  background-color: ${Colors.White};
  border-radius: 10px;
  border: 1px solid ${Colors.LightBlue};
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  box-shadow: ${BoxShadow.Secondary};
`;

export default Invoices;
