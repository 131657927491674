import { FormControlLabel, SwitchProps } from '@mui/material';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

import StyledSwitch from '../atoms/SwitchButton';
import { Fonts } from '../themes';

export interface Props extends SwitchProps {
  controlName: string;
  label: string;
  labelPlacement: 'end' | 'start' | 'top' | 'bottom' | undefined;
  onSwitchChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function SwitchControl({
  controlName,
  label,
  labelPlacement,
  onSwitchChange,
  ...rest
}: Props) {
  return (
    <>
      <FormControl
        name={controlName}
        control={<StyledSwitch {...rest} onChange={onSwitchChange} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </>
  );
}

const FormControl = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: ${Fonts.Sofia};
  }
`;
export default SwitchControl;
