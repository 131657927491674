/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  company as apiCompany,
  me as apiMe,
  members as apiMembers,
  projects as apiProjects,
} from '../../api';
import { GetCompanyOwnerType } from '../../api/company';
import SVG from '../../assets/svg';
import { Modal, ModalBox } from '../../atoms';
import { useNotification } from '../../hooks';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import { Routes } from '../../routes';
import { refreshUser, selectors } from '../../store';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';
import {
  ImageModal,
  MyCompany,
  MyProfile,
  SignatureBlock,
  Team,
} from './components';

function Account() {
  const { showError } = useNotification();
  const dispatch = useDispatch();
  const user = useSelector(selectors.user);

  const [imageUrl, setImageUrl] = useState('');
  const [isAddedSign, setIsAddedSign] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [membersCount, setMembersCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [companyInfo, setCompanyInfo] = useState<GetCompanyOwnerType | null>(
    null,
  );

  useEffect(() => {
    apiCompany.getCompany().then(({ data }) => setCompanyInfo(data[0]));
  }, []);

  const maxAllowedMembers = useSelector(selectors.maxAllowedMembers);
  const maxAllowedProjects = useSelector(selectors.maxAllowedProjects);

  const refreshSignature = () => {
    setIsLoading(true);
    apiMe
      .getMySign()
      .then(({ data }) => URL.createObjectURL(data))
      .then(newImageUrl => {
        dispatch(refreshUser({ ...user, sign: newImageUrl }));
        setImageUrl(newImageUrl);
        setIsAddedSign(true);
      })
      .catch(error => {
        setIsAddedSign(false);
        setImageUrl('');
        showError(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    refreshSignature();
  }, []);

  useEffect(() => {
    apiProjects.getProjects().then(({ data }) => {
      const ownProjects = data?.filter(project => project.created);

      return setProjectsCount(ownProjects.length);
    });
  }, []);

  useEffect(() => {
    apiMembers.getAllMembers().then(({ data }) => setMembersCount(data.length));
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <SideMenu />
        {!isLoading ? (
          <>
            <div>
              <Modal modalIsOpen={isModalOpen} setModalIsOpen={setIsModalOpen}>
                <ModalBox
                  title="Change the picture"
                  onCloseButtonClick={() => setIsModalOpen(false)}
                >
                  <ImageModal setIsModalOpen={setIsModalOpen} />
                </ModalBox>
              </Modal>
              <FlexBox>
                <LeftBox>
                  <ProfileBoxContainer>
                    <TextProfile>Profile</TextProfile>
                    <Profile>
                      <Container>
                        {user.avatar ? (
                          <Avatar src={user.avatar} alt="logo" />
                        ) : (
                          <ColoredName>
                            {`${user.firstName[0]}${user.lastName[0]}`}
                          </ColoredName>
                        )}
                      </Container>
                      <UploadButton onClick={() => setIsModalOpen(true)}>
                        Upload Photo
                      </UploadButton>
                    </Profile>
                  </ProfileBoxContainer>
                  {!user.isMember && (
                    <ProfileBoxContainer>
                      <TextSignature>Signature</TextSignature>
                      <SignatureBlock
                        refreshSignature={refreshSignature}
                        imageUrl={imageUrl}
                        isAddedSign={isAddedSign}
                      />
                    </ProfileBoxContainer>
                  )}
                </LeftBox>
                <RightBox>
                  <MyProfile />
                </RightBox>
              </FlexBox>
            </div>

            <RowWrapper>
              <CompanyWrapper>
                <UsedInfo>
                  <BilledCostInfo to={`${Routes.plan}`}>
                    <AddMemberBG>
                      <img src={SVG.billingInfo} alt="billing" width="20px" />
                    </AddMemberBG>
                    <ProjectWrapper>
                      <BilledCostText>Current Plan</BilledCostText>
                      <BilledCost>
                        {companyInfo?.activeSubscription
                          ? companyInfo?.activeSubscription.product?.name
                          : 'Free'}
                      </BilledCost>
                    </ProjectWrapper>
                  </BilledCostInfo>

                  <BilledCostInfo to={`${Routes.plan}`}>
                    <AddMemberBG>
                      <img src={SVG.profileAvtar} alt="avtar" width="22px" />
                    </AddMemberBG>
                    <ProjectWrapper>
                      Team Members
                      <span
                        style={{
                          color: `${Colors.DarkBlue}`,
                          fontSize: `${rem(12.5)}`,
                        }}
                      >
                        {membersCount}/{maxAllowedMembers}
                      </span>{' '}
                    </ProjectWrapper>
                  </BilledCostInfo>
                  <BilledCostInfo to={`${Routes.plan}`}>
                    <AddMemberBG>
                      <img src={SVG.file} alt="file" width="22px" />
                    </AddMemberBG>
                    <ProjectWrapper>
                      Projects
                      <span
                        style={{
                          color: `${Colors.DarkBlue}`,
                          fontSize: `${rem(12.5)}`,
                        }}
                      >
                        {projectsCount}/
                        {maxAllowedProjects === Infinity ? (
                          <span>&infin;</span>
                        ) : (
                          maxAllowedProjects
                        )}{' '}
                      </span>
                    </ProjectWrapper>
                  </BilledCostInfo>
                </UsedInfo>
                <BoxContainer>
                  <Text>My company</Text>
                  <MyCompany />
                </BoxContainer>
              </CompanyWrapper>
              <TeamBoxContainer>
                <Team
                  refreshSignature={refreshSignature}
                  companyInfo={companyInfo}
                  setCompanyInfo={setCompanyInfo}
                  setMembersCount={setMembersCount}
                />
              </TeamBoxContainer>
            </RowWrapper>
          </>
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
}

const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const BoxContainer = styled.div`
  cursor: pointer;
  border: 1px solid ${Colors.DarkSkyBlue};
  border-radius: 10px;
  background-color: ${Colors.White};
  padding: 30px;
`;

const ProfileBoxContainer = styled(BoxContainer)`
  min-height: 243px;
  padding: 30px;
`;

const TeamBoxContainer = styled(BoxContainer)`
  padding: 25px 20px 0px 30px;
`;
const Text = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0;
`;

const TextSignature = styled(Text)`
  padding: 0;
  margin-bottom: 20px;
`;
const TextProfile = styled(Text)`
  padding: 30px 0px 0px 30px;
  padding: 0;
  margin-bottom: 2px;
`;
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const FlexBox = styled.div`
  display: flex;
  margin-top: 25px;
  gap: 25px;
`;

const LeftBox = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 29px;
`;
const RightBox = styled.div`
  width: 75%;
  border: 1px solid ${Colors.DarkSkyBlue};
  border-radius: 10px;
  background-color: ${Colors.White};
  height: fit-content;
`;

const Container = styled.div`
  box-shadow: 0px 0px 20px 0px rgba(10, 67, 124, 0.3);
  margin-bottom: 15px;
  border-radius: 50%;
  width: 104px;
  height: 104px;
  border: 4px solid #fff;
`;

const ColoredName = styled.div`
  font-family: ${Fonts.Sofia};
  border-radius: 50%;
  background-color: ${Colors.SkyBlue};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadButton = styled.div`
  background-color: ${Colors.SkySecondaryBlue};
  padding: 13px 41px;
  border-radius: 6px;
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 36px;
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const UsedInfo = styled.div`
  margin-bottom: 25px;
  font-family: ${Fonts.Sofia};
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 17px;
  width: 100%;
`;

const BilledCostInfo = styled(Link)`
  height: 82px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(2, 48, 97, 0.2);
  background: ${Colors.White};
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  color: ${Colors.DarkBlack};
`;

const AddMemberBG = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.SkyBlue};
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${rem(10)};
`;

const BilledCostText = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const BilledCost = styled.div`
  color: ${Colors.DarkBlue};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export default Account;
