import styled from 'styled-components';

export const ItemsLayout = styled.div`
  min-height: 420px;
  margin-top: 10px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 15px;

  & > * {
    min-width: calc((100% - 80px) / 5);
  }
`;
