import { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

function FilterInput({
  value,
  onChange,
  placeholder,
  className,
  disabled,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <InputBox>
      <StyledInput
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder ?? 'Search...'}
        className={className}
        disabled={disabled}
      />
      {value.length > 0 && (
        <ClearIconBg onClick={handleClearClick}>
          <ClearIcon src={SVG.crossBlue} alt="clear" />
        </ClearIconBg>
      )}
      <SearchImgBg>
        <SearchImg src={SVG.search} alt="search" />
      </SearchImgBg>
    </InputBox>
  );
}

const InputBox = styled.div`
  position: relative;
  min-width: 250px;
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
`;

const SearchImgBg = styled.div`
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 0px 6px 6px 0px;
  background: #023061;
`;

const SearchImg = styled.img`
  position: absolute;
  top: 19px;
  right: 15px;
  width: 20px;
  filter: brightness(0) invert(1);
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 6px 30px 6px 10px;
  outline: none;
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 6px 0 0 6px;
  background-color: ${Colors.White};

  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkBlack};
  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(10)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
`;

const ClearIconBg = styled.div`
  position: absolute;
  top: 22px;
  right: 70px;
  width: 15px;
  height: 20px;
  cursor: pointer;
`;

const ClearIcon = styled.img`
  width: 100%;
`;

export default FilterInput;
