import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CreateIcon } from '../../../assets/svg/Create.svg';
import { ReactComponent as InviteAndTrackIcon } from '../../../assets/svg/Invite-and-track.svg';
import { ReactComponent as OpportunitiesIcon } from '../../../assets/svg/Opportunities.svg';
import { ReactComponent as SafeIcon } from '../../../assets/svg/Safe.svg';
import { ReactComponent as SendAndNegotiateIcon } from '../../../assets/svg/Send-and-negotiate.svg';
import { Colors, Fonts, rem } from '../../../themes';
import Create from './Create';
import Invite from './Invite';
import Opportunity from './Opportunity';
import Safe from './Safe';
import SendAndNegotiate from './SendAndNegotiate';

interface ListItemsProps {
  activeTab: boolean;
}

interface TabProps {
  label: string;
  onClick: (label: string) => void;
  activeTab: boolean;
}

function Tab({ label, onClick, activeTab }: TabProps) {
  const getImageSource = () => {
    switch (label) {
      case 'Send & Negotiate':
        return <SendAndNegotiateSvg activeTab={activeTab} />;
      case 'Opportunities':
        return <OpportunitiesSvg activeTab={activeTab} />;
      case 'Create':
        return <CreateSvg activeTab={activeTab} />;
      case 'Invite & Track':
        return <InviteAndTrackSvg activeTab={activeTab} />;
      case 'Safe':
        return <SafeSvg activeTab={activeTab} />;
      default:
        return '';
    }
  };

  return (
    <ListItems activeTab={activeTab} onClick={() => onClick(label)}>
      <Wrapper>
        {getImageSource()}
        {label}
      </Wrapper>
    </ListItems>
  );
}

interface TabsProps {
  children: React.ReactNode;
}

function Tabs({ children }: TabsProps) {
  const [activeTab, setActiveTab] = useState('Send & Negotiate');
  const switchTabs = (tab: string) => {
    setActiveTab(tab);
  };

  const tabComponents: { [key: string]: React.ReactNode } = {
    'Send & Negotiate': <SendAndNegotiate />,
    Opportunities: <Opportunity />,
    Create: <Create />,
    'Invite & Track': <Invite />,
    Safe: <Safe />,
  };

  return (
    <div>
      <List>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            const { label } = child.props;
            return (
              <Tab
                activeTab={activeTab === label}
                key={label}
                label={label}
                onClick={switchTabs}
              />
            );
          }
          return null;
        })}
      </List>
      {tabComponents[activeTab]}
    </div>
  );
}

function MessageTab() {
  return (
    <Tabs>
      <div label="Send & Negotiate" />
      <div label="Opportunities" />
      <div label="Create" />
      <div label="Invite & Track" />
      <div label="Safe" />
    </Tabs>
  );
}

const ListItems = styled.li<ListItemsProps>`
  color: ${Colors.DarkGrayishBlue};
  cursor: pointer;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(15)};

  ${props =>
    props.activeTab &&
    css`
      color: ${Colors.DarkBlue};

      ::after {
        content: '';
        position: relative;
        height: 3px;
        margin-top: 0.5rem;
        border-radius: 6px;
        background-color: ${Colors.NavyBlue};
        display: block;
      }
    `}
`;

const List = styled.ul`
  display: flex;
  list-style-type: none;
  border-bottom: 1px solid lightgray;
  width: 100%;
  gap: 90px;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    gap: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: 25px;
`;

const SendAndNegotiateSvg = styled(SendAndNegotiateIcon)<{
  activeTab: boolean;
}>`
  & > g > path {
    fill: ${props =>
      props.activeTab ? Colors.DarkBlue : Colors.DarkGrayishBlue};
  }
`;

const OpportunitiesSvg = styled(OpportunitiesIcon)<{ activeTab: boolean }>`
  & > g > path {
    fill: ${props =>
      props.activeTab ? Colors.DarkBlue : Colors.DarkGrayishBlue};
  }
`;

const CreateSvg = styled(CreateIcon)<{ activeTab: boolean }>`
  & > g > path {
    fill: ${props =>
      props.activeTab ? Colors.DarkBlue : Colors.DarkGrayishBlue};
  }
`;

const InviteAndTrackSvg = styled(InviteAndTrackIcon)<{ activeTab: boolean }>`
  & > g > path {
    fill: ${props =>
      props.activeTab ? Colors.DarkBlue : Colors.DarkGrayishBlue};
  }
`;

const SafeSvg = styled(SafeIcon)<{ activeTab: boolean }>`
  & > g > path {
    fill: ${props =>
      props.activeTab ? Colors.DarkBlue : Colors.DarkGrayishBlue};
  }
`;
export default MessageTab;
