import { ReactElement } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { Colors } from '../../themes';
import { ModalBox } from '..';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(5, 5, 5, 0.45)',
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.Transparent,
    border: 'none',
    padding: '0',
  },
};

Modal.setAppElement('#modalRoot');

type Props = {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  children: ReactElement;
  className?: string;
  disabledOverlayClick?: boolean;
  title: string;
};

function ChooseModal({
  modalIsOpen,
  setModalIsOpen,
  children,
  className,
  disabledOverlayClick,
  title,
}: Props) {
  return (
    <Modal
      className={className}
      isOpen={modalIsOpen}
      onRequestClose={() => !disabledOverlayClick && setModalIsOpen(false)}
      style={customStyles}
      contentLabel="Modal"
    >
      <ModalBox title={title} onCloseButtonClick={() => setModalIsOpen(false)}>
        <ChooseWrapper>{children}</ChooseWrapper>
      </ModalBox>
    </Modal>
  );
}

const ChooseWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
`;

export default ChooseModal;
