import AvatarBig from './Avatar-big.jpg';
import AvatarSmall from './Avatar-small.jpg';
import Avtar1 from './Avtar1.png';
import Avtar2 from './Avtar2.png';
import Avtar3 from './Avtar3.png';
import Create from './Create.png';
import FiveStar from './FiveStar.png';
import FourStar from './FourStar.png';
import HandShake from './HandShake.png';
import HeroSection from './hero-section.png';
import InviteAndTrack from './InviteAndTrack.png';
import Landing from './landing.png';
import Negotiate from './Negotiate.png';
import Opportunities from './Opportunities.png';
import Plan from './Plan.jpg';
import Safe from './Safe.png';
import SavingTimeBg from './SavingTimeBg.png';
import Section1 from './Section 1.png';
import Section2 from './Section 2.png';
import Section3 from './Section 3.png';
import Section4 from './Section 4.png';
import Section5 from './Section 5.png';
import SelectedPlan from './SelectedPlan.png';
import Stripe from './Stripe.jpg';
import Templates from './Templates.jpg';
import Welcome from './Welcome.jpg';

const IMG = {
  AvatarBig,
  AvatarSmall,
  Landing,
  Plan,
  Stripe,
  SelectedPlan,
  Templates,
  Welcome,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  HeroSection,
  Avtar1,
  Avtar2,
  Avtar3,
  FiveStar,
  FourStar,
  Negotiate,
  Opportunities,
  Create,
  InviteAndTrack,
  Safe,
  SavingTimeBg,
  HandShake,
};

export default IMG;
