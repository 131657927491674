import { Pointer } from '../threadPointer';

export enum NegotiationStatus {
  Unresolved = 'unresolved',
  ApprovedBySenderWaitingRecipient = 'approved_by_sender_waiting_recipient',
  ApprovedByRecipientWaitingSender = 'approved_by_recipient_waiting_sender',
  ApprovedByBothParties = 'approved_by_both_parties',
  RejectedBySenderWaitingRecipient = 'rejected_by_sender_waiting_recipient',
  RejectedByRecipientWaitingSender = 'rejected_by_recipient_waiting_for_sender',
  RejectedByBothParties = 'rejected_by_both_parties',
}

export type Thread = {
  id: number;
  created: string;
  nda: number;
  pointers: Pointer[];
  authorId: string;
  author: string;
  negotiationDate: string;
  negotiationStatus: NegotiationStatus;
  text: string;
  timeAgo?: string;
};

export type CreateThreadRequest = {
  nda: number;
  pointers: number[];
  text: string;
};

export type UpdateThreadRequest = Partial<CreateThreadRequest>;

export type ThreadsList = Thread[];
