import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../api';
import SVG from '../assets/svg';
import { Popover, UserLogo } from '../atoms';
import { Routes } from '../routes';
import { logout } from '../store';
import { BoxShadow, Colors, Fonts, rem } from '../themes';

type Props = {
  avatar: string | null;
  firstName: string;
  lastName: string;
  companyName: string | null;
};

function Profile({ avatar, firstName, lastName, companyName }: Props) {
  const dispatch = useDispatch();

  const [anchorButtons, setAnchorButtons] = useState<HTMLElement | null>(null);

  const openNdaButtons = (event: MouseEvent<HTMLElement>) => {
    setAnchorButtons(event.currentTarget);
  };

  const handleLogout = () => {
    authorization.logout().then(() => {
      localStorage.removeItem('signer');
      dispatch(logout());
    });
  };

  return (
    <>
      <BaseButton type="button" onClick={openNdaButtons}>
        <LogoBox>
          <UserLogo
            logo={avatar}
            namesLetters={`${firstName[0]}${lastName[0]}`}
          />
        </LogoBox>
        <StyledProfileInfo>
          <StyledProfileName>
            {firstName} {lastName}
          </StyledProfileName>
          <StyledCompanyName>{companyName}</StyledCompanyName>
        </StyledProfileInfo>
        <img
          width="15px"
          src={SVG.openArrowWhite}
          alt="Open Profile controls"
        />
        {/* <StyledProfileSettings src={SVG.settings} alt="profile-settings" /> */}
      </BaseButton>
      <Popover
        onOverlayClick={(event: MouseEvent) => {
          if (event.currentTarget !== event.target) return;
          setAnchorButtons(null);
        }}
        refElem={anchorButtons}
        offsetY={-95}
      >
        <PopOverContainer>
          <StyledButton type="button">
            <ProfileWrapper to={Routes.account}>
              <img src={SVG.profileAvtar} alt="Upload NDA" />
              <ProfileText>Profile</ProfileText>
            </ProfileWrapper>
          </StyledButton>
          <StyledButton type="button">
            <img src={SVG.logout} alt="Look NDA" />
            <Logout onClick={handleLogout}>Logout</Logout>
          </StyledButton>
        </PopOverContainer>
      </Popover>
    </>
  );
}

const ProfileWrapper = styled(NavLink)`
  display: flex;
  text-decoration: none;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const LogoBox = styled.div`
  flex-basis: 30px;
`;

const StyledProfileInfo = styled.div`
  max-width: 140px;
  display: flex;
  flex-direction: column;
`;

const StyledProfileName = styled.div`
  display: inline-block;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.White};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const StyledCompanyName = styled(StyledProfileName)`
  font-size: ${rem(8)};
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  height: 30px;
  min-width: 84px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const BaseButton = styled(StyledButton)`
  box-shadow: ${BoxShadow.Base};
  border: 1px solid ${Colors.LightBlue};
`;

const PopOverContainer = styled.div`
  width: 174px;
  height: 92px;
  padding: 12px 13px;
  background-color: ${Colors.White};
  border-radius: 10px;
  box-shadow: ${BoxShadow.Third};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 30px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${Colors.White} transparent;
  }
`;

const ProfileText = styled.div`
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: #b8b8b8;

  &: hover {
    color: ${Colors.LightSecondGrey};
  }
`;

const Logout = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: #b8b8b8;

  &: hover {
    color: ${Colors.LightSecondGrey};
  }
`;

export default Profile;
