import { DashboardGroupType } from '..';
import { PaginationResponse } from '../types';

export const ProjectsPaginationLimit = 9;

export type CreateProjectRequest = {
  name: string;
  summary?: string;
  members: number[];
  isArchived?: boolean;
  cim?: File;
  teaser?: File;
};

export type UpdateProjectRequest = Partial<CreateProjectRequest>;

export type OneProjectType = {
  id: number;
  name: string;
  created: string;
  status: string;
  summary: string;
  members: number[];
  isArchived: boolean;
  projectBranches: DashboardGroupType[];
  teaser?: File | null;
  cim?: File | null;
  user: number;
};

export type GetPaginationProjectsList = PaginationResponse<OneProjectType>;
export type GetProjectsList = OneProjectType[];
