import styled from 'styled-components';

import { ButtonEllipse } from '../atoms';
import { BoxShadow, Colors, Fonts, rem } from '../themes';

type Props = {
  title: string;
  description?: string;
  redButtonText?: string;
  onCancelClick: () => void;
  onConfirmClick: () => void;
  isLoading?: boolean;
};

function ConfirmDelete({
  title,
  description,
  redButtonText,
  onCancelClick,
  onConfirmClick,
  isLoading,
}: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}

      <ButtonsBox>
        {!isLoading && <Button text="Keep" onClick={onCancelClick} />}
        <Delete
          text={isLoading ? 'Loading...' : redButtonText ?? 'Delete'}
          onClick={onConfirmClick}
        />
      </ButtonsBox>
    </Container>
  );
}

const Container = styled.div`
  max-width: 300px;
  border-radius: 5px;
  font-family: ${Fonts.Sofia};
  color: ${Colors.TextGrey};
  background-color: ${Colors.White};
  text-align: center;
  box-shadow: ${BoxShadow.Secondary};
`;

const Title = styled.div`
  padding: 15px 30px;
  border-bottom: 1px solid ${Colors.LightGrey};
  font-size: ${rem(11)};
`;

const Description = styled.div`
  padding: 15px 30px 0;
  font-size: ${rem(8)};
  color: ${Colors.DarkGrey};
`;

const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const Button = styled(ButtonEllipse)`
  min-width: 90px;
  height: 30px;
  padding: 0 5px;
  border-radius: 15px;
  border: 1px solid ${Colors.WeakGrey};
  font-size: ${rem(10)};
  color: ${Colors.TextGrey};
`;

const Delete = styled(Button)`
  background-color: ${Colors.Red};
  border-color: ${Colors.Red};
  color: ${Colors.White};

  &:hover {
    background-color: ${Colors.DarkRed};
    border-color: ${Colors.DarkRed};
  }
`;

export default ConfirmDelete;
