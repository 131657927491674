import { OneNdaType } from '../nda';
import { ColorType, Signership } from '../signership';
import { PaginationResponse } from '../types';

export const PaginationDashboardLimit = 9;

export type DashboardGroupType = {
  id: number;
  name: string;
  project: number;
  nda: OneNdaType;
  signerGroups: number[];
  signers: number[];
  signership: Signership[];
  colorStatus: ColorType;
  unresolvedCommentsCount: number;
  sendTeaser: boolean;
  allowComments: boolean;
};

export type SendAllSignersNda = {
  projectBranch: number;
  note: string;
  subject: string;
};

export type SendOneSignerNda = SendAllSignersNda & { signer: number };

export type GetAllDashboardsList = PaginationResponse<DashboardGroupType>;
