import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import StyledSwitch from './SwitchButton';

type Props = {
  selectedItem: string;
  setSelectedItem: (selectedItem: string) => void;
  titleOne: string;
  titleTwo: string;
};

function Toggle({ selectedItem, setSelectedItem, titleOne, titleTwo }: Props) {
  const handleChange = () => {
    setSelectedItem(selectedItem === titleOne ? titleTwo : titleOne);
  };
  return (
    <Wrapper>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{titleOne}</Typography>
        <StyledSwitch
          checked={selectedItem === titleTwo}
          onChange={handleChange}
        />
        <Typography>{titleTwo}</Typography>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export default Toggle;
