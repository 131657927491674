import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';

const TermsAndCondition = () => (
  <PageWrapper>
    <Wrapper>
      <div>
        <div>Namda Terms and Conditions </div>
        <p>Updated March 2024</p>
        <p>
          This Terms of Service agreement (“<b>Agreement</b>”) is between Namda
          Ltd, (“<b>Namda</b>,” “<b>we</b>,” “<b>us</b>” or “<b>our</b>”), and
          any person or entity ( “<b>you</b>”, “<b>your</b>” or “<b>Customer</b>
          ”) that accesses or uses the Namda services, websites or any Namda
          mobile applications (collectively the “Sites”) or the products or
          services made available through or in connection with the Sites (the
          Sites and such products and services are collectively the “
          <b>Services</b>”). This Agreement is effective on the date you
          purchase the Subscription Plan (the “<b>Effective Date</b>”).
        </p>
        <p>
          <SubTitle>1. Acceptance of Agreement Terms.</SubTitle> By accessing or
          using the Services (including but not limited to browsing the Site),
          you acknowledge that you have read, understood, and agree to be bound
          by the terms of this Agreement. If you are agreeing to this Agreement
          on behalf of an entity, you represent and warrant that you have the
          authority to bind such entity to this Agreement, and your agreement
          shall constitute the agreement of the entity to this Agreement, and
          the terms “you” and “your” shall include the entity. If you do not
          agree to all of the terms of this Agreement, do not access or use the
          Services.
        </p>
        <p>
          <SubTitle>2. Changes to this Agreement.</SubTitle> Namda reserves the
          right to change this Agreement from time to time. If we change this
          Agreement, we will notify you of any such changes by posting the
          revised Agreement on the Services and/or by sending you a
          communication using information associated with your account. You
          should review this Agreement periodically for changes. All changes
          shall be effective upon posting, and we will revise the “Last Updated”
          date above on the date any revised version of this Agreement becomes
          effective.{' '}
          <b>
            Your continued use of or access of the Services after any changes to
            this Agreement constitutes your agreement to be bound by any such
            changes.
          </b>
        </p>
        <SubTitle>3. Services.</SubTitle>
        <p>
          <SubTitle>3.1. Description.</SubTitle> The Services we offer include
          the project management applications, services, tools, features, and
          functionality made available through the Sites, including associated
          hosting, cloud storage, and data processing services. Depending on the
          plan Customer selects (the “<SubTitle>Subscription Plan</SubTitle>”),
          the Services include a private Namda workspace dedicated for use by
          you (a “<SubTitle>Workspace</SubTitle>”) or a private Namda
          organization (an “<SubTitle>Organization</SubTitle>”) which includes
          and administers one or more Workspaces.
        </p>
        <p>
          <SubTitle>3.2. Documentation and Site Materials.</SubTitle> Namda may
          make certain user guides, manuals, videos, tutorials, webinars, and
          other information, documentation, and materials made available on the
          Services (“<SubTitle>Documentation</SubTitle>”). You may only use the
          Documentation in connection with your use of the Services. All
          content, information, and other materials contained in, made
          accessible on, or provided through the Services and Documentation,
          including the Namda logo and all designs, text, graphics, pictures,
          images, information, data, software, sound files, and other files, and
          the selection and arrangement of any of the above are “
          <SubTitle>Site Materials</SubTitle>”.
        </p>
        <p>
          <SubTitle>3.3. Provision of Services.</SubTitle> Namda will (a)
          provide you with technical and other support services for the Services
          in accordance with the support package included in your Subscription;
          and (b) use commercially reasonable efforts to make the Services
          available to you, except for scheduled downtime and any unavailability
          caused by circumstances beyond the reasonable control of Namda such as
          acts of God, floods, fires, earthquakes, or other natural disasters,
          acts of terror, civil disturbances, Internet service provider failures
          or delays, or failures or delays caused by issues with the facilities,
          equipment, or services maintained by you and necessary for your access
          to and use of the Services.
        </p>
        <p>
          <SubTitle>3.4. Your Responsibilities.</SubTitle> Customer is
          responsible for (a) ensuring that only you and users you invite to
          access and use a Workspace (your “
          <SubTitle>Workspace Team Members</SubTitle>”) are permitted to access
          and use the Workspace; (b) ensuring that only you and users you invite
          to access and use an Organization (your “
          <SubTitle>Organization Users</SubTitle>”) are permitted to access and
          use the Organization; (c) ensuring that all access to and use of the
          Services by you and your Organization Users and Workspace Team Members
          comply with this Agreement; (d) all Customer Content (defined below);
          (e) all actions taken via the Organization Account, Workspace Account
          and the User Accounts (each defined below) on the the Services and the
          security of all user credentials associated with the Organization
          Account, Workspace Account and User Accounts; and (f) providing,
          installing, and maintaining, at your own expense, any and all
          equipment, facilities, and services necessary for you and your
          Organization Users and Workspace Team Members to access and use the
          Services.
        </p>
        <p>
          <SubTitle>3.5. Use Restrictions and Customer Conduct.</SubTitle>{' '}
          Customer may only use the Services or Site Materials for the purposes
          expressly permitted in this Agreement. Without limiting the following,
          the rights granted to you in Section 7.2 do not authorize you to do
          any of the following; and you agree in connection with your access and
          use of the Services and/or Site Materials that you will not: (a)
          enable any third party to access or use the Services or Site
          Materials; (b) modify or create any derivative work based upon any
          part of the Services or Site Materials; (c) distribute, copy, license,
          rent, or sell any part of the Services or Site Materials to any third
          party; (d) grant any sublicense or other rights to any third party;
          (e) reverse engineer, disassemble, or decompile all or any portion of,
          or attempt to discover or recreate the source code for the Services;
          (f) use any data mining, robots, or similar data gathering or
          extraction methods; (g) download (other than the page caching) any
          part or any information contained in the Site Materials, except as
          expressly permitted on the Services; (h) remove, obscure, or alter any
          proprietary rights notices; (i) engage in or permit any harassing,
          intimidating, predatory, infringing or unlawful activities that could
          create any liability or result in harm to Namda or any third party;
          (j) develop a competitive product or service; (k) engage in or permit
          any use, reproduction, distribution, disposition, possession,
          disclosure, sale, or other activity involving any part of the Services
          or Site Materials that is not expressly authorized under this
          Agreement or otherwise in writing by Namda; (l) attempt to circumvent
          any content-filtering techniques or security measures; (m) attempt to
          access any features or areas of the Services that you are not
          authorized to access; (n) develop any third-party applications or
          services that interact with the Services without Namda’s prior written
          consent; (o) infringe or violate any intellectual property or
          proprietary rights of any third party; or (p) use any private or
          personal information of a third party without such third party’s
          consent.
        </p>
        <p>
          <SubTitle>3.6. Access to Customer Content.</SubTitle> Any documents,
          files, folders, information, data, text, photos, videos, graphics, and
          other items, content, or materials created, uploaded, posted,
          submitted, provided, or stored on or through the Services by you
          (“Customer Content”) to an Organization may be viewable by you and
          other Organization Users. Customer Content in a Workspace may be
          viewable by you and other Workspace Team Members. If you use any areas
          of the Sites that are publicly accessible and provide any Customer
          Content to such areas, you understand that such Customer Content may
          be viewable by the public. You are solely responsible for any Customer
          Content you provide in any publicly-accessible areas of the Sites.
        </p>
        <p>
          <SubTitle>3.7. Customer Content Restrictions.</SubTitle> Customer
          agrees not to create, provide, or store any Customer Content through
          or to the Services that (a) may create any liability, violate any
          applicable federal, state, regional, local, or foreign laws,
          regulations, rules, codes, judgments, and orders (“
          <SubTitle>Applicable Laws</SubTitle>”), or result in any harm or
          injury to Namda or any third party; (b) involves the publication of
          any material or content that is false, defamatory, untruthful,
          unlawful, harassing, or obscene; (c) violates any privacy rights of
          any third party or promotes bigotry, racism, hatred, or harm; (d)
          constitutes an infringement of any intellectual property or
          proprietary rights of any third party; or (e) contains any software
          viruses, corrupted data, or other harmful, malicious, disruptive, or
          destructive files or content. In addition to the other rights afforded
          to Namda under this Agreement, Namda reserves the right, but has no
          obligation, to take remedial action if any Customer Content materially
          violates any of the terms of this Agreement, which may include the
          removal or disablement of access to such Customer Content, without any
          liability to you.
        </p>
        <p>
          <SubTitle>3.8. Changes to Services.</SubTitle> We reserve the right to
          alter, enhance, upgrade, improve, modify, discontinue or otherwise
          change the Services and Site Materials, in whole or in part, as we
          deem appropriate and in our discretion and without notice.
        </p>
        <p>
          <SubTitle>3.9. Professional Services.</SubTitle> Namda may provide
          implementation, training, consulting, or other professional services
          to you pursuant to a separate written agreement entered into and
          signed by both parties.
        </p>
        <p>
          <SubTitle>4. Accounts.</SubTitle> In order to access or use certain
          Services, you must have an account. “
          <SubTitle>User Accounts</SubTitle>” are created by individuals after
          receiving a Customer’s invitation to join an Organization or
          Workspace. Each User Account is linked to a unique email address. Once
          created, a User Account may be used by the Organization User or
          Workspace Team Member to access and use the Organization or Workspace
          and other Services. Each Workspace has a “
          <SubTitle>Workspace Account</SubTitle>” which is your administrative
          account that allows you to administer, manage, and use the Workspace.
          The Workspace Account is linked to your unique email address. Each
          Organization has an “<SubTitle>Organization Account</SubTitle>” which
          is your administrative account that allows the Customer to administer,
          manage, and use the Organization. Workspace Accounts and Organization
          Accounts are each an “<SubTitle>Administrative Account</SubTitle>”.
        </p>
        <SubTitle>5. Confidentiality and Privacy.</SubTitle>
        <p>
          <SubTitle>5.1. Security.</SubTitle> Namda will implement and maintain
          reasonable physical, administrative, and technical measures in its
          facilities and on its systems and networks to protect Customer Content
          from unauthorized access by any third party. Namda will not be
          responsible for any unauthorized access to any Customer Content that
          (a) occurs through your systems or networks; (b) results from any
          vulnerabilities or weaknesses in your devices, equipment, facilities,
          networks, or systems; (c) results from the use or misuse of the
          Administrative Account or any User Accounts; or (d) results from any
          breach of your privacy or data protection policies or procedures by
          you or any third party.
        </p>
        <p>
          <SubTitle>5.2. Notice.</SubTitle> You will immediately notify Namda in
          writing if any unauthorized use of an Administrative Account or User
          Account, or any other breach of security related to the Services comes
          to your attention. In the event any unauthorized third party obtains
          access to the Services through you (directly or indirectly), you agree
          to take all steps necessary to terminate such unauthorized use and
          provide Namda with such cooperation and assistance related to such
          unauthorized access as Namda may reasonably request.
        </p>
        <p>
          <SubTitle>5.3. Privacy.</SubTitle> The{' '}
          <Policy to={Routes.privacy}>Namda Privacy Policy</Policy> describes
          and governs how we collect, use, and share information about users of
          the Services. By accessing or using the Services, you agree to the
          collection, use, and disclosure of such information by us as described
          in the Privacy Policy.
        </p>
        <SubTitle>6. Fees and Payment.</SubTitle>
        <p>
          <SubTitle>6.1. Fees.</SubTitle> Except for Free Trials and/or Free
          Plans described below, you will pay all fees for your Subscription
          Plan, together with any seat or license fees (collectively “
          <SubTitle>Licenses</SubTitle>”) for your Organization Users or
          Workspace Team Members and any other fees applicable to your use of
          the Services (collectively “<SubTitle>Fees</SubTitle>”). All Fees are
          payable in your local currency. Information regarding the Fees and
          your Subscription Plan are accessible via your Administrative Account.
          You may manage or upgrade your Subscription Plan (including the number
          of Licenses available for your Organization Users or Workspace Team
          Members) via your Administrative Account. You agree to pay all Fees
          associated with any training, professional services, upgrades or
          additional License purchases made by you via your Administrative
          Account. Unless otherwise agreed by the parties in writing, (a) all
          Fees are based on your Subscription Plan and the number of Licenses
          you purchase and not on actual usage; (b) your payment obligations and
          Subscription Plan are non-cancelable; (c) all Fees paid are
          non-refundable; and (d) you may not downgrade your Subscription Plan
          or decrease the number of Licenses purchased during the term of your
          Subscription Plan. If you downgrade your Subscription Plan or decrease
          the number of Licenses purchased, the downgrade or License decrease
          will not take effect until renewal at the end of the current term
          (i.e. the downgrade or License decrease will take effect at the
          beginning of the next term).
        </p>
        <p>
          <SubTitle>6.2. Proration.</SubTitle> Depending on your Subscription
          Plan or the type of purchase being made, the Fees associated may be
          prorated to cover the number of days from date of purchase to the end
          of the applicable Billing Period. “<SubTitle>Billing Period</SubTitle>
          ” means the recurring billing period during which you will be charged
          the Fees throughout the term of this Subscription Plan.
        </p>
        <p>
          <SubTitle>6.3. Changes to Fees.</SubTitle> Namda may change the Fees
          with not less than 30 days’ prior notice to you; provided, that any
          increase in the Fees for your Subscription Plan will not be effective
          until your next renewal period. Any change in the Fees will take
          effect on the first Billing Period after the 30 day notice period has
          expired.
        </p>
        <p>
          <SubTitle>6.4. Payment.</SubTitle> You will be charged on a recurring
          basis for the Fees set forth in the Settings section of your
          Administrative Account. You must designate, set up, and maintain a
          valid payment method in your account for making recurring automatic
          payments for the Fees. For recurring automatic payments, (a) you
          authorize Namda, or a third-party payment processor of Namda, to
          charge your designated payment method for the amount of the Fees
          applicable to each Billing Period on the first day of such Billing
          Period for the duration of the term of the Subscription Plan and any
          renewal subscription term(s); and (b) you must maintain a valid
          designated payment method with up to date and accurate information at
          all times during the term of the Subscription Plan. Requests to revoke
          authorization for future automatic recurring payments must be
          submitted in writing. Any Fees not paid when due will bear interest at
          the rate of 1.5% per month or the maximum rate permitted by law,
          whichever is less, computed and compounded daily until the date paid.
          You will reimburse Namda for any costs and expenses (including
          reasonable attorneys’ fees) incurred by Namda associated with
          collecting any overdue payments. Without limiting our other rights or
          remedies, Namda may accelerate your unpaid Fees obligations so that
          all such obligations become immediately due and payable, suspend
          Services until such amounts are paid in full, provided that we will
          provide you at least 10 days’ prior notice of the overdue and
          accelerated amount before suspending Services.
        </p>
        <p>
          <SubTitle>6.5. Invoices.</SubTitle> If Namda invoices you for the Fees
          applicable to a Billing Period, you will pay all Fees set forth in the
          invoice within 30 days of your receipt of the invoice or by a later
          payment due date if such is set forth in the invoice.
        </p>
        <p>
          <SubTitle>6.6. Taxes.</SubTitle> The Fees and other amounts payable to
          Namda under this Agreement do not include any taxes, customs, duties,
          fees, or other amounts assessed or imposed by any governmental
          authority, including VAT and applicable sales tax. You are responsible
          for all taxes in connection with your subscribed Services (other than
          taxes imposed on Namda’s net income). You will pay or reimburse Namda
          for all such amounts on demand or provide certificates or other
          evidence of exemption.
        </p>
        <p>
          <SubTitle>6.7. Payment Disputes.</SubTitle> If you dispute any Fees
          due during a Billing Period, you must provide written notice to Namda
          within 30 days from the payment due date with an explanation of the
          nature of the dispute. Unless Namda receives a written notice of the
          dispute within such time period, the Fees due for such Billing Period
          will be deemed correct and payable in full by you.
        </p>
        <SubTitle>6.8 Free Trials; Free Plans</SubTitle>
        <p>
          <SubTitle>6.8.1 Registration.</SubTitle> We may offer a free trial or
          a free plan of Services that will allow you to access and use certain
          features and functionalities of the Services on a trial basis, free of
          charge, for a limited period of time (“Free Trial or FreePlan”). If
          you register for a Free Trial or Free Plan, this Agreement and any
          additional terms and conditions to which you agree during the
          registration process for your Free Trial or Free Plan will govern your
          access to and use of the Services during your Free Trial or Free Plan
          period.
        </p>
        <p>
          <SubTitle>6.8.2. Free Trial or Free Plan Period.</SubTitle> If you
          register for a Free Trial, the term of your Free Trial will continue
          until the earlier of (a) the end of the Free Trial period for which
          you registered or (b) the date after you register for your Free Trial
          on which you purchase a Subscription Plan. If you register for a Free
          Plan, the term of your Free Plan will continue until the earlier of
          (a) the date after you register for your Free Plan on which you
          purchase a Subscription Plan or (b) a termination date Namda may
          choose at any time and in our sole discretion by providing notice to
          you through the Services.
        </p>
        <p>
          <SubTitle>6.8.3. Availability of Customer Content.</SubTitle> You
          acknowledge and agree Customer Content during your Free Trial or Free
          Plan may not be available to you after the date on which your Free
          Trial expires or Free Plan concludes. If you purchase a Subscription
          Plan that offers different features or functionalities than those
          provided during your Free Trial/Plan, you may lose access to certain
          features and functionalities of the Services that were available to
          you during your Free Trial/Plan. You may export certain Customer
          Content during your Free Trial/Plan prior to the date on which your
          Free Trial expires or Free Plan concludes, or as otherwise permitted
          by us. We reserve the right to permanently delete your Customer
          Content 30 days following the expiration of your Free Trial or
          conclusion of your Free Plan, unless you purchase a Subscription Plan
          before deletion that includes Services at least equivalent to those
          provided during your Free Trial/Plan.
        </p>
        <p>
          <SubTitle>6.8.4. Disclaimer.</SubTitle> NOTWITHSTANDING SECTION 11 OR
          ANYTHING TO THE CONTRARY IN THIS AGREEMENT, THE SERVICES ARE PROVIDED
          ON AN “AS IS” AND “AS AVAILABLE” BASIS DURING THE FREE TRIAL OR FREE
          PLAN, WITH ALL FAULTS, DEFECTS, AND ERRORS AND WITHOUT WARRANTIES OF
          ANY KIND, EITHER EXPRESS OR IMPLIED. NAMDA HEREBY SPECIFICALLY
          DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
          SERVICES, SITE MATERIALS, AND CUSTOMER CONTENT AND ANY OTHER MATTERS
          UNDER THIS AGREEMENT IN CONNECTION WITH YOUR USE OF THE SERVICES
          DURING YOUR FREE TRIAL OR FREE PLAN.
        </p>
        <SubTitle>7. Ownership and Intellectual Property Rights.</SubTitle>
        <p>
          <SubTitle>7.1. Customer Content.</SubTitle> Namda does not obtain any
          ownership rights in Customer Content. However, by providing or causing
          the provision of Customer Content to or through the Services, you
          grant (and you warrant that you have all rights necessary to grant) to
          Namda (and its affiliates) a non-exclusive, non transferable,
          sublicensable, worldwide, royalty-free license to transmit, process,
          store, use, copy, modify, create derivative works based upon Customer
          Content solely in connection with our provision of the Services and
          performance of our obligations under this Agreement. You understand
          that you are solely responsible for your Customer Content. You
          represent and warrant to us that your Customer Content and our use of
          your Customer Content as contemplated by this Agreement will not
          misappropriate or infringe the copyright, trademark rights, moral
          rights, rights of publicity, intellectual property, proprietary rights
          or other rights of any third party. You and Namda will comply with all
          Applicable Laws in connection with the processing of the Customer
          Content.
        </p>
        <p>
          <SubTitle>7.2. Namda Content.</SubTitle> Subject to the terms of this
          Agreement, Namda grants you, for the duration of the term, a
          non-exclusive, non-transferrable, non-sublicensable limited license to
          access and use the Site Materials and Services in your Subscription
          Plan solely for your internal use in the regular course of your
          business. This license includes the right for you to permit your
          Organization Users or Workspace Team Members to access and use the
          Organization or Workspace and the Services pursuant to your
          Subscription Plan. The maximum number of Organization Users or
          Workspace Team Members permitted to access and use the Organization or
          Workspace may not exceed the number of Organization User or Workspace
          Team Member seats/licenses included in your Subscription Plan or
          otherwise purchased by you.
        </p>
        <p>
          <SubTitle>7.3. Feedback.</SubTitle> You may submit questions,
          comments, suggestions, feedback, ideas, plans, notes, drawings,
          original or creative materials or other information and materials
          about the Services, Site Materials, or us (collectively “
          <SubTitle>Feedback</SubTitle>”) at any time. Feedback is
          non-confidential. Namda will own all rights in and to the Feedback,
          including all intellectual property rights, and will be entitled to
          the use Feedback for any purpose, without acknowledgment or
          compensation to you.
        </p>
        <p>
          <SubTitle>7.4 Rights in Services.</SubTitle> Namda and its licensors
          own all right, title, and interest, including all intellectual
          property rights, in and to the Services and Site Materials. Any
          customizations of the Services provided by Namda for your use as part
          of your Subscription Plan pursuant to this Agreement will be
          exclusively owned by Namda. Except as explicitly stated in this
          Agreement, Namda and its licensors reserve all rights in and to the
          Services and Site Materials. Except for the license and rights
          expressly granted to you, nothing in this Agreement will be construed
          as conferring any right or license to any intellectual property rights
          of Namda or any third party, whether by estoppel, implication, or
          otherwise. No title to or ownership of the Services or Site Materials
          is transferred to you or any third party under this Agreement.
        </p>
        <SubTitle>8. Term; Termination; Suspension.</SubTitle>
        <p>
          <SubTitle>8.1. Term.</SubTitle> This Agreement commences on the
          earlier of (a) the date you access or use the Services or (b) the
          Effective Date and will remain in effect until the date termination of
          this Agreement becomes effective in accordance with this Agreement.
        </p>
        <p>
          <SubTitle>8.2. Term of Your Subscription Plan.</SubTitle> The term of
          your Subscription Plan will commence on the Effective Date and will be
          valid for the term specified for your Subscription Plan at the time
          you make your purchase unless terminated earlier in accordance with
          the terms of this Agreement or your Subscription Plan. If You upgrade
          or make changes to your Subscription Plan, such upgrade or changes may
          affect the term of your Subscription Plan. Any promotions, extensions
          or additional periods applicable to your Subscription Plan and
          provided to you by Namda, in our sole discretion, will be deemed to be
          included in the term of your Subscription Plan.
        </p>
        <p>
          <SubTitle>8.3. Auto-Renewal of Your Subscription Plan.</SubTitle> The
          term of your Subscription Plan will automatically renew for successive
          subscription terms unless you either (a) cancel your Subscription Plan
          via your Administrative Account before the date of automatic renewal,
          or (b) provide us with written notice of non-renewal at least 30 days
          prior to the end of your then current subscription term.
        </p>
        <p>
          <SubTitle>8.4. Termination.</SubTitle> If either party commits a
          material breach of this Agreement, the other party may give the
          breaching party written notice of the breach. Unless otherwise agreed
          by the parties in writing, the term of this Agreement will terminate
          immediately if the breaching party does not cure such breach within 30
          days after its receipt of such written notice. In addition, Namda may
          terminate this Agreement, at any time and in our sole discretion, by
          providing notice to you through the Services.
        </p>
        <SubTitle>8.5. Effect of Termination.</SubTitle>
        <p>
          <SubTitle>8.5.1.</SubTitle> The termination of a Subscription Plan or
          this Agreement will not affect either party’s rights with respect to
          actions occurring prior to termination. Except as expressly set forth
          in this Agreement, Namda will have no liability for any costs, losses,
          damages, penalties, fines, expenses, or liabilities arising out of or
          related to our termination of any Subscription Plan or this Agreement.
        </p>
        <p>
          <SubTitle>8.5.2.</SubTitle> Upon the expiration or termination of your
          Subscription Term and upon the expiration or termination of this
          Agreement, (a) all licenses and rights granted to you to access and
          use the terminated Services will immediately and automatically
          terminate; (b) provided, however, that unless you are in material
          breach of this Agreement, Namda will make certain Customer Content
          available for download by you for a period of at least 30 days after
          the date of such expiration or termination. Namda may permanently
          delete the Customer Content after such 30-day period unless prohibited
          by law.
        </p>
        <p>
          <SubTitle>8.5.3.</SubTitle> If Namda terminates your Subscription Plan
          or this Agreement pursuant to Section 8.4 due to a material breach by
          you, all unpaid Fees obligations, including those that would have
          become due during the term of your Subscription Plan, become
          immediately due and payable. You agree to immediately pay to Namda all
          unpaid Fees then payable and that would have become due had this
          Agreement or Subscription Plan not been terminated.
        </p>
        <p>
          <SubTitle>8.5.4.</SubTitle> If Namda terminates this Agreement for
          convenience pursuant to Section 8.4, Namda will provide a pro-rata
          refund of any Fees paid by you and applicable to the period following
          the effective date of termination.
        </p>
        <p>
          <SubTitle>8.5.6.</SubTitle> The parties’ respective rights and
          obligations which by their nature should survive termination,
          including but not limited to all defined terms and all payment
          obligations and liabilities accrued prior to the effective date of
          expiration or termination will survive any expiration or termination
          of this Agreement.
        </p>
        <p>
          <SubTitle>8.6. Suspension of Services.</SubTitle> Namda may suspend
          your access to and use of the Services if (a) you fail to pay any Fees
          or other amounts when due and payable under this Agreement; or (b)
          Namda reasonably determines that you have breached or are breaching
          any of the terms of this Agreement.
        </p>
        <SubTitle>9. Indemnification.</SubTitle>
        <p>
          <SubTitle>9.1 By Customer.</SubTitle> You agree to defend, indemnify,
          and hold Namda, its independent contractors, service providers, and
          consultants, and each of their respective directors, employees, and
          agents (collectively, “Namda Parties”), harmless from and against any
          and all third-party claims, damages, liabilities, costs and expenses,
          including without limitation reasonable attorneys’ fees (collectively
          “Claims”) that arise from or related to your (a) Customer Content; (b)
          use of any facilities, equipment, or services in connection with the
          Services; (c) Feedback; (d) any breach or violation of any Applicable
          Laws, this Agreement, or any rights of any third party; (e) the
          combination of the Services and other products, equipment, software,
          or services not supplied, authorized, or recommended in writing by
          Namda; or (f) gross negligence or willful misconduct. Namda shall have
          the right to participate in the defense of such Claims, at its own
          cost. You may not settle or negotiate any Claims that result in
          liability to, or an obligation upon, Namda Parties, financial or
          otherwise, without the written consent of Namda.
        </p>
        <p>
          <SubTitle>9.2 By Namda.</SubTitle> We agree to defend, indemnify, and
          hold Customer, and your independent contractors, service providers,
          and consultants, and each of their respective directors, employees,
          and agents (collectively, “Customer Parties”), harmless from and
          against any and all Claims that arise from or related to (a) our
          breach or violation of any Applicable Laws in connection with
          providing the Services; (b) our breach or violation of this Agreement;
          (c) our breach or violation of any rights of any third party; or (f)
          our gross negligence or willful misconduct. Customer shall have the
          right to participate in the defense of such Claims, at your own cost.
          We may not settle or negotiate any Claims that result in liability to,
          or an obligation upon, Customer Parties, financial or otherwise,
          without your written consent.
        </p>
        <p>
          <SubTitle>10. Limitations of Liability.</SubTitle> UNDER NO
          CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN TORT,
          CONTRACT, WARRANTY, STRICT LIABILITY OR OTHERWISE, SHALL EITHER PARTY
          BE LIABLE TO THE OTHER PARTY OR ANY OTHER PERSON FOR (A) ANY INDIRECT,
          SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE
          (INCLUDING LOSS OF PROFIT, REVENUE, USE, DATA OR FOR BUSINESS
          INTERRUPTION) ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, THE
          SERVICES, OR SITE MATERIALS, EVEN IF THE PARTY HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH LOSSES OR DAMAGES, OR (B) DIRECT DAMAGES IN EXCESS
          OF AN AMOUNT EQUAL TO THE SUM TOTAL OF AMOUNTS, IF ANY, YOU HAVE PAID
          NAMDA UNDER THIS AGREEMENT.
        </p>
        <SubTitle>11. Warranties and Disclaimers.</SubTitle>
        <p>
          <SubTitle>11.1. Warranties.</SubTitle> Namda and you each represents
          and warrants that (a) this Agreement constitutes a valid and binding
          agreement enforceable against such party in accordance with its terms;
          (b) each has all necessary rights and authority to perform its
          obligations under this Agreement; and (c) the performance of this
          Agreement does not and will not violate the terms or conditions of any
          other agreement to which such party is a party or by which it is
          otherwise bound.
        </p>
        <SubTitle>11.2. Disclaimers.</SubTitle>
        <p>
          <SubTitle>11.2.1.</SubTitle> TO THE FULLEST EXTENT PERMISSIBLE BY LAW,
          EXCEPT FOR THOSE SET FORTH IN SECTION 11.1 ABOVE, NAMDA DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES, SITE
          MATERIALS AND THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, ANY
          IMPLIED WARRANTIES OF USE OR FITNESS FOR A PARTICULAR PURPOSE,
          MERCHANTABILITY, AND NON-INFRINGEMENT, ANY WARRANTY THAT THE USE OF
          THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND ANY WARRANTIES
          ARISING BY COURSE OF PERFORMANCE OR TRADE USAGE.
        </p>
        <p>
          <SubTitle>11.2.2</SubTitle> NAMDA MAKES NO WARRANTIES, EXPRESS OR
          IMPLIED: (A) REGARDING CUSTOMER CONTENT OR ANY FACILITIES, EQUIPMENT,
          OR SERVICES USED BY YOU IN CONNECTION WITH THE SERVICES; (B) REGARDING
          THE ACCURACY, COMPLETENESS, OR TIMELINESS OF ANY INFORMATION OR DATA
          PROVIDED IN CONNECTION WITH OR RESULTS OBTAINED THROUGH USE OF THE
          SERVICES OR SITE MATERIALS; (C) THAT THE SERVICES ARE FREE FROM BUGS,
          ERRORS, DEFECTS, OR DEFICIENCIES. TO THE EXTENT THAT NAMDA MAY NOT AS
          A MATTER OF APPLICABLE LAWS DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE
          AND DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED PURSUANT
          TO SUCH LAW.
        </p>
        <SubTitle>12. General Terms.</SubTitle>
        <p>
          <SubTitle>12.1. Governing Law, Jurisdiction, and Venue.</SubTitle>{' '}
          This Agreement shall be governed by, and construed in accordance with,
          the laws of the United Kingdom, without reference to its conflict of
          law provisions. Any judicial proceedings arising out of or relating to
          this Agreement, the Services, or Site Materials must be brought in,
          and you and Namda hereby consent to the exclusive jurisdiction of the
          United Kingdom. You and Namda waive any right to object to venue in
          such courts.
        </p>
        <p>
          <SubTitle>12.2. Marketing</SubTitle> Unless you provide Namda with
          prior written notice to the contrary, you agree that Namda may
          reference you as a customer and display your corporate name and logo
          on Namda’s website and other promotional materials where Namda
          identifies its other customers, subject to any trademark usage
          policies or brand guidelines provided or published by you.
        </p>
        <p>
          <SubTitle>12.3. Excused Performance.</SubTitle> Namda will not be
          liable for, or be considered to be in breach of this Agreement on
          account of, any delay or failure to perform as required by this
          Agreement resulting from any cause or condition beyond its reasonable
          control, including without limitation acts of God, floods, fires,
          earthquakes, or other natural disasters, pandemics or epidemics, acts
          of war or terror, acts or orders of government, civil disturbances,
          breakdowns, malfunctions, interruptions or other issues with computer
          facilities, power, internet service, or other systems, or failures or
          delays caused by breakdowns, malfunctions or other issues with the
          facilities, equipment, or services maintained by you and necessary for
          your use of the Services.
        </p>
        <p>
          <SubTitle>12.4. Assignment.</SubTitle> You may not assign this
          Agreement or any of your rights or obligations under this Agreement
          without the prior written consent of Namda. Any attempted assignment
          without such consent will be void.
        </p>
        <p>
          <SubTitle>12.5. Waiver.</SubTitle> A party’s failure or delay in
          exercising any right under this Agreement will not limit or operate as
          a waiver thereof, nor will the waiver of any breach by the other party
          waive any other or further breach.
        </p>
        <p>
          <SubTitle>12.6. Severability.</SubTitle> If any provision of this
          Agreement is for any reason deemed illegal or invalid, such illegality
          or invalidity will not affect the validity of the remainder of this
          Agreement, and each such term or provision will be valid and
          enforceable to the fullest extent permitted by law.
        </p>
        <p>
          <SubTitle>12.7. Export Restrictions.</SubTitle> Any software and all
          underlying information and technology downloaded or viewed from the
          Services (collectively, the “Software or Technical Data”) may be
          subject to export or import controls and regulations. You are
          responsible for complying with all trade regulations and laws both
          foreign and domestic. Except as authorized by law, you agree and
          warrant not to export or re-export the Software or Technical Data to
          any county, or to any person, entity, or end user subject to export
          controls, including persons or entities listed on the U.S. Department
          of Commerce Bureau of Export Administration’s Denied Parties List and
          the U.S. Department of Treasury’s Specially Designated Nationals. You
          further warrant that no U.S. federal agency has suspended, revoked, or
          denied its export privileges.
        </p>
        <p>
          <SubTitle>12.8. Entire Agreement.</SubTitle> This Agreement, together
          with all agreements and terms incorporated by reference, sets forth
          the entire agreement between you and Namda and supersedes any and all
          other oral or written agreements or understandings between the parties
          with respect to the Services. No provision of any purchase order,
          confirmation, or other business form provided by Customer to Namda
          will alter, add to, or supersede the terms and conditions of this
          Agreement.
        </p>
      </div>
    </Wrapper>
  </PageWrapper>
);

const Wrapper = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.75)};
`;

const SubTitle = styled.b`
  font-weight: 700;
`;

const Policy = styled(NavLink)`
  color: ${Colors.DarkGrayishBlue};
`;
export default TermsAndCondition;
