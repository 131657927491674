import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { projects as apiProjects } from '../../../../api';
import SVG from '../../../../assets/svg';
import { selectors } from '../../../../store';
import { Colors, Fonts, rem, rootBorderRadius } from '../../../../themes';

interface IProps {
  membersCount: number;
  currencySymbol: string;
}

function BillingInfo({ currencySymbol, membersCount }: IProps) {
  const subscriptionCost = useSelector(selectors.subscriptionCost);
  const subscriptionInterval = useSelector(selectors.subscriptionInterval);
  const maxAllowedMembers = useSelector(selectors.maxAllowedMembers);
  const maxAllowedProjects = useSelector(selectors.maxAllowedProjects);

  const [projectsCount, setProjectsCount] = useState(0);

  useEffect(() => {
    apiProjects.getProjects().then(({ data }) => {
      const ownProjects = data?.filter(project => project.created);

      return setProjectsCount(ownProjects.length);
    });
  }, []);

  return (
    <Container>
      <UsedInfo>
        <BilledCostInfo>
          <AddMemberBG>
            <img src={SVG.billingInfo} alt="billing" width="25px" />
          </AddMemberBG>
          <ProjectWrapper>
            <BilledCostText>Billed per {subscriptionInterval}</BilledCostText>
            <BilledCost>
              {currencySymbol} {subscriptionCost.toFixed(2)}
            </BilledCost>
          </ProjectWrapper>
        </BilledCostInfo>

        <BilledStats>
          <AddMemberBG>
            <img src={SVG.profileAvtar} alt="avtar" width="22px" />
          </AddMemberBG>
          <ProjectWrapper>
            Team Members
            <span
              style={{ color: `${Colors.DarkBlue}`, fontSize: `${rem(12.5)}` }}
            >
              {membersCount}/{maxAllowedMembers}
            </span>{' '}
          </ProjectWrapper>
        </BilledStats>
        <BilledStats>
          <AddMemberBG>
            <img src={SVG.file} alt="file" width="22px" />
          </AddMemberBG>
          <ProjectWrapper>
            Projects
            <span
              style={{ color: `${Colors.DarkBlue}`, fontSize: `${rem(12.5)}` }}
            >
              {projectsCount}/
              {maxAllowedProjects === Infinity ? (
                <span>&infin;</span>
              ) : (
                maxAllowedProjects
              )}{' '}
            </span>
          </ProjectWrapper>
        </BilledStats>
      </UsedInfo>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  border-radius: ${rootBorderRadius};
`;

const AddMemberBG = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.SkyBlue};
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 100px);
  font-size: ${rem(10)};
`;

const UsedInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0 0;
`;

const BilledCostInfo = styled.div`
  width: 270px;
  height: 82px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(2, 48, 97, 0.2);
  background: ${Colors.White};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const BilledCost = styled.div`
  color: ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const BilledCostText = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const BilledStats = styled.div`
  width: 270px;
  height: 82px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(2, 48, 97, 0.2);
  background: ${Colors.White};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default BillingInfo;
