enum Errors {
  FailedUpdateTokens = 'Failed update Tokens',
  RefreshTokenExpired = 'Refresh Token expired',
  TemporaryServerIssues = 'Temporary server issues. Please try again later',
  EnterPassword = 'Please, enter your password',
  EnterName = 'Please, enter your Name',
  EnterFirstName = 'Please, enter first name',
  EnterLastName = 'Please, enter last name',
  EnterFirstAndLastname = 'Please, enter First name & Last name',
  EnterYourEmail = 'Please, enter your e-mail',
  EnterEmail = 'Please, enter the E-mail address',
  EnterValidEmail = 'Please, enter a valid E-mail',
  EnterField = 'Please, fill this field',
  BlankField = 'This field may not be blank.',
  MatchPasswords = 'Passwords must match',
  ChouseSelectOptions = 'Please, choose options from the list',
  ChouseTemplate = 'Please, select any Template',
  SetPointer = 'Please, choose pointer on Document',
  CantSendWithoutNda = 'Cant send mail for recipients without project NDA. Upload NDA before send mail',
  NotEnoughProjects = 'You dont have enough allowed Projects',
  NotEnoughMembers = 'You dont have enough allowed Team Members',
  UploadFile = 'Please, upload a File',
  UploadFileSize = 'Maximum file size exceeded. Please upload a PDF file that is 3 MB or smaller.',
  AddSignatureBeforeSign = 'Before sing NDA, add you Signature',
  AddPaymentMethod = 'Please, add Payment method before change plan/upgrade plan',
  EnterAllFields = 'Please, fill all fields',
  ProjectsLimit = 'You’ve exceeded the number of projects/signatures/editors - please contact your admin to upgrade the plan',
  ExpiredToken = 'Registration Link is not valid because the term has expired. Please use password renewal',
  ExistContact = 'Contact already added',
  EnterNdaName = 'Please, enter the Project NDA name',
  EnterProjectName = 'Please, enter the Project name',
  EnterCompanyName = 'Please, enter Company name',
  EnterLocation = 'Please, enter Location',
  CheckTerms = 'Please, Check terms & condition',
  ExistingEmail = 'Company already has this email in contacts list',
  EnterNdaTempleteName = 'Please, enter the NDA template name',
  AllowDecreaseMember = 'Maximum downgrade available till',
  RecipientContact = 'This user is part of the team who issued the NDA, so It can not see it as a Recipient',
  CimAndTeaserError = 'Either clear your file or upload lesser file for teaser and cim.',
}

export default Errors;
