import { ChangeEvent, FocusEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void;
  error?: string;
  maxLength?: number;
  disabled?: boolean;
  minRows?: number;
  name?: string;
};

function Textarea({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  className,
  maxLength,
  disabled,
  minRows,
  name,
}: Props) {
  return (
    <Container>
      <StyledTextarea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        maxLength={maxLength}
        minRows={minRows || 1}
        autoFocus={false}
        disabled={disabled}
        name={name}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 10px;
  background-color: ${Colors.White};

  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkBlack};
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 10px;
  outline: none;
  resize: none;
  ::placeholder {
    font-size: ${rem(10)};
    color: ${Colors.StrongGrey};
    font-family: ${Fonts.Sofia};
  }

  :disabled {
    background-color: ${Colors.Transparent};
  }
`;

const Error = styled.div`
  margin-top: 12px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Sofia};
  color: ${Colors.LightRed};
`;

export default Textarea;
