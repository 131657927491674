import { PaginationResponse } from '../types';

export const TemplatesPaginationLimit = 9;

export type TemplateType = {
  id: number;
  name: string;
  project: number;
  file: string;
};

export type UpdateTemplateRequest = {
  name?: string;
  project?: number;
};

export type GetAllTemplatesList = TemplateType[];
export type GetTemplatesList = PaginationResponse<TemplateType>;
