import styled from 'styled-components';

import IMG from '../../../assets/IMG';
import SVG from '../../../assets/svg';
import { Colors, Fonts, rem } from '../../../themes';

function Create() {
  return (
    <Wrraper>
      <ImgSec src={IMG.Create} alt="create" />
      <ContentWrapper>
        <Title>
          Organise the potential Buyers and rate them to track the probability
          of closing
        </Title>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            Once the company information is shared with many parties, you can
            track their status with Namda. Simply organise your interested
            parties by status : interested, pass, considering
          </Details>
        </CheckWrapper>
      </ContentWrapper>
    </Wrraper>
  );
}

const Wrraper = styled.div`
  display: flex;
  padding: 65px 50px 50px 50px;
  gap: 65px;
  @media (max-width: 768px) {
    display: block;
    gap: 0;
    padding: 0;
  }
`;

const ImgSec = styled.img`
  width: 100%;
  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const ContentWrapper = styled.div``;

const Title = styled.p`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(18.75)};
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const Details = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
`;

const Img = styled.img`
  margin-top: 7px;
  margin-right: 12px;
`;
export default Create;
