import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UnlimitedList } from '../types';
import {
  CreateThreadRequest,
  Thread,
  ThreadsList,
  UpdateThreadRequest,
} from './types';

const getThreadsByNdaId = (
  ndaId: number,
): Promise<AxiosResponse<ThreadsList>> =>
  api.get(`/projects/comment_threads/?nda=${ndaId}&limit=${UnlimitedList}`);

const createThread = (
  body: CreateThreadRequest,
): Promise<AxiosResponse<Thread>> =>
  api.post('/projects/comment_threads/', body);

const updateThread = (
  id: number,
  body: UpdateThreadRequest,
): Promise<AxiosResponse<Thread>> =>
  api.patch(`/projects/comment_threads/${id}/`, body);

const approveThread = (threadId: number): Promise<AxiosResponse<Thread>> =>
  api.get(`/projects/comment_threads/${threadId}/negotiation_approve/`);

const rejectThread = (threadId: number): Promise<AxiosResponse<Thread>> =>
  api.get(`/projects/comment_threads/${threadId}/negotiation_reject/`);

const deleteThread = (threadId: number): Promise<AxiosResponse> =>
  api.delete(`/projects/comment_threads/${threadId}/`);

const thread = {
  createThread,
  updateThread,
  getThreadsByNdaId,
  approveThread,
  rejectThread,
  deleteThread,
};
export default thread;
