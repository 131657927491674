import styled from 'styled-components';

import { Reports } from '../../../api';
import SVG from '../../../assets/svg';
import { Colors, Fonts, rem, rootBorderRadius } from '../../../themes';

type Props = {
  reports: Reports;
};

function formatSecondsToTime(seconds: number): string {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${days} D : ${hours} H : ${minutes} M`;
}

function Analytics({ reports }: Props) {
  return (
    <Container>
      <AnalyticsDashboard>
        <CardWrapper>
          <AddMemberBG>
            <img src={SVG.file} alt="file" width="22px" />
          </AddMemberBG>
          <AnalyticsItems>
            <Desc>Projects</Desc>
            <ValueBox>{reports.numberOfProjects}</ValueBox>

            <CardBg>
              <img src={SVG.folderSkyBg} alt="folder bg" />
            </CardBg>
          </AnalyticsItems>
        </CardWrapper>
        <CardWrapper>
          <AddMemberBG>
            <img src={SVG.file} alt="file" width="22px" />
          </AddMemberBG>
          <AnalyticsItems>
            <Desc>All NDAs</Desc>
            <ValueBox>{reports.numberOfNda}</ValueBox>

            <CardBg>
              <img src={SVG.folderSkyBg} alt="folder bg" />
            </CardBg>
          </AnalyticsItems>
        </CardWrapper>
        <CardWrapper>
          <AddMemberBG>
            <img src={SVG.file} alt="file" width="22px" />
          </AddMemberBG>
          <AnalyticsItems>
            <Desc>NDAs with threads</Desc>
            <ValueBox>{reports.numberOfActiveNdaWithCommentThreads}</ValueBox>

            <CardBg>
              <img src={SVG.fileDocumentBg} alt="folder bg" />
            </CardBg>
          </AnalyticsItems>
        </CardWrapper>
        <CardWrapper>
          <AddMemberBG>
            <img src={SVG.pencilDraw} alt="file" width="22px" />
          </AddMemberBG>
          <AnalyticsItems>
            <Desc>Median time to sign</Desc>
            <ValueBox>
              {reports.medianNdaSecondsFromSentToSign
                ? formatSecondsToTime(reports.medianNdaSecondsFromSentToSign)
                : '-'}
            </ValueBox>

            <CardBg>
              <img src={SVG.pencilDrawBg} alt="folder bg" />
            </CardBg>
          </AnalyticsItems>
        </CardWrapper>
      </AnalyticsDashboard>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkBlack};
`;

const AnalyticsDashboard = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 30px;
`;

const AnalyticsItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueBox = styled.div`
  color: ${Colors.OffGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Desc = styled.div`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const AddMemberBG = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.SkyBlue};
`;

const CardBg = styled.div`
  position: absolute;
  right: -15px;
  bottom: -25px;
`;

const CardWrapper = styled.div`
  display: flex;
  height: 120px;
  border-radius: ${rootBorderRadius};
  border: 1px solid ${Colors.LightSecondBlue};
  background: ${Colors.White};
  align-items: center;
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  position: relative;
  padding: 0 0 0 16px;
  overflow: hidden;
  gap: 15px;
`;

export default Analytics;
