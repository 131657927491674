import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { members as apiMembers, MemberType } from '../../../api';
import { GetCompanyOwnerType } from '../../../api/company';
import SVG from '../../../assets/svg';
import { DotsLoading, Modal, ModalBox } from '../../../atoms';
import CommonModal from '../../../atoms/Modal/CommonModal';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../../helpers';
import { useNotification } from '../../../hooks';
import ChooseSignatureModal from '../../../organisms/ChooseSignatureModal';
import { selectors } from '../../../store';
import {
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../../../themes';
import {
  InputLabel,
  InputWrapper,
  StyledButton,
  StyledInput,
  TopContainer,
} from './MyProfile';
import TeamMember from './TeamMember';

type Props = {
  refreshSignature: () => void;
  setCompanyInfo: React.Dispatch<
    React.SetStateAction<GetCompanyOwnerType | null>
  >;
  companyInfo: GetCompanyOwnerType | null;
  setMembersCount: React.Dispatch<React.SetStateAction<number>>;
};

function Team({
  refreshSignature,
  setCompanyInfo,
  companyInfo,
  setMembersCount,
}: Props) {
  const { showError } = useNotification();

  const user = useSelector(selectors.user);
  const maxAllowedMembers = useSelector(selectors.maxAllowedMembers);

  const [members, setMembers] = useState<MemberType[]>([]);
  const [isOpenAddBox, setIsOpenAddBox] = useState(false);
  const [email, setEmail] = useState(initialValue);

  const [isOpenUpdateSign, setIsOpenUpdateSign] = useState(false);
  const [isAddPlanQuantity, setIsAddPlanQuantity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { product: currentUserPlan } = useSelector(selectors.subscription);

  useEffect(() => {
    apiMembers.getAllMembers().then(res => {
      setEmail(initialValue);
      setMembers(res.data);
    });
  }, []);

  const addNewMember = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) return;

    if (isEmptyInput(email, setEmail, Errors.EnterYourEmail)) return;

    if (members.length >= maxAllowedMembers) {
      showError(Errors.NotEnoughMembers);
      setIsAddPlanQuantity(true);
      return;
    }

    if (!companyInfo?.restOfAllowedMembersUnderLimit) {
      showError(Errors.ProjectsLimit);
      return;
    }

    setIsLoading(true);
    apiMembers
      .createMember({ email: email.value })
      .then(res => {
        apiMembers
          .getAllMembers()
          .then(({ data }) => setMembersCount(data.length));
        setMembers(prev => [...prev, res.data]);
        setEmail(initialValue);
        setIsOpenAddBox(false);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.email, setEmail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteMember = (memberId: number) => {
    // TODO when API will update

    if (isLoading) return;
    setIsLoading(true);

    apiMembers
      .deleteMember(memberId)
      .then(() => {
        setMembers(prev => prev.filter(({ id }) => id !== memberId));
        apiMembers
          .getAllMembers()
          .then(({ data }) => setMembersCount(data.length));
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.email, setEmail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleAddMemberMode = () => {
    if (!user.sign) {
      setIsOpenUpdateSign(true);
      return;
    }

    setIsOpenAddBox(prev => !prev);
  };

  const closeModal = () => setIsAddPlanQuantity(false);
  return (
    <Wrapper isAddPlan={isAddPlanQuantity}>
      <TopTeamContainer
        isActiveSubscription={!!companyInfo?.activeSubscription}
      >
        <TitleText>Team management</TitleText>
        {!user.isMember && (
          <AddMember
            onClick={toggleAddMemberMode}
            isActiveSubscription={!!companyInfo?.activeSubscription}
          >
            <img src={SVG.whitePlus} alt="" />
            <span>Add e-mail address</span>
          </AddMember>
        )}
      </TopTeamContainer>
      <ChooseSignatureModal
        isOpenChoiceModal={isOpenUpdateSign}
        setIsOpenChoiceModal={setIsOpenUpdateSign}
        refreshSignature={refreshSignature}
        userName={`${user.firstName} ${user.lastName}`}
        title="You do not have a signature saved yet, please add one"
      />

      <Modal
        modalIsOpen={isAddPlanQuantity}
        setModalIsOpen={setIsAddPlanQuantity}
      >
        <ModalBox
          title="Add team member on current plan"
          onCloseButtonClick={() => setIsAddPlanQuantity(false)}
        >
          <CommonModal
            closeModal={closeModal}
            isAddPlanQuantity={isAddPlanQuantity}
            email={email}
            setMembers={setMembers}
            setIsOpenAddBox={setIsOpenAddBox}
          />
        </ModalBox>
      </Modal>

      {members.length >= 0 && !currentUserPlan.product && (
        <CommonModal closeModal={closeModal} email={email} />
      )}

      <Container isActiveSubscription={!!companyInfo?.activeSubscription}>
        <MemberContainer>
          {isLoading && <DotsLoading />}
          {members.map(member => (
            <OneMember
              key={member.id}
              email={member.email}
              projects={member.projectsCount}
              canDeleteMember={!user.isMember}
              deleteMember={() => deleteMember(member.id)}
              isLoading={isLoading}
            />
          ))}
        </MemberContainer>
        {members.length === 0 && (
          <Description>You have no team members yet</Description>
        )}
        {isOpenAddBox && (
          <form onSubmit={addNewMember}>
            {isLoading && <DotsLoading />}
            <AddContainer>
              <InputLabel>Email</InputLabel>
              <HideText onClick={toggleAddMemberMode}>Hide add Box</HideText>
            </AddContainer>
            <BottomContainer>
              <InputWrapper>
                <EmailWrapper>
                  <StyledInput
                    type="email"
                    disabled={isLoading}
                    value={email.value}
                    onChange={handleInputChange(setEmail)}
                    onBlur={handleInputBlur(email, setEmail)}
                    placeholder="Enter Team Member E-mail"
                    error={email.errorMsg}
                  />
                </EmailWrapper>
              </InputWrapper>
              <StyledButton type="submit" text="Add" />
            </BottomContainer>
          </form>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isAddPlan: boolean }>`
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  position: ${props => (props.isAddPlan ? 'unset' : 'relative')};
  height: ${props => (props.isAddPlan ? '0' : '100%')};
`;

const MemberContainer = styled.div`
  & > div {
    &:last-child {
      border-bottom: 0px;
    }
  }
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  ${removeScrollStyles}
`;
const Container = styled.div<{ isActiveSubscription: boolean }>`
  border-radius: ${rootBorderRadius};
  filter: ${props => (props.isActiveSubscription ? 'blur(0)' : 'blur(1px)')};
`;

const TopTeamContainer = styled.div<{ isActiveSubscription: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: ${props => (props.isActiveSubscription ? 'blur(0)' : 'blur(1px)')};
`;

const AddMember = styled.div<{ isActiveSubscription: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
  border-radius: 5px;
  font-family: ${Fonts.Sofia};
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: ${props => (props.isActiveSubscription ? '1' : '0.7')};
`;

const OneMember = styled(TeamMember)`
  padding-bottom: 20px;
`;

const Description = styled.p`
  padding: 15px;
  margin: 0;
`;

const Text = styled.div`
  font-family: ${Fonts.Sofia};

  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
const TitleText = styled(Text)`
  font-size: ${rem(12.5)};
  color: ${Colors.Black};
`;
const HideText = styled(Text)`
  font-size: ${rem(10)};
  color: ${Colors.DarkBlue};
  &:hover {
    cursor: pointer;
  }
`;

const AddContainer = styled(TopContainer)`
  margin-bottom: 10px;
`;

const BottomContainer = styled(TopContainer)`
  gap: 15px;
  align-items: baseline;
  margin-bottom: 15px;
`;

const EmailWrapper = styled.div`
  width: 100%;
`;

export default Team;
