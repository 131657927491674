import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UnlimitedList } from '../types';
import {
  Comment,
  CommentsList,
  CreateCommentRequest,
  NegotiationsList,
  UpdateCommentRequest,
} from './types';

const getCommentsByThreadId = (
  threadId: number,
): Promise<AxiosResponse<CommentsList>> =>
  api.get(
    `/projects/comments/?comment_thread=${threadId}&limit=${UnlimitedList}`,
  );

const getNegotiationsByThreadId = (
  threadId: number,
): Promise<AxiosResponse<NegotiationsList>> =>
  api.get(
    `/projects/negotiations/?comment_thread=${threadId}&limit=${UnlimitedList}`,
  );

const createComment = (
  body: CreateCommentRequest,
): Promise<AxiosResponse<Comment>> => api.post('/projects/comments/', body);

const updateComment = (
  id: number,
  body: UpdateCommentRequest,
): Promise<AxiosResponse<Comment>> =>
  api.patch(`/projects/comments/${id}/`, body);

const deleteComment = (commentId: number): Promise<AxiosResponse> =>
  api.delete(`/projects/comments/${commentId}/`);

const comments = {
  getCommentsByThreadId,
  getNegotiationsByThreadId,
  createComment,
  updateComment,
  deleteComment,
};
export default comments;
