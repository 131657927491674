import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  unreadEvents: number;
  className?: string;
};

function UnreadEventsCount({ unreadEvents, className }: Props) {
  if (unreadEvents === 0) return <></>;

  return (
    <UnreadEvents className={className}>
      {unreadEvents <= 9 ? unreadEvents : '9+'}
    </UnreadEvents>
  );
}

const UnreadEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding-top: 3px;
  border-radius: 50%;
  background-color: ${Colors.DarkRed};
  overflow: hidden;

  color: ${Colors.White} !important;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(8)};
  line-height: 0;
  color: ${Colors.DarkGrey};
`;

export default UnreadEventsCount;
