import { useRef } from 'react';
import styled from 'styled-components';

import CardWrapper from '../../../atoms/CardWrapper';
import FileWrapper from '../../../atoms/FileWrapper';

type Props = {
  value: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  setSizeError: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  sizeError: { [key: string]: boolean };
};

function AddTeaserAndCim({ value, onChange, setSizeError, sizeError }: Props) {
  const teaserRef = useRef<HTMLInputElement | null>(null);
  const cimRef = useRef<HTMLInputElement | null>(null);

  const handleRemove = (
    type: string,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    if (type === 'teaser') {
      onChange({ ...value, teaser: null });
      if (teaserRef.current) {
        teaserRef.current.value = '';
      }
    } else if (type === 'cim') {
      onChange({ ...value, cim: null });
      if (cimRef.current) {
        cimRef.current.value = '';
      }
    }
  };
  return (
    <TeaserCimContainer>
      <CardWrapper title="Add a Teaser to your project">
        <FileWrapper
          value={value.teaser}
          onChange={event => {
            if (!event.target.files) return;
            onChange({ ...value, teaser: event.target?.files[0] });
          }}
          removeFile={(event: React.MouseEvent<HTMLDivElement>) =>
            handleRemove('teaser', event)
          }
          name="teaser"
          fileRef={teaserRef}
          setSizeError={setSizeError}
          sizeError={sizeError}
        />
      </CardWrapper>
      <CardWrapper title="Add a CIM to your project">
        <FileWrapper
          value={value.cim}
          onChange={event => {
            if (!event.target.files) return;
            onChange({ ...value, cim: event.target?.files[0] });
          }}
          removeFile={(event: React.MouseEvent<HTMLDivElement>) =>
            handleRemove('cim', event)
          }
          name="cim"
          fileRef={cimRef}
          setSizeError={setSizeError}
          sizeError={sizeError}
        />
      </CardWrapper>
    </TeaserCimContainer>
  );
}

const TeaserCimContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: unwrap;
  gap: 35px;
  > div {
    width: 48%;
  }
`;

export default AddTeaserAndCim;
