import { Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import RECIPIENT_STATUS from '../assets/svg/RECIPIENT_STATUS';
import { PopoverBox } from './HidenBoxHovered';

export enum StatusEnum {
  Comment = 'comment',
  Received = 'received',
  Refused = 'refused',
  Sent = 'nda_sent',
  Signed = 'nda_signed',
}

type Props = {
  status: string | null;
  className?: string;
};

function RecipientStatus({ status, className }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const imgSrc = RECIPIENT_STATUS[status as StatusEnum] ?? SVG.disabled;

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isEllipsisActive = (e: HTMLElement) => {
    if (imgSrc === SVG.disabled) {
      return false;
    }
    return true;
  };

  const isOpen = Boolean(anchorEl);

  const formatStatus = () => {
    if (!status) return '';
    return status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  return (
    <Container
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      aria-owns={isOpen ? 'mouse-over-popover' : undefined}
    >
      <Image className={className} src={imgSrc} alt={status ?? ''} />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={anchorEl && isEllipsisActive(anchorEl) ? isOpen : false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <PopoverBox>{formatStatus() || 'No activites yet!'}</PopoverBox>
      </Popover>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 21px;
  height: 21px;
  margin: 0 auto;
`;

export default RecipientStatus;
