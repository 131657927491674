import { format } from 'date-fns';
import { memo } from 'react';
import { Area, AreaChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import { BoxShadow, Colors, Fonts, rootBorderRadius } from '../../../themes';

type Props = {
  ndaData: {
    month: string;
    Recall: number;
    Send: number;
    Signed: number;
  }[];
};

function NdaAreaChart({ ndaData }: Props) {
  const emptyData = [
    {
      count: 0,
      month: format(
        new Date(new Date().getFullYear(), new Date().getMonth()),
        'MMM',
      ),
    },
  ];

  return (
    <Container>
      <AreaChart
        width={550}
        height={310}
        data={ndaData.length ? ndaData : emptyData}
        margin={{
          top: 10,
          right: 10,
          left: -30,
          bottom: -10,
        }}
      >
        <XAxis
          type="category"
          dataKey="month"
          scale="point"
          tickLine={false}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis type="number" tickLine={false} allowDecimals={false} />
        <Tooltip
          labelStyle={{
            display: 'none',
          }}
        />

        <Area
          type="monotone"
          dataKey="Send"
          fill="#45D26499"
          stroke="#45D26499"
        />
        <Area
          type="monotone"
          dataKey="Signed"
          fill="#0088FE99"
          stroke="#0088FE99"
        />
        <Area
          type="monotone"
          dataKey="Recall"
          fill="#FF804299"
          stroke="#FF804299"
        />
        <Legend
          verticalAlign="bottom"
          height={36}
          iconType="circle"
          iconSize={10}
        />
      </AreaChart>
    </Container>
  );
}

const Container = styled.div`
  border-radius: ${rootBorderRadius};
  background-color: ${Colors.White};
  font-family: ${Fonts.Sofia};
  width: fit-content;
`;

export const CustomTooltip = styled.div`
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${Colors.White};
  border: 0.5px solid ${Colors.LightGrey};
  box-sizing: border-box;
  box-shadow: ${BoxShadow.Secondary};
  border-radius: 6px;
`;

export default memo(NdaAreaChart);
