import styled from 'styled-components';

import { ReactComponent as InfoSVG } from '../assets/svg/Info.svg';
import { Colors, Fonts, rem, rootBorderRadius } from '../themes';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const InfoBlock = ({ children }: Props) => (
  <Wrapper>
    <StyledInfoSVG width={30} height={30} />

    <TextBlock>{children}</TextBlock>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 10px;

  background-color: ${Colors.InfoBlue};
  border-radius: ${rootBorderRadius};
`;

const TextBlock = styled.div`
  margin-left: 10px;
  & > p,
  & > span {
    margin: 0;

    font-family: ${Fonts.Sofia};
    font-size: ${rem(8)};
  }
`;

const StyledInfoSVG = styled(InfoSVG)`
  & > * {
    fill: ${Colors.MiddleSecondGrey};
  }
`;

export default InfoBlock;
