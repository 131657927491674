import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BoxShadow, Colors, Fonts, rem } from '../../../themes';
import MessageAccordion from './Accordian';
import MessageTab from './Tabs';

function Features() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="features">
      <Title>Our Features</Title>
      <Details>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor
      </Details>
      <BoxContainer>
        {isMobileView ? <MessageAccordion /> : <MessageTab />}
      </BoxContainer>
    </div>
  );
}

const BoxContainer = styled.div`
  max-width: 1296px;
  margin: 0 auto;
  background: ${Colors.White};
  box-shadow: ${BoxShadow.Base};
  border-radius: 8px;
  margin-top: 48px;
  margin-bottom: 80px;
`;

const Title = styled.div`
  color: ${Colors.DarkBlack};
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(26.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 80px;
  @media (max-width: 768px) {
    font-size: ${rem(20.25)};
    padding-top: 0;
    margin-bottom: 10px;
  }
`;

const Details = styled.div`
  color: ${Colors.LightSecondGrey};
  text-align: center;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-top: 10px;
`;

export default Features;
