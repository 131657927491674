import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { billing as apiBilling, ProductType } from '../api';
import { ButtonEllipse } from '../atoms';
import { useNotification } from '../hooks';
import { Loader } from '../molecules';
import {
  projectAddonDetail,
  useUpgrade,
} from '../pages/plan/components/Plans/helpers';
import { Routes } from '../routes';
import { selectors, updateSubscription } from '../store';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  closeModal?: () => void;
};

function BuyAdditionalProject({ closeModal }: Props) {
  const { showError } = useNotification();
  const dispatch = useDispatch();
  const history = useHistory();
  const subscription = useSelector(selectors.subscription);
  const user = useSelector(selectors.user);

  const [isConfirmBuyProject, setIsConfirmBuyProject] = useState(false);
  const [allPlans, setAllPlans] = useState<ProductType[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { upgradeSubscription } = useUpgrade();

  useEffect(() => {
    apiBilling.getProducts().then(({ data }) => setAllPlans(data));
  }, []);

  const buyAdditionalProject = async () => {
    if (isLoading) return;

    const itemPriceId = projectAddon?.prices?.chargebeePriceId;
    if (!itemPriceId) return;

    setIsLoading(true);
    try {
      const addon = [
        {
          itemPriceId,
          quantity: 1,
        },
      ];

      await upgradeSubscription({ addon }).then(async response => {
        dispatch(
          updateSubscription({
            ...subscription,
            product: {
              ...subscription.product,
              projectAllowed: (subscription.product.projectAllowed || 0) + 1,
            },
          }),
        );
        closeModal && closeModal();
      });
    } catch (error: any) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goToPlans = () => {
    closeModal && closeModal();
    history.push(Routes.plan);
  };

  const unlimitedProjectsPlan = allPlans.find(
    plan => plan.numberOfProjectsAllowed,
  );

  const projectAddon = projectAddonDetail(
    subscription.product?.product?.addon,
    subscription.product?.product?.price?.interval as string,
  );

  const price =
    projectAddon && projectAddon.prices
      ? projectAddon.prices.unitAmount / 100
      : 0;

  const ConfirmationBlock = () => (
    <>
      <InfoBlock>
        Are you sure you want to buy one additional project for{' '}
        {user.currencySymbol} {price} ?
      </InfoBlock>
      <ButtonsBox>
        <CancelButton
          text="Cancel"
          onClick={() => closeModal && closeModal()}
        />
        <StyledButton text="Confirm" onClick={buyAdditionalProject} />
      </ButtonsBox>
    </>
  );

  return (
    <Container>
      {isLoading && <Loader />}
      <PlanName>
        Current plan:{' '}
        <AccentText>{subscription.product?.product?.name || 'Free'}</AccentText>
      </PlanName>

      {isConfirmBuyProject ? (
        <>
          <ConfirmationBlock />
        </>
      ) : (
        <>
          {subscription.product.status && (
            <>
              <InfoBlock>
                {user.currencySymbol} {price} per one additional project{' '}
              </InfoBlock>
              <ButtonsBox>
                <StyledButton
                  text="Add project on plan"
                  onClick={() => setIsConfirmBuyProject(true)}
                />
              </ButtonsBox>
            </>
          )}
        </>
      )}
      {!subscription.product.status && (
        <>
          <InfoBlock>
            Better you can choose{' '}
            <AccentText pointer onClick={goToPlans}>
              {unlimitedProjectsPlan?.name}
            </AccentText>{' '}
            plan
          </InfoBlock>
          <InfoBlock>
            {user.currencySymbol}{' '}
            {(unlimitedProjectsPlan?.prices[0]?.unitAmount || 0) / 100} per{' '}
            {unlimitedProjectsPlan?.prices[0]?.interval} /{' '}
            {(unlimitedProjectsPlan?.prices[1]?.unitAmount || 0) / 100} per{' '}
            {unlimitedProjectsPlan?.prices[1]?.interval}
          </InfoBlock>
          <ButtonsBox>
            <LinkButton text="Go to plans page" onClick={goToPlans} />
          </ButtonsBox>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  font-family: ${Fonts.Sofia};
`;

const PlanName = styled.p`
  width: fit-content;
  margin: 5px auto;
  font-size: ${rem(14)};
  text-align: center;
  color: ${Colors.TextGrey};
  font-weight: bold;
`;

const InfoBlock = styled.div`
  padding: 5px;
  color: ${Colors.TextGrey};
  font-size: ${rem(14)};
  text-align: center;
`;

const AccentText = styled.strong<{ pointer?: boolean }>`
  font-size: ${rem(16)};
  font-weight: 700;
  color: ${Colors.LightBlue};
  ${props => props.pointer && 'cursor: pointer;'}
`;

const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const CancelButton = styled(ButtonEllipse)`
  background-color: ${Colors.DarkRed};
  color: ${Colors.White};
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.White};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
`;

const LinkButton = styled(ButtonEllipse)`
  width: 200px;
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
`;

export default BuyAdditionalProject;
