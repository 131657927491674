import { AxiosResponse } from 'axios';

import api from '../authApi';
import { ContentTypes } from '../guestApi';
import { UnlimitedList } from '../types';
import {
  EditNdaRequest,
  GetNdaList,
  GetPaginationNdaList,
  OneNdaType,
} from './types';

const getAllNda = (): Promise<AxiosResponse<GetNdaList>> =>
  api.get(`/projects/nda/?limit=${UnlimitedList}`);

const getNdaByProjectId = (
  id: number,
): Promise<AxiosResponse<GetPaginationNdaList>> =>
  api.get(`/projects/nda/?project=${id}`);

const getNdaPdf = (id: number): Promise<AxiosResponse> =>
  api.get(`/projects/nda/${id}/get_nda_pdf/`, {
    responseType: 'blob',
  });

const getProjectBranchNda = (
  id: number,
): Promise<AxiosResponse<GetPaginationNdaList>> =>
  api.get(`/projects/nda/?project_branch=${id}`);

const createOneNda = (form: FormData): Promise<AxiosResponse<OneNdaType>> =>
  api.post('/projects/nda/', form, {
    headers: { 'Content-Type': ContentTypes.formData },
  });

const editNda = (
  id: number,
  form: FormData | EditNdaRequest,
): Promise<AxiosResponse<OneNdaType>> =>
  api.patch(`/projects/nda/${id}/`, form, {
    headers: {
      'Content-Type':
        form instanceof FormData ? ContentTypes.formData : ContentTypes.json,
    },
  });

const deleteNda = (id: number): Promise<AxiosResponse> =>
  api.delete(`/projects/nda/${id}/`);

const nda = {
  getAllNda,
  createOneNda,
  getNdaByProjectId,
  getNdaPdf,
  getProjectBranchNda,
  editNda,
  deleteNda,
};

export default nda;
