import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  EventType,
  Reports as ReportsType,
  reports as apiReports,
  ReportsFilter,
} from '../../api';
import { Loader } from '../../molecules';
import { ActivitiesBox, SideMenu } from '../../organisms';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem, rootBorderRadius } from '../../themes';
import {
  Analytics,
  AreaCharts,
  FilterSelect,
  NdaAreaChart,
  PieChart,
} from './components';

function Reports() {
  const [events, setEvents] = useState<EventType[]>([]);

  const [reports, setReports] = useState<ReportsType | null>(null);
  const [reportsFilter, setReportsFilter] = useState<ReportsFilter>({});

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRenderComponent(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    apiReports.getReports(reportsFilter).then(({ data }) => setReports(data));
  }, [reportsFilter]);

  const currentDate = new Date();
  const lastSixMonths = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 5; i >= 0; i--) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1,
    );
    lastSixMonths.push(date);
  }

  const data = lastSixMonths.map(date => {
    const matchingData = reports?.numberOfProjectsCreatedByMonth.find(
      item => item.year && item.month === date.getMonth() + 1,
    );

    const reportData = reports?.numberOfProjectBranchesCreatedByMonth.find(
      item => item.year && item.month === date.getMonth() + 1,
    );

    return {
      month: format(date, 'MMM'),
      Project: matchingData ? matchingData.number : 0,
      Recipients: reportData ? reportData.number : 0,
    };
  });

  const ndaData = lastSixMonths.map(date => {
    const signedData = reports?.numberOfNdaSignedByMonth.find(
      item => item.year && item.month === date.getMonth() + 1,
    );

    const recalledNda = reports?.numberOfNdaRecalledByMonth.find(
      item => item.year && item.month === date.getMonth() + 1,
    );

    const numberOfNdaSentByMonth = reports?.numberOfNdaSentByMonth.find(
      item => item.year && item.month === date.getMonth() + 1,
    );

    return {
      month: format(date, 'MMM'),
      Recall: recalledNda ? recalledNda.number : 0,
      Send: numberOfNdaSentByMonth ? numberOfNdaSentByMonth.number : 0,
      Signed: signedData ? signedData.number : 0,
    };
  });

  return (
    <PageWrapper>
      <ContentWrapper>
        <SideMenu setAllEvents={allEvents => setEvents(allEvents)} />
        <FilterSelect setNewFilter={setReportsFilter} />

        {reports ? (
          <>
            <StatsWrapper>
              <Analytics reports={reports} />
              <ActivitiesBox
                events={events}
                emptyMessage="There are no projects activities"
              />
            </StatsWrapper>

            <ChartWrapper>
              <SignedNdaBox>
                <Title>NDAs</Title>
                {renderComponent && <PieChart chartData={reports} />}
              </SignedNdaBox>

              <ProjectsBox>
                <Title>Project/ Recipients</Title>
                <AreaCharts data={data} />
              </ProjectsBox>

              <ProjectsBox>
                <Title>Send NDAs/ Signed NDAs/ Recalled NDAs</Title>
                <NdaAreaChart ndaData={ndaData} />
              </ProjectsBox>
            </ChartWrapper>
          </>
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
}

const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const StatsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 30px;
  }
`;

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
`;

const ProjectsBox = styled.div`
  padding: 20px 15px;
  border-radius: ${rootBorderRadius};
  background-color: ${Colors.White};
  border: 1px solid #d5e4f1;
  width: 100%;
  height: 390px;
`;

const SignedNdaBox = styled.div`
  padding: 20px 15px;
  border-radius: ${rootBorderRadius};
  background-color: ${Colors.White};
  width: 100%;
  border: 1px solid #d5e4f1;
  height: 390px;
  font-family: ${Fonts.Sofia};
`;

const Title = styled.p`
  width: fit-content;
  margin: 0 0 14px 0;

  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
`;

export default Reports;
