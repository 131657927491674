import styled from 'styled-components';

import SVG from '../assets/svg';
import { rem, removeScrollStyles } from '../themes';
import HidenBoxHovered from './HidenBoxHovered';
import UnreadEventsCount from './UnreadEventsCount';

type Props = {
  name: string;
  unreadEvents: number;
};

function FolderCard({ name, unreadEvents }: Props) {
  return (
    <Container>
      <LogoWrapper>
        <img src={SVG.projectFolderCircle} alt="Project Folder" />
        <FolderLogo
          src={SVG.projectYellowFolder}
          width="50px"
          alt="project folder"
        />
        <UnreadEvents unreadEvents={unreadEvents} />
      </LogoWrapper>
      <ProjectName value={name} popoverWidth="200px" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  position: relative;
`;

const FolderLogo = styled.img`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 47%;
  left: 52%;
  transform: translate(-50%, -50%);
`;

const ProjectName = styled(HidenBoxHovered)`
  font-size: ${rem(11.25)};
  text-overflow: ellipsis;
  text-align: start;
  margin-left: 15px;

  ${removeScrollStyles}
`;

const UnreadEvents = styled(UnreadEventsCount)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -40%);
`;

export default FolderCard;
