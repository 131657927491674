import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FolderCard } from '../../../atoms';
import { Routes } from '../../../routes';
import { Project } from './OneProject';

type Props = {
  projectId: number;
  groupId: number;
  name: string;
  unreadEvents: number;
};

function SignersProject({ projectId, groupId, name, unreadEvents }: Props) {
  return (
    <Link to={`${Routes.projects}/${projectId}/group/${groupId}`}>
      <StyledProject>
        <FolderCard name={name} unreadEvents={unreadEvents} />
      </StyledProject>
    </Link>
  );
}

const Link = styled(NavLink)`
  text-decoration: none;
`;

const StyledProject = styled(Project)`
  justify-content: center;
`;

export default SignersProject;
