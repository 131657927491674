/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { billing, Subscription } from '../../../../api';
import SVG from '../../../../assets/svg';
import { Modal, ModalBox } from '../../../../atoms';
import { updateSubscription } from '../../../../store';
import { Fonts, rem } from '../../../../themes';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<string>>;
}
const ProcessingModal = ({ isOpen, setIsOpen }: IProps) => {
  const [subscription, setSubscription] = useState<Subscription>();
  const dispatch = useDispatch();

  const getSubscription = async () => {
    billing.getSubscription().then(({ data }) => {
      setSubscription(data);
    });
  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await getSubscription();
    }, 2000);

    if (subscription && subscription.product) {
      setIsOpen('');
      dispatch(updateSubscription({ product: subscription }));
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [dispatch, setIsOpen, subscription]);

  return (
    <>
      <Modal
        modalIsOpen={isOpen}
        setModalIsOpen={() => setIsOpen('')}
        disabledOverlayClick
      >
        <ModalBox>
          <Container>
            <ProcessIcon src={SVG.process} alt="process" />
            <Text>Payment is processing, please don't close this. </Text>
          </Container>
        </ModalBox>
      </Modal>
    </>
  );
};

const Container = styled.div`
  text-align: center;
  padding: 20px 0px;
`;

const Text = styled.div`
  margin-top: 10px;
  font-size: ${rem(18)};
  font-family: ${Fonts.Sofia};
`;

const ProcessIcon = styled.img`
  width: 10%;
`;
export default ProcessingModal;
