import styled from 'styled-components';

import { Colors, Fonts } from '../themes';

type Props = {
  size: 'small' | 'big';
  onClick?: () => void;
};

function Logo({ size, onClick }: Props) {
  return (
    <StyledLogo size={size} onClick={onClick}>
      namda
    </StyledLogo>
  );
}

const StyledLogo = styled.div<Props>`
  display: inline-block;
  font-family: ${Fonts.Sofia};
  color: ${Colors.BackgroundGrey};
  cursor: pointer;
  font-size: ${props => (props.size === 'small' ? '2rem' : '3rem')};
`;

export default Logo;
