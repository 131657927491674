import { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import ErrorText from '../molecules/ErrorText';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder: string;
  className?: string;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  ref: any;
  name: string;
};

function FormInput({
  value = '',
  placeholder,
  error,
  className,
  disabled,
  type,
  ref,
  name,
  onChange,
}: Props) {
  return (
    <InputContainer>
      <StyledInput
        type={type ?? 'text'}
        defaultValue={value}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        ref={ref}
        name={name}
        onChange={onChange}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </InputContainer>
  );
}
export const InputContainer = styled.div`
  width: 100%;
`;
export const StyledInput = styled.input`
  width: 100%;
  padding: 10px;

  display: block;
  outline: none;

  border-radius: 10px;
  background-color: ${Colors.White};
  border: 0.5px solid ${Colors.WeakGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11)};
  color: ${Colors.DarkBlack};

  ::placeholder {
    font-family: ${Fonts.Sofia};
    font-size: ${rem(11)};
    color: ${Colors.StrongGrey};
  }
  :disabled {
    background-color: transparent;
  }
`;

export default FormInput;
