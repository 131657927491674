import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  billing as apiBilling,
  members as apiMembers,
  ProductType,
} from '../../api';
import { Toggle } from '../../atoms';
import { Loader } from '../../molecules';
import { SideMenu } from '../../organisms';
import { selectors } from '../../store';
import { PageWrapper } from '../../templates';
import { Colors, Fonts, rem } from '../../themes';
import { Billing, Plans } from './components';

function Plan() {
  const subscription = useSelector(selectors.subscription);
  const user = useSelector(selectors.user);

  const [allPlans, setAllPlans] = useState<ProductType[]>([]);

  const [selectedPeriod, setSelectedPeriod] = useState('Monthly');

  const [isLoading, setIsLoading] = useState(true);
  const [membersCount, setMembersCount] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    apiBilling
      .getProducts()
      .then(({ data }) => setAllPlans(data))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    apiMembers.getAllMembers().then(({ data }) => setMembersCount(data.length));
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <SideMenu />

        <Main>
          {!isLoading ? (
            <PlanContainer>
              <BillingWrapper>
                <Billing
                  membersCount={membersCount}
                  currencySymbol={user.currencySymbol}
                />
              </BillingWrapper>

              <StyledToggle
                selectedItem={selectedPeriod}
                setSelectedItem={setSelectedPeriod}
                titleOne="Monthly"
                titleTwo="Annually -20%"
              />

              <Plans
                allPlans={allPlans}
                userSubscription={subscription.product}
                selectedPeriod={selectedPeriod}
                membersCount={membersCount}
                currencySymbol={user.currencySymbol}
              />

              <CustomPlanWrapper>
                <CustomPlan>Need A Custom Plan?</CustomPlan> Contact our
                <SalesLink
                  target="_blank"
                  to={{ pathname: 'mailto:support@namda.io' }}
                >
                  sales team
                </SalesLink>
              </CustomPlanWrapper>
            </PlanContainer>
          ) : (
            <Loader />
          )}
        </Main>
      </ContentWrapper>
    </PageWrapper>
  );
}

const BillingWrapper = styled.div`
  position: relative;
  z-index: 99;
  width: 930px;
  margin: 0 auto;
`;
const ContentWrapper = styled.div`
  padding: 10px 0;
`;

const StyledToggle = styled(Toggle)`
  margin: 20px auto;
`;

const CustomPlanWrapper = styled.div`
  width: 100%;
  height: 58px;
  border-radius: 6px;
  border: 1px solid ${Colors.LightSecondBlue};
  background: ${Colors.White};
  padding-left: 10px;
  margin: 20px auto 0 auto;
  justify-content: start;
  display: flex;
  align-items: center;

  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const CustomPlan = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-right: 5px;
`;

const SalesLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  margin-left: 5px;
`;
const Main = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.25)};
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export default Plan;
