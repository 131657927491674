import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UnlimitedList } from '../types';
import {
  CreateSignerReguest,
  GetSignerList,
  OneSignerGroup,
  UpdateSignerReguest,
} from './types';

const getSignersGroups = (): Promise<AxiosResponse<GetSignerList>> =>
  api.get(`/projects/signers_group/?limit=${UnlimitedList}`);

const createSignersGroup = (
  body: CreateSignerReguest,
): Promise<AxiosResponse<OneSignerGroup>> =>
  api.post('/projects/signers_group/', body);

const updateSignersGroup = (
  id: number,
  body: UpdateSignerReguest,
): Promise<AxiosResponse<OneSignerGroup>> =>
  api.patch(`/projects/signers_group/${id}/`, body);

const deleteSignersGroup = (id: number): Promise<AxiosResponse> =>
  api.delete(`/projects/signers_group/${id}/`);

const signers = {
  createSignersGroup,
  updateSignersGroup,
  deleteSignersGroup,
  getSignersGroups,
};

export default signers;
