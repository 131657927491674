import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

type Props = {
  onSaveClick: () => void;
  onDiscardClick: () => void;
};

function SaveOrDiscard({ onSaveClick, onDiscardClick }: Props) {
  return (
    <ControlBlock>
      <Button type="button" onClick={onSaveClick}>
        Save
      </Button>
      <DiscardButton type="button" onClick={onDiscardClick}>
        Discard
      </DiscardButton>
    </ControlBlock>
  );
}

const ControlBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 10px;

  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};

  & > *:first-child {
    margin-right: 10px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
  border: 1px solid ${Colors.WeakGrey};
  background-color: ${Colors.White};
  color: ${Colors.TextGrey};
`;

const DiscardButton = styled(Button)`
  background-color: ${Colors.DarkRed};
  color: ${Colors.White};
`;

export default SaveOrDiscard;
