import { useState } from 'react';
import styled from 'styled-components';

import { contacts as apiContacts, ContactType } from '../api';
import SVG from '../assets/svg';
import { ButtonEllipse, Input } from '../atoms';
import { Errors } from '../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmail,
  isEmptyInput,
} from '../helpers';
import { useNotification } from '../hooks';
import { BoxShadow, Colors, rem } from '../themes';

type Props = {
  addNewContact: (contact: ContactType) => void;
  closeForm: () => void;
};

function NewContactForm({ addNewContact, closeForm }: Props) {
  const { showError } = useNotification();
  const [name, setName] = useState(initialValue);
  const [email, setEmail] = useState(initialValue);

  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;

    if (isEmptyInput(name, setName, Errors.EnterFirstAndLastname)) {
      isValid = false;
    } else {
      const [, lastName] = name.value.split(' ');
      if (isEmptyInput(lastName, setName, Errors.EnterLastName)) {
        isValid = false;
      }
    }

    if (isEmptyInput(email, setEmail, Errors.EnterYourEmail)) {
      isValid = false;
    }

    if (!isEmail(email.value)) {
      setEmail({ ...email, errorMsg: Errors.EnterValidEmail });
      isValid = false;
    }

    if (email.errorMsg || name.errorMsg) {
      isValid = false;
    }

    return isValid;
  };

  const addContact = () => {
    if (isLoading) return;
    if (!validate()) return;

    const [firstName, lastName] = name.value.split(' ');

    setIsLoading(true);
    apiContacts
      .createContact({ email: email.value, firstName, lastName })
      .then(({ data }) => {
        setName(initialValue);
        setEmail(initialValue);

        addNewContact(data);
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.lastName) showError(data.lastName[0]);

        apiFieldErrorHandler(data.email, setEmail);
        apiFieldErrorHandler(data.firstName, setName);
      })
      .finally(() => {
        setIsLoading(false);
        closeForm();
      });
  };

  return (
    <Container>
      <UserLogoBox>
        <UserLogo width="55px" src={SVG.userIcon} alt="Default user logo" />
      </UserLogoBox>

      <InputsBox>
        <StyledInput
          disabled={isLoading}
          value={name.value}
          onChange={handleInputChange(setName)}
          onBlur={handleInputBlur(name, setName)}
          placeholder="First name & Last name"
          error={name.errorMsg}
        />
        <StyledInput
          disabled={isLoading}
          value={email.value}
          onChange={handleInputChange(setEmail)}
          onBlur={handleInputBlur(email, setEmail)}
          placeholder="E-mail"
          error={email.errorMsg}
        />
        <StyledButton text="Add the Contact" onClick={addContact} />
      </InputsBox>

      <CloseImg onClick={closeForm} src={SVG.rejected} alt="close button" />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  padding: 15px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  box-shadow: ${BoxShadow.Base};
  border: 0.5px solid ${Colors.WeakGrey};
  border-radius: 15px;
`;

const CloseImg = styled.img`
  width: 24px;

  display: block;

  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms linear;

  &:hover {
    transform: scale(1.1);
  }
`;

const UserLogoBox = styled.div`
  padding: 15px 15px 0 0;
`;

const UserLogo = styled.img`
  flex-basis: 30px;
`;

const InputsBox = styled.div`
  flex-grow: 2;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledInput = styled(Input)`
  width: 350px;
  font-size: ${rem(10)};
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.LightBlue};
  background-color: ${Colors.White};
  color: ${Colors.TextGrey};
  margin-top: 20px;
`;

export default NewContactForm;
