import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { templates as apiTemplates } from '../../../api';
import { Input } from '../../../atoms';
import CardWrapper from '../../../atoms/CardWrapper';
import FileWrapper from '../../../atoms/FileWrapper';
import SolidButton from '../../../atoms/SolidButton';
import { Errors } from '../../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../../helpers';
import { useNotification, usePermissions } from '../../../hooks';
import ErrorText from '../../../molecules/ErrorText';
import { Routes } from '../../../routes';
import { PageWrapper } from '../../../templates';
import { Colors, Fonts, rem } from '../../../themes';

function AddTemplate() {
  const { showError } = useNotification();
  const { canAddNdatemplate } = usePermissions();
  const history = useHistory();
  const [name, setName] = useState(initialValue);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (file) setFileError('');
  }, [file]);

  const addNewTemplate = (event: SyntheticEvent) => {
    event.preventDefault();

    if (isEmptyInput(name, setName, Errors.EnterNdaTempleteName)) return;
    if (!file) {
      setFileError(`${Errors.UploadFile} for NDA Template`);
      return;
    }

    if (isLoading) return;
    if (!canAddNdatemplate) return;

    const form = new FormData();
    form.append('name', name.value);

    form.append('file', file);

    setIsLoading(true);
    apiTemplates
      .createOneTemplate(form)
      .then(({ data }) => history.push(Routes.templates))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
        if (data.file) showError(data.file[0]);

        apiFieldErrorHandler(data.name, setName);
      })
      .finally(() => setIsLoading(false));
  };

  const handleRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  return (
    <PageWrapper>
      <StyledForm onSubmit={addNewTemplate}>
        <CardWrapper title="Add a new NDA Template">
          <>
            <Label>NDA Name</Label>
            <Input
              disabled={isLoading}
              value={name.value}
              onChange={handleInputChange(setName)}
              onBlur={handleInputBlur(name, setName)}
              placeholder="NDA Name"
              error={name.errorMsg}
            />
            <FileWrapper
              value={file}
              onChange={event => {
                if (!event.target.files) return;
                setFile(event.target?.files[0]);
              }}
              removeFile={handleRemove}
              name="template"
              fileRef={inputRef}
              placeholder="Upload a Template File"
            />
            {fileError && <Error>{fileError}</Error>}
          </>
        </CardWrapper>
        <ButtonContainer>
          <StyledButton
            type="button"
            text="Cancel"
            onClick={() => history.push(Routes.templates)}
          />
          <StyledSubmitButton
            type="submit"
            text={isLoading ? 'Save...' : 'Save'}
          />
        </ButtonContainer>
      </StyledForm>
    </PageWrapper>
  );
}

const StyledForm = styled.form`
  padding: 10px;
`;

const Error = styled(ErrorText)`
  text-align: center;
`;

const Label = styled.div`
  color: ${Colors.Black};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
`;

export default AddTemplate;
