import React, { useState } from 'react';
import styled from 'styled-components';

import SVG from '../../../assets/svg';
import { Colors, Fonts, rem } from '../../../themes';
import Create from './Create';
import Invite from './Invite';
import Opportunity from './Opportunity';
import Safe from './Safe';
import SendAndNegotiate from './SendAndNegotiate';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

function Accordion({ title, children }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(prevState => !prevState);
  };

  const getImageSource = () => {
    switch (title) {
      case 'Send & Negotiate':
        return `${SVG.sendAndNegotiate}`;
      case 'Opportunities':
        return `${SVG.opportunities}`;
      case 'Create':
        return `${SVG.create}`;
      case 'Invite & Track':
        return `${SVG.inviteAndTrack}`;
      case 'Safe':
        return `${SVG.safe}`;
      default:
        return '';
    }
  };

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={toggleAccordion}>
        <AccordionTitle>
          <img
            src={getImageSource(title)}
            alt={title}
            width="38"
            height="38"
            style={{ marginRight: '0.5rem' }}
          />
          {title}
        </AccordionTitle>
        <AccordionIcon
          src={SVG.downArrow}
          alt="arrow"
          isOpen={isOpen}
          width={24}
        />
      </AccordionHeader>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionWrapper>
  );
}

function MessageAccordion() {
  return (
    <AccordionContainer>
      <Accordion title="Send & Negotiate">
        <SendAndNegotiate />
      </Accordion>
      <Accordion title="Opportunities">
        <Opportunity />
      </Accordion>
      <Accordion title="Create">
        <Create />
      </Accordion>
      <Accordion title="Invite & Track">
        <Invite />
      </Accordion>
      <Accordion title="Safe">
        <Safe />
      </Accordion>
    </AccordionContainer>
  );
}

const AccordionContainer = styled.div`
  width: 100%;
`;

const AccordionWrapper = styled.div`
  border-bottom: 1px solid lightgray;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
`;

const AccordionTitle = styled.h3`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(15)};
  color: ${Colors.DarkBlue};
  margin: 0;
  font-weight: 300;
  @media (max-width: 425px) {
    font-size: ${rem(13)};
    display: flex;
    align-items: center;
  }
`;

const AccordionIcon = styled.img<{ isOpen: boolean }>`
  margin-left: auto;
  transform: rotate(${props => (props.isOpen ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const AccordionContent = styled.div`
  padding: 20px;
`;

export default MessageAccordion;
