import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { authorization, LoginDataType, updateTokens } from '../../api';
import SVG from '../../assets/svg';
import { ButtonEllipse } from '../../atoms';
import AuthWrapper from '../../atoms/AuthWrapper';
import MuiInput from '../../atoms/MuiInput';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import MuiInputPassword from '../../molecules/MuiInputPassword';
import { Routes } from '../../routes';
import { loginUser } from '../../store';
import { Colors, Fonts, rem } from '../../themes';

interface LocationState {
  from: {
    pathname: string;
  };
}

function Login() {
  const { showError } = useNotification();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(initialValue);
  const [password, setPassword] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const validate = () => {
    let isValid = true;

    if (isEmptyInput(email, setEmail, Errors.EnterEmail)) {
      isValid = false;
    }
    if (isEmptyInput(password, setPassword, Errors.EnterPassword)) {
      isValid = false;
    }
    if (email.errorMsg || password.errorMsg) {
      isValid = false;
    }

    return isValid;
  };

  const handleLogin = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!validate()) return;

    setIsLoading(true);

    authorization
      .login({
        email: email.value,
        password: password.value,
      })
      .then(({ data }) => {
        updateTokens();
        dispatch(loginUser(data as LoginDataType));
        const signer = localStorage.getItem('signer');
        if (signer) {
          localStorage.removeItem('signer');
          history.push(signer);
          return;
        }
        if (location.state?.from) {
          history.replace(location.state?.from);
        } else {
          history.push(Routes.account);
        }
      })
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.email, setEmail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthWrapper isLoading={isLoading}>
      <>
        <FromSubmit onSubmit={handleLogin}>
          <Text>Sign In</Text>
          <InputWrapper>
            <MuiInput
              required
              type="email"
              value={email.value}
              onChange={handleInputChange(setEmail)}
              onBlur={handleInputBlur(email, setEmail)}
              placeholder="Email"
              error={email.errorMsg}
            />
          </InputWrapper>
          <InputWrapper>
            <MuiInputPassword
              required
              value={password.value}
              onChange={handleInputChange(setPassword)}
              onBlur={handleInputBlur(password, setPassword)}
              placeholder="Password"
              error={password.errorMsg}
            />
          </InputWrapper>
          <ForgotPassword to={Routes.forgotPassword}>
            <img src={SVG.lock} alt="lock" style={{ marginRight: '10px' }} />
            Forgot Password ?
          </ForgotPassword>
          <ButtonWrapper>
            <StyledButton type="submit" text="Sign In" />
          </ButtonWrapper>
        </FromSubmit>
        <SignUpText>
          Not yet an Account,
          <StyledLink to={Routes.register}>Register for Free</StyledLink>
        </SignUpText>
      </>
    </AuthWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 31px;
  position: relative;
`;

const FromSubmit = styled.form`
  width: 100%;
`;

const Text = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;

const StyledButton = styled(ButtonEllipse)`
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  width: 124px;
  height: 48px;
  border-radius: 10px;
  border: none;

  &:hover {
    border: none;
  }
`;

const ForgotPassword = styled(NavLink)`
  font-size: ${rem(8.75)};
  font-family: ${Fonts.Roboto};
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-top: 20px;
  text-align: right;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SignUpText = styled.div`
  color: ${Colors.LightSecondGrey};
  margin-top: 30px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Roboto};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-left: 5px;
`;

export default Login;
