import { ClickAwayListener } from '@mui/material';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { EventEnum, EventType } from '../api';
import SVG from '../assets/svg';
import { ButtonEllipse } from '../atoms';
import { Activity } from '../molecules';
import {
  Colors,
  Fonts,
  rem,
  removeScrollStyles,
  rootBorderRadius,
} from '../themes';

type ShowedEvent = EventType & {
  authorsCount: number;
  sameAuthorCount: number;
};

type Props = {
  events: EventType[];
  emptyMessage?: string;
  className?: string;
};

function ActivitiesBox({ events, emptyMessage, className }: Props) {
  const [shownEvents, setShownEvents] = useState<ShowedEvent[]>([]);

  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    const groupedEvents = events.reduce<ShowedEvent[]>((acc, event) => {
      const lastEvent = acc[acc.length - 1];
      // it's first event on list
      if (!lastEvent)
        return [{ ...event, authorsCount: 1, sameAuthorCount: 1 }];

      // if status of current event not same like lastEvent status we will add new event
      if (event.status !== lastEvent?.status) {
        return [...acc, { ...event, authorsCount: 1, sameAuthorCount: 1 }];
      }

      // if current and prev events have same authorId we will modify lastEvent in list
      if (event.authorId === lastEvent?.authorId) {
        return [
          ...acc.slice(0, acc.length - 1),
          { ...event, authorsCount: 1, sameAuthorCount: 2 },
        ];
      }

      // if the last event was created from many events by one author
      //  and has the same status as the current event, we will add a new event
      if (lastEvent?.sameAuthorCount > 1) {
        return [...acc, { ...event, authorsCount: 1, sameAuthorCount: 1 }];
      }

      // if the last event has the same status as the current event, we will modify lastEvent in list
      return [
        ...acc.slice(0, acc.length - 1),
        {
          ...lastEvent,
          authorsCount: lastEvent.authorsCount + 1,
          sameAuthorCount: 1,
        },
      ];
    }, []);

    if (isShowMore) {
      setShownEvents(groupedEvents);
    } else {
      setShownEvents(groupedEvents.slice(0, 3));
    }
  }, [events, isShowMore]);

  return (
    <ClickAwayListener onClickAway={() => setIsShowMore(false)}>
      <Items className={className} isShowMore={isShowMore}>
        {shownEvents.length === 0 && (
          <EmptyMessage>
            {emptyMessage ?? <span>No activity to display</span>}
          </EmptyMessage>
        )}

        {shownEvents.map(activity => (
          <Activity
            key={activity.id}
            type={activity.status as EventEnum}
            authorsCount={activity.authorsCount}
            sameAuthorCount={activity.sameAuthorCount}
            author={activity.author}
            time={activity.created}
          />
        ))}

        {shownEvents.length >= 3 && (
          <FlexBox onClick={() => setIsShowMore(!isShowMore)}>
            <ShowMore text={isShowMore ? 'Show Less' : 'Show more'} />

            <Image
              width="16px"
              src={SVG.openArrow}
              alt="Open NDA controls"
              isShowMore={isShowMore}
            />
          </FlexBox>
        )}
      </Items>
    </ClickAwayListener>
  );
}

type ShowMoreProps = {
  isShowMore: boolean;
};

const Items = styled.div<ShowMoreProps>`
  background-color: ${Colors.White};
  border-radius: ${rootBorderRadius};
  color: ${Colors.DarkGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ isShowMore }) =>
    isShowMore &&
    css`
      width: 100%;
      height: fit-content;
      max-height: 78vh;
      overflow: auto;

      ${removeScrollStyles}
    `}
`;

const Image = styled.img<ShowMoreProps>`
  transition: ease-in-out 0.3s;
  transform: ${({ isShowMore }) => (isShowMore ? 'rotate(180deg)' : 'none')};
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
`;

const ShowMore = styled(ButtonEllipse)`
  width: 90px;
  color: ${Colors.DarkBlue};
  font-size: ${rem(8.75)};
  font-family: ${Fonts.Sofia};
  font-weight: 300;

  &:hover {
    background-color: #fff;
    color: ${Colors.LightBlue};
    border-color: transparent;
  }
`;

const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  padding: 25px 30px;
  border-bottom: 1px solid rgba(2, 48, 97, 0.14);
  color: ${Colors.TextGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  white-space: nowrap;
`;

export default ActivitiesBox;
