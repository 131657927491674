import { SyntheticEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { authorization } from '../../api';
import { ButtonEllipse } from '../../atoms';
import AuthWrapper from '../../atoms/AuthWrapper';
import MuiInput from '../../atoms/MuiInput';
import { Errors } from '../../const';
import {
  apiFieldErrorHandler,
  handleInputBlur,
  handleInputChange,
  initialValue,
  isEmptyInput,
} from '../../helpers';
import { useNotification } from '../../hooks';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

function ForgotPassword() {
  const { showError } = useNotification();

  const [email, setEmail] = useState(initialValue);
  const [isLetterSent, setIsLetterSent] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (isEmptyInput(email, setEmail, Errors.EnterYourEmail)) return;

    setIsLoading(true);
    authorization
      .resetPassword({
        email: email.value,
      })
      .then(() => setIsLetterSent(true))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);

        apiFieldErrorHandler(data.email, setEmail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthWrapper isLoading={isLoading}>
      <>
        {!isLetterSent ? (
          <FromSubmit onSubmit={handlePasswordReset}>
            <Text>Forgot Password</Text>
            <InputWrapper>
              <MuiInput
                type="email"
                value={email.value}
                onChange={handleInputChange(setEmail)}
                onBlur={handleInputBlur(email, setEmail)}
                placeholder="E-mail for restore"
                error={email.errorMsg}
              />
            </InputWrapper>
            <ButtonWrapper>
              <StyledButton type="submit" text="Send mail" />
            </ButtonWrapper>
          </FromSubmit>
        ) : (
          <SuccessLetterSent>
            The e-mail was sent successfully
          </SuccessLetterSent>
        )}
        <GoToLogin to={Routes.login}>Back to Login</GoToLogin>
        <SignUpText>
          Not yet an Account,
          <StyledLink to={Routes.register}>Register for Free</StyledLink>
        </SignUpText>
      </>
    </AuthWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 31px;
  position: relative;
`;

const FromSubmit = styled.form`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;

const StyledButton = styled(ButtonEllipse)`
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  width: 124px;
  height: 48px;
  border-radius: 10px;
  border: none;

  &:hover {
    border: none;
  }
`;

const GoToLogin = styled(NavLink)`
  font-size: ${rem(8.75)};
  font-family: ${Fonts.Roboto};
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-top: 20px;
  text-align: right;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const SignUpText = styled.div`
  color: ${Colors.LightSecondGrey};
  margin-top: 20px;
  font-size: ${rem(10)};
  font-family: ${Fonts.Roboto};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const StyledLink = styled(NavLink)`
  color: ${Colors.DarkBlue};
  text-decoration: none;
  margin-left: 5px;
`;

const SuccessLetterSent = styled.div`
  padding: 25px;
  font-family: ${Fonts.Sofia};
  font-size: ${rem(15)};
  color: ${Colors.LightBlue};
`;

const Text = styled.div`
  color: ${Colors.DarkBlack};
  font-size: ${rem(12.5)};
  font-family: ${Fonts.Sofia};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
`;

export default ForgotPassword;
