import styled from 'styled-components';

import { UserLogo } from '../atoms';
import { Colors, Fonts, rem } from '../themes';

export type UsersInProject = {
  logo: string | null;
  namesLetters: string;
};

type Props = {
  avatarWidth?: number;
  showedAvatars: number;
  users: UsersInProject[];
};

function UsersLogos({ avatarWidth, showedAvatars, users }: Props) {
  const showedAvatarsArr =
    users.length > showedAvatars
      ? Array.from(Array(showedAvatars + 1).keys())
      : Array.from(Array(users.length).keys());

  return (
    <Container
      avatarWidth={avatarWidth ?? 20}
      showedAvatarsArr={showedAvatarsArr}
    >
      {users.slice(0, showedAvatars).map((user, idx) => (
        <UserLogo
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          width={avatarWidth ?? 26}
          logo={user.logo}
          namesLetters={user.namesLetters}
        />
      ))}

      {users.length > showedAvatars && (
        <OverflowCount width={avatarWidth ?? 26}>{`+${
          users.length - showedAvatars
        }`}</OverflowCount>
      )}
    </Container>
  );
}

const Container = styled.div<{
  avatarWidth: number;
  showedAvatarsArr: number[];
}>`
  position: relative;
  height: ${props => props.avatarWidth}px;
  min-width: ${props =>
    1 +
    Math.ceil(props.avatarWidth * props.showedAvatarsArr.length * (3 / 4))}px;

  & > * {
    position: absolute;
  }

  ${props =>
    props.showedAvatarsArr.map(
      userNumber => `
        & > *:nth-child(${userNumber + 1}) {
          left: ${Math.floor(props.avatarWidth * (3 / 4) * userNumber)}px;
        }`,
    )}
`;

const OverflowCount = styled.div<{ width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;

  width: ${props => props.width ?? 30}px;
  height: ${props => props.width ?? 30}px;
  border-radius: 50%;
  overflow: hidden;

  font-family: ${Fonts.Sofia};
  font-size: ${props => (props.width ? rem(props.width / 3) : rem(10))};
  color: ${Colors.White};
  background-color: ${Colors.LightBlue};
`;

export default UsersLogos;
