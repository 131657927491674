import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonEllipse } from '../../atoms';
import { LandingPageData } from '../../const';
import { Routes } from '../../routes';
import { Colors, Fonts, rem } from '../../themes';

function HeroSection() {
  const heroSec = LandingPageData.heroSection;

  return (
    <Wrapper>
      <ContentWrapper>
        <LandingInfoBox>
          <PlatformDescription>
            {heroSec.platformDescription}
          </PlatformDescription>
          <StyledText>{heroSec.platformInfo}</StyledText>
          <ButtonWrapper>
            <Link to={Routes.projects}>
              <AddMemberBG>
                <img src={heroSec.upload} alt="upload" width="22px" />
              </AddMemberBG>
              <StyledButton text={heroSec.uploadButtonText} />
            </Link>
          </ButtonWrapper>
        </LandingInfoBox>
        <LandingImg>
          <StyledLandingImage alt="landing" src={heroSec.LandingHome} />
        </LandingImg>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.LightBlue};
  padding: 0;
  margin: 0;
  min-height: 636px;
  @media (min-width: 1024px) {
    padding: 0 40px;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 1320px;
  margin: auto;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
  }
`;

const LandingInfoBox = styled.div`
  flex-basis: 50%;
`;

const LandingImg = styled.div`
  position: relative;
  right: -3%;
  bottom: 15px;
  @media (max-width: 1440px) {
    right: 0;
  }
  @media (max-width: 1024px) {
    right: 0;
    bottom: 25px;
  }
  @media (max-width: 768px) {
    right: 0;
    bottom: 0;
  }
`;

const AddMemberBG = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.LightBlue};
  position: absolute;
  left: 179px;
  top: 3px;
  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
    left: 160px;
  }
`;

const PlatformDescription = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(27.5)};
  color: ${Colors.White};
  font-style: normal;
  font-weight: 300;
  line-height: 54px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: ${rem(15)};
  }
`;

const StyledText = styled.div`
  font-family: ${Fonts.Sofia};
  font-size: ${rem(12.5)};
  color: ${Colors.White};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: ${rem(10)};
  }
`;

const StyledLandingImage = styled.img`
  height: auto;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 35%;
`;
const Link = styled(NavLink)`
  text-decoration: none;
  position: relative;
`;

const StyledButton = styled(ButtonEllipse)`
  width: 224px;
  height: 48px;
  justify-content: start;
  border-radius: 30px;
  border: 1px solid ${Colors.LightFifthGrey};
  background-color: ${Colors.White};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
  font-weight: 300;
  padding: 15px;
  &:hover {
    background-color: ${Colors.LightFifthGrey};
    border: 1px solid ${Colors.LightFifthGrey};
  }
  @media (max-width: 768px) {
    width: 200px;
    height: 43px;
  }
`;

export default HeroSection;
