import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  members as apiMembers,
  MemberType,
  PriceInterval as Interval,
} from '../api';
import { ButtonEllipse } from '../atoms';
import { useNotification } from '../hooks';
import { Loader } from '../molecules';
import {
  memberAddonDetail,
  useUpgrade,
} from '../pages/plan/components/Plans/helpers';
import { Routes } from '../routes';
import { selectors, updateSubscription } from '../store';
import { Colors, Fonts, rem } from '../themes';

interface Email {
  value: string;
  errorMsg: string;
}
type Props = {
  closeModal?: () => void;
  isAddPlanQuantity?: boolean;
  email?: Email;
  setMembers?: React.Dispatch<React.SetStateAction<MemberType[]>>;
  setIsOpenAddBox?: React.Dispatch<React.SetStateAction<boolean>>;
};

function ChangePlanQuantity({
  closeModal,
  isAddPlanQuantity,
  email,
  setMembers,
  setIsOpenAddBox,
}: Props) {
  const { showError } = useNotification();
  const dispatch = useDispatch();
  const history = useHistory();

  const subscription = useSelector(selectors.subscription);
  const user = useSelector(selectors.user);

  const [isConfirmAddQuantity, setIsConfirmAddQuantity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const memberAddon = memberAddonDetail(
    subscription?.product?.product?.addon,
    subscription?.product?.product?.price?.interval as string,
  );
  const { upgradeSubscription } = useUpgrade();

  const changePlanQuantity = async () => {
    if (!memberAddon?.prices?.chargebeePriceId) return;
    setIsLoading(true);
    const addon = [
      {
        itemPriceId: memberAddon?.prices?.chargebeePriceId,
        quantity: 1,
      },
    ];
    try {
      await upgradeSubscription({ addon }).then(async data => {
        dispatch(
          updateSubscription({
            ...subscription,
            product: {
              ...subscription.product,
              quantity: (subscription.product.quantity || 0) + 1,
            },
          }),
        );
        getCurrentMember();
      });
    } catch (error: any) {
      showError(error.message);
    } finally {
      setIsLoading(false);
      closeModal && closeModal();
    }
  };

  const getCurrentMember = () =>
    apiMembers.createMember({ email: email?.value as string }).then(res => {
      (setMembers || (() => {}))(prev => [...prev, res.data]);
      (setIsOpenAddBox || (() => {}))(false);
    });

  const goToPlans = () => {
    closeModal && closeModal();
    history.push(Routes.plan);
  };

  if (!subscription?.product.product || !subscription?.product.quantity)
    return (
      <Container isAddPlan={isAddPlanQuantity}>
        <InfoBlock>
          To invite your team on Namda and share your projects and stats with
          them, upgrade to a{' '}
          <AccentText pointer onClick={goToPlans}>
            Boutique or Bulge-Bracket Plan
          </AccentText>
        </InfoBlock>
      </Container>
    );

  return (
    <Container isAddPlan={isAddPlanQuantity}>
      {isLoading && <Loader />}
      <PlanName>
        Current plan:{' '}
        <AccentText> {subscription?.product.product.name} </AccentText>
      </PlanName>

      {isConfirmAddQuantity ? (
        <>
          <InfoBlock>
            Your subscription will increase by {user.currencySymbol}
            {(memberAddon?.prices?.unitAmount || 0) / 100} per {Interval.MONTH}
          </InfoBlock>
          <ButtonsBox>
            <CancelButton text="Cancel" onClick={closeModal} />
            <StyledButton text="Confirm" onClick={changePlanQuantity} />
          </ButtonsBox>
        </>
      ) : (
        <ButtonsBox>
          <StyledButton
            text="Add team member on plan"
            onClick={() => setIsConfirmAddQuantity(true)}
          />
        </ButtonsBox>
      )}
    </Container>
  );
}

const Container = styled.div<{ isAddPlan?: boolean }>`
  font-family: ${Fonts.Sofia};
  background-color: ${Colors.Transparent};
  position: ${props => (props.isAddPlan ? 'unset' : 'absolute')};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

const PlanName = styled.p`
  width: fit-content;
  margin: 10px auto;
  font-size: ${rem(14)};
  text-align: center;
  color: ${Colors.TextGrey};
`;

const AccentText = styled.strong<{ pointer?: boolean }>`
  font-size: ${rem(16)};
  font-weight: 700;
  ${props => props.pointer && 'cursor: pointer;'}
  color: ${Colors.LightBlue};
`;

const InfoBlock = styled.div`
  padding: 20px;
  color: ${Colors.TextGrey};
  font-size: ${rem(14)};
  text-align: center;
  position: relative;
`;

const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const CancelButton = styled(ButtonEllipse)`
  background-color: ${Colors.DarkRed};
  color: ${Colors.White};
`;

const StyledButton = styled(ButtonEllipse)`
  border: 1px solid ${Colors.White};
  background-color: ${Colors.LightBlue};
  color: ${Colors.White};
  width: 100%;
`;

export default ChangePlanQuantity;
