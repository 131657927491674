import { ChangeEvent } from 'react';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  placeholder?: string;
  className?: string;
  file: any | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  removeFile: () => void;
};

function ImageInput({
  placeholder,
  className,
  file,
  onChange,
  removeFile,
}: Props) {
  return (
    <Wrapper className={className}>
      <Label htmlFor="file-input">
        <NotUploadImage src={SVG.uploadImage} alt="upload input" />
        <span>{placeholder ?? 'Please, upload image'}</span>
        <StyledInput
          id="file-input"
          type="file"
          accept="image/png, image/jpeg"
          onChange={onChange}
          value=""
        />
      </Label>
      {file && (
        <FileContainer>
          <FileName>{file.name}</FileName>
          <RemoveButton onClick={removeFile}>remove</RemoveButton>
        </FileContainer>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: fit-content;
  font-family: ${Fonts.Sofia};
`;

const NotUploadImage = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 10px auto;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const FileName = styled.span`
  color: ${Colors.MiddleSecondGrey};
  font-size: ${rem(10)};
`;

const RemoveButton = styled.span`
  color: ${Colors.DarkRed};
  font-size: ${rem(9)};
  text-decoration: underline;
  cursor: pointer;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 5px 20px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid ${Colors.LightBlue};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(10)};
  color: ${Colors.DarkGrey};
  cursor: pointer;
`;

const StyledInput = styled.input`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
`;

export default ImageInput;
