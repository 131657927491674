/* eslint-disable no-nested-ternary */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { PriceInterval as Interval, ProductType } from '../../../../api';
import IMG from '../../../../assets/IMG';
import SVG from '../../../../assets/svg';
import { DefaultFreePlan } from '../../../../const';
import { selectors } from '../../../../store';
import { BoxShadow, Colors, rem } from '../../../../themes';
import PlanBody from './PlanBody';
import PlanStatus from './PlanStatus';

type Props = {
  chosen: boolean;
  onePlan?: ProductType;
  freePlan?: true;
  selectedPeriod?: string;
  membersCount: number;
  currencySymbol: string;
  mainPlan?: ProductType;
};

function OnePlan({
  chosen,
  onePlan,
  freePlan,
  selectedPeriod,
  membersCount,
  currencySymbol,
  mainPlan,
}: Props) {
  const [selectedProjects, setSelectedProjects] = useState('');
  const [selectedMembers, setSelectedMembers] = useState('');
  const maxAllowedMembers = useSelector(selectors.maxAllowedMembers);
  const defaultMembers = useSelector(selectors.defaultMembers);

  const monthPrice = onePlan?.prices.find(p => p.interval === Interval.MONTH);
  const yearPrice = onePlan?.prices.find(p => p.interval === Interval.YEAR);

  const monthCost = monthPrice ? monthPrice.unitAmount / 100 : 0;
  const yearCost = yearPrice ? yearPrice.unitAmount / 100 : 0;
  const isDisableUpgrade = useMemo(() => {
    if (selectedMembers && parseInt(selectedMembers, 10) < 0) {
      const remainingMembers =
        maxAllowedMembers - membersCount - defaultMembers;

      if (remainingMembers + parseInt(selectedMembers, 10) < 0) {
        return remainingMembers < 0 ? 0 : remainingMembers;
      }
      return null;
    }

    return null;
  }, [defaultMembers, maxAllowedMembers, membersCount, selectedMembers]);

  return (
    <Container chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
      <Plan chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
        <PricingPlanBg chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
          {freePlan ? (
            <img src={SVG.medal} alt="medal" width="36px" />
          ) : onePlan?.name === 'Bulge-Bracket' ? (
            <img src={SVG.diamond} alt="diamond" width="36px" />
          ) : (
            <img src={SVG.crown} alt="crown" width="36px" />
          )}
        </PricingPlanBg>

        <ProjectWrapper>
          <PlanName chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
            {freePlan ? DefaultFreePlan.name : onePlan?.name}
          </PlanName>

          {chosen && (
            <LabelWrapper>
              <img src={IMG.SelectedPlan} alt="Selected Plan" />
            </LabelWrapper>
          )}

          <PlanCost chosen={chosen} freePlan={freePlan} onePlan={onePlan}>
            {freePlan ? (
              <PlanCostWrapper>
                <Cost>
                  {' '}
                  {currencySymbol} {DefaultFreePlan.cost}
                </Cost>
              </PlanCostWrapper>
            ) : selectedPeriod === 'Monthly' ? (
              <PlanCostWrapper>
                <Cost>
                  {' '}
                  {currencySymbol} {monthCost} /
                </Cost>
                per {Interval.MONTH}
              </PlanCostWrapper>
            ) : (
              <PlanCostWrapper>
                <Cost>
                  {' '}
                  {currencySymbol} {yearCost} /
                </Cost>
                per {Interval.YEAR}
              </PlanCostWrapper>
            )}
          </PlanCost>
        </ProjectWrapper>
      </Plan>

      <PlanBody
        freePlan={freePlan}
        onePlan={onePlan}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        chosen={chosen}
        isDisableUpgrade={isDisableUpgrade}
        selectedPeriod={selectedPeriod}
        currencySymbol={currencySymbol}
        monthPlanCost={monthCost}
        yearPlanCost={yearCost}
      />

      <PlanStatus
        freePlan={freePlan}
        chosen={chosen}
        onePlan={onePlan}
        selectedMembers={selectedMembers}
        selectedProjects={selectedProjects}
        selectedPeriod={selectedPeriod === 'Monthly' ? 'month' : 'year'}
        isDisableUpgrade={isDisableUpgrade}
        setSelectedProjects={setSelectedProjects}
        setSelectedMembers={setSelectedMembers}
        currencySymbol={currencySymbol}
        mainPlan={mainPlan}
      />
    </Container>
  );
}

const Container = styled.div<{
  chosen: boolean;
  red?: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  width: 420px;
  height: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan) {
      return chosen ? '654px' : '589px';
    }
    return '547px';
  }};
  min-height: 470px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  border: 2px solid
    ${({ chosen }) => (chosen ? Colors.LightBlue : 'transparent')};
  background-color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.DarkBlue;
    }
    return Colors.White;
  }};
  border-radius: 12px;
  transition: 0.2s ease-in-out;
  box-shadow: ${BoxShadow.Secondary};

  &:hover {
    border: 2px solid ${Colors.LightBlue};
  }
`;

const PricingPlanBg = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return 'rgb(2 48 97 / 10%)';
  }}; ;
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: calc(100% - 100px);
  font-size: ${rem(10)};
`;

const Plan = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  height: 100px;

  display: flex;
  gap: 20px;
  align-items: center;

  font-size: ${rem(9)};
  border-bottom: 1px solid
    ${({ chosen, freePlan, onePlan }) => {
      if (!freePlan && onePlan && chosen) {
        return 'rgb(255 255 255 / 12%)';
      }
      return Colors.LightGrey;
    }}; ;
`;
const PlanName = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  font-size: ${rem(14)};
  text-align: center;
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlack;
  }};
`;

const PlanCost = styled.div<{
  chosen: boolean;
  freePlan?: true;
  onePlan?: ProductType;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  text-align: center;
  color: ${({ chosen, freePlan, onePlan }) => {
    if (!freePlan && onePlan && chosen) {
      return Colors.White;
    }
    return Colors.DarkBlack;
  }};
  font-weight: 300;
`;

const PlanCostWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Cost = styled.div`
  padding: 0px 5px;

  font-size: ${rem(12.5)};
`;

const LabelWrapper = styled.div`
  right: -11px;
  top: -11px;

  position: absolute;
`;

export default OnePlan;
