import { AxiosResponse } from 'axios';

import api from '../authApi';
import { UnlimitedList } from '../types';
import {
  CreateProjectBranchRequest,
  GetProjectBranchesList,
  ProjectBranchType,
  UpdateProjectBranchRequest,
} from './types';

const getAllProjectBranches = (): Promise<
  AxiosResponse<GetProjectBranchesList>
> => api.get(`/projects/project_branches/?limit=${UnlimitedList}`);

const getBranchById = (id: number): Promise<AxiosResponse<ProjectBranchType>> =>
  api.get(`/projects/project_branches/${id}/`);

const getBranchByProjectId = (
  id: number,
): Promise<AxiosResponse<GetProjectBranchesList>> =>
  api.get(`/projects/project_branches/?project=${id}&limit=${UnlimitedList}`);

const createProjectBranch = (
  body: CreateProjectBranchRequest,
): Promise<AxiosResponse<ProjectBranchType>> =>
  api.post('/projects/project_branches/', body);

const updateProjectBranch = (
  id: number,
  body: UpdateProjectBranchRequest,
): Promise<AxiosResponse<ProjectBranchType>> =>
  api.patch(`/projects/project_branches/${id}/`, body);

const projectBranches = {
  getAllProjectBranches,
  getBranchById,
  getBranchByProjectId,
  createProjectBranch,
  updateProjectBranch,
};

export default projectBranches;
