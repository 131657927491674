import { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import SVG from '../../../assets/svg';
import { Colors, Fonts, rem } from '../../../themes';

type Props = {
  email: string;
  projects: number;
  className?: string;
  canDeleteMember: boolean;
  deleteMember: () => void;
  isLoading: boolean;
};

function TeamMember({
  email,
  projects,
  className,
  canDeleteMember,
  deleteMember,
  isLoading,
}: Props) {
  const [anchorDelete, setAnchorDelete] = useState<HTMLElement | null>(null);

  const confirmDeleteEmailAlert = () => {
    Swal.fire({
      title: `Are you sure, you want to delete team member ${email}?`,
      iconHtml: `
      <img src="${SVG.deleteImg}" alt="delete" width="40px" />`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      showLoaderOnConfirm: isLoading,
      preConfirm: deleteMember,
      customClass: {
        confirmButton: 'confirm-button',
        icon: 'icon',
        title: 'title',
        container: 'container',
      },
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        setAnchorDelete(null);
      }
    });
  };
  return (
    <Editor className={className}>
      <EditorInfo>
        <span>{email}</span>
      </EditorInfo>
      <EditorWrapper>
        <EditorProjects>{projects} projects</EditorProjects>
        {canDeleteMember && (
          <ButtonBox onClick={confirmDeleteEmailAlert}>
            <img width="20px" src={SVG.deleteImg} alt="Delete" />
          </ButtonBox>
        )}
      </EditorWrapper>
    </Editor>
  );
}

const Editor = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: ${rem(11)};
  padding: 15px 0px;
  border-bottom: 1px solid ${Colors.WeakGrey};
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: ${Fonts.Sofia};
`;
const EditorInfo = styled(Text)`
  margin-right: 15px;
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
  width: 50%;
`;

const EditorProjects = styled(Text)`
  font-size: ${rem(10)};
  margin-top: 3.5px;
  color: ${Colors.LightSecondGrey};
`;

const EditorWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonBox = styled.div`
  cursor: pointer;
  display: flex;
`;

export default TeamMember;
