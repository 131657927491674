import { loadChargebee } from 'chargebee-js';

import { billing } from '../../../../api';
import { Addon, UpdateSubscription } from '../../../../api/billing';

interface IData {
  plan: string;
  price: number;
}
export interface ICheckout {
  data: IData;
  onClose: () => void;
  onError: (error: any) => void;
  onStep?: (currentStep: string) => void;
}

const useChargebee = () => {
  const chargebeePromise = () =>
    loadChargebee({
      site: process.env.REACT_APP_CHARGEBEE_SITE_NAME as string,
      isItemsModel: true,
    });

  return { chargebeePromise };
};

export const useSubscribe = () => {
  const { chargebeePromise } = useChargebee();
  const handleCheckout = async ({
    data,
    onClose,
    onError,
    onStep,
  }: ICheckout): Promise<void> => {
    const cbInstance = await chargebeePromise();
    if (!cbInstance) return;
    await cbInstance.openCheckout({
      layout: 'in_app',
      close: () => onClose(),
      step: (currentStep: string) => onStep && onStep(currentStep),
      hostedPage: async () => {
        const response = await billing.addSubscription(data);
        return {
          content: response.data,
          url: response.data.url,
        };
      },
      error: (error: any) => {
        onError(error.data);
        onClose();
      },
    });
  };

  return { handleCheckout };
};

export const projectAddonDetail = (
  data?: Addon[],
  interval: string = 'month',
) => {
  if (!data) return null;
  const projectAddon = data.find(d => d.name.toLowerCase().includes('project'));
  const project = {
    ...projectAddon,
    prices: projectAddon?.prices?.find(p => p.interval === interval),
  };
  return project;
};

export const memberAddonDetail = (
  data?: Addon[],
  interval: string = 'month',
) => {
  if (!data) return null;
  const memberAddon = data.find(d => d.name.toLowerCase().includes('member'));
  const member = {
    ...memberAddon,
    prices: memberAddon?.prices?.find(p => p.interval === interval),
  };
  return member;
};

export const useUpgrade = () => {
  const upgradeSubscription = async (data: UpdateSubscription) =>
    billing.updateSubscription(data).catch(({ data: error }: any) => {
      if (error.nonFieldErrors) {
        throw new Error(error.nonFieldErrors[0]);
      }
      if (error.detail) {
        throw new Error(error.detail);
      }
    });
  return { upgradeSubscription };
};
