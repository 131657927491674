import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    $root-font-size: 10px;

    html {
        font-size: ($root-font-size / 10px) * 100%;
    }

    body {
        margin: 0;
        padding: 0;
       
        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }
`;

export default GlobalStyles;
