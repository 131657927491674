import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { nda as apiNda } from '../../api';
import SolidButton from '../../atoms/SolidButton';
import { Errors } from '../../const';
import { initialValue } from '../../helpers';
import { useNotification, usePermissions } from '../../hooks';
import { Routes } from '../../routes';
import { PageWrapper } from '../../templates';
import { Colors, rem } from '../../themes';
import AddNda from '../projects/components/AddNda';
import { Nda } from '../projects/components/types';

type Params = {
  projectId: string;
};
function EditProjectNda() {
  const { showError } = useNotification();
  const { canChangeNda } = usePermissions();
  const { projectId } = useParams<Params>();
  const history = useHistory();

  const [nda, setNda] = useState<Nda>({
    file: null,
    ...initialValue,
    template: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (projectId) {
      apiNda.getNdaByProjectId(Number(projectId)).then(({ data }) => {
        if (!data.results[0]) {
          return;
        }
        const { name, template, id } = data.results[0];
        setNda({ ...nda, value: name, id });
      });
    }
  }, [projectId]);

  const editNda = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) return;
    if (!canChangeNda) return;
    if (!nda.value) {
      setNda({ ...nda, errorMsg: Errors.EnterNdaName });
      return;
    }
    if (nda.id) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('name', nda.value);
      if (nda.file) {
        formData.append('file', nda.file);
      }
      if (nda.template) {
        formData.append('template', nda?.template?.value);
      }
      apiNda
        .editNda(nda.id, formData)
        .then(({ data }) => {
          setNda({ ...nda, value: data.name });
          history.push(`${Routes.projects}/${projectId}`);
        })
        .catch(({ data }) => {
          if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
          if (data.detail) showError(data.detail);
        })
        .finally(() => setIsLoading(false));
    } else {
      addNda();
    }
  };

  const addNda = () => {
    const formData = new FormData();
    formData.append('project', `${projectId}`);
    formData.append('name', nda.value);
    if (nda.file) {
      formData.append('file', nda.file);
    } else {
      formData.append('file', '');
    }
    if (nda.template) {
      formData.append('template', nda?.template?.value);
    }

    setIsLoading(true);
    apiNda
      .createOneNda(formData)
      .then(res => history.push(`${Routes.projects}/${projectId}`))
      .catch(({ data }) => {
        if (data.nonFieldErrors) showError(data.nonFieldErrors[0]);
        if (data.detail) showError(data.detail);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PageWrapper>
      <StyledForm onSubmit={editNda}>
        <AddNda value={nda} onChange={setNda} isLoading={isLoading} />
        <ButtonContainer>
          <StyledButton
            type="button"
            text="Cancel"
            onClick={() => history.push(`${Routes.projects}/${projectId}`)}
          />
          <StyledSubmitButton
            isLoading={isLoading}
            type="submit"
            text={isLoading ? 'Save...' : 'Save'}
          />
        </ButtonContainer>
      </StyledForm>
    </PageWrapper>
  );
}

const StyledForm = styled.form`
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;
const StyledSubmitButton = styled(SolidButton)`
  background-color: ${Colors.DarkBlue};
  color: ${Colors.White};
  font-size: ${rem(11.25)};
`;

const StyledButton = styled(SolidButton)`
  background-color: ${Colors.PaleAquaBlue};
  color: ${Colors.Black};
  font-size: ${rem(11.25)};
`;

export default EditProjectNda;
