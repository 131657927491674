import styled from 'styled-components';

import IMG from '../../../assets/IMG';
import SVG from '../../../assets/svg';
import { Colors, Fonts, rem } from '../../../themes';

function Opportunity() {
  return (
    <Wrraper>
      <ImgSec src={IMG.Opportunities} alt="opportunity" />
      <ContentWrapper>
        <Title>
          Market your{' '}
          <span style={{ color: `${Colors.LightBlue}` }}>opportunities</span> to
          more potential buyers
        </Title>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            With Namda, you can upload your NDA templates, link them to a
            project & use that template to share it with many more parties. Each
            send will take you a few seconds.
          </Details>
        </CheckWrapper>
        <CheckWrapper>
          <Img src={SVG.circleCheck} alt="check" />
          <Details>
            Create your own templates library for your future projects & save
            even more time. Allow your counterparties to amend your NDA or not.
            Easily sign the NDA’s in the cloud. Your info memos are
            automatically sent by Namda once the NDA is agreed
          </Details>
        </CheckWrapper>
      </ContentWrapper>
    </Wrraper>
  );
}

const Wrraper = styled.div`
  display: flex;
  padding: 65px 50px 50px 50px;
  gap: 65px;
  @media (max-width: 768px) {
    display: block;
    gap: 0;
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 40%;
  }
`;

const ImgSec = styled.img`
  width: 100%;
`;

const ContentWrapper = styled.div``;

const Title = styled.p`
  color: ${Colors.DarkBlack};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(18.75)};
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const Details = styled.div`
  color: ${Colors.LightSecondGrey};
  font-family: ${Fonts.Sofia};
  font-size: ${rem(11.25)};
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
`;

const Img = styled.img`
  margin-top: 7px;
  margin-right: 12px;
`;
export default Opportunity;
