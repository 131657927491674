import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

const TextButton = styled.button`
  outline: none;
  border: none;
  background-color: ${Colors.Transparent};
  font-family: ${Fonts.Sofia};
  color: ${Colors.DarkGrey};
  font-size: ${rem(12)};
  transition: all 150ms linear;

  ${props =>
    props?.disabled
      ? 'cursor: not-allowed'
      : `
    cursor: pointer;
    &:hover {
    color: ${Colors.Black};
    }
    `}
`;
export default TextButton;
