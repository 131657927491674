import { ReactElement } from 'react';
import styled from 'styled-components';

import { Colors, Fonts, rem } from '../themes';

const Card = styled.fieldset`
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  border: 1px solid ${Colors.PaleAquaBlue};
  background: ${Colors.White};
  margin-bottom: 40px;
  > legend {
    color: ${Colors.DarkBlue};
    font-family: Sofia Pro;
    font-size: ${rem(12.5)};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: ${Fonts.Sofia};
  }
`;

type Props = {
  title: string;
  children: ReactElement;
};

function CardWrapper({ title, children }: Props) {
  return (
    <Card>
      <legend>{title}</legend>
      {children}
    </Card>
  );
}

export default CardWrapper;
