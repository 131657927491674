import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import SVG from '../assets/svg';
import { Loader } from '../molecules';
import { Routes } from '../routes';
import { selectors } from '../store';
import { Colors, Fonts, rem } from '../themes';

type Props = {
  children: ReactElement;
  isLoading: boolean;
};

function AuthWrapper({ children, isLoading }: Props) {
  const isAuth = useSelector(selectors.isAuthenticated);

  return (
    <Wrapper>
      <SignUpWrapper>
        <>{isLoading && <Loader />}</>
        <ContentWrapper>
          <LogoLink to={isAuth ? Routes.projects : Routes.main}>namda</LogoLink>
          {children}
        </ContentWrapper>
      </SignUpWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.BackgroundGrey};
  padding: 10px 15px;
  padding-top: 50px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoLink = styled(NavLink)`
    color: ${Colors.LightBlue};
    text-align: center;
    font-family: ${Fonts.Sofia};
    font-size: ${rem(18.75)};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: absolute;
    left: 40px;
    top: -25px;
    text-decoration: none;
    @media (max-width: 426px) {
      left: 20px;
    }
}`;

const SignUpWrapper = styled.div`
  position: relative;
  background-color: ${Colors.SkyBlue};
  border-radius: 0 50px 50px 50px;
  padding: 30px 40px;
  max-width: 620px;
  width: 100%;
  &::before {
    background-image: url(${SVG.signUp});
    width: 100%;
    content: '';
    height: 50px;
    position: absolute;
    top: -38px;
    background-repeat: no-repeat;
    left: -1px;
  }
  @media (max-width: 426px) {
    padding: 15px 20px;
  }
`;

export default AuthWrapper;
